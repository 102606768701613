import jtl from "tools/jtl";
import { CssStyle } from "app/arch/editor-instruction/css-styles";
import { Position } from "app/arch/types";


export interface ShapeCalculatorProps {
  style: CssStyle;
  startPoint: Position;
  endPoint: Position;
}

export class ShapeCalcBase {
  protected _style: CssStyle;
  protected _startPoint: Position;
  protected _endPoint: Position;

  constructor(props: ShapeCalculatorProps) {
    this._style = props.style;
    this._startPoint = props.startPoint;
    this._endPoint   = props.endPoint;
  }

  get arrowHeadWidth() { 
    return jtl.css.valueToNumber(this._style.arrowHeadWidth);
  }

  get arrowHeadHeight() { 
    return jtl.css.valueToNumber(this._style.arrowHeadHeight);
  }

  get arrowHeadIdentX() { 
    return jtl.css.valueToNumber(this._style.arrowHeadIdentX);
  }

  get arrowHeadIdentY() { 
    return jtl.css.valueToNumber(this._style.arrowHeadIdentY);
  }

  get arrowShaftWidth() { 
    return jtl.css.valueToNumber(this._style.arrowShaftWidth);
  }

  get borderWidth() { 
    return jtl.css.valueToNumber(this._style.borderWidth);
  }

  get outlineWidth() { 
    return jtl.css.valueToNumber(this._style.outlineWidth);
  }

  get arrowAngle() { 
    return jtl.geometry.calculateAngle(...this._startPoint, ...this._endPoint);
  }
};