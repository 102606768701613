
import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig   } from 'lego-v2/menu/config';

import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';

import { useDocState }     from "app/ui/contexts/document";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import { Icon }            from "app/ui/icons/icons";
import useContextMenu      from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useMarkerEdit       from 'app/ui-v2/editor-markers/hooks/use-marker-edit';
import useMarkerSelectedCheck from 'app/ui-v2/editor-instruction/hooks/use-marker-selected-check';

import useEditorStatesSetters from "../../../hooks/use-editor-states-setters";



const useDocMarkerMenu = () => {
  const document = useDocState();
  const t = useTranslations();

  const {
    hideMenu
  } = useContextMenu();

  const {
    setContent,
    setRepoMarkers,
    setHeaderMarkers,
  } = useEditorStatesSetters();

  const editMarker__ = useMarkerEdit();
  const checkSelectedMarker = useMarkerSelectedCheck();

  const createMarker = () => {
    const markerAddr = document.repoMarkers.addMarker();
    document.saveUndo();
    setRepoMarkers();

    editMarker__(markerAddr);
    hideMenu();
  }

  const editMarker = (markerAddr: RepoMarkersTypes.MarkerAddr) => {
    editMarker__(markerAddr);
    hideMenu();
  }

  const deleteMarker = (markerAddr: RepoMarkersTypes.MarkerAddr) => {
    document.docMarker_delete(markerAddr);
    document.saveUndo();

    setContent();
    setRepoMarkers();
    setHeaderMarkers();

    checkSelectedMarker();

    hideMenu();
  }


  const getConfig = (markerAddr: RepoMarkersTypes.MarkerAddr) => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('marker, new'),
                iconComponent: Icon.Create,
                onClick: (event: React.MouseEvent) => createMarker(),
              }
            },
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('marker, edit'),
                iconComponent: Icon.Edit,
                onClick: (event: React.MouseEvent) => editMarker(markerAddr),
              }
            },                   
          ],
        },        
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('marker, delete'),
                iconComponent: Icon.Remove,
                variant: 'delete',
                onClick: (event: React.MouseEvent) => deleteMarker(markerAddr),
              }
            },          
          ],
        },
      ]
    }
  
    return config;
  }

  
  const hook = (markerAddr: RepoMarkersTypes.MarkerAddr) => {
    const config = getConfig(markerAddr);
    return config;
  }

  return hook;
}


export default useDocMarkerMenu;