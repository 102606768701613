import jtl from 'tools/jtl';

import { CssStyle } from 'app/arch/editor-instruction/css-styles';

import { MainWrapper } from './styles';
import { StyledBox   } from './styles';


const WIDTH_INIT  = 75;
const HEIGHT_INIT = 50;

interface Props {
  style: CssStyle
  width?: number,
  height?: number,
}


export const WidgetPreviewComponent: React.FC<Props> = (props: Props) => {
  const {
    style
  } = props;

  const borderWidth  = jtl.css.valueToNumber(style.borderWidth, 0);
  const outlineWidth = jtl.css.valueToNumber(style.outlineWidth, 0);

  const widthInit  = props.width !== undefined ? props.width : WIDTH_INIT;
  const heightInit = props.height !== undefined ? props.height : HEIGHT_INIT;

  const width  = widthInit  - 2 * (borderWidth + outlineWidth);
  const height = heightInit - 2 * (borderWidth + outlineWidth);

  return (
    <MainWrapper
      style={{
        padding: `${outlineWidth}px`,
      }}
    >
      <StyledBox
        style={{
          ...style,
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
      </StyledBox>
    </MainWrapper>
  );
}
