import { useRef } from "react";

import useRescaleAtPoint from "lego-hooks/rescale/use-rescale-at-point";
import { ScrollProps } from "lego-hooks/rescale/use-rescale-scrollbar-update";

import { Position } from "app/arch/types";
import useDocumentScaleSet from "app/ui-v2/editor-instruction/__document/hooks/use-document-scale-set";
import { useDebouncedCallback } from 'use-debounce';


interface Props {
  deskScroller: HTMLElement | null,
}


const useDeskRescale = ({
  deskScroller
}: Props) => {

  const setScale = useDocumentScaleSet();
  const rescaleAtPoint = useRescaleAtPoint();

  const scrollbarPropsRef = useRef<ScrollProps | null>({
    scrollLeft: 0,
    scrollTop: 0
  });

  // This must be done on render,
  // not after render as it would be if useEffect 
  // is used. If scrollbar is set after render
  // it creates flicker. 
  const scrollbarProps = scrollbarPropsRef.current;
  if ( scrollbarProps && deskScroller ) {
    deskScroller.scrollLeft = scrollbarProps.scrollLeft;
    deskScroller.scrollTop = scrollbarProps.scrollTop;
    scrollbarPropsRef.current = null;
  }

  const __rescale = (scaleInit: number, scaleNew: number) => {
    const bbox = deskScroller!.getBoundingClientRect();

    const scalePoint = [
      bbox.width / 2 + bbox.left,
      bbox.height / 2 + bbox.top
    ] as Position;

    setScale(scaleNew);

    const scrollbarNew = rescaleAtPoint({
      scaleInit,
      scaleNew,
      scalePoint,
      scrollbarInit: deskScroller!
    });

    scrollbarPropsRef.current = scrollbarNew;
  }

  const __rescaleDeb = useDebouncedCallback(
    (scaleInit: number, scaleNew: number) => {
      __rescale(scaleInit, scaleNew);
    }, 5);

  const rescaleDesk = (scaleInit: number, scaleNew: number) => {
    if (! deskScroller) {
      return;
    }
    __rescale(scaleInit, scaleNew);
    // __rescaleDeb(scaleInit, scaleNew);
  }

  return rescaleDesk;
}

export default useDeskRescale;