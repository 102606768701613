import Logger from "libs/debug";

import { Size } from "app/arch/types";

import * as TypesShared from "../../states/types";
import { ContentElementsSizes }        from "../../states/slicing/content";
import Content, { ContentTypes as ContentTypes } from "../../states/persistent/content";

import * as Types    from "./types";
import * as Defaults from "./defaults";


export const slice = (props: {
  isPrintout: boolean,

  pageSize: Size,

  docInfoVisible: TypesShared.PageItemVisibility,
  docCustomFieldsVisible: TypesShared.PageItemVisibility,
  docMarkersVisible: TypesShared.PageItemVisibility,

  docContent: Content,
  elementsSizes: ContentElementsSizes,

}) => {

  const {
    isPrintout,
    pageSize,

    docInfoVisible,
    docCustomFieldsVisible,
    docMarkersVisible,

    docContent,
    elementsSizes,
  } = props;

  const sectionsConfig = docContent.getSectionsConfig();
  const sectionsEnabled = sectionsConfig.enabled;

  const log = Logger.getContentSlicer();

  log.debug("[ContentSlicer] elements sizes for slicer");
  log.debug(elementsSizes);

  /***************
   *   Vars      *
   ***************/
  let page: Types.Page = Defaults.getPage();
  let pages: Types.Pages = [];
  let pageHeightAvailable = pageSize[1];
  let pageItem_Content = Defaults.getPageItem_Content();
  let contentItem_Section = Defaults.getContentItem_Section({sectionId: ""});


  /***************
   *   Helpers   *
   ***************
    *    Start    *
    ***************/

  const __addPageRaw = () => {
    page = Defaults.getPage();
    pages.push(page);
    pageHeightAvailable = pageSize[1];
  }

  const __addDocInfo_conditionally = () => {
    const visible = (
        docInfoVisible === TypesShared.PageItemVisibility.ALL ||
      ( docInfoVisible === TypesShared.PageItemVisibility.FIRST_PAGE && pages.length === 1 )
    );

    if ( ! visible ) {
      return;
    }

    const pageItem_DocInfo = Defaults.getPageItem_DocInfo();
    page.items.push(pageItem_DocInfo);

    const docInfoHeight = elementsSizes.pageHeader.getDocInfoHeight();
    pageHeightAvailable -= docInfoHeight;
  }
  
  const __addDocCustomFields_conditionally = () => {
    const visible = (
        docCustomFieldsVisible === TypesShared.PageItemVisibility.ALL ||
      ( docCustomFieldsVisible === TypesShared.PageItemVisibility.FIRST_PAGE && pages.length === 1 )
    );

    if ( ! visible ) {
      return;
    }
    
    const pageItem_DocCustomFields = Defaults.getPageItem_DocCustomFields();
    page.items.push(pageItem_DocCustomFields);

    const docCustomFieldsHeight = elementsSizes.pageHeader.getDocCustomFieldsHeight();
    pageHeightAvailable -= docCustomFieldsHeight;
  }

  const __addDocMarkers_conditionally = () => {
    const visible = (
        docMarkersVisible === TypesShared.PageItemVisibility.ALL ||
      ( docMarkersVisible === TypesShared.PageItemVisibility.FIRST_PAGE && pages.length === 1 )
    );

    if ( ! visible ) {
      return;
    }
    const pageItem_DocMarkers = Defaults.getPageItem_DocMarkers();
    page.items.push(pageItem_DocMarkers);

    const docMarkersHeight = elementsSizes.pageHeader.getDocMarkersHeight();
    pageHeightAvailable -= docMarkersHeight;
  }

  const __addContent = () => {
    pageItem_Content = Defaults.getPageItem_Content();
    page.items.push(pageItem_Content);
  }

  const __addContent_headerRow = () => {
    const pageItem_headerRow = Defaults.getContentItem_HeaderRow();
    pageItem_Content.items.push(pageItem_headerRow);

    const headerRowHeight = elementsSizes.table.getHeaderRowHeight();
    pageHeightAvailable -= headerRowHeight;   
  }

  const __addContent_sectionAdder = () => {
    const contentItem_SectionAdder = Defaults.getContentItem_SectionAdder();
    pageItem_Content.items.push(contentItem_SectionAdder);
  }

  const __addContent_section = (sectionAddr: ContentTypes.SectionAddr) => {
    contentItem_Section = Defaults.getContentItem_Section(sectionAddr);
    pageItem_Content.items.push(contentItem_Section);
  }

  const __addSection_sectionName = (sectionAddr: ContentTypes.SectionAddr) => {
    const sectionItem_SectionName = Defaults.getSectionItem_SectionName();
    contentItem_Section.items.push(sectionItem_SectionName);

    const sectionNameHeight = elementsSizes.sections.getSectionNameHeight(sectionAddr);
    pageHeightAvailable -= sectionNameHeight; 
  }

  const __addSection_row = (rowAddr: ContentTypes.RowAddr) => {   
    const sectionItem_row = Defaults.getSectionItem_CellsRow({rowId: rowAddr.rowId});
    contentItem_Section.items.push(sectionItem_row);

    const rowHeight = elementsSizes.sections.getSectionRowHeight(rowAddr);
    pageHeightAvailable -= rowHeight;
  }

  const __addPage = () => {
    __addPageRaw();
    __addDocInfo_conditionally();
    __addDocCustomFields_conditionally();
    __addDocMarkers_conditionally();
    __addContent();
    __addContent_headerRow();
  }

  /***************
   *   Helpers   *
   ***************
   *     End     *
   ***************/
  

  __addPage();

  /**********************
   **********************
   **                  **
   **   Content        **
   **                  **
   **********************
   **********************/

  const sectionsAddrs = docContent.getSectionsAddrs();

  sectionsAddrs.forEach((sectionAddr) => {
    
    const rowsAddrs = (function() {
      const rowsAddrsAll = docContent.getRowsAddrs(sectionAddr);

      if ( ! isPrintout ) {
        return rowsAddrsAll;
      }

      // It is printout

      if ( ! docContent.isSectionLast(sectionAddr)) {
        return rowsAddrsAll;
      }

      // It is printout 
      // and it is last section
      const rowsAddrsButLast = rowsAddrsAll.slice(0, -1);
      return rowsAddrsButLast
    }());

     

    const firstRowHeight = (function() {
      if (rowsAddrs.length === 0) {
        return 0;
      }
      const firstRowAddr = rowsAddrs[0];
      const firstRowHeight = elementsSizes.sections.getSectionRowHeight(firstRowAddr);
      return firstRowHeight;
    }());

    const sectionNameHeight = (
      sectionsEnabled ?
      elementsSizes.sections.getSectionNameHeight(sectionAddr) :
      0
    );
    const heightNeeded = (sectionNameHeight + firstRowHeight);

    if (heightNeeded > pageHeightAvailable) {
      __addPage();
    }

    __addContent_section(sectionAddr);

    if (sectionsEnabled) {
      __addSection_sectionName(sectionAddr);
    }

    rowsAddrs.forEach((rowAddr) => {
      const rowHeight = elementsSizes.sections.getSectionRowHeight(rowAddr);

      if (rowHeight > pageHeightAvailable) {
        __addPage();
        __addContent_section(sectionAddr);
      }

      __addSection_row(rowAddr);
    });
  }); // Sections for loop end


  if (sectionsEnabled && ! isPrintout) {
    const sectionAdderHeight = elementsSizes.table.getSectionAdderHeight();
    if (sectionAdderHeight > pageHeightAvailable) {
      __addPage();
    }
    __addContent_sectionAdder();
  }

  log.debug("[ContentSlicer] Content slicer, pages defs:");
  log.debug(pages as Types.Pages);

  return pages;
}
