import styled from "styled-components";


const zIndex = {
  progress: 200,
  toast: 210,
  stripe: 190,
  demo: 220,
}


export const ModalsWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  z-index: 500000;
  pointer-events: none;
`;

export const ProgressWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  z-index: ${zIndex.progress};
`;

export const InfoWrapper = styled.div`
  position: absolute;
  right: 2px;
  bottom: 2px;

  z-index: ${zIndex.toast};
`;

export const StripeWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.stripe};
`;

export const DemoWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;

  z-index: ${zIndex.demo};
`;
