import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useSelectedReset = () => {
  const document = useDocState();
  const {
    setHeaderFieldsSession
  } = useEditorStatesSetters();

  const hook = (props?: {
    skipStateUpdate?: boolean
  }) => {
    const skipStateUpdate = props?.skipStateUpdate ?? false;

    document.headerFieldsSession.setPageColumnSelected(null);
    document.headerFieldsSession.setPageCellSelected(null);

    if (skipStateUpdate) {
      return;
    }

    setHeaderFieldsSession();
  }

  return hook;
}


export default useSelectedReset;
