import { useEffect } from 'react';
import useKeyBindingsBase     from 'lego/components/key-bindings/hooks/use-key-bindings';
import { KeyBindingPriority } from 'lego/components/key-bindings/arch/types';

import { UUID } from 'app/arch/types';
import useFunctions from './__use-functions';


const useKeyBindings = () => {
  const fns = useFunctions();

  const {
    addKeyDownBinding,
    removeKeyDownBinding,
  } = useKeyBindingsBase();

  useEffect(() => {
    const bindingIds: UUID[] = [];

    let tmpId: UUID ='';

    tmpId = addKeyDownBinding({
      check: (event: any) => event.key === 'Escape',
      callback: fns.resetSelectedWidgets,
      priority: KeyBindingPriority.EDITOR_IMAGE
    });
    bindingIds.push(tmpId);


    tmpId = addKeyDownBinding({
      check: (event: any) => event.key === 'Delete',
      callback: fns.deleteSelectedWidgets,
      priority: KeyBindingPriority.EDITOR_IMAGE
    });
    bindingIds.push(tmpId);

    return () => {
      bindingIds.forEach(id => removeKeyDownBinding(id));
    };
  }, []);
}

export default useKeyBindings;