import styled from "styled-components";
import ExpandLessIconBase from '@mui/icons-material/ExpandLess';
import ExpandMoreIconBase from '@mui/icons-material/ExpandMore';
import ButtonBase from "lego/styles/button";
import InputBase  from "lego/styles/input";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 56px;
  height: 100%;
`;


export const InputWrapper = styled.div`
  width: 100%;
`;


export const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;

`;


const SIZE  = 14;


const getIconStyle = (props: any) => {
  return `
    max-width:  ${SIZE}px;
    max-height: ${SIZE}px;
  `;
}

export const Button = styled(ButtonBase)`
  max-width:  ${SIZE}px;
  min-width:  ${SIZE}px;
  max-height: ${SIZE}px;
  min-height: ${SIZE}px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-style: solid;
  border-color: ${props => props.theme.defs.colors.border.primary};
  border-width: ${props => props.theme.defs.border.width.normal};
`;

export const ButtonIncrease = styled(Button)`
  border-radius: 
    0
    ${props => props.theme.defs.border.radius.xsmall}
    0
    0
  ;
    border-bottom-width: 0px;
    border-bottom-style: none;

`;

export const ButtonDecrease = styled(Button)`
  border-radius: 
    0
    0 
    ${props => props.theme.defs.border.radius.xsmall}
    0 
  ;

  border-top-width: 0px;
  border-top-style: none;
`;

export const ExpandLessIcon = styled(ExpandLessIconBase)`
  ${props => getIconStyle(props)};
`;

export const ExpandMoreIcon = styled(ExpandMoreIconBase)`
  ${props => getIconStyle(props)};
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  min-height: 1px;
  max-height: 1px;

  background: ${props => props.theme.defs.colors.border.primary};
`;


export const Input = styled(InputBase)`
  width: 100%;
  height: 29px;

  border-radius: 
    ${props => props.theme.defs.border.radius.xsmall}
    0
    0 
    ${props => props.theme.defs.border.radius.xsmall}
  ;
  font-size:     ${props => props.theme.defs.font.size.normal};
  padding-left:  ${props => props.theme.defs.padding.normal};
  border-right-style: none;

  &:focus-visible {
    border-radius: ${props => props.theme.defs.border.radius.xsmall};
  }
`;