import styled from "styled-components";
import { StyledBtnIconBase } from "lego-v2/btn-icon";


export const StyledDropdownBtnBase = styled(StyledBtnIconBase)`
  position: relative;
  top: -2px;

  .cnt-icon-text {
    display: flex;
    gap: 0px;
    align-items: center;
    /* background: rgba(255, 255,0, 0.3); */
  }

  .text {
    font-size: 10px;
    height: 11px;
    margin-left: 4px;
    overflow: clip;
    text-overflow: ellipsis;
    text-align: left; 
    width: 50px;
    max-width: 60px;
  }

  .icon {
    width: 11px;
    height: 11px;
    margin-left: 2px;
    margin-right: 4px;
    /* position: relative; */
    /* top: 0px; */
  }

  .chevron-down {
    width: 8px;
    height: 8px;
    user-select: none;
    margin-left: 5px;
    margin-right: 2px;
    margin-top: 2px;
    background-color: transparent;
    background-size: contain;
    background-image: url(/images/editor-text/toolbar/icons/chevron-down.svg);
  }
`;
