
import { gql } from '@apollo/client';

export namespace query {

  export const getUserSettings = () => {
    return (
      gql`
        query {
          userSettings {
            settings
          }
          user {
            email
          }
        }
      `
    );
  }


  export const getUserLogin = () => {
    return (
      gql`
        query {
          userLogin
        }
      `
    );
  }

  export const getInstructions = () => gql`
    query GetInstructions($repoId: Int!) {
      instructions(repoId: $repoId) {
        id
        title
        dateUpdated
      }
    }
  `;

  export const getInstruction = () => gql`
    query GetInstruction($instructionId: Int!) {
      instruction(id: $instructionId) {
        id
        title
        dateUpdated
        description
      }
    }
  `;

  // export const getInstruction = gql`
  //   query instruction($id: Int!) {
  //     instruction(id: $id) {
  //       instruction {
  //         id
  //         title
  //         description
  //       }
  //     }
  //   }
  // `;


  export const getInstructionReleases = () => gql`
    query GetInstructionReleases($instructionId: Int!) {
      instructionReleases(instructionId: $instructionId) {
        instruction {
          id
          title
          description
        }
        releases {
          id
          revision
          dateCreated
        }
      }
    }
  `;


  export const getInstruction2 = (id: number) => {
    return (
      gql`
        {
          instruction(id: ${id}) {
            id
            content
          }
        }
      `
    );
  }


  export const getDocTemplates = () => gql`
    query GetDocTemplates($reposIds: [Int!]!) {
      docTemplates(reposIds: $reposIds) {
        repo {
          id
        }
        content
      }
    }`;


  export const getWidgetsStyles = (instructionId: number) => {
    return (
      gql`
        {
          widgetsStyles(instructionId: ${instructionId}) {
            id
            name
            style
          }
        }
      `
    );
  }


  export const getPDFGenerateLock = () => {
    return (
      gql`
        {
          pdfGenerateLock {
            locked
            timeout
          }
        }
      `
    );
  }


  export const getInstructionPack = (id: number) => {
    return (
      gql`
        {
          instructionPack(id: ${id}) {
            instruction {
              id
              revision
              content
            }
            instructionDraft {
              content
              releaseInfo
            }
            images {
              id
              filename
              width
              height
            }
            widgetsStyles {
              id
              name
              style
            }
            widgetsStylesPredefined {
              id
              name
              style
            }
          }
        }
      `
    );
  }


  export const getPrices = () => {
    return (
      gql` {
        prices {
          active
          lookupKey
          currency
          amount
        }
      }`
    );
  }

  export const getSubscriptions = () => {
    return (
      gql`
        {
          subscriptions {
            subscriptionId
            status
            currency
            daysUntilDue
            payPeriod
            price
            priceLookupKey
            product
            cancelAtPeriodEnd
            currentPeriodStart
            currentPeriodEnd
            collectionMethod
            cancelAt
            canceledAt
            startDate
            endedAt
            testMode
          }
        }
      `
    );
  }


  export const getSubscription = () => {
    return (
      gql`
        query GetSubscription($subscriptionId: String!) {
          subscription(subscriptionId: $subscriptionId) {
            subscriptionId
            status
            currency
            daysUntilDue
            payPeriod
            price
            priceLookupKey
            product
            cancelAtPeriodEnd
            currentPeriodStart
            currentPeriodEnd
            collectionMethod
            cancelAt
            canceledAt
            startDate
            endedAt
            testMode
          }
        }
      `
    );
  }


  export const getInvoices = () => gql`
    {
      invoices {
        invoiceId
        created
        paid
        paidAt
        paidAmount
        status
        urlHosted
        urlPdf
        periodStart
        periodEnd
        subscriptionPeriodStart
        subscriptionPeriodEnd
        subscription {
          subscriptionId
        }
      }
    }
  `;


  export const getRepoMembers = () => gql` 
    query getRepo($repoId: Int!) {
      repo(repoId: $repoId) {
        repo {
          id
          name
        }
        members {
          access
          status
          username
        }
      }
    }
  `;


  export const getRepos = () => gql` 
    {
      repos {
        repo {
          id
          name
        }
        members {
          access
          status
          username
        }
      }
    }`;

} // namespace query

