import React from 'react';
import { IconContext } from 'react-icons';

import Icons from 'app/ui/icons/icons';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import { useGuestRun } from './use-guest-run';

import { Button }   from './styles';
import { IconWrap } from './styles';
import { Text }     from './styles';


interface Props {
}


export const BtnGuestRunComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const guestRun = useGuestRun();

  const handleRunAsGuest = () => {
    guestRun.run();
  }

  const IconDemo = Icons.Demo;

  return (
    <Button
      data-test='app-run-demo'
      onClick={handleRunAsGuest}
    >
      <IconWrap>
        <IconContext.Provider value={{ size: '26px' }} >
          <IconDemo />
        </IconContext.Provider>   
      </IconWrap>

      <Text>
        { t('run app as guest') }
      </Text>

    </Button>
  )
}
