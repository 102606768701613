import Document from "./document";
import CrashWatcher from "./crash-watcher";
import { LexicalRegistryChangelog } from "./lexical-registry";
import { LexicalRegistryContent2 }  from "./lexical-registry";
import { LexicalRegistryContent2Widgets } from "./lexical-registry";
import { LexicalRegistryContent2CustomFields } from "./lexical-registry/lexical-registry-content2-custom-fields";


export class EditorInstruction {
  private _recoveryMode: boolean;
  private _document: Document;
  private _crashWatcher: CrashWatcher;

  private _lexicalRegistryContent2: LexicalRegistryContent2;
  private _lexicalRegistryChangelog: LexicalRegistryChangelog;
  private _lexicalRegistryContent2Widgets: LexicalRegistryContent2Widgets;
  private _lexicalRegistryContent2CustomFields: LexicalRegistryContent2CustomFields

  constructor(docId: number) {
    this._recoveryMode = false;

    this._document = new Document(docId);
    this._crashWatcher = new CrashWatcher();

    this._lexicalRegistryChangelog       = new LexicalRegistryChangelog();
    this._lexicalRegistryContent2        = new LexicalRegistryContent2();
    this._lexicalRegistryContent2Widgets = new LexicalRegistryContent2Widgets();
    this._lexicalRegistryContent2CustomFields = new LexicalRegistryContent2CustomFields();
  }

  get document()     { return this._document;  }
  get crashWatcher() { return this._crashWatcher; }
  
  get lexicalRegistrChangelog()       { return this._lexicalRegistryChangelog; }
  get lexicalRegistrContent2()        { return this._lexicalRegistryContent2; }
  get lexicalRegistrContent2Widgets() { return this._lexicalRegistryContent2Widgets; }
  get lexicalRegistryContent2CustomFields() { return this._lexicalRegistryContent2CustomFields; }

  set recoveryMode(recoveryMode: boolean) { this._recoveryMode = recoveryMode };
  get recoveryMode() { return this._recoveryMode; }
};
