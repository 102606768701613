import { useState } from 'react';

import DraggerComponent  from 'lego-v2/dragger/ui';
import { DraggerUpdate } from 'lego-v2/dragger/arch';
import TooltipComponent  from 'lego/components/tooltip';

import { settings } from 'app/configs';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useDocumentScaleRef from 'app/ui-v2/editor-instruction/__document/hooks/use-document-scale-ref';

import { MainWrapper }  from './styles';
import { IconResize }   from './styles';
import { MarkerStyled } from './styles';


interface Props {
  disabled: boolean,

  onUpdateResizeStart?: (event: any) => void;
  onUpdateResize?: (update: DraggerUpdate) => void;
  onUpdateResizeDone?: (update: DraggerUpdate) => void;
}


export const ResizerWidgetComponent: React.FC<Props> = (props: Props) => {
  const {
    disabled,

    onUpdateResizeStart, 
    onUpdateResize,
    onUpdateResizeDone,

  } = props;

  const t = useTranslations();

  // This works as it gets refresh on 'handleUpdateResizeStart'
  // However it would be better just to pass to dragger scale=1
  // and then use scale in calculation in parent resizer.
  // Leaving it for now.
  const scaleRef = useDocumentScaleRef();

  const key = settings.actionKey.imageResizeModeToggle;
  const tooltipPre = t('hold <<KEY>> to modify image size');
  const tooltip = tooltipPre.replace('<<KEY>>', key.toUpperCase());
  
  const [isDragging, setIsDragging] = useState(false);

  const handleUpdateResizeStart = (event: any) => {
    event.stopPropagation();
    setIsDragging(true);
    onUpdateResizeStart?.(event);
  }   

  const handleUpdateResize = (draggerUpdate: DraggerUpdate) => {
    onUpdateResize?.(draggerUpdate);
  }

  const handleUpdateResizeDone = (draggerUpdate: DraggerUpdate) => {
    onUpdateResizeDone?.(draggerUpdate);
    setIsDragging(false);
  }

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  }
  
  return (
    <MainWrapper
      onClick={stopPropagation}
      // onPointerDown={stopPropagation}

      onTouchStart={stopPropagation}
      onTouchMove={stopPropagation}
      onTouchEnd={stopPropagation}
    >
      <DraggerComponent
        position={[0, 0]}
        onUpdateStart={handleUpdateResizeStart}
        onUpdate={handleUpdateResize}
        onUpdateDone={handleUpdateResizeDone}
        scale={scaleRef.current !}
      >
        {/* <TooltipComponent
          title={tooltip}
        > */}
        <MarkerStyled $visible={! disabled}>
          <IconResize />
        </MarkerStyled>
      </DraggerComponent>


    </MainWrapper>
  );
}
