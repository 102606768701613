
/**
 * State
 */
export type State = {
  title: string;
  description: string;
};


/**
 * Getters
 */
export const getTitle       = (state: State) => state.title;
export const getDescription = (state: State) => state.description;


/**
 * Initial State
 */
export const createInitialState = (): State => {
  const initState: State = {
    title: '',
    description: '',
  }

  return initState;
}
