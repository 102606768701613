import React from 'react';
import DebugPointComponent from '../debug-point';
import { MainWrapper } from './styles';


interface Props {
}


export const DebugModalComponent: React.FC<Props> = (props: Props) => {
  return (
    <MainWrapper>
      <DebugPointComponent />
    </MainWrapper>
  );
}
