import styled from "styled-components";

import ListItemBase from 'lego/styles/list-item';
import { MdDelete } from 'react-icons/md';
import { MdEdit }   from "react-icons/md";


export const ListItem = styled(ListItemBase)`
  gap: ${props => props.theme.defs.gap.normal};

  padding-left:  ${props => props.theme.menuItem.padding};
  padding-right: ${props => props.theme.menuItem.padding};
`;

export const BtnsWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

export const BtnWrapper = styled.div`
`;

export const Revision = styled.div`
  flex: 0 0 80px;
  /* background: red; */
`;

export const ReleaseDate = styled.div`
  flex: 1 1 0px;
  min-width: 0px;
  /* background: red; */
`;


const getIconProps = (props: any) => {
  return `
    &:hover {
      scale: 1.8;
    }

    transform-origin: 50% 50%;

    transition: 
      scale 
      ${props.theme.defs.transition.time.normal} 
      ease-in-out;
  `;
}

export const IconDelete = styled(MdDelete)`
  ${ props => getIconProps(props) };
`;

export const IconEdit = styled(MdEdit)`
  ${ props => getIconProps(props) };
`;
