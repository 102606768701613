import { IconAddr }        from "./types";
import { IconDescriptor } from "./types";

import * as AntDesignIcons    from "react-icons/ai";
import * as BootstrapIcons    from "react-icons/bs";
import * as BoxIcons          from "react-icons/bi";
import * as CircumIcons       from "react-icons/ci";
import * as CssGgIcons        from "react-icons/cg";
import * as Devicons          from "react-icons/di";
import * as FeatherIcons      from "react-icons/fi";
import * as FlatColorIcons    from "react-icons/fc";
import * as FontAwesome5      from "react-icons/fa";
import * as FontAwesome6      from "react-icons/fa6";
import * as GameIcons         from "react-icons/gi";
import * as GithubOcticons    from "react-icons/go";
import * as GrommetIcons      from "react-icons/gr";
import * as Heroicons         from "react-icons/hi";
import * as Heroicons2        from "react-icons/hi2";
import * as IcoMoonFree       from "react-icons/im";
import * as Icons8LineAwesome from "react-icons/lia";
import * as Ionicons4         from "react-icons/io";
import * as Ionicons5         from "react-icons/io5";
import * as LucideIcons       from "react-icons/lu";
import * as MaterialDesignIcons from "react-icons/md";
import * as PhosphorIcons     from "react-icons/pi";
import * as RadixIcons        from "react-icons/rx";
import * as RemixIcons        from "react-icons/ri";
import * as SimpleIcons       from "react-icons/si";
import * as SimpleLineIcons   from "react-icons/sl";
import * as TablerIcons       from "react-icons/tb";
import * as ThemifyIcons      from "react-icons/tfi";
import * as Typicons          from "react-icons/ti";
import * as VSCodeIcons       from "react-icons/vsc";
import * as WeatherIcons      from "react-icons/wi";



type ResolverType = { 
  [name: string]: IconAddr[]
};


class IconsRepo__ {
  private static instance: IconsRepo__;

  private _icons: any[][];
  private _resolver: ResolverType;
  private _resolverKeys: string[];


  private constructor() {
    this._icons = [];
    this._resolver = {};
    this._resolverKeys = [];

    this.setupIcons();
  }

  private setupIcons() {
    const IconsLibs = [
      AntDesignIcons,
      BootstrapIcons,
      BoxIcons,
      CircumIcons,
      CssGgIcons,
      Devicons,
      FeatherIcons,
      FlatColorIcons,
      FontAwesome5,
      FontAwesome6,
      GameIcons,
      GithubOcticons,
      GrommetIcons,
      Heroicons,
      Heroicons2,
      IcoMoonFree,
      Icons8LineAwesome,
      Ionicons4,
      Ionicons5,
      LucideIcons,
      MaterialDesignIcons,
      PhosphorIcons,
      RadixIcons,
      RemixIcons,
      SimpleIcons,
      SimpleLineIcons,
      TablerIcons,
      ThemifyIcons,
      Typicons,
      VSCodeIcons,
      WeatherIcons
    ];
  
    this._icons = IconsLibs.map((icons) => Object.entries(icons).map(([key, Icon]) => {
      const iconDescriptor: IconDescriptor = { key, Icon };
      return iconDescriptor;
    }));

    const resolver: ResolverType = {};

    this._icons.forEach((icons, row) => {
      icons.forEach((icon, column) => {
        if ( ! (icon.key in resolver)) {
          resolver[`${icon.key}`] = []
        }
        else {
          // console.warn(icon.key);
        }
        resolver[`${icon.key}`].push({row, column});
      })
    });

    this._resolver = resolver;
    this._resolverKeys = Object.keys(resolver);
  }


  getIcons(iconSearchText: string) {
    if (iconSearchText.length < 3) {
      return [];
    }

    const matchedKeys = this._resolverKeys.filter((key) => {
      return key.toLowerCase().includes(iconSearchText.toLowerCase());
    });
    
    const matchedIconsIdxs = matchedKeys.map((key: string) => {
      return this._resolver[key];
    })

    return matchedIconsIdxs.flat();
  }

  getIcon(iconIdx: IconAddr) {
    return this._icons[iconIdx.row][iconIdx.column];
  }

  getRandom() {
    const row = Math.floor(Math.random() * this._icons.length);
    const columns = this._icons[row];
    const column = Math.floor(Math.random() * columns.length);

    return {row, column};
  }

  public static getInstance(): IconsRepo__ {
    if ( ! IconsRepo__.instance) {
      IconsRepo__.instance = new IconsRepo__();
    }
    return IconsRepo__.instance;
  }
}

const IconsRepo = IconsRepo__.getInstance();
export default IconsRepo;
