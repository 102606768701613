import React from 'react';
import { useEditor }           from 'app/ui-v2/editor-instruction/hooks/use-editor';
import LexicalToolbarComponent from 'app/ui-v2/editor-instruction/hls/lexical/lexical-toolbar';
import { CellMenuProps } from '../types';

import { TopToolbar } from './styles';


interface Props extends CellMenuProps {
}


export const CellTextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr
  } = props;

  const editor = useEditor();
  const lexicalRegistry = editor.lexicalRegistryContent2CustomFields;
  const lexical = lexicalRegistry.getLexical(pageCellAddr);

  return (
    <TopToolbar>
      <LexicalToolbarComponent lexical={lexical} />
    </TopToolbar>  
  );
}
  
