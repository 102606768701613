import React from 'react';
import { useRecoilValue } from 'recoil';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';
import { UIState_HeaderFieldsSession } from 'app/ui/states/editor-instruction';
import TopToolbarCommonComponent from 'app/ui-v2/editor-instruction/hls/top-toolbar-common';


interface Props {
}


export const BlankMenuComponent: React.FC<Props> = (props: Props) => {
  const cellSelected = useRecoilValue(UIState_ContentSession.cellSelected);
  const pageCellSelected = useRecoilValue(UIState_HeaderFieldsSession.pageCellSelected);

  const visible = ( ! cellSelected && ! pageCellSelected);

  if ( ! visible ) {
    return null;
  }

  return (
    <TopToolbarCommonComponent />
  );
}

