import React from 'react';
import TooltipComponent from 'lego/components/tooltip';

import { PanelControlsItemSectionControl_ButtonGroupConfig as Config} from '../../config';
import { unpackBoolean } from '../../tools';

import { ButtonGroup       } from './styles';
import { ButtonContent     } from './styles';
import { ButtonGroupLeft   } from './styles';
import { ButtonGroupMiddle } from './styles';
import { ButtonGroupRight  } from './styles';


interface Props {
  config: Config;
}


export const ControlButtonGroupComponent: React.FC<Props> = (props: Props) => {
  const { config } = props;

  const getButton = (idx: number) => {
    if (idx === 0) {
      return ButtonGroupLeft; 
    }
    else if (idx === config.buttons.length - 1 ) {
      return ButtonGroupRight;
    }
    else {
      return ButtonGroupMiddle;
    }
  }
  const renderButtons = () => {
    const buttons_ = config.buttons.filter((button) => ! button.skip);
    
    const buttons = buttons_.map((button, idx) => {
      const Button = getButton(idx);
      const Icon = button.Icon || null;
      const buttonDisabled = unpackBoolean(config.disabled);
      const buttonSelected = unpackBoolean(button.selected);
      const btnDataTest = `${config.dataTest}-${idx}`;

      return (
        <TooltipComponent
          key={idx}
          title={button.tooltip}
        >
          <Button
            onClick={() => config.onClick(button.value) }
            $selected={buttonSelected}
            disabled={buttonDisabled}
            data-test={btnDataTest}
          >
            {
              button.text && 
              button.text.length > 0 && 
              <ButtonContent>
              { button.text }
              </ButtonContent>
            }
            {
              Icon &&
              <Icon />
            }

          </Button>
        </TooltipComponent>
      );
    });

    return buttons;
  }

  return (
    <ButtonGroup>
      { renderButtons() }
    </ButtonGroup>
  );
}
  
