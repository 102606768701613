import styled from "styled-components";
import ListItemBase from 'lego/styles/list-item';
import TextWrap from "lego/styles/text-wrap";


export const ListItem = styled(ListItemBase)`
  justify-content: space-between;
  /* background: red; */
`;


export const Title = styled(TextWrap)`
  min-width: 60px;
  flex: 1 1 0;
`;


export const BtnsWrapper = styled.div`
  flex: 0 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: ${props => props.theme.defs.gap.small};
`;


export const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;

  &:hover {
    scale: 1.6;
    /* outline: solid 2px white; */
    /* border-radius: 50%; */
  }

  transform-origin: 50% 50%;
  transition: 
    all 
    ${props => props.theme.defs.transition.time.normal} 
    ease-in-out;
`;

