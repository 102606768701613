import { useRef } from 'react';
import { useMutation as useMutationApollo } from '@apollo/client';
import { Msgs } from './use-common';
import { useCommon } from './use-common';
import { useProgressModal } from 'lego-v2/progress-modal';


export const useMutation = (
  mutation: any,
  msgs?: Msgs,
) => {
  const variablesRef = useRef<any | null>();

  const { 
    showProgressModal, 
    hideProgressModal
  } = useProgressModal();


  const [mutateFn, { 
    data, 
    loading,
    error
  }] = useMutationApollo(mutation);
  
  useCommon({msgs, error, loading});

  const extractVariables = (args: any[]) => {
    if (args.length < 1) {
      return null;
    }

    if ( ! ('variables' in args[0] )) {
      return null;
    }

    return args[0].variables;
  }

  const mutateFnWrap = async (...args: any) => {
    try {
      variablesRef.current = extractVariables(args);
      return await mutateFn(...args);
    } catch (e: any) {
      hideProgressModal();
      console.error('GraphQL mutation error:', e.message);
    }
  }

  return {
    mutation: mutateFnWrap,
    loading,
    data,
    error,
    variables: variablesRef.current
  }
}
