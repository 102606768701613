import jtl from "tools/jtl";

import { Position } from "app/arch/types";
import { Size     } from "app/arch/types";
import { CssStyle } from "app/arch/editor-instruction/css-styles";

import { SmartLines } from "./smart-lines";
import { FrameCtrlType } from 'lego-v2/frame-resize/arch';


export class SmartLinesFrameResizer extends SmartLines {
  loadFromFrameUpdate(
    ctrlType: FrameCtrlType, 
    size: Size, 
    position: Position, 
    widgetStyle: CssStyle
  ) {
    const borderWidth  = jtl.css.valueToNumber(widgetStyle.borderWidth);
    const outlineWidth = jtl.css.valueToNumber(widgetStyle.outlineWidth);
    const totalWidth   = borderWidth + outlineWidth;

    const CalculateFrameLinesHandlers: any = {
      [FrameCtrlType.BOTTOM_MIDDLE] : () => this.addLine_ctrlBottomMiddle(size, position, totalWidth),
      [FrameCtrlType.MIDDLE_RIGHT]  : () => this.addLine_ctrlMiddleRight(size, position, totalWidth),
      [FrameCtrlType.BOTTOM_RIGHT]  : () => this.addLine_ctrlBottomRight(size, position, totalWidth),

      [FrameCtrlType.TOP_LEFT]      : () => this.addLine_ctrlTopLeft(size, position, totalWidth),
      [FrameCtrlType.TOP_MIDDLE]    : () => this.addLine_ctrlTopMiddle(size, position, totalWidth),
      [FrameCtrlType.TOP_RIGHT]     : () => this.addLine_ctrlTopRight(size, position, totalWidth),

      [FrameCtrlType.MIDDLE_LEFT]  : () => this.addLine_ctrlMiddleLeft(size, position, totalWidth),
      [FrameCtrlType.BOTTOM_LEFT]  : () => this.addLine_ctrlBottomLeft(size, position, totalWidth),
    };

    const calculateFrameLineHandler: any = CalculateFrameLinesHandlers[ctrlType];
    calculateFrameLineHandler(size, position, totalWidth);
  }



  private addLine_ctrlBottomRight(size: Size, position: Position, totalWidth: number) {
    this.addLine_ctrlBottomMiddle(size, position, totalWidth);
    this.addLine_ctrlMiddleRight(size, position, totalWidth);
  }

  private addLine_ctrlTopLeft(size: Size, position: Position, totalWidth: number) {
    this.addLine_ctrlTopMiddle(size, position, totalWidth);
    this.addLine_ctrlMiddleLeft(size, position, totalWidth);
  }
  
  private addLine_ctrlTopRight(size: Size, position: Position, totalWidth: number) {
    this.addLine_ctrlTopMiddle(size, position, totalWidth);
    this.addLine_ctrlMiddleRight(size, position, totalWidth);
  }
  
  private addLine_ctrlBottomLeft(size: Size, position: Position, totalWidth: number) {
    this.addLine_ctrlBottomMiddle(size, position, totalWidth);
    this.addLine_ctrlMiddleLeft(size, position, totalWidth);
  }

  private addLine_ctrlBottomMiddle(size: Size, position: Position, totalWidth: number) {
    const lineContentNaked = {
      x0: position[0], 
      y0: position[1] + size[1], 

      x1: position[0] + size[0], 
      y1: position[1] + size[1], 
    };

    const lineContentBordered = {
      x0: position[0], 
      y0: position[1] + size[1] + totalWidth, 

      x1: position[0] + size[0], 
      y1: position[1] + size[1] + totalWidth, 
    };

    this.addLine(lineContentNaked);
    this.addLine(lineContentBordered);
  }

  private addLine_ctrlMiddleRight(size: Size, position: Position, totalWidth: number) {
    const lineContentNaked = {
      x0: position[0] + size[0], 
      y0: position[1] , 

      x1: position[0] + size[0], 
      y1: position[1] + size[1], 
    };

    const lineContentBordered = {
      x0: position[0] + size[0] + totalWidth, 
      y0: position[1] , 

      x1: position[0] + size[0] + totalWidth, 
      y1: position[1] + size[1], 
    };

    this.addLine(lineContentNaked);
    this.addLine(lineContentBordered);
  }

  private addLine_ctrlTopMiddle(size: Size, position: Position, totalWidth: number) {
    const lineContentNaked = {
      x0: position[0], 
      y0: position[1] - totalWidth, 

      x1: position[0] + size[0], 
      y1: position[1] - totalWidth, 
    };

    const lineContentBordered = {
      x0: position[0], 
      y0: position[1], 

      x1: position[0] + size[0], 
      y1: position[1], 
    };

    this.addLine(lineContentNaked);
    this.addLine(lineContentBordered);
  }

  private addLine_ctrlMiddleLeft(size: Size, position: Position, totalWidth: number) {
    const lineContentNaked = {
      x0: position[0] - totalWidth, 
      y0: position[1], 

      x1: position[0] - totalWidth, 
      y1: position[1] + size[1], 
    };

    const lineContentBordered = {
      x0: position[0], 
      y0: position[1], 

      x1: position[0], 
      y1: position[1] + size[1], 
    };

    this.addLine(lineContentNaked);
    this.addLine(lineContentBordered);
  }
}
