import styled from 'styled-components';


export const MainWrapper = styled.div<{$visible: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  cursor: wait;

  opacity: ${props => props.$visible ? 1 : 0};
  pointer-events: ${props => props.$visible ? 'all' : 'none'};  ;

  transition: 
    opacity 
    ${props => props.theme.defs.transition.time.normal} 
    ease-in-out
  ;

  background-color: rgba(0, 0, 0, 0.8);
`;
