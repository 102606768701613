import React from 'react';
import jtl from 'tools/jtl';

import { Instruction }     from 'app/arch/backend/types';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import PanelItemComponent from 'app-views/components/panel-item';
import { PanelItemConfig } from 'app-views/components/panel-item/config';
import ItemContentComponent from './item-content';

import { IconEdit }     from './styles';
import { IconSettings } from './styles';
import { IconDelete }   from './styles';


interface Props {
  instruction: Instruction;

  onInstructionEdit: (instructionId: number) => void;
  onInstructionSettings: (instructionId: number) => void;
  onInstructionSelect: (instructionId: number) => void;
}


export const InstructionItemComponent: React.FC<Props> = (props: Props) => {
  const {
    instruction,

    onInstructionEdit,
    onInstructionSettings,
    onInstructionSelect,
  } = props;

  const t = useTranslations();

  const handleInstructionSettings = (event: React.MouseEvent) => {
    event.stopPropagation();
    onInstructionSettings(+instruction.id);
  }

  const handleInstructionEdit = (event: React.MouseEvent) => {
    event.stopPropagation();
    onInstructionEdit(+instruction.id);
  }

  const handleInstructionSelect = (event: React.MouseEvent) => {
    event.stopPropagation();
    onInstructionSelect(+instruction.id);
  }

  const config: PanelItemConfig = {
    // title: instruction.title,
    content: {
      component: ItemContentComponent,
      props: {
        instruction,
      },
    },
    buttonsAutoHide: true,
    onClick: handleInstructionSelect,
    buttons: [
      {
        tooltip: t('document edit tooltip'),
        onClick: handleInstructionEdit,
        Icon: IconEdit
      },
      {
        tooltip: t('document settings tooltip'),
        onClick: handleInstructionSettings,
        Icon: IconSettings
      },
    ]
  }

  return (
    <PanelItemComponent
      config={config}
    />
  );
}
  
