import { MutableRefObject } from "react";
import { useRef } from "react";
import { useEffect } from "react";

import { RichTextPlugin   } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable  } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";

import { EditorEditableBase }     from "./styles";
import { EditorEditableDisabled } from "./styles";
import { Placeholder }            from "./styles";


interface Props {
  editorTextRef?: MutableRefObject<HTMLDivElement | null>;
  disabled?: boolean;
  EditorStyled?: React.ElementType;
}


export const EditorTextBody: React.FC<Props> = (props: Props) => {
  const { 
    editorTextRef,
    disabled,
  } = props;

  const editorTextWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (editorTextRef === undefined) {
      return;
    }

    if (editorTextWrapperRef.current === null) {
      return;
    }
    
    editorTextRef.current = getEditorTextHolder();
  }, [editorTextWrapperRef]);

  const getEditorTextHolder = () => {
    if (editorTextWrapperRef.current === null) {
      const msg = `Editor content placeholder not found`;
      throw new Error(msg);
    }

    const contentHolder = editorTextWrapperRef.current.getElementsByTagName('div')[0];
    return contentHolder ;
  }

  // That would be backward compatible
  // but don't think it is needed

  // const EditorStyled = (
  //   props.EditorStyled || (
  //     ! disabled ? 
  //     EditorEditableBase : 
  //     EditorEditableDisabled
  //   )
  // );

  const EditorStyled = props.EditorStyled || EditorEditableBase;

  return (
    <RichTextPlugin
      contentEditable={
        <EditorStyled ref={editorTextWrapperRef} >
          <ContentEditable className="editor-input" />
        </EditorStyled>
      }
      placeholder={<PlaceholderComponent />}
      ErrorBoundary={LexicalErrorBoundary}
    />
  );
}



//--------------------------
// Placeholder
//

const PlaceholderComponent = () => {
  // Placeholder styled component
  // has display: none
  return (
    <Placeholder>
      Enter text...
    </Placeholder>
  );
}
