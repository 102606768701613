import EditorImageComponent from 'app/ui-v2/editor-image';
import EditorLogoComponent   from 'app/ui-v2/editor-logo/components/editor-logo';
import EditorMarkerComponent from 'app/ui-v2/editor-markers';

import ContextElementComponent from 'app/ui-v2/editor-instruction/global-components/context-element';
import ContextMenuComponent    from 'app/ui-v2/editor-instruction/global-components/context-menu';
import ContextPanelComponent   from 'app/ui-v2/editor-instruction/global-components/context-panel';


interface Props {
}


export const LayerControlsComponent: React.FC<Props> = (props: Props) => {

  return (
    <>
      <EditorImageComponent />

      <EditorLogoComponent />
      <EditorMarkerComponent />

      <ContextMenuComponent />
      <ContextPanelComponent />
      <ContextElementComponent />
    </>
  );
}
