import React from 'react';
import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';

import { useDocState }        from 'app/ui/contexts/document';
import { DNDContext }         from 'app/ui-v2/editor-instruction/dnd-context';
import DNDDropComponent       from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';

import { DropWrapper } from './styles';


interface Props {
  pageColumnAddr: Types.PageColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellDropComponent: React.FC<Props> = (props: Props) => {
  const {
    pageColumnAddr,
    children
  } = props;

  const document  = useDocState();
  const getDNDData = useDNDDataGet();
  
  const {
    setHeaderFields
  } = useEditorStatesSetters();
  
  const handleDrop = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const droppedColumnAddr = data.columnAddr;

    document.headerFields.moveColumn(droppedColumnAddr, pageColumnAddr);
    document.saveUndo();
    setHeaderFields();
  }

  const contextsDefs = [{
    dndContext: DNDContext.viewContent.customFields.column,
    onDropV2: handleDrop
  }];

  return (
    <DNDDropComponent 
      contextsDefs={contextsDefs} 
      Wrapper={DropWrapper}
    >
      { children }
    </DNDDropComponent>
  );
}
  
