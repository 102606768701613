import React from 'react';

import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import * as Tools from "app/arch/editor-instruction/document/states/persistent/content/tools";

import { useDocState }    from 'app/ui/contexts/document';
import useSelectedReset   from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useRowSelectedSet  from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/row/use-row-selected-set';
import useCellSelectedSet from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell/use-cell-selected-set';

import { MainWrapper } from './styles';


interface Props {
  cellAddr: Types.CellAddr;
  children: React.ReactNode;
}


export const CellIndexSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children,
  } = props
  
  const document = useDocState();
  const resetAllSelected = useSelectedReset();
  const setRowSelected = useRowSelectedSet();
  const setCellSelected = useCellSelectedSet();

  const handleSelectRow = (event: React.MouseEvent) => {
    event.stopPropagation();

    const selectedRow = document.contentSession.getRowSelected();
    const isSelected  = (
      selectedRow &&
      Tools.compareRowAddr(cellAddr, selectedRow)
    );

    if ( isSelected ) {
      return;
    }

    // Select cell as well, in order
    // get get panel column menu active for
    // idx row.
    const skipStateUpdate = true;

    resetAllSelected();
    setCellSelected({ cellAddr });
    setRowSelected({ rowAddr: cellAddr });
  }


  return (
    <MainWrapper onPointerDown={handleSelectRow}>
      { children }
    </MainWrapper>
  );
}
