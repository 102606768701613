import React from 'react';
import { useState } from 'react';
import { IconContext } from 'react-icons';

import TooltipComponent from 'lego/components/tooltip';

import { PanelItemConfig }   from './config';
import { PanelButtonConfig } from './config';

import { ListItem } from './styles';
import { Title } from './styles';
import { BtnsWrapper } from './styles';
import { BtnWrapper } from './styles';


interface Props {
  config: PanelItemConfig;
}


export const PanelItemComponent: React.FC<Props> = (props: Props) => {
  const { config } = props;
  const [isHover, setIsHover] = useState(false);

  const content = config.content;
  const Content = content?.component;
  const ContentProps =  content?.props;

  const handlePointerEnter = () => {
    setIsHover(true);
  }

  const handlePointerLeave = () => {
    setIsHover(false);
  }
  
  const renderButton = (btnDef: PanelButtonConfig, idx: number) => {
    const Icon = btnDef.Icon;
    return (
      <React.Fragment key={idx}>
        <TooltipComponent title={btnDef.tooltip}>
          <BtnWrapper onClick={btnDef.onClick}>
            <Icon 
              onClick={btnDef.onClick}
            />
          </BtnWrapper>
        </TooltipComponent>
      </React.Fragment>
    );
  }

  const renderButtons = () => {
    const btnsComponents = config.buttons.map((btnDef: PanelButtonConfig, idx: number) => renderButton(btnDef, idx));
    return btnsComponents;
  }

  const getBtnsVisible = () => {
    return config.buttonsAutoHide ? isHover : true;
  }

  return (
    <ListItem
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
      onClick={config.onClick}
      selected={config.selected}
    >
      {
        config.title &&
        ! Content &&
        <Title>
          { config.title }
        </Title>
      }

      {
        config.content && 
        Content &&
        <Content 
          {...ContentProps} 
          hover={isHover}
        />
      }

      {
        getBtnsVisible() && 
        <BtnsWrapper>
          <IconContext.Provider 
            value={{ size: `20px` }}
          >
            { renderButtons() }
          </IconContext.Provider>
        </BtnsWrapper>
      }
    </ListItem>
  );
}
  
