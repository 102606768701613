import { useRef } from "react";
import { Position } from "app/arch/types";
import useDebugPointSet from 'app/ui-v2/app/__modules/debug/hooks/use-debug-point-set';
import useControlsDraggersEnabledSet from "app/ui-v2/app/__modules/controls/hooks/use-controls-draggers-enabled-set";


export interface Props {
  disable: boolean;
  getScale: () => number;

  onStart?: () => void;
  onMove?: (
    scaleInit: number,
    scaleNew: number, 
    scalePointInit: Position,
    scalePointNew: Position,
  ) => void;
  onEnd?: () => void;
}


const useRescaleByTouch = (props: Props) => {
  const {
    disable,

    getScale,

    onStart,
    onMove,
    onEnd,
  } = props;

  const scaleInit = useRef<number>(0);
  const distanceInit = useRef<number>(0);
  const scalePointInit = useRef<Position>([0, 0]);

  const setDebugPoint = useDebugPointSet();
  const setDraggersEnabled = useControlsDraggersEnabledSet();

  const __getMinDistanceThreshold = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const diagonal = Math.hypot(width, height);

    let minDistance = diagonal * 0.10;
    minDistance = Math.min(100, minDistance);
    minDistance = Math.max(50, minDistance);

    return minDistance;
  };

  const __getDistance = (
    touch1: React.Touch, 
    touch2: React.Touch
  ) => {
    const dx = touch2.clientX - touch1.clientX;
    const dy = touch2.clientY - touch1.clientY;

    const dist = Math.hypot(dx, dy);
    return dist;
  }

  const __getMiddlePoint = (
    touch1: React.Touch, 
    touch2: React.Touch
  ) => {
    const x = (touch1.clientX + touch2.clientX) / 2;
    const y = (touch1.clientY + touch2.clientY) / 2;

    const middlePoint = [x, y] as Position;
    return middlePoint;
  }

  const __calculateScale = (
    touch1: React.Touch, 
    touch2: React.Touch
  ) => {
    const distance = __getDistance(touch1, touch2);

    const scaleFactor = distance  / distanceInit.current;
    const newScale = scaleFactor * scaleInit.current;

    return newScale;
  }

  const handleTouchStart = (
    event: React.TouchEvent
  ) => {
    if ( disable ) {
      return;
    }

    const touches = event.touches;
    if (touches.length !== 2) {
      return;
    }

    setDraggersEnabled(false);

    onStart?.();
    event.stopPropagation();

    scaleInit.current = getScale();
    scalePointInit.current = __getMiddlePoint(touches[0], touches[1]);
    distanceInit.current = Math.max(
      __getDistance(touches[0], touches[1]), 
      __getMinDistanceThreshold()
    );
  };


  const handleTouchMove = (
    event: React.TouchEvent
  ) => {
    if ( disable ) {
      return;
    }

    const touches = event.touches;
    if (touches.length !== 2) {
      return;
    }

    event.stopPropagation();

    const scaleNew = __calculateScale(touches[0], touches[1]);
    const scalePoint = __getMiddlePoint(touches[0], touches[1]);
    
    setDebugPoint({x: scalePoint[0], y: scalePoint[1]});

    onMove?.(
      scaleInit.current,
      scaleNew, 
      scalePointInit.current,
      scalePoint,
    );
  };
  
  const handleTouchEnd = (
    event: React.TouchEvent
  ) => {
    if ( disable ) {
      return;
    }

    // const changedTouches = Array.from(event.changedTouches);
    // const primaryTouchEnded = changedTouches.some(touch => touch.identifier === 0);

    if (event.touches.length !== 1) {
      return;
    }
    event.stopPropagation();
    onEnd?.();
    setDraggersEnabled(true);
  }

  return {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  }
}

export default useRescaleByTouch;