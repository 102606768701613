import styled from "styled-components";
import { StyledBtnBase      } from "lego-v2/styled";


export interface StyledBtnIconBaseProps {
  $icon?: string;
}

export const StyledBtnIconBase = styled(StyledBtnBase)<StyledBtnIconBaseProps>`
  justify-content: center;

  width: 30px;
  height: 30px;

  .icon {
    display: flex;
    user-select: none;
    background-size: contain;
    box-sizing: border-box;
    background-repeat: no-repeat;
    
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;

    ${ props => props.$icon && `background-image: url(${props.$icon});` }   
  }
`;
