import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { 
  $getSelection, 
  $isRangeSelection, 
  COMMAND_PRIORITY_EDITOR, 
  LexicalCommand, 
  createCommand 
} from "lexical";
import SmartListNode, { SmartListItemNode } from ".";
import { insertSmartList } from "./SmartListNode";


export const INSERT_SMART_LIST_COMMAND: LexicalCommand<string> = createCommand(
  "INSERT_SMART_LIST_COMMAND"
);


export function SmartListPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if ( ! editor.hasNodes([SmartListNode, SmartListItemNode])) {
      throw new Error("SmartListPlugin: SmartListNode and/or SmartListItemNode not registered on editor");
    }

    return editor.registerCommand<string>(
      INSERT_SMART_LIST_COMMAND,
      (payload) => {

        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          insertSmartList(editor);
        }
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
