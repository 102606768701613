import produce from 'immer';

//----------------------------
//
// State's
//
//----------------------------

export type State = {
  id: number | null,
  revision: number,
};


//----------------------------
// Producers
//

export const setInstructionId = produce((
  draft: State, 
  id: number, 
) => {
  draft.id = id;
});

export const setRevision = produce((
  draft: State, 
  revision: number, 
) => {
  draft.revision = revision;
});


//-----------------
// Getters

export const getId = (state: State) => {
  return state.id;
}

export const getRevision = (state: State) => {
  return state.revision;
}

//----------------------------
//
// Create initial state
//

export const createInitialState = () => {
  return {
    id: null,
    revision: 0,
  }
}
