import { LexicalRegistry } from "./lexical-registry";
import * as Types from "../document/states/persistent/content/types";


type ItemAddr = Types.WidgetAddr


export class LexicalRegistryContent2Widgets extends LexicalRegistry<ItemAddr> {
  protected getDebugName(): string {
    return "Content2ViewWidgets";
  }

  protected getId(itemAddr: ItemAddr) {
    const {
      columnId,
      sectionId,
      rowId,
      imageId,
      widgetId,
    } = itemAddr;

    const id = (
      `${columnId}::`  +
      `${sectionId}::` +
      `${rowId}::`     +
      `${imageId}::`   +
      `${widgetId}`
    );

    return id;
  }
}
