import { atom }           from 'recoil';
import { selector }       from 'recoil';
import { selectorFamily } from 'recoil';

import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { HeaderFieldsSessionState as State } from 'app/arch/editor-instruction/document/states/sessions/header-fields-session';


/**
 * State
 */
export const state = atom<State.State>({
  key: "ei_docContent_docCustomFieldsSession",
  default: State.createInitialState()
});


/**
 * Page Column
 */
export const pageColumnSelected = selector({
  key: "ei_docContent_docCustomFieldsSession_pageColumnSelected",
  get: ({ get }) => {
    const stateObj = get(state);
    const selectedPageColumnAddr = State.getPageColumnSelected(stateObj);
    return selectedPageColumnAddr;
  }
});

export const isPageColumnSelected = selectorFamily({
  key: "ei_docContent_docCustomFieldsSession_isPageColumnSelected",
  get: (pageColumnAddr: Types.PageColumnAddr) => ({ get }) => {
    const stateObj = get(state);
    const selected = State.isPageColumnSelected(stateObj, pageColumnAddr);
    return selected;
  }
});


/**
 * Page Cell
 */
export const pageCellSelected = selector({
  key: "ei_docContent_docCustomFieldsSession_pageCellSelected",
  get: ({ get }) => {
    const stateObj = get(state);
    const selectedCellAddr = State.getPageCellSelected(stateObj);
    return selectedCellAddr;
  }
});

export const isPageCellSelected = selectorFamily({
  key: "ei_docContent_docCustomFieldsSession_isPageCellSelected",
  get: (pageCellAddr: Types.PageCellAddr) => ({ get }) => {
    const stateObj = get(state);
    const selected = State.isPageCellSelected(stateObj, pageCellAddr);
    return selected;
  }
});
