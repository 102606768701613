import { CssStyle } from 'app/arch/editor-instruction/css-styles';
import { createContext } from 'react';
import { OnDoneFn } from './types';

export interface BackendServiceProps {
  createWidgetStyle?: (
    instructionId: number, 
    styleName: string, 
    style: CssStyle,
    onDone?: OnDoneFn
  ) => void;
  
  updateWidgetStyleName?: (
    id: number, 
    name: string,
    onDone?: OnDoneFn  
  ) => void;

  deleteWidgetStyle?: (
    id: number,
    onDone?: OnDoneFn  
  ) => void;
};

export const BackendServiceContext = createContext<BackendServiceProps>({
});

