import React from 'react';
import { useRef }    from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import { Input }           from './styles';
import { InputWrapper }    from './styles';
import { ControlsWrapper } from './styles';
import { ExpandLessIcon }  from './styles';
import { ExpandMoreIcon }  from './styles';
import { ButtonDecrease }  from './styles';
import { ButtonIncrease }  from './styles';
import { MainWrapper }     from './styles';
import { Separator }       from './styles';


interface Props {
  value: number | null;
  step?: number;
  min?: number,
  max?: number,
  disabled?: boolean,

  onChangeDone?: (value: number) => void;
}


export const SliderValueComponent: React.FC<Props> = (props: Props) => {
  const { 
    value, 
    disabled,
    onChangeDone 
  } = props;
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const step = props.step !== undefined ? props.step : 1;

  const minValue = props.min !== undefined ? props.min : 0;
  const maxValue = props.max !== undefined ? props.max : 100;

  const cancelBlurHandle = useRef(false);

  const [inputValue, setInputValue] = useState<number>(value || 0);

  useEffect(() => {
    if (inputValue !== value) {
      setInputValue(value || 0);
    }
  }, [value]);

  const checkValueBoundries = (value: number) => {
    value = Math.min(value, maxValue);
    value = Math.max(value, minValue);

    return value;
  }

  const handleChangeDone = (value: number) => {
    onChangeDone?.(value);
  }

  const handleIncreaseValue = () => {
    if (value === null) {
      handleChangeDone(step);
      return;
    }

    const newValue = checkValueBoundries(value + step);
    onChangeDone?.(newValue);
  }

  const handleDecreaseValue = () => {
    if (value === null) {
      onChangeDone?.(0);
      return;
    }

    const newValue = checkValueBoundries(value - step);
    onChangeDone?.(newValue);
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = +event.target.value;
    
    if (isNaN(newValue)) {
      console.warn("not a digit");
      return;
    }
    
    setInputValue(newValue);
  }

  const handleInputFocus = () => {
    setInputFocused(true);
  }
 
  const handleInputBlur = () => {
    if (cancelBlurHandle.current) {
      cancelBlurHandle.current = false;
      return;
    }

    setInputFocused(false);

    if (value !== null) {
      const valueChecked = checkValueBoundries(inputValue);
      onChangeDone?.(valueChecked);
    }
  }

  const handleInputKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();

    if (event.key === "Enter") {
      inputRef.current?.blur();
      const value = checkValueBoundries(inputValue);
      onChangeDone?.(value);

    }
    else if (event.key === "Escape") { 
      cancelBlurHandle.current = true;
      inputRef.current?.blur();
      setInputFocused(false);
      setInputValue(value || 0);
    }
  }


  return (
    <MainWrapper>
      <InputWrapper>
        <Input 
          ref={inputRef}
          value={inputValue} 
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onKeyDown={handleInputKeyDown}
          disabled={disabled}  
        />
      </InputWrapper>
      
      {
        ! inputFocused && 
        <ControlsWrapper>
          <ButtonIncrease 
            onClick={handleIncreaseValue}
            disabled={disabled}  
          >
            <ExpandLessIcon />
          </ButtonIncrease>

          <Separator />
          
          <ButtonDecrease 
            onClick={handleDecreaseValue}
            disabled={disabled}  
          >
            <ExpandMoreIcon />
          </ButtonDecrease>

        </ControlsWrapper>
      }
    </MainWrapper>
  );
}
  
