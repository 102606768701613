import produce from 'immer';
import * as Types from './types';
import * as Tools from './tools';
import * as Defaults from './defaults';


//----------------------------
// State
//

export type State = {
  releaselogs: Types.Releaselogs
};


//----------------------------
// Producers
//

/**
 * 
 * Releaselog
 * 
 */

export const addReleaselog = produce((
  draft: State, 
  releaselogAddr: Types.ReleaselogAddr,
) => {
  const releaselogsAddrs = getReleaselogsAddrs(draft);
  const releaselogsProps = getReleaselogsProps(draft);
  const releaselogsChangelogs = getReleaselogsChangelogs(draft);

  const releaselogKey = Tools.getReleaselogKey(releaselogAddr);
  const releaselogProps = Defaults.getReleaselogProps();
  const releaselogChangelog = Defaults.getReleaselogChangelog();
  
  releaselogsAddrs.push(releaselogAddr);
  releaselogsProps[releaselogKey] = releaselogProps;
  releaselogsChangelogs[releaselogKey] = releaselogChangelog;
});

export const setReleaselogEditable = produce((
  draft: State, 
  releaselogAddr: Types.ReleaselogAddr,
  editable: boolean,
) => {
  const releaselogProps = getReleaselogProps(draft, releaselogAddr);
  releaselogProps.editable = editable;
});

export const updateReleaselogInfo = produce((
  draft: State, 
  releaselogAddr: Types.ReleaselogAddr,
  update: Types.ReleaseInfoUpdate,
) => {
  const releaselogProps = getReleaselogProps(draft, releaselogAddr);
  const info = releaselogProps.info;
  releaselogProps.info = {
    ...info,
    ...update,
  }
});

export const updateReleaselogInfoDescription = produce((
  draft: State, 
  releaselogAddr: Types.ReleaselogAddr,
  update: Types.ReleaseInfoDescriptionUpdate,
) => {
  const info = getReleaselogInfo(draft, releaselogAddr);
  const description = info.description;

  info.description = {
    ...description,
    ...update,
  }
});


/**
 * 
 * Column
 * 
 */

export const addColumn = produce((
  draft: State, 
  columnAddr: Types.ColumnAddr,
  columnType:  Types.ColumnType,
  columnProps?: Types.ColumnPropsUpdate,
) => {
  const columnsAddrs = getColumnsAddrs(draft, columnAddr);
  const idx = columnsAddrs.length;

  __addColumnAtIdx(
    draft,
    idx,
    columnAddr,
    columnType,
    columnProps
  );
});

export const addColumnAfter = produce((
  draft: State, 
  srcColumnAddr: Types.ColumnAddr,
  columnAddr: Types.ColumnAddr,
  columnType:  Types.ColumnType,
  columnProps?: Types.ColumnPropsUpdate,
) => {
  const srcColumnIdx = getColumnIdx(draft, srcColumnAddr);
  const idx = srcColumnIdx + 1;

  __addColumnAtIdx(
    draft,
    idx,
    columnAddr,
    columnType,
    columnProps
  );
})

const __addColumnAtIdx = (
  draft: State, 
  idx: number,
  columnAddr: Types.ColumnAddr,
  columnType:  Types.ColumnType,
  columnPropsUpdate?: Types.ColumnPropsUpdate,
) => {
  const columnsAddrs = getColumnsAddrs(draft, columnAddr);
  const columnsProps = getColumnsProps(draft, columnAddr);

  const columnKey = Tools.getColumnKey(columnAddr);
  const columnProps = {
    ...Defaults.getColumnProps(columnType),
    ...columnPropsUpdate
  };

  columnsAddrs.splice(idx, 0, columnAddr);
  columnsProps[columnKey] = columnProps;

  //
  // Create cells
  // 
  const rowsAddrs = getRowsAddrs(draft, columnAddr);
  rowsAddrs.forEach((rowAddr) => {
    const cellAddr = {
      releaselogId: columnAddr.releaselogId,
      columnId: columnAddr.columnId,
      rowId: rowAddr.rowId,
    }

    __createCell(draft, cellAddr);
  });
}

export const deleteColumn = produce((
  draft: State, 
  columnAddr: Types.ColumnAddr,
) => {
  const columnsAddrs = getColumnsAddrs(draft, columnAddr);
  const columnsProps = getColumnsProps(draft, columnAddr);

  const columnIdx = getColumnIdx(draft, columnAddr);
  const columnKey = Tools.getColumnKey(columnAddr);
  
  columnsAddrs.splice(columnIdx, 1);
  delete columnsProps[columnKey];

  //
  // Delete column cells
  //
  const rowsAddrs = getRowsAddrs(draft, columnAddr);
  rowsAddrs.forEach((rowAddr) => {
    const cellAddr: Types.CellAddr = {
      releaselogId: columnAddr.releaselogId,
      columnId: columnAddr.columnId,
      rowId: rowAddr.rowId,
    }

    __deleteCell(draft,cellAddr);
  });
});

export const moveColumn = produce((
  draft: State, 
  srcColumnAddr: Types.ColumnAddr,
  dstColumnAddr: Types.ColumnAddr,
) => {
  if ( ! Tools.compareReleaselogAddr(srcColumnAddr, dstColumnAddr)) {
    console.warn("Src column has different release id than destination column");
    return;
  }

  if ( Tools.compareColumnAddr(srcColumnAddr, dstColumnAddr )) {
    console.log(`Src and dst columns are the same. Skipping move.`)
    return;
  }

  const srcColumnIdx = getColumnIdx(draft, srcColumnAddr);
  const srcIdxLowerThanDstIdx = (srcColumnIdx <  getColumnIdx(draft, dstColumnAddr));

  const columnsAddrs = getColumnsAddrs(draft, srcColumnAddr);

  const srcColumn = columnsAddrs.splice(srcColumnIdx, 1)[0];
  const dstColumnIdx = getColumnIdx(draft, dstColumnAddr);

  if (srcIdxLowerThanDstIdx) {
    columnsAddrs.splice(dstColumnIdx + 1, 0, srcColumn);
  }
  else {
    columnsAddrs.splice(dstColumnIdx, 0, srcColumn);
  }
});

export const setColumnName = produce((
  draft: State, 
  columnAddr: Types.ColumnAddr,
  columnName: string,
) => {
  const columnProps = getColumnProps(draft, columnAddr);
  columnProps.name = columnName;
});

export const updateColumnProps = produce((
  draft: State, 
  columnAddr: Types.ColumnAddr,
  update: Types.ColumnPropsUpdate,
) => {
  const columnProps = getColumnProps(draft, columnAddr);
  Object.assign(columnProps, update);
});

export const updateColumnCSS = produce((
  draft: State, 
  columnAddr: Types.ColumnAddr,
  cssUpdate: React.CSSProperties,
) => {
  const columnProps = getColumnProps(draft, columnAddr);
  columnProps.css = {
    ...columnProps.css,
    ...cssUpdate,
  }
});

export const updateColumnHeaderCSS = produce((
  draft: State, 
  columnAddr: Types.ColumnAddr,
  cssUpdate: React.CSSProperties,
) => {
  const columnProps = getColumnProps(draft, columnAddr);
  const columnHeader = columnProps.header;
  columnHeader.css = {
    ...columnHeader.css,
    ...cssUpdate,
  }
});


/**
 * 
 * Row
 * 
 */

export const addRow = produce((
  draft: State, 
  rowAddr: Types.RowAddr,
) => {
  const rowsAddrs = getRowsAddrs(draft, rowAddr);
  const rowIdx = rowsAddrs.length;
  
  __addRowAtIdx(
    draft,
    rowIdx, 
    rowAddr, 
  );
});

export const addRowAbove = produce((
  draft: State, 
  srcRowAddr: Types.RowAddr,
  rowAddr: Types.RowAddr,
) => {
  const rowIdx = getRowIdx(draft, srcRowAddr);

  __addRowAtIdx(
    draft,
    rowIdx, 
    rowAddr, 
  );
});

export const addRowBelow = produce((
  draft: State, 
  srcRowAddr: Types.RowAddr,
  rowAddr: Types.RowAddr,
) => {
  const rowIdx = getRowIdx(draft, srcRowAddr);

  __addRowAtIdx(
    draft,
    rowIdx + 1, 
    rowAddr, 
  );
});

const __addRowAtIdx = (
  draft: State, 
  idx: number,
  rowAddr: Types.RowAddr,
) => {
  const rowsAddrs = getRowsAddrs(draft, rowAddr);
  const rowsProps = getRowsProps(draft, rowAddr);

  const rowKey = Tools.getRowKey(rowAddr);
  const rowProps = Defaults.getRowProps();
  
  rowsAddrs.splice(idx, 0, rowAddr);
  rowsProps[rowKey] = rowProps;
  
  const columnsAddrs = getColumnsAddrs(draft, rowAddr);
  columnsAddrs.forEach((columnAddr) => {
    const cellAddr = {
      releaselogId: rowAddr.releaselogId,
      rowId: rowAddr.rowId,
      columnId: columnAddr.columnId
    }

    __createCell(draft, cellAddr);
  });
}



export const moveRow = produce((
  draft: State, 
  srcRowAddr: Types.RowAddr,
  dstRowAddr: Types.RowAddr,
) => {
  if ( ! Tools.compareReleaselogAddr(srcRowAddr, dstRowAddr)) {
    console.warn("Src row has different release id than destination row");
    return;
  }

  if (Tools.compareRowAddr(srcRowAddr, dstRowAddr)) {
    console.log(`Src and dst rows are the same. Skipping move.`)
    return;
  }

  const srcRowIdx = getRowIdx(draft, srcRowAddr);
  const srcIdxLowerThanDstIdx = (srcRowIdx <  getRowIdx(draft, dstRowAddr));

  const rowsAddrs = getRowsAddrs(draft, srcRowAddr);

  const srcRow = rowsAddrs.splice(srcRowIdx, 1)[0];
  const dstRowIdx = getRowIdx(draft, dstRowAddr);

  if (srcIdxLowerThanDstIdx) {
    rowsAddrs.splice(dstRowIdx + 1, 0, srcRow);
  }
  else {
    rowsAddrs.splice(dstRowIdx, 0, srcRow);
  }
});


/**
 * 
 * Cell
 * 
 */

export const cellText_writeEditorState = produce((
  draft: State, 
  cellAddr: Types.CellAddr,
  editorState: string
) => {
  const cell = getCell(draft, cellAddr);
  const textCell = cell as Types.TextCell;

  textCell.editorState = editorState;
});


const __createCell = (
  draft: State, 
  cellAddr: Types.CellAddr
) => {
  const cells = getCells(draft, cellAddr);
  const cellKey = Tools.getCellKey(cellAddr);

  if (cellKey in cells) {
    const msg = `Can create new cell, as cell already exists`;
    throw new Error(msg);
  }

  const columnProps = getColumnProps(draft, cellAddr);
  const cellDef = Defaults.getCell(columnProps.type);
  cells[cellKey] = cellDef;
}

export const __deleteCell = (
  draft: State, 
  cellAddr: Types.CellAddr
) => {
  const cells = getCells(draft, cellAddr);
  const cellKey = Tools.getCellKey(cellAddr);

  if (! (cellKey in cells)) {
    const msg = `Cell not found`;
    throw new Error(msg);
  }

  delete cells[cellKey];
}


//----------------------------
// Getters
//

/**
 * 
 * Releaselogs
 * 
 */

export const getReleaselogsAddrs = (
  state: State
): Types.ReleaselogsAddrs => { 
  const releaselogs = __getReleaselogs(state);
  return releaselogs.addrs;
}

export const getReleaselogsProps = (
  state: State
): Types.ReleaselogsProps => { 
  const releaselogs = __getReleaselogs(state);
  return releaselogs.props;
}

export const getReleaselogsChangelogs = (
  state: State
): Types.ReleaselogsChangelogs => { 
  const releaselogs = __getReleaselogs(state);
  return releaselogs.changelogs;
}

export const getReleaselogActive = (
  state: State
): Types.ReleaselogAddr => {
  const releaselogsAddrs = getReleaselogsAddrs(state);
  const lastIdx = releaselogsAddrs.length - 1;
  return releaselogsAddrs[lastIdx];
}

const __getReleaselogs = (
  state: State
): Types.Releaselogs => { 
  return state.releaselogs;
}


/**
 * 
 * Releaselog
 * 
 */

export const getReleaselogProps = (
  state: State,
  releaselogAddr: Types.ReleaselogAddr,
): Types.ReleaselogProps => { 
  const releaselogsProps = getReleaselogsProps(state);
  const releaselogKey    = Tools.getReleaselogKey(releaselogAddr);
  
  const releaselogProps = releaselogsProps[releaselogKey];
  if (releaselogProps === undefined) {
    const msg = `Releaselog not found`;
    throw new Error(msg);
  }

  return releaselogProps;
}

export const getReleaselogChangelog = (
  state: State,
  releaselogAddr: Types.ReleaselogAddr,
): Types.ReleaselogChangelog => { 
  const changelogs    = getReleaselogsChangelogs(state);
  const releaselogKey = Tools.getReleaselogKey(releaselogAddr);

  const changelog = changelogs[releaselogKey];
  if (changelog === undefined) {
    const msg = `Changelog not found`;
    throw new Error(msg);
  }

  return changelog;
}

export const getReleaselogInfo = (
  state: State, 
  releaselogAddr: Types.ReleaselogAddr,
): Types.ReleaselogInfo => {
  const releaselogProps = getReleaselogProps(state, releaselogAddr);
  return releaselogProps.info;
}

export const getReleaselogIdx = (
  state: State, 
  releaselogAddr: Types.ReleaselogAddr,
): number => {
  const releaselogsAddrs = getReleaselogsAddrs(state);

  const idx = releaselogsAddrs.findIndex((releaselogAddr_) => Tools.compareReleaselogAddr(releaselogAddr_, releaselogAddr));
  if (idx === -1) {
    const msg = "Releaselog not found";
    throw new Error(msg);
  }

  return idx;
}

export const isReleaselogEditable = (
  state: State, 
  releaselogAddr: Types.ReleaselogAddr,
): boolean => {
  const releaselogProps = getReleaselogProps(state, releaselogAddr);
  return releaselogProps.editable;
}

export const isReleaselogActive = (
  state: State,
  releaselogAddr: Types.ReleaselogAddr,
): boolean => {
  const releaselogsAddrs = getReleaselogsAddrs(state);
  const releaselogIdx    = getReleaselogIdx(state, releaselogAddr);

  const isLast = (releaselogIdx === releaselogsAddrs.length - 1);
  return isLast;
}


/**
 * 
 * Columns
 * 
 */

export const getColumnsAddrs = (
  state: State, 
  releaselogAddr: Types.ReleaselogAddr
): Types.ColumnsAddrs => {
  const columns = __getColumns(
    state, 
    releaselogAddr
  );
  return columns.addrs;
}

export const getColumnsProps = (
  state: State, 
  releaselogAddr: Types.ReleaselogAddr
): Types.ColumnsProps => {
  const columns = __getColumns(
    state, 
    releaselogAddr
  );
  return columns.props;
}

const __getColumns = (
  state: State, 
  releaselogAddr: Types.ReleaselogAddr
): Types.Columns => {
  const changelog = getReleaselogChangelog(
    state, 
    releaselogAddr
  );
  return changelog.columns;
}


/**
 * 
 * Column
 * 
 */

export const getColumnProps = (
  state: State, 
  columnAddr: Types.ColumnAddr
): Types.ColumnProps => {

  const columnsProps = getColumnsProps(
    state, 
    columnAddr
  );
  const columnKey = Tools.getColumnKey(columnAddr);

  const columnProps = columnsProps[columnKey];
  if (columnProps === undefined) {
    const msg = 'Column not found';
    throw new Error(msg);
  }

  return columnProps;
}

export const getColumnIdx = (
  state: State, 
  columnAddr: Types.ColumnAddr
): number => {
  const columnsAddrs = getColumnsAddrs(state, columnAddr);
  
  const idx = columnsAddrs.findIndex((columnAddr_) => Tools.compareColumnAddr(columnAddr, columnAddr_));
  if (idx === - 1) {
    const msg = `Column not found`;
    throw new Error(msg);
  }

  return idx;
}

export const isColumnIndexType = (
  state: State, 
  columnAddr: Types.ColumnAddr
): boolean => {
  const columnProps = getColumnProps(state, columnAddr);
  const isIndex = (columnProps.type === Types.ColumnType.INDEX);
  return isIndex;
}



/**
 * 
 * Rows
 * 
 */

export const getRowsAddrs = (
  state: State, 
  releaselogAddr: Types.ReleaselogAddr
): Types.RowsAddrs => {
  const rows = __getRows(
    state, 
    releaselogAddr
  );
  return rows.addrs;
}

export const getRowsProps = (
  state: State, 
  releaselogAddr: Types.ReleaselogAddr
): Types.RowsProps => {
  const rows = __getRows(
    state, 
    releaselogAddr
  );
  return rows.props;
}

const __getRows = (
  state: State, 
  releaselogAddr: Types.ReleaselogAddr
): Types.Rows => {
  const changelog = getReleaselogChangelog(
    state, 
    releaselogAddr
  );
  return changelog.rows;
}


/**
 * 
 * Row
 * 
 */

export const getRowIdx = (
  state: State, 
  rowAddr: Types.RowAddr
): number => {
  const rowsAddrs = getRowsAddrs(state, rowAddr);
  const idx  = rowsAddrs.findIndex((rowAddr_) => Tools.compareRowAddr(rowAddr, rowAddr_));
  
  if (idx === - 1) {
    const msg = "Row not found";
    throw new Error(msg);
  }

  return idx;
}

export const isRowLast = (
  state: State, 
  rowAddr: Types.RowAddr
): boolean => {
  const rowsAddrs = getRowsAddrs(state, rowAddr);
  const rowIdx    = getRowIdx(state, rowAddr);
  const isLast = (rowIdx === rowsAddrs.length - 1);
  return isLast;
}

export const isRowPresent = (
  state: State, 
  rowAddr: Types.RowAddr
): boolean => {
  const rowsAddrs = getRowsAddrs(state, rowAddr);
  const present = rowsAddrs.some((rowAddr_) => Tools.compareRowAddr(rowAddr_, rowAddr));
  return present;
}


/**
 * 
 * Cells
 * 
 */

export const getCells = (
  state: State, 
  releaselogAddr: Types.ReleaselogAddr
): Types.Cells => {
  const changelog = getReleaselogChangelog(
    state, 
    releaselogAddr
  );
  return changelog.cells;
}


/**
 * 
 * Cell
 * 
 */

export const getCell = (
  state: State, 
  cellAddr: Types.CellAddr
): Types.CellTypes => {
  const cells = getCells(
    state,
    cellAddr
  );
  const cellKey = Tools.getCellKey(cellAddr);
  const cell = cells[cellKey];

  if (cell === undefined) {
    const msg = 'Cell not found';
    throw new Error(msg);
  }

  return cell;
}

export const cellText_getEditorState = (
  state: State, 
  cellAddr: Types.CellAddr
): string | null => {
  const cell = getCell(state, cellAddr);
  const textCell = cell as Types.TextCell;

  return textCell.editorState;
}


//----------------------------
// Create initial state
//

export const createInitialState = () => {
  const state: State = {
    releaselogs: Defaults.getReleaselogs(),
  }

  return state;
}
