import React from 'react';
import { useRecoilValue } from 'recoil';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_EditorImageWidgetsStyles } from 'app/ui/states/editor-instruction';

import StylesListComponent from '../parts/styles-list';


interface Props {
  widgetAddr: ContentTypes.WidgetAddr | null
}


export const StylesUserComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr
  } = props;

  const stylesUser = useRecoilValue(UIState_EditorImageWidgetsStyles.widgetsStyles);

  return (
    <StylesListComponent
      widgetAddr={widgetAddr}
      styles={stylesUser}
    />
  );
}
  
