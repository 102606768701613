import { MutableRefObject } from 'react';
import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import EditorTextBaseComponent  from 'app/ui/components/editor-txt';

import ContentSavePlugin  from './content-save-plugin';
import RegistrationPlugin from './registration-plugin';


interface Props {
  cellAddr: Types.CellAddr;
  editorTextRef: MutableRefObject<HTMLDivElement | null>;
}


export const CellTextEditorComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr, 
    editorTextRef,
  } = props;


  return (
    <EditorTextBaseComponent
      editorTextRef={editorTextRef}
      Plugins={[
        <RegistrationPlugin cellAddr={cellAddr} />,
        <ContentSavePlugin  cellAddr={cellAddr} />
      ]}
    />
  );
}
