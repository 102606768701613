import styled from "styled-components";


interface Props  {
  $color: string;
  $background: string;
  $selected?: boolean;
}

 const SelectableCell = styled.div<Props>`
  cursor: pointer;

  color: ${props => 
    props.$selected ? 
    props.theme.defs.accentColor.contrast :
    props.$color
  };
  
  background: ${props => 
    props.$selected ? 
    props.theme.defs.accentColor.dark : 
    props.$background
  };
`;

export default SelectableCell;