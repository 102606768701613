import i18next from 'i18next';

import { LexicalTools } from 'app/arch/tools';
import { Position } from 'app/arch/types';
import { Size }     from 'app/arch/types';
import { Arrow }    from 'app/arch/editor-image/types/arrows';

import { settings } from 'app/configs';

import { RepoMarkersTypes } from '../repo-markers';
import { RepoImagesTypes } from '../../loadable/repo-images';

import  * as Tools from './tools';
import  * as Types from './types';


/**
 * 
 * Columns
 * 
 */

export const getColumns = (): Types.Columns => ({
  addrs: [],
  props: {},
});

export const getColumnProps = (
  columnType: Types.ColumnType
): Types.ColumnPropsTypes => {

  type ColumnCreateFn = () => Types.ColumnPropsTypes

  const ColumnCreateFnMap: {[key in Types.ColumnType]: ColumnCreateFn} = {
    [Types.ColumnType.INDEX   ] : __getColumnIndexProps,
    [Types.ColumnType.TEXT    ] : __getColumnTextProps,
    [Types.ColumnType.IMAGES  ] : __getColumnImagesProps,
    [Types.ColumnType.MARKERS ] : __getColumnMarkersProps,
  }

  const createColumnPropsFn = ColumnCreateFnMap[columnType];
  const columnProps = createColumnPropsFn();
  
  return columnProps;
};

const __getColumnIndexProps = (): Types.ColumnIndexProps => ({
  ...__getColumnBaseProps(Types.ColumnType.INDEX),
  css: {
    padding: '5px',
    fontSize: '14px',
    fontWeight: '600',

    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
  },
});

const __getColumnTextProps = (): Types.ColumnTextProps => ({
  ...__getColumnBaseProps(Types.ColumnType.TEXT),
});

const __getColumnImagesProps = (): Types.ColumnImagesProps => ({
  ...__getColumnBaseProps(Types.ColumnType.IMAGES),
  css: {
    padding: '5px',

    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '5px',
  },
  imageIdx: {
    visible: true,
    placement: Types.ImageIdxPlacement.TOP_RIGHT
  },
  imageBorder: {
    css: {
      borderColor: "#666",
      borderRadius: "3px",
      borderStyle: "solid",
      borderWidth: "1px",
    }
  }
});

const __getColumnMarkersProps = (): Types.ColumnMarkersProps => ({
  ...__getColumnBaseProps(Types.ColumnType.MARKERS),
  css: {
    padding: '5px',

    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '0px',
  },
});


const __getColumnBaseProps = (
  columnType: Types.ColumnType
): Types.ColumnProps => ({
  type: columnType,
  name: "",
  visible: true,
  width: 200,
  css: {
    padding: '5px',
  },
  header: {
    css: {
      /*
        Do not put here props like:
        background, color, fontSize etc, 
        as they are set as part table.header defaults.
        Putting them here will mess things up, for
        example putting background here - will stop
        selected header cell background from being
        accent color.

        Is this true? 
        This has been copied from releaselogs.
        I guess it is true but not sure.
      */
      justifyContent: "flex-start"
    }
  }
});


/**
 * 
 * Rows
 * 
 */

export const getRows = (): Types.Rows => ({
  addrs: [],
  props: {},
});

/**
 * 
 * Row
 * 
 */

export const getRowProps = (): Types.RowProps => ({
});


/**
 * 
 * Cells
 * 
 */

export const getCells = (): Types.Cells => ({
});


/**
 * 
 * Sections
 * 
 */
export const getSectionsConfig = (): Types.SectionsConfig => ({
  enabled: false,
  indexing: Types.SectionsIndexing.GLOBAL,
  sectionsNames: {
    css: {
      justifyContent: 'flex-start',
      padding: '5px',
      fontSize: '14px',
      fontWeight: '400',
      fontVariant: 'none',
    }
  }
});


export const getSections = (): Types.Sections => ({
  addrs: [],
  props: {},
  rows:  {},
  cells: {},
  config: getSectionsConfig()
});


/**
 * 
 * Section
 * 
 */

export const getSectionProps = (): Types.SectionProps => {
  const t = i18next.t;
  return {
    name: t("section, default name"),
    css: {}
  }
}


/**
 * 
 * Content
 * 
 */

export const getContent = (): Types.Content => ({
  sections: getSections(),
  columns:  getColumns(),
});


/**
 * 
 * Content Cell
 * 
 */

export const getCellImages = (): Types.Images => ({
  addrs: [],
  props: {}
});

export const getCell = (cellType: Types.ColumnType): Types.CellTypes => {

  type CellCreateFn = () => Types.CellTypes

  const CellCreateFn: {[key in Types.ColumnType]: CellCreateFn} = {
    [Types.ColumnType.INDEX   ] : __getCellIndex,
    [Types.ColumnType.TEXT    ] : __getCellText,
    [Types.ColumnType.IMAGES  ] : __getCellImages,
    [Types.ColumnType.MARKERS ] : __getCellMarkers,
  }

  const cellCreateFn = CellCreateFn[cellType];
  const cell = cellCreateFn();
  
  return cell;
};

const __getCellIndex = (): Types.IndexCell => ({
});

const __getCellText = (): Types.TextCell => ({
  editorState: LexicalTools.getEditorInitStateSerial(),
});

const __getCellImages = (): Types.ImagesCell => ({
  images: getCellImages()
});

const __getCellMarkers = (): Types.MarkersCell => ({
  markers: {
    addrs: [],
    props: {}
  }
});


/**
 * Widgets
 */

export const getWidgets = (): Types.Widgets => ({
  addrs: [],
  props: {},
});


/**
 * 
 * Image
 * 
 */

export const getImageProps = (
  backendImage: RepoImagesTypes.Image
): Types.ImageProps => {
  const viewScale = getImageViewScale(backendImage);
  const viewArea  = getImageViewArea(backendImage);

  const imageProps: Types.ImageProps = {
    bid: backendImage.bid,
    viewArea,
    viewScale,
    widgets: getWidgets(),
  };

  return imageProps;
}

export const getImageBoxedSize = (image: RepoImagesTypes.Image) => {
  // Calculate image size to fit into 
  // default EditorImage box  
  const defaultImageSettings = settings.editorImage.newImage;

  const scaleWidth  = defaultImageSettings.width / image.size[0];
  const scaleHeight = defaultImageSettings.height / image.size[1];
  const scale = Math.min(scaleWidth, scaleHeight);

  const size = [
    Math.floor(scale * image.size[0]),
    Math.floor(scale * image.size[1]) 
  ] as Size;

  return size;
}

export const getImageViewScale = (image: RepoImagesTypes.Image) => {
  // Calculate default image scale for CelImages
  //
  // Try to scale to default height. 
  // If using scale from default height calculation, 
  // cause scale width to be to large, use width scale.

  const size = getImageBoxedSize(image);
  const imageScaleTo = settings.printView.imageScaleTo;
  const scaleX = imageScaleTo.default.width / size[0];
  const scaleY = imageScaleTo.default.height / size[1];
  const widthTooLarge = (scaleY * size[0] > imageScaleTo.default.width);

  const viewScale = (
    widthTooLarge ?
    scaleX :
    scaleY
  );
  return viewScale;
}

export const getImagePosition = (image: RepoImagesTypes.Image) => {
  const size = getImageBoxedSize(image);
  const deskSettings = settings.editorImage.desk;

  const position = [
    Math.floor((deskSettings.width - size[0]) / 2),
    Math.floor((deskSettings.height - size[1]) / 2),
  ] as Position;
  
  return position;
}

export const getImageViewArea = (image: RepoImagesTypes.Image) => {
  const size     = getImageBoxedSize(image);
  const position = getImagePosition(image);

  const viewArea = {
    x1: position[0], 
    y1: position[1], 

    x2: size[0] + position[0] - 1,
    y2: size[1] + position[1] - 1
  };

  return viewArea;
}


/**
 * 
 * Image Widgets
 * 
 */
// TODO any
type StyleTemp = any;

type WidgetDataCreateFn = (
  imageProps: Types.ImageProps,
  style: StyleTemp,
  customConstructData?: any,
) => Types.WidgetProps; 

export const getWidgetProps = (
  widgetType: Types.WidgetType,
  imageProps: Types.ImageProps,
  style: StyleTemp, 
  customConstructData?: any,
): Types.WidgetProps => {

  const WidgetDataCreateFnMap: {[key in Types.WidgetType]: WidgetDataCreateFn} = {
    [Types.WidgetType.IMAGE       ] : __getWidgetData_image,
    [Types.WidgetType.ARROW_PLAIN ] : __getWidgetData_arrowPlain,
    [Types.WidgetType.ARROW_TEXT  ] : __getWidgetData_arrowText,
    [Types.WidgetType.RECTANGLE   ] : __getWidgetData_rectangle,
    [Types.WidgetType.ELLIPSE     ] : __getWidgetData_ellipse,
    [Types.WidgetType.TEXT        ] : __getWidgetData_text,
  }

  const styleTyped = Tools.getWidgetStyleTyped(widgetType, style);

  const widgetDataCreateFn = WidgetDataCreateFnMap[widgetType];
  const widgetProps = widgetDataCreateFn(
    imageProps,
    styleTyped, 
    customConstructData
  );
  return widgetProps;
};

const __getWidgetBase = (
  type: Types.WidgetType,
  style: StyleTemp,
): Omit<Types.WidgetBaseProps, "type"> => ({
  name: type,
  locked: false,
  style,
  css: {},
});

const __getWidgetData_image: WidgetDataCreateFn = (
  imageProps: Types.ImageProps,
  style: StyleTemp,
  customConstructData: {
    backendImage: RepoImagesTypes.Image
  }
): Types.WidgetImageProps => {
  const { 
    backendImage
  } = customConstructData;

  const size     = getImageBoxedSize(backendImage);
  const position = getImagePosition(backendImage);

  const widgetImageProps: Types.WidgetImageProps = {
    ...__getWidgetBase(Types.WidgetType.IMAGE, style),
    type: Types.WidgetType.IMAGE,
    size,
    position,
    bid: backendImage.bid,
    imageSize: size,
    imagePosition: [0, 0] as Position, // this needs to be 0, 0 at beggining
    imageClipInset: {
      top:    0,
      right:  0,
      bottom: 0,
      left:   0
    }
  }

  return widgetImageProps;
}

const __getWidgetData_arrowPlain: WidgetDataCreateFn = (
  imageProps: Types.ImageProps,
  style: StyleTemp,
): Types.WidgetArrowProps => {
  const anchorPoints = __getWidgetArrow_anchorPoints(imageProps, 0, 10);
  const {
    startPoint,
    endPoint,
  } = anchorPoints;

  const widgetProps: Types.WidgetArrowProps = {
    ...__getWidgetBase(Types.WidgetType.ARROW_PLAIN, style),
    type: Types.WidgetType.ARROW_PLAIN,
    startPoint,
    endPoint,
  };

  return widgetProps;
}

const __getWidgetData_arrowText: WidgetDataCreateFn = (
  imageProps: Types.ImageProps,
  style: StyleTemp,
): Types.WidgetArrowTextProps => {
  const tailSize: Size = [80, 50];
  const anchorPoints = __getWidgetArrow_anchorPoints(
    imageProps, 
    tailSize[0], 
    tailSize[1]
  );

  const {
    startPoint,
    endPoint,
  } = anchorPoints;

  const widgetProps: Types.WidgetArrowTextProps = {
    ...__getWidgetBase(Types.WidgetType.ARROW_TEXT, style),
    type: Types.WidgetType.ARROW_TEXT,
    tailSize,
    jointed: false,
    pointer: Arrow.PointerType.ARROW,
    editorTextState: LexicalTools.getEditorInitStateSerial(),
    startPoint,
    endPoint,
  };


  return widgetProps;
}

const __getWidgetArrow_anchorPoints = (
  imageProps: Types.ImageProps,
  tailXDelta: number,
  tailYDelta: number
) => {
  const WIDGET_HEAD_OFFSET = 30;
  const WIDGET_TAIL_OFFSET = 10;
  const va = imageProps.viewArea;

  const startPoint: Position = [
    (va.x1 + va.x2) / 2, 
    va.y1 + WIDGET_HEAD_OFFSET
  ];

  const endPoint: Position = [
    (va.x1 + va.x2 - tailXDelta) / 2, 
    va.y2 - WIDGET_TAIL_OFFSET - tailYDelta 
  ];

  return {
    startPoint,
    endPoint
  }
}

const __getWidgetData_rectangle: WidgetDataCreateFn = (
  imageProps: Types.ImageProps,
  style: StyleTemp,
): Types.WidgetRectangleProps => {
  const dimension = __getWidgetData_boxCommon(imageProps);

  const {
    size,
    position
  } = dimension;
  
  const widgetProps: Types.WidgetRectangleProps = {
    ...__getWidgetBase(Types.WidgetType.RECTANGLE, style),
    type: Types.WidgetType.RECTANGLE,
    size,
    position
  }

  return widgetProps;
}

const __getWidgetData_ellipse: WidgetDataCreateFn = (
  imageProps: Types.ImageProps,
  style: StyleTemp,
): Types.WidgetEllipseProps => {
  const dimension = __getWidgetData_boxCommon(imageProps);

  const {
    size,
    position
  } = dimension;
  
  const widgetProps: Types.WidgetEllipseProps = {
    ...__getWidgetBase(Types.WidgetType.ELLIPSE, style),
    type: Types.WidgetType.ELLIPSE,
    size,
    position
  }

  return widgetProps;
}

const __getWidgetData_text: WidgetDataCreateFn = (
  imageProps: Types.ImageProps,
  style: StyleTemp,
): Types.WidgetTextProps => {
  const dimension = __getWidgetData_boxCommon(imageProps);

  const {
    size,
    position
  } = dimension;
  
  const widgetProps: Types.WidgetTextProps = {
    ...__getWidgetBase(Types.WidgetType.TEXT, style),
    type: Types.WidgetType.TEXT,
    size,
    position,
    editorTextState: LexicalTools.getEditorInitStateSerial(),
  }

  return widgetProps;
}

const __getWidgetData_boxCommon = (
  imageProps: Types.ImageProps,
) => {
  const WIDGET_INIT_SIZE = [100, 100] as Size;
  const va = imageProps.viewArea;
    
  const startPoint: Position = [
    (va.x1 + va.x2 - WIDGET_INIT_SIZE[0]) / 2, 
    (va.y1 + va.y2 - WIDGET_INIT_SIZE[1]) / 2
  ];
  
  const widgetData = {
    size: WIDGET_INIT_SIZE,
    position: startPoint,
  }

  return widgetData;
}


/**
 * 
 * Markers
 * 
 */

export const getMarkerProps = (
  repoMarkerAddr: RepoMarkersTypes.MarkerAddr
): Types.MarkerProps => ({
  repoMarkerAddr
});

