import React from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import Logger from 'libs/debug';

import StateLoaderComponent  from './state-setter';
import ImagesLoaderComponent from './images-loader';


const enum State {
  INIT              = 'init',
  DOC_STATES_LOADED = 'doc-states-loaded',
  IMAGES_LOADED     = 'images-loaded',
  // LOADED         = 'loaded'
};

type RenderFunctions = {[state in State]: () => JSX.Element};


interface Props {
  onDone: () => void;
}


export const LoadersComponent: React.FC<Props> = (props: Props) => {
  const { onDone } = props;
  const [state, setState] = useState<State>(State.INIT);

  const log = Logger.getAppLoaders();

  useEffect(() => {
    log.debug(`Editor loader step: ${state}`);

    if (state === State.IMAGES_LOADED) {
      onDone();
    }
  }, [state]);


  const renderLoadInstructionStates = () => (
    <StateLoaderComponent
      onInstructionLoaded={() => setState(State.DOC_STATES_LOADED) }
    />
  );


  const renderLoadImages = () => (
    <ImagesLoaderComponent 
      onLoaded={() => { setState(State.IMAGES_LOADED); }}
    />
  );

  const renderLoaded = () => (
    <React.Fragment></React.Fragment>
  );

  const Renders: RenderFunctions = {
    [State.INIT]:              renderLoadInstructionStates,
    [State.DOC_STATES_LOADED]: renderLoadImages,
    [State.IMAGES_LOADED]:     renderLoaded,
  }

  const rendered = Renders[state];

  return rendered();
}
