import React from 'react';
import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useColumnMenu  from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-custom-fields/column/use-page-column-menu';
import usePageColumnSelectedSet from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-custom-fields/column/use-page-column-selected-set';

import { MainWrapper } from './styles';


interface Props {
  pageColumnAddr: Types.PageColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellContextMenuComponent: React.FC<Props> = (props: Props) => {
  const { 
    pageColumnAddr,
    children
  } = props;

  const getMenuConfig = useColumnMenu();
  const { showMenu  } = useContextMenu();
  const setPageColumnSelected = usePageColumnSelectedSet();

  const handleContextMenu = (event: React.MouseEvent) => {
    setPageColumnSelected({ pageColumnAddr });
    const config = getMenuConfig(pageColumnAddr);

    showMenu({
      event,
      config
    });
  }

  return (
    <MainWrapper onContextMenu={handleContextMenu}>
      { children }
    </MainWrapper>
  );
}
