import { useIsMobile } from 'lego-hooks/use-is-mobile';
import * as Types from '../types';

import MenubarTouchComponent from '../menubar-touch/menubar';
import MenubarClickComponent from '../menubar-click/menubar';


export interface Props extends Types.MenubarProps {
}


export const MenubarComponent: React.FC<Props> = (props: Props) => {
  const isMobile = useIsMobile();

  const Menubar = (
    isMobile ?
    MenubarTouchComponent :
    MenubarClickComponent
  );

  return <Menubar {...props}/>;
}
