import { useEffect } from 'react';
import KeyboardBindings from 'lego/components/key-bindings/arch/keyboard-bindings';
import RegistryKeysDown from 'lego/components/key-bindings/arch/registry-keys-down';
import { KeyBinding   } from 'lego/components/key-bindings/arch/types';


interface Props {
}


export const KeyBindingsComponent: React.FC<Props> = (props: Props) => {
  useEffect(() => {
    window.addEventListener('keyup',   handleKeyUp);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keyup',   handleKeyUp);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  const handleKeyDown = (event: KeyboardEvent) => {
    if ( ! KeyboardBindings.enabled ) {
      return;
    }

    process_bindings(event, KeyboardBindings.keyDownBindings);
    processKeyDown_registry(event);
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if ( ! KeyboardBindings.enabled ) {
      return;
    }

    process_bindings(event, KeyboardBindings.keyUpBindings);
    processKeyUp_registry(event);
  };
  
  const process_bindings = (event: KeyboardEvent, bindings: KeyBinding[]) => {

    for (const binding of bindings) {
      if (binding.check(event)) {
        console.log(`KeyBinding (${event.key}), priority ${binding.priority}`);
        binding.callback(event);
        
        if (binding.preventDefault) {
          event.preventDefault();
        }

        if (binding.stopPropagation) {
          event.stopPropagation();
        }


        if ( ! binding.fallThrough) {
          break;
        }
      } 
    }

    if  ( ! ((event.ctrlKey || event.metaKey) && event.key === 'r') ) {
    }
  }

  const processKeyDown_registry = (event: KeyboardEvent) => {
    RegistryKeysDown.keyDown(event.key);
  }

  const processKeyUp_registry = (event: KeyboardEvent) => {
    RegistryKeysDown.keyUp(event.key);
  }

  return null;
}
