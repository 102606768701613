import * as CustomFieldsTypes from '../../persistent/header-fields/types';
import * as CustomFieldsTools from '../../persistent/header-fields/tools';


//----------------------------
// State
//

export type State = {
  pageColumn: {
    selected: CustomFieldsTypes.PageColumnAddr | null,
  },

  pageCell: {
    selected: CustomFieldsTypes.PageCellAddr | null,
  }
};


/**
 * Page Column
 */

export const getPageColumnSelected = (state: State) => state.pageColumn.selected;

export const isPageColumnSelected  = (
  state: State, 
  pageColumnAddr: CustomFieldsTypes.PageColumnAddr
) => {
  const pageColumnSelected = state.pageColumn.selected;
  const selected = (
    pageColumnSelected !== null &&
    CustomFieldsTools.comparePageColumnAddr(pageColumnAddr, pageColumnSelected)
  );

  return selected;
}


/**
 * Page Cell
 */
export const getPageCellSelected = (state: State) => state.pageCell.selected;

export const isPageCellSelected  = (
  state: State, 
  pageCellAddr: CustomFieldsTypes.PageCellAddr
) => {
  const pageCellSelected = state.pageCell.selected;
  const selected = (
    pageCellSelected !== null &&
    CustomFieldsTools.comparePageCellAddr(pageCellAddr, pageCellSelected)
  );

  return selected;
}


//----------------------------
// Create initial state
//

export const createInitialState = (): State => {
  const state: State = {
    pageColumn: {
      selected: null,
    },

    pageCell: {
      selected: null,
    }
  }

  return state;
}
