import React from 'react';

import GoogleOAuthReadyCheckComponent from './google-oauth-ready-check';
import GoogleLoginBtnComponent from 'app/ui-v2/app/__modules/google-oauth/components/google-login-btn';

import { Panel } from './styles';


interface Props {
  signup?: boolean;
}


export const GoogleAuthenticationComponent: React.FC<Props> = (props: Props) => {
  const signup = (
    props.signup !== undefined ?
    props.signup :
    false
  );

  return (
    <Panel>
      <GoogleOAuthReadyCheckComponent>
        <GoogleLoginBtnComponent signup={signup}/>
      </GoogleOAuthReadyCheckComponent>
    </Panel>
  );
};

