import React from 'react';
import { MutableRefObject } from "react";

import EditorTextComponent from 'app/ui/components/editor-txt';
import EditorTextStyledComponent from "./editor-text-styled";

import Context from './context';
import WidgetEditorTextEditableUpdaterComponent from '../widget-editor-text-editable-updater';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';


interface Props {
  widgetAddr: ContentTypes.WidgetAddr;
  editDisabled: boolean;
  editorTextRef: MutableRefObject<HTMLDivElement | null>;
  EditorTextPlugins: React.ReactNode[];
}


export const WidgetEditorTextComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr,
    editDisabled,
    editorTextRef,
    EditorTextPlugins
  } = props;

  return (
    <>
      <Context.Provider value={{widgetAddr}}>
        <EditorTextComponent
          editorTextRef={editorTextRef}
          disabled={editDisabled}
          Plugins={EditorTextPlugins}
          EditorStyled={EditorTextStyledComponent}
        />
      </Context.Provider>

      {/* Must be located in DOM after EditorTextComponent */}
      <WidgetEditorTextEditableUpdaterComponent
        widgetAddr={widgetAddr}
        editDisabled={editDisabled}
      />
    </>
  );
}
