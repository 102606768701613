import { useEffect }  from 'react';
import { query }      from 'app/arch/backend';
import { InvoicesTypes } from 'app/arch/app/user/states/invoices';
import { useQueryManual } from 'app/arch/backend/use-query-manual';
import { useDebouncedCallback } from 'use-debounce';


type OnDoneFn = (invoices: InvoicesTypes.InvoicesRaw) => void;
type OnErrorFn = (error: string) => void;


const INIT_DELAY = 5000;


export interface Props {
  onDone?: OnDoneFn;
  onError?: OnErrorFn;
}


const useUserInvoicesFetch = (props: Props) => {
  const {
    onDone,
    onError,
  } = props;

  const { data, error, fetch } = useQueryManual({
    query: query.getInvoices(),
  });

  useEffect(() => {
    fetchDeb();
  
    return () => {
      fetchDeb.cancel();
    }
  }, []);

  // It is here so when component
  // remounts twice in Strict mode
  // this would get executed twice.
  // Also serves as a dealy to take load of update of
  // server when app loads.
  const fetchDeb = useDebouncedCallback(() => {
    fetch();
  }, INIT_DELAY);


  useEffect(() => {
    if ( ! data ) {
      return;
    }

    const invoices = data.invoices;
    onDone?.(invoices);
  }, [data]);


  useEffect(() => {
    if ( ! error) {
      return;
    }

    onError?.(error.message)
  }, [error]);
}
  

export default useUserInvoicesFetch;