import React from 'react';
import IconsRepo from 'tools/icon-repo/icons-repo';
import { MarkerViewProps } from '../types';

import { ViewWrapper } from './styles';


interface Props extends MarkerViewProps {
}


export const MarkerViewIconComponent: React.FC<Props> = (props: Props) => {
  const {
    markerProps
  } = props;

  const iconDescriptor = IconsRepo.getIcon(markerProps.icon.addr);
  const Icon = iconDescriptor.Icon;
  const size = markerProps.icon.size;

  return (
    <ViewWrapper 
      style={{
        maxHeight: `${size}px`, 
        maxWidth:  `${size}px`, 
        minHeight: `${size}px`, 
        minWidth:  `${size}px`, 
        height:    `${size}px`, 
        width:     `${size}px`,   
        ...markerProps.icon.css,      
      }}
    >
      <Icon style={{
          width: `100%`,
          height: `100%`,
        }} 
      />
    </ViewWrapper>
  );
}
