import React from 'react';

import LexicalToolbarComponent from 'app/ui-v2/editor-instruction/hls/lexical/lexical-toolbar';
import { useEditor } from 'app/ui-v2/editor-instruction/hooks/use-editor';

import { CellMenuProps } from '../types';
import TopToolbar from '../styles/top-toolbar';


interface Props extends CellMenuProps {
}


export const CellTextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr
  } = props;
  
  const editor = useEditor();
  const lexicalRegistry = editor.lexicalRegistrContent2;
  const lexical = lexicalRegistry.getLexical(cellAddr);

  return (
    <TopToolbar>
      <LexicalToolbarComponent lexical={lexical} />
    </TopToolbar>  
  );
}
  
