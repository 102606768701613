import styled from "styled-components";
import CircleIcon from '@mui/icons-material/Circle';
import MenuItem   from "lego/styles/menu_item";


export const Item = styled(MenuItem)`
  height: 30px;
  gap: ${props => props.theme.defs.gap.small};
  /* background: blue; */
`;

export const IconWrapper = styled.div`
  pointer-events: none;

  display: flex;
  justify-content: center;
  align-items: center;

  width:  26px;
  height: 100%;

  /* background-color: red; */
`;

export const IconScaler = styled.div`
  pointer-events: none;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  
  /* scale: ${props => props.theme.defs.scale.small}; */
  scale: 0.8;
`;


export const TextWrapper = styled.div`
  pointer-events: none;
  flex-grow: 1;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  height: 100%;
  max-width: 120px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* background-color: red; */
`;

export const KeyBindingWrapper = styled.div`
  pointer-events: none;
  
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

/* background-color: red; */
`;

export const BlankIcon = styled(CircleIcon)`
  color: transparent;
`;