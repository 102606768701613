import { useRecoilValue } from 'recoil';

import useKeyBindings from './hooks/key-bindings/use-key-bindings';

import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';
import EditorImageWindowComponent       from './components/editor-image-window';
import EditorImageContentComponent      from './components/editor-image-content';
import EditorImageDeskComponent         from './components/editor-image-desk';
import EditorImageDeskContentComponent  from './components/editor-image-desk-content';
import EditorImageWidgetInfoComponent   from './components/editor-image-widget-info';
import EditorImageContentMenusComponent from './components/editor-image-content-menus';
import MultiWidgetsSelectorComponent    from './components/multi-widgets-selector';
import EditorImageDeskResetSelectedComponent from './components/editor-image-desk-reset-selected';


interface Props {
}


export const EditorImageComponent: React.FC<Props> = (props: Props) => {
  useKeyBindings();
  const imageAddr = useRecoilValue(UIState_EditorImageSession.imageEdited);

  if (imageAddr === null) {
    return null;
  }

  return (
    <EditorImageWindowComponent>
      <EditorImageWidgetInfoComponent>
        <EditorImageContentMenusComponent>
          <EditorImageContentComponent imageAddr={imageAddr}>
            <EditorImageDeskComponent imageAddr={imageAddr} >
              {/* <MultiWidgetsSelectorComponent imageAddr={imageAddr} > */}
                <EditorImageDeskResetSelectedComponent>
                  <EditorImageDeskContentComponent imageAddr={imageAddr} />
                </EditorImageDeskResetSelectedComponent>
              {/* </MultiWidgetsSelectorComponent> */}
            </EditorImageDeskComponent>
          </EditorImageContentComponent>
        </EditorImageContentMenusComponent>
      </EditorImageWidgetInfoComponent>
    </EditorImageWindowComponent>
  );
}

