import styled from "styled-components";
import ToolbarHorizontal from "lego/styles/toolbar-horizontal";


const TopToolbar = styled(ToolbarHorizontal)`
  /* gap: ${props => props.theme.defs.gap.small}; */
  background: ${props => props.theme.defs.colors.background.nine};
`;


export default TopToolbar;