
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";
import { RecoilRoot    } from 'recoil';

import { ApolloProvider } from '@apollo/client';

import reportWebVitals from './reportWebVitals';
import router from './routes/router';
import { graphqlClientAuth } from './graphql/graphql';

import app from 'app/arch/app';
import Logger from 'libs/debug';
import AppComponent   from 'app/ui/components/app';
import ThemeComponent from 'app/ui/components/app/theme';
import GoogleOAuthProviderComponent from 'app/ui-v2/app/__modules/google-oauth/components/google-oauth-provider';

import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

app.auth.init();
Logger.init();

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ApolloProvider client={graphqlClientAuth}>
        <ThemeComponent>
          <GoogleOAuthProviderComponent>
            <AppComponent>
              <RouterProvider router={router} />
            </AppComponent>
          </GoogleOAuthProviderComponent>
        </ThemeComponent>
      </ApolloProvider>
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
