import styled from "styled-components";
import ListItemBase from 'lego/styles/list-item';
import TextWrap from "lego/styles/text-wrap";



export const MainWrapper = styled.div<{$selected: boolean}>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.normal};

  box-sizing: border-box;

  border-radius: ${props => props.theme.defs.border.radius.normal};
  border-width:  ${props => props.theme.defs.border.width.small};

  border-color: ${props => 
    props.$selected ? 
    props.theme.defs.accentColor.primary : 
    "transparent"
  };

  border-style: ${props => 
    props.$selected ? 
    "solid" :
    "none"
  };

  padding: ${props => 
    props.$selected ? 
    props.theme.defs.padding.medium :
    "0px"
  };

  margin:  ${props => 
    props.$selected ? 
    props.theme.defs.padding.medium :
    "0px"
  };
  margin-left: 0px;
  margin-right: 0px;

`;

export const ListItem = styled(ListItemBase)`
  ${props => props.selected ? `
    background: transparent;
    color: ${props.theme.menuItem.color.primary};
  ` : ''
  }
`;


export const MemberUsername = styled(TextWrap)`
  width: 100%;
  font-size: ${props => props.theme.defs.font.size.large};
`;
