import React from 'react';

import { settings } from 'app/configs';

import DraggerComponent  from 'lego-v2/dragger/ui';
import { DraggerProps  } from 'lego-v2/dragger/ui/Dragger';
import { DraggerUpdate } from 'lego-v2/dragger/arch';

import { StickyLinesRaw   } from 'tools/smart-lines/types';
import { StickyLinesApply } from 'tools/smart-lines/types';

import SmartLinesControlComponent from '../smart-lines-control';
import { SmartLinesControlProps } from '../smart-lines-control/SmartLinesControl';
import useIsKeyDown from '../key-bindings/hooks/use-is-key-down';



interface Props extends SmartLinesControlProps<DraggerUpdate> {
  controlProps: DraggerProps;
}


export const DraggerSmartLinesComponent: React.FC<Props> = (props: Props) => {
  const SmartDragger = SmartLinesControlComponent<DraggerUpdate, DraggerProps>;
  const isKeyDown = useIsKeyDown();

  const handleApplyStickyLines = (update: DraggerUpdate, stickyLines: StickyLinesRaw) => {
    if (isKeyDown(settings.actionKey.smartLinesDisabled)) { 
      return update;
    }

    const {
      position:  stickedPosition,
      deltaMove: stickedDeltaMove,
    } = StickyLinesApply(
      stickyLines,
      update.position, 
      update.delta
    );

    update.position = stickedPosition;
    update.delta    = stickedDeltaMove;

    return update;
  }

  return (
    <SmartDragger
      {...props}
      onApplyStickyLines={handleApplyStickyLines}
      ControlProps={props.controlProps}
      ControlComponent={DraggerComponent}
    />
  );
}
