import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import { ContentTypes as ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { useEditor } from "app/ui-v2/editor-instruction/hooks/use-editor";


interface Props {
  widgetAddr: ContentTypes.WidgetAddr,
}


export const RegistrationPlugin: React.FC<Props> = (props: Props) => {
  const { 
    widgetAddr,
  } = props;

  const editor = useEditor();
  const [lexical] = useLexicalComposerContext();

  useEffect(() => {
    const registry = editor.lexicalRegistrContent2Widgets;
    registry.addLexical(widgetAddr, lexical);

    return () => {
      registry.removeLexical(widgetAddr);
    };
    
  }, [lexical]);

  return null;
}

