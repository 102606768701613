import { RefObject } from 'react';

import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import useWidgetEdit   from 'app/ui-v2/editor-image/hooks/widget/use-widget-edit';
import useCaretOnPoint from './use-caret-on-point-set';

import { MainWrapper } from "./styles";


interface Props {
  widgetAddr: ContentTypes.WidgetAddr;
  editorTextRef: RefObject<HTMLDivElement | null>;
  editDisabled: boolean;

  children: React.ReactNode;
}



// This should be called WidgtetEditorTextEdit
export const WidgetEditorTextEditComponent: React.FC<Props> = (props: Props) => {
  const {
    editorTextRef,
    widgetAddr,
    editDisabled,

    children,
  } = props;

  const docState = useDocState();
  const editWidget = useWidgetEdit();
  const setCaretOnPoint = useCaretOnPoint({
    editorTextRef,
  });

  const handleEdit = (event: React.MouseEvent) => {
    const widgetEdit = docState.editorImageSession.getWidgetEdited();
    if ( widgetEdit !== null && ContentTools.compareWidgetAddr(widgetEdit, widgetAddr) ) {
      // Do nothing if is already being edited, 
      // and let double click be handle by underlying
      // EditorText - to select text underneath cursor
      return;
    }

    editWidget({
      widgetAddr,
      editDisabled,
    });

    setCaretOnPoint(event.clientX, event.clientY);
  }


  return (
    <MainWrapper onDoubleClick={handleEdit}>
    { children }
    </MainWrapper>
  );
}
