import styled from "styled-components";


export const StyledSmartLine = styled.div`
  position: absolute;
  height: 1px;
  width:  1px;

  background: ${props => props.theme.defs.accentColor.primary};
  opacity: 0.4;
`;
