import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import jtl from 'tools/jtl';

import { SmartLinesWidgets  } from 'tools/smart-lines/smart-lines-widgets';
import { SmartLinesAnalyzer } from 'tools/smart-lines/smart-lines-analyzer';
import { StickyLinesApply   } from 'tools/smart-lines/types';
import { StickyLinesRaw     } from 'tools/smart-lines/types';

import { Position } from 'app/arch/types';
import { Size     } from 'app/arch/types';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';

import { DeltaMove   } from 'app/ui/hooks';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { UIState_EditorImageSession }  from 'app/ui/states/editor-instruction';
import { UIState_EditorImageSettings } from 'app/ui/states/editor-instruction';

import { useSmartLinesWidgetsSrcLinesSetter }    from '../../../hooks/smart-lines/use-smart-lines-widgets-src-lines-setter';
import { useSmartLinesWidgetsLinesSetter }       from '../../../hooks/smart-lines/use-smart-lines-widgets-lines-setter';
import { useSmartLinesWidgetsStickyLinesSetter } from '../../../hooks/smart-lines/use-smart-lines-widgets-sticky-lines-setter';

import WidgetsContextMenuComponent from './widgets-context-menu';

import { SelectedArea }                 from './styles';
import { WidgetsContextMenuFiller }     from './styles';
import { WidgetsContextMenuPositioner } from './styles';
import { useDebouncedCallback } from 'use-debounce';


const BOX_OFFSET = 6;
const DRAG_DEBOUNCE_DELAY = 1;


interface Props {
  scale: number;
  imageAddr: ContentTypes.ImageAddr;
}


export const SelectedWidgetsBoxComponent: React.FC<Props> = (props: Props) => {
  const {
    scale,
    imageAddr,
  } = props;

  const document = useDocState();
  const selectedWidgetsAddrs = useRecoilValue(UIState_EditorImageSession.multiSelectionSelectedWidgets) || [];
  const smartLinesSettings   = useRecoilValue(UIState_EditorImageSettings.smartLines);

  const [draggingStartPosition, setDraggingStartPosition] = useState<Position | null>(null);
  const [widgetsStartProperties, setWidgetsStartPositions] = useState<any>({});
  const [dragging, setDragging] = useState(false);
  const [_, setRender]          = useState<any>({});
  const [contextMenuPosition, setContextMenuPosition] = useState<null | Position>(null);
  const widgetsContextMenuWrapperRef = useRef<HTMLDivElement>(null);

  const {
    setContent
  } = useEditorStatesSetters();

  const {
    setStickyLines,
    unsetStickyLines,
  } = useSmartLinesWidgetsStickyLinesSetter();

  const {
    setSrcLines,
    unsetSrcLines
  } = useSmartLinesWidgetsSrcLinesSetter();

  const {
    setSmartLines,
    unsetSmartLines
  } = useSmartLinesWidgetsLinesSetter();

  const updateGlobalState = useDebouncedCallback(() => {
    if ( ! dragging ) {
      return;
    }
    setContent();
    setRender({});

  }, DRAG_DEBOUNCE_DELAY);

  if (selectedWidgetsAddrs.length === 0) {
    return null;
  }

  const widgetsAddrsAll = document.content.cellImages_image_getWidgetsAddrs(imageAddr);
  const notSelectedWidgetsAddrs = widgetsAddrsAll.filter(widgetAddr => (! selectedWidgetsAddrs.includes(widgetAddr)));
  const selectedWidgetsProps = selectedWidgetsAddrs.map(widgetAddr => document.content.cellImages_image_getWidgetProps(widgetAddr));

  const widgetsBBox =  ContentTools.getWidgetsBBox(selectedWidgetsProps);

  const area = {
    x: widgetsBBox.x,
    y: widgetsBBox.y,
    width:  widgetsBBox.width,
    height: widgetsBBox.height,
  }

  const getAreaStyle = () => {
    const areaStyle = {
      left:   `${area.x - BOX_OFFSET}px`,
      top:    `${area.y - BOX_OFFSET}px`,
      width:  `${area.width  + 2 * BOX_OFFSET}px`,
      height: `${area.height + 2 * BOX_OFFSET}px`
    }

    return areaStyle;
  }


  /**
   * Handle Point Down
   */
  const handlePointerDown = (event: React.PointerEvent) => {
    // if (event.button === 2) {
    //   return;
    // }

    event.stopPropagation();
    setDragging(true);
    setDraggingStartPosition([event.clientX, event.clientY]);

    // Remember widgets start positions
    //--------------------
    const widgetsStartPositions = selectedWidgetsAddrs.reduce((result: any, widgetAddr) => {
    
      const widgetProps = document.content.cellImages_image_getWidgetProps(widgetAddr);
      const widgetPropsCopy = jtl.object.copy(widgetProps);

      const widgetKey = ContentTools.getWidgetKey(widgetAddr);
      result[widgetKey] = widgetPropsCopy;

      return result;
    }, {});
    setWidgetsStartPositions(widgetsStartPositions);


    // Set src smart lines
    //--------------------
    const srcSmartLines = new SmartLinesWidgets();
    srcSmartLines.loadWidgets(selectedWidgetsProps);
    setSrcLines(srcSmartLines.raw);

    // Dst smart lines
    //--------------------
    setSmartLines({
      srcWidgetsAddrs: selectedWidgetsAddrs,
      dstWidgetsAddrs: notSelectedWidgetsAddrs
    });
  }


  /**
   * Handle Point Move
   */
  const handlePointerMove = (event: React.PointerEvent) => {
    // if (contextMenuPosition !== null) {
    //   event.stopPropagation();
    //   return;
    // }

    if ( ! dragging ) return;
    event.stopPropagation();

    const dx = (event.clientX - draggingStartPosition![0]) / scale;
    const dy = (event.clientY - draggingStartPosition![1]) / scale;
    updateWidgets(dx, dy);
    updateGlobalState();
  }

  const updateWidgets = (dx: number, dy: number) => {
    const stickyLines = getStickyLines(dx, dy);
    setStickyLines(stickyLines);

    if ( ! stickyLines.horizontal && ! stickyLines.vertical) {
      // console.log("not sticky")
      updateWidgetFree(dx, dy);
    }
    else {
      // console.log("sticky");
      updateWidgetSticky(dx, dy, stickyLines);
    }    
  }

  /**
   * Handle Point Up
   */
  const handlePointerUp = (event: React.PointerEvent) => {
    if ( ! dragging ) {
      return;
    }
    event.stopPropagation();
    finishDragging();
  }
  

  /**
   * Handle Point Leave
   */
  const handlePointerLeave = (event: React.MouseEvent) => {
    if ( ! dragging ) {
      return;
    }
    finishDragging();
  }
  

  const getStickyLines = (dx: number, dy: number) => {
    // Sticky lines
    const srcSmartLines = new SmartLinesWidgets();

    // TODO multiselection widget type based specialization 
    // for size and position processing
    selectedWidgetsAddrs.forEach((widgetAddr) => {
      const widgetProps = document.content.cellImages_image_getWidgetProps(widgetAddr);
      const widgetKey = ContentTools.getWidgetKey(widgetAddr);
      const widgetStartProps = widgetsStartProperties[widgetKey];

      let widgetStartPosition: Position = [0, 0];
      let widgetSize: Size = [0, 0];

      if (ContentTools.isWidgetBoxed(widgetProps.type)) {
        const widgetBoxedProps = widgetProps as ContentTypes.WidgetBoxedProps;
        widgetSize = widgetBoxedProps.size;
        widgetStartPosition = widgetStartProps.position;

        const position = [
          widgetStartPosition[0] + dx,
          widgetStartPosition[1] + dy,
        ] as Position;
  
        const ok = srcSmartLines.loadCustom(widgetSize, position, widgetProps.style);

      }
      else if (ContentTools.isWidgetArrowText(widgetProps.type) ) {
        // fix me
        const endPoint = widgetStartProps.endPoint;
        const tailSize = widgetStartProps.tailSize;

        let position = [
          endPoint[0] - tailSize[0] / 2 + dx,
          endPoint[1] - tailSize[1] / 2 + dy
        ] as Position;
  
        const ok = srcSmartLines.loadCustom(
          tailSize, 
          position, 
          widgetProps.style
        );
      }
      else if (ContentTools.isWidgetSmartLess(widgetProps.type) ) {
        // Plain arrow
        // skip
      }
      else {
        console.warn(`Not implemented for ${widgetProps.type}`);
        return;
      }
    });

    const dstWidgetsProps = notSelectedWidgetsAddrs.map(widgetAddr => document.content.cellImages_image_getWidgetProps(widgetAddr));
    const dstSmartLines = new SmartLinesWidgets();
    dstSmartLines.loadWidgets(dstWidgetsProps);

    const analyzer = new SmartLinesAnalyzer(
      srcSmartLines.raw,
      dstSmartLines.raw
    );
    const stickyLines = analyzer.getStickyLines(smartLinesSettings.stickyLines.stickyness);
    return stickyLines;
  }

  const updateWidgetFree = (dx: number, dy: number) => {
    selectedWidgetsAddrs.forEach((widgetAddr) => {
      const widgetKey = ContentTools.getWidgetKey(widgetAddr);
      const widgetProps = document.content.cellImages_image_getWidgetProps(widgetAddr);

      const widgetStartProps = widgetsStartProperties[widgetKey];

      if (ContentTools.isWidgetBoxed(widgetProps.type)) {
        const position = widgetStartProps.position;
        const newPosition = [
          position[0] + dx,
          position[1] + dy
        ] as Position;

        document.content.cellImages_image_widget_setPosition(
          widgetAddr,
          newPosition
        );
      }
      else if (ContentTools.isWidgetArrow(widgetProps.type)) {
        const startPoint = widgetStartProps.startPoint;
        const endPoint   = widgetStartProps.endPoint;

        const newStartPoint = [
          startPoint[0] + dx,
          startPoint[1] + dy
        ] as Position;

        const newEndPoint = [
          endPoint[0] + dx,
          endPoint[1] + dy
        ] as Position;

        const newPosition = {
          startPoint: newStartPoint, 
          endPoint: newEndPoint
        }

        document.content.cellImages_image_widget_setPosition(
          widgetAddr,
          newPosition
        );
      }
      else {
        console.warn('implement me!');
      }
    });
  }

  const updateWidgetSticky = (dx: number, dy: number, stickyLines: StickyLinesRaw) => {
    selectedWidgetsAddrs.forEach((widgetAddr) => {
      const widgetKey = ContentTools.getWidgetKey(widgetAddr);
      const widgetProps = document.content.cellImages_image_getWidgetProps(widgetAddr);
      const widgetStartProps = widgetsStartProperties[widgetKey];

      if (ContentTools.isWidgetBoxed(widgetProps.type)) {
        const position = widgetStartProps.position;
        const newPosition = [
          position[0] + dx,
          position[1] + dy
        ] as Position;

        const delta: DeltaMove = { x: dx, y: dy };
        const { position: newPositionSticked } = StickyLinesApply(
          stickyLines,
          newPosition,
          delta
        );
  
        document.content.cellImages_image_widget_setPosition(
          widgetAddr,
          newPositionSticked
        );
      }
      else if (ContentTools.isWidgetArrow(widgetProps.type)) {
        const startPoint = widgetStartProps.startPoint;
        const endPoint   = widgetStartProps.endPoint;

        const newStartPoint = [
          startPoint[0] + dx,
          startPoint[1] + dy
        ] as Position;

        const newEndPoint = [
          endPoint[0] + dx,
          endPoint[1] + dy
        ] as Position;

        const deltaMove: DeltaMove = { x: dx, y: dy };

        const { position: newStartPointSticked } = StickyLinesApply(
          stickyLines,
          newStartPoint, 
          deltaMove,
        );

        const { position: newEndPointSticked } = StickyLinesApply(
          stickyLines,
          newEndPoint, 
          deltaMove,
        );

        const newPosition = {
          startPoint: newStartPointSticked,
          endPoint: newEndPointSticked
        }

        document.content.cellImages_image_widget_setPosition(
          widgetAddr,
          newPosition
        );
      }
      else {
        console.warn('implement me!');
      }
    });
  }



    

  const finishDragging = () => {
    updateGlobalState.flush();
    setContent();
    setDragging(false);

    unsetSmartLines();
    unsetStickyLines();
    unsetSrcLines();
  }

  const handleContextMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const position = [
      event.clientX,
      event.clientY,
    ] as Position;

    setContextMenuPosition(position)
  }

  const handleContextMenuPointerDown = (event: React.MouseEvent) => {
    if (event.button === 2) {
      // Otherwise desk dragger will kick in
      event.stopPropagation();
    }
  }

  const handleContextMenuHide = () => {
    setContextMenuPosition(null);
  }
  
  const getContextMenuStyle = (): any => {
    if (contextMenuPosition === null) {
      return {
        display: 'none'
      }
    }

    const offset = [0, 0] as Position;
    let parent : any= widgetsContextMenuWrapperRef.current !;

    while(parent) {
      offset[0] += parent.offsetLeft;
      offset[1] += parent.offsetTop;
      parent = parent.offsetParent;
    }

    const left = contextMenuPosition[0] - offset[0];
    const top  = contextMenuPosition[1] - offset[1];

    return {
      left: `${left}px`,
      top:  `${top}px`,
    }
  }

  return (
    <>
    {
      selectedWidgetsAddrs &&
      <SelectedArea 
        $dragging={dragging}
        style={getAreaStyle()} 
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        onPointerLeave={handlePointerLeave}
      >
      {
        <WidgetsContextMenuFiller
          onContextMenu={handleContextMenu}
          onPointerDown={handleContextMenuPointerDown}
          ref={widgetsContextMenuWrapperRef}
        >
          <WidgetsContextMenuPositioner
            style={getContextMenuStyle()}
          >
            <WidgetsContextMenuComponent 
              onHide={handleContextMenuHide}
              imageAddr={imageAddr}
              widgetsAddrs={selectedWidgetsAddrs}
            />
          </WidgetsContextMenuPositioner>
        </WidgetsContextMenuFiller>
      }
      </SelectedArea>
    }
    </>
  );
}

