import styled from "styled-components";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.xsmall};
`;


export const ColorRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: inherit;
`;
