import React from 'react';
import { useRecoilValue } from 'recoil';

import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';
import { UIState_ViewsCommon } from 'app/ui/states/editor-instruction';
import useColumnCSS from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/column/use-column-css';
import useCellsRowContext from '../../../../../hooks/use-cells-row-context';

import { HeaderCell } from './styles';


interface Props {
  cellAddr: Types.CellAddr;
  children: React.ReactNode;
}


export const CellIndexColumnCssComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr, 
    children,
  } = props;

  const tableHeaderCSS  = useRecoilValue(UIState_ViewsCommon.tableHeaderCSS);
  const columnCSS       = useColumnCSS(cellAddr);
  const rowSelected     = useRecoilValue(UIState_ContentSession.isRowSelected(cellAddr));
  const sectionSelected = useRecoilValue(UIState_ContentSession.isSectionSelected(cellAddr));
  
  const cellsRowContext = useCellsRowContext();
  const rowAdder = cellsRowContext.rowAdder;

  const cellSelected = (
    rowSelected || 
    (sectionSelected && ! rowAdder)
  );

  const {
    color: columnColor,
    background: columnBackground,
    ...columnStyle
  } = columnCSS;

  const {
    color: headerColor,
    background: headerBackground,
  } = tableHeaderCSS;

  const color = (
    ! rowAdder ?
    headerColor as string :
    columnColor as string
  );

  const background = (
    ! rowAdder ?
    headerBackground as string :
    columnBackground as string
  );

  return (
    <HeaderCell 
      style={columnStyle}

      $selected={cellSelected}
      $color={color}
      $background={background}
    >
      { children }
    </HeaderCell>
  );
}
