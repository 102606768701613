import React from 'react';

import PanelTitledComponent from 'app-views/components/panel-titled';
import { DocumentRelease }  from 'app/arch/backend/types';
import { useTranslations }  from 'app/ui/hooks/app/use-translation';
import useDocumentReleaseDownload from 'app/ui-v2/app/hooks/use-document-release-download';
import ProgressBoxComponent from 'app/ui-v2/hls/progress-box';
import useDocReleasesFetch  from 'app/ui-v2/home-page/home-page-user/hooks/use-doc-releases-fetch';

import ReleaseItemComponent from '../release-item';

import { PanelHeader } from './styles';
import { RevisionHeader } from './styles';
import { ReleaseDateHeader } from './styles';
import { ProgressWrapper } from './styles';
import { Text } from './styles';
import { TextWrap } from './styles';
import { TextWrapper } from './styles';



interface Props {
  documentId: number;
}


export const ReleasesListComponent: React.FC<Props> = (props: Props) => {
  const {
    documentId
  } = props;

  const t = useTranslations();
  const downloadDocumentRelease = useDocumentReleaseDownload();

  const {
    document,
    docReleases,
    loaded
  } = useDocReleasesFetch({
    documentId,
    skipMsgs: true
  });
 

  const handleReleseSelect = (release: DocumentRelease) => {
    const revision = release.revision;

    downloadDocumentRelease({
      documentId, 
      revision,
    });
  }

  const renderPanelHeader = () => {
    return (
      <PanelHeader>

        <RevisionHeader>
          <TextWrap>
            { t("document revision, header") }
          </TextWrap>
        </RevisionHeader>

        <ReleaseDateHeader >
          <TextWrap>
            { t("document release date, header") }
          </TextWrap>
        </ReleaseDateHeader >
        
      </PanelHeader>
    );
  }

  const renderRelease = (
    release: DocumentRelease, 
  ) => (
    <ReleaseItemComponent 
      key={release.id}
      release={release}
      onReleseSelect={handleReleseSelect}
    />
  );

  const renderReleases = () => {
    const ReleasesComps = docReleases.map((release: DocumentRelease) => renderRelease(release));
    return ReleasesComps;
  }


  const displayProgress = (! loaded);
  const displayReleases = (loaded && docReleases.length > 0);
  const displayNoReleaseInfo = (loaded && docReleases.length === 0);

  return (
    <>
    { 
      displayProgress &&
      <ProgressWrapper>
        <ProgressBoxComponent
          title={t("document releases loading")}
        />
      </ProgressWrapper>
    }
    
    {
      displayReleases &&
      <PanelTitledComponent
        title={t('document releases')}
        Header={ 
          <>{ renderPanelHeader() }</> 
        }
      >
        { renderReleases() }
      </PanelTitledComponent>
    }

    {
      displayNoReleaseInfo &&
      <TextWrapper>
        <Text>
          { t("document, no releases info")}
        </Text>
      </TextWrapper>
    }
    </>
  );
}
  
