import { useContext } from "react";
import Context from "../components/document-states/desk-scroller-ref/context";


const useDeskScrollerState = () => {
  const context = useContext(Context);
  return context;

}


export default useDeskScrollerState;