import React from 'react';

import { CellPropsBase } from '../types';

import ColumnCssComponent             from '../parts/column-css';
import CellOverflowClipperComponent   from '../parts/cell-overflow-clipper';
import CellSelectedBorderComponent    from '../parts/cell-selected-border';
import CellSelectComponent            from '../parts/cell-select';
import CellsLineSelectedComponent     from '../parts/cells-line-selected';
import CellImagesDropComponent        from './cell-images-drop';
import CellImagesContextMenuComponent from './cell-images-context-menu';
import ImagesComponent                from './images';
import ImagesUploadingComponent       from './images-uploading';


interface Props extends CellPropsBase {
}


export const CellImagesComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
  } = props;
  
  return (
    <CellSelectedBorderComponent cellAddr={cellAddr} >
      <CellOverflowClipperComponent>
        <CellsLineSelectedComponent cellAddr={cellAddr} >
          <CellSelectComponent cellAddr={cellAddr} >
            <CellImagesContextMenuComponent cellAddr={cellAddr} >
              <CellImagesDropComponent cellAddr={cellAddr} >
                <ColumnCssComponent cellAddr={cellAddr} >
                  <ImagesComponent cellAddr={cellAddr} /> 
                  <ImagesUploadingComponent cellAddr={cellAddr} />
                </ColumnCssComponent>
              </CellImagesDropComponent>
            </CellImagesContextMenuComponent>
          </CellSelectComponent>
        </CellsLineSelectedComponent>
      </CellOverflowClipperComponent>
    </CellSelectedBorderComponent>
  );
}
