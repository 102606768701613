import { useRecoilValue } from 'recoil';

import { PanelDraggableConfig } from 'lego/components/panel-draggable/config';

import { Icon }               from "app/ui/icons/icons";
import { useDocState }        from "app/ui/contexts/document";
import { UIState_Content } from 'app/ui/states/editor-instruction';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { ContentTools, ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';


// This has been started after states migration but not finished
// this is used by draggable panel to move columns up and down in 
// columns stack
const usePanelConfig = () => {
  const document = useDocState();

  const columnsAddrs = useRecoilValue(UIState_Content.columnsAddrs);
  // const columnsVisibleIds     = useRecoilValue(UIState_DocOutlook.columnsIds_columnVisible);
  // const columnsNameVisibleIds = useRecoilValue(UIState_DocOutlook.columnsIds_columnNameVisible);

  const {
    setContent,
  } = useEditorStatesSetters();


  const handleShowColumn = (
    columnAddr: ContentTypes.ColumnAddr, 
  ) => {
    // document.docOutlook.showColumn(columnId);
    // document.saveUndo();
  }

  const handleHideColumn = (
    columnAddr: ContentTypes.ColumnAddr, 
  ) => {
    // document.docOutlook.hideColumn(columnId);
    // document.saveUndo();
  }
  
  const handleShowColumnTitle = (
    columnAddr: ContentTypes.ColumnAddr, 
  ) => {
    // document.docOutlook.showColumnName(columnId);
    // document.saveUndo();
    // setDocOutlook();
  }

  const handleHideColumnTitle = (
    columnAddr: ContentTypes.ColumnAddr, 
  ) => {
    // document.docOutlook.hideColumnName(columnAddr);
    // document.saveUndo();
    // setDocOutlook();
  }

  const handleItemMove = (srcIdx: number, dstIdx: number) => {
    // document.docOutlook.moveColumnByIdx(srcIdx, dstIdx);
    // document.saveUndo();
    // setDocOutlook();
  }

  const handleSetColumnName = (
    columnAddr: ContentTypes.ColumnAddr, 
    columnName: string
  ) => {
    document.content.updateColumn(columnAddr, {name: columnName});
    document.saveUndo();
    setContent();
  }


  const getConfig = () => {
    const items = columnsAddrs.map((columnAddr) => {
      const key = ContentTools.getColumnKey(columnAddr);

      // const title = document.docContent2.getColumn(columnId).name;
      const title = document.content.getColumnName(columnAddr);
      const editable = true;

      // const columnVisible = columnsVisibleIds.includes(columnId);
      // const titleVisible  = columnsNameVisibleIds.includes(columnId);
  
      const columnVisible = true;
      const titleVisible = true;
      
      const IconColumnVisible      = columnVisible ? Icon.Show : Icon.Hide;
      const IconColumnTitleVisible = titleVisible  ? Icon.Title.Visible : Icon.Title.Hidden;
      
      const onToggleColumnVisible = () => { 
        columnVisible ?
          handleHideColumn(columnAddr) :
          handleShowColumn(columnAddr);
      }
  
      const onToggleColumnTitleVisible = () => { 
        titleVisible ?
          handleHideColumnTitle(columnAddr) :
          handleShowColumnTitle(columnAddr);
      }
  
      return {
        key,
        value: title,
        editable,
        onValueChangeDone: (value: string) => handleSetColumnName(columnAddr, value),
        buttons: [
          {
            Icon: IconColumnTitleVisible,
            onClick: onToggleColumnTitleVisible
          },
          {
            Icon: IconColumnVisible,
            onClick: onToggleColumnVisible
          },
        ]
      }
    })
  
    const config: PanelDraggableConfig = {
      items,
      onItemMove: (srcIdx: number, dstIdx: number) => handleItemMove(srcIdx, dstIdx),
    }
  
    return config;
  }
  
  const hook = () => {
    const config = getConfig();
    return config;
  }

  return hook;
}


export default usePanelConfig;