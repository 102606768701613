import { useEffect } from 'react';
import i18next from 'i18next';

import jtl from 'tools/jtl';

import { query }             from 'app/arch/backend';
import { useQuery }          from 'app/arch/backend/use-query';
import { CssStylePack }      from 'app/arch/editor-instruction/css-styles';
import { PrintReleaseInfoTools } from 'app/arch/editor-instruction/document/states/printout/printout-release-info';
import { useInstruction }    from 'app/ui/hooks/editor-instruction/use-instruction';
import { useIsPrintout }     from 'app/ui/components/editor-instruction/use-is-printout';
import { useDocState }       from 'app/ui/contexts/document';

import { useReloadRecoilStates } from './use-reload-recoil-states';
import useDocument from 'app/ui/contexts/document/use-document';


interface Props {
  onInstructionLoaded: () => void;
}


// TODO turn it into hook
export const StateLoaderComponent: React.FC<Props> = (props: Props) => {
  const { onInstructionLoaded } = props;
  const docState = useDocState();
  const document = useDocument();

  const instruction = useInstruction();
  
  const t = i18next.t;
  const isPrintout = useIsPrintout();
  
  const { 
    reloadAllStates,
    reloadInstructionState,
    reloadDocImagesState,
    reloadWidgetStylesState,
    reloadContentStates,
    reloadSessionStates,

    loadDocumentState
  
  } = useReloadRecoilStates();

  //
  // Set initail/default states
  //
  useEffect(() => { 
    reloadAllStates();
  }, []);

  const { loading, error, data: instructionData } = useQuery(
    query.getInstructionPack(instruction.id !),
    {
      onStart: {
        msg: t("loading instruction")
      },
      onEnd: {
        msg: t("instruction loaded")
      }
    }  
  );

  //
  // Setting state loaded from backend
  //
  useEffect(() => {
    if ( ! instructionData) return;

    const pack = instructionData.instructionPack;

    loadInstructionState(pack);
    loadRepoImagesState(pack);
    loadWidgetsStylesState(pack);
    loadPrintoutReleaseInfoState(pack);
    loadContentStates(pack);
    
  }, [instructionData]);


  //
  // Load instruction state
  //
  const loadInstructionState = (pack: any) => {
    docState.instruction.setInstructionId(+pack.instruction.id); 
    docState.instruction.setRevision(pack.instruction.revision);
    reloadInstructionState();
  }


  //
  // Load document images state
  //
  const loadRepoImagesState = (pack: any) => {
    docState.repoImages.loadImages(pack.images);
    reloadDocImagesState();
  }


  //
  // Load widget styles state
  // 
  const loadWidgetsStylesState = (instructionPack: any) => {
    const widgetsStyles = instructionPack.widgetsStyles;
    const widgetsStylesPredefined = instructionPack.widgetsStylesPredefined;
    
    const stylesDeserialized: CssStylePack[] = [];
    const stylesPredefinedDeserialized: CssStylePack[] = [];

    widgetsStyles.forEach((widgetStyle: any) => {
      const style = JSON.parse(widgetStyle.style);
      const stylePack = {
        ...widgetStyle,
        style
      }
      stylesDeserialized.push(stylePack);
    });

    widgetsStylesPredefined.forEach((widgetStyle: any) => {
      const style = JSON.parse(widgetStyle.style);
      const stylePack = {
        ...widgetStyle,
        style
      }
      stylesPredefinedDeserialized.push(stylePack);
    });

    docState.editorImageWidgetsStyles.setStyles(stylesDeserialized);
    docState.editorImageWidgetsStyles.setStylesPredefined(stylesPredefinedDeserialized);

    reloadWidgetStylesState();
  }

  const loadPrintoutReleaseInfoState = (instructionPack: any) => {
    // This state will be only used for printout.
    // While editing document, data from this state is not used.
    const releaseInfoSerial = instructionPack.instructionDraft.releaseInfo;
    if (releaseInfoSerial === null) {
      return;
    }

    const releaseInfo = JSON.parse(releaseInfoSerial);
    const stateValid = PrintReleaseInfoTools.isStateValid(releaseInfo);

    if ( ! stateValid ) {
      const msg = "Invalid PrintoutReleaseInfo state";
      throw new Error(msg);
    }

    docState.printoutReleaseInfo.state = releaseInfo;
    // There is no need to reload recoil.
    // It will be render based on data from state 
    // directly (no state changes on print).
  }

  //
  // Load content (and other) states
  // 
  const loadContentStates = (instructionPack: any) => {
    const instruction = (
      isPrintout ?
      instructionPack.instructionDraft :
      instructionPack.instruction
    );

    const statesSerialized = instruction.content;

    //
    // All states are blank
    //
    if (statesSerialized.length === 0) {
      onInstructionLoaded();      
      return;
    }

    const stateDeserialized = jtl.serialize.deserialize(statesSerialized);

    // All states are available
    //
    loadDocumentState(stateDeserialized);

    document.statesLoaded();

    reloadContentStates();
    onInstructionLoaded();      
  }

  return null;
}
