import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { useMutation } from 'app/arch/backend/use-mutation';
import { mutation } from 'app/arch/backend';
import { useDocState } from 'app/ui/contexts/document';
import { UIState_EditorImageWidgetsStyles } from 'app/ui/states/editor-instruction';
import { MsgDuration } from '../../../ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { MutationProps } from './types';
import { OnDoneFn } from './types';


export const useDeleteWidgetStyle = () => {
  // TODO FIXME - can be overwritten
  // if multiply calls at once
  // should be some kind of list/container
  const onDoneRef = useRef<null | undefined | OnDoneFn>(null);

  const document = useDocState();
  const setWidgetsStylesState = useSetRecoilState(UIState_EditorImageWidgetsStyles.state);

  //-----------------------
  // Delete widget style
  //
  const { 
    mutation: deleteWidgetStyleMutationRaw,
    data:     deleteWidgetStyleData,
  } = useMutation(
    mutation.deleteWidgetStyle,
    {
      onStart: {
        msg:  "Deleting...",
      },
      onEnd: 
      {
        msg: "Style deleted",
        duration: MsgDuration.MIDDLE
      }
    }
  );

  useEffect(() => {
    if (deleteWidgetStyleData === null || deleteWidgetStyleData === undefined) {
      return;
    }

    const response = deleteWidgetStyleData.widgetStyleDelete;
    if (response.ok) {
      document.editorImageWidgetsStyles.deleteStyle(response.id);
      setWidgetsStylesState(document.editorImageWidgetsStyles.state);
      onDoneRef.current && onDoneRef.current();
      onDoneRef.current = null;
    }
    else {
      console.warn(`Could not delete style`);
    }
  }, [deleteWidgetStyleData]);

  const deleteWidgetStyleMutation = (props: MutationProps) => {
    const { 
      variables, 
      onDone 
    } = props;

    onDoneRef.current = onDone;
    deleteWidgetStyleMutationRaw({variables});
  }

  return {
    deleteWidgetStyleMutation
  }
}