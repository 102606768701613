import { useEffect } from 'react';


interface Props {
  trackingId: string;
}


declare global {
  interface Window {
    ga: any;
    gtag: any;
    dataLayer: any;
  }
}


const useGoogleAnalytics = (props: Props) => {
  const {
    trackingId,
  } = props;

  useEffect(() => {
    // Check if the Google Analytics script is already loaded
    if ( ! window.ga ) {
      // Create a script tag
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;

      document.head.appendChild(script);

      // TODO
      // we could wait for google script to get loaded
      // before proceeding with loading whole app. 
      // However if google was unlikely down, 
      // then app would not load.
      script.onload = () => {
        // Initialize the Google Analytics library
        window.dataLayer = window.dataLayer || [];
        window.gtag = function gtag() {
          window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
        window.gtag('config', trackingId);
      };
    }
  }, [trackingId]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    document.addEventListener('keypress', handleKeypress);
    
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('keypress', handleKeypress);
    }
  }, []);

  const getLabel = (event: any) => {
    const label = (
      event.target.nodeName + 
      (event.target.id ? '#' + event.target.id : '')
    );

    return label;
  }

  const handleClick = (event: any) => {
    if ( ! window.gtag ) {
      console.warn(`Google analytics not ready`);
      return;
    }

    window.gtag ('event', 'click', {
      'event_category': 'user_interaction',
      'event_label': getLabel(event),
      'value': 1 
    });
  }

  const handleKeypress= (event: any) => {
    if ( ! window.gtag ) {
      console.warn(`Google analytics not ready`);
      return;
    }

    window.gtag ('event', 'keypress', {
      'event_category': 'user_interaction',
      'event_label': getLabel(event),
      'value': 1 
    });
  }

}

export default useGoogleAnalytics;