import styled from 'styled-components';
import { getScrollbarCSS } from 'lego/styles/scrollbar';
import { EditorEditableBase } from 'app/ui/components/editor-txt/styles';


interface StyledWrapperProps {
  $edited: boolean;
  $marginTop:    number;
  $marginRight:  number;
  $marginBottom: number;
  $marginLeft:   number;
}

export const StyledWrapper = styled(EditorEditableBase).attrs<StyledWrapperProps>(props =>({
  style: {
    paddingRight:  `${props.$marginRight}px`,

    marginTop:    `${props.$marginTop}px`,
    marginBottom: `${props.$marginBottom}px`,
    marginLeft:   `${props.$marginLeft}px`,

    width:  `calc(100% - ${props.$marginLeft}px)`,
    height: `calc(100% - ${props.$marginTop + props.$marginBottom}px)`,
  }
}))<StyledWrapperProps>`
  position: absolute;
  overflow-y: ${props => props.$edited ? 'auto' : 'clip'};
  box-sizing: border-box;

  ${props => getScrollbarCSS(props)};
  
  &::-webkit-scrollbar-track {
    background: #ddd;
  }
`;
