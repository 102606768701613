import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from  'app/ui/contexts/document';

import StylesListComponent from '../parts/styles-list';


interface Props {
  widgetAddr: ContentTypes.WidgetAddr | null
}


export const StylesSystemComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr
  } = props;

  const document = useDocState();
  const stylesSystem = document.editorImageWidgetsStyles.getStylesPredefined();

  return (
    <StylesListComponent
      widgetAddr={widgetAddr}
      styles={stylesSystem}
      deleteDisabled={true}
    />
  );
}
  
