import produce from 'immer';
import { CssStyle, CssStylePack } from 'app/arch/editor-instruction/css-styles';


//----------------------------
//
// State's
//
export type State = {
  styles: CssStylePack[]
  stylesPredefined: CssStylePack[]
};

//----------------------------
//
// Producers
//

export const setStyles = produce((
  draft: State,
  styles: CssStylePack[],
) => {
  draft.styles = [...styles];
});

export const createStyle = produce((
  draft: State,
  styleId: number,
  name: string,
  style: CssStyle
) => {
  const pack: CssStylePack = {
    id: styleId,
    name,
    style
  }

  draft.styles.push(pack);
});

export const updateStyleName = produce((
  draft: State,
  styleId: number,
  name: string,
) => {
  const style = draft.styles.find(style => style.id === styleId) !;
  style.name = name;
});

export const setStylesPredefined = produce((
  draft: State,
  styles: CssStylePack[],
) => {
  draft.stylesPredefined = [...styles];
});


export const deleteStyle = produce((
  draft: State,
  id: number
) => {
  const style = draft.styles.filter((value: CssStylePack, idx: number) => value.id === id)[0];
  const idx = draft.styles.indexOf(style);
  draft.styles.splice(idx, 1);
});


//----------------------------
//
// Create initial state
//

export const createInitialState = () => {
  return {
    styles: [],
    stylesPredefined: []
  }
}

