import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { InvoicesTypes } from 'app/arch/app/user/states/invoices';
import useUserInvoicesFetch from 'app/ui-v2/app/__modules/user/hooks/use-user-invoices-fetch';
import useUserInvoices      from 'app/ui-v2/app/__modules/user/hooks/use-user-invoices';
import useUserStateSetters  from 'app/ui-v2/app/__modules/user/hooks/use-user-state-setters';

// this should go to common
import LoaderAuthBaseComponent from '../../loaders-ordered/base/loader-auth-base';
import { LoaderCoreProps }     from '../../loaders-ordered/base/loader-auth-base/types';
import { LoadersTools }        from '../../loaders-ordered';


interface Props {
}


export const LoaderUserInvoicesComponent: React.FC<Props> = (props: Props) => (
  <LoaderAuthBaseComponent
    debugTitle={"user invoices"}
    LoaderCoreAuthComponent={LoaderCoreAuth}
    LoaderCoreUnauthComponent={LoaderCoreUnauth}
  />
);


/**
 * UserInvoices Auth
 */

const LoaderCoreAuth: React.FC<LoaderCoreProps> = (props: LoaderCoreProps) => {
  const [ready, setReady] = useState(false);
  const invoices = useUserInvoices();

  const logger = LoadersTools.getLogger();
  logger.debug(`Loader user invoices - ready: ${ready}`);
  
  const {
    setUserInvoices,
  } = useUserStateSetters();

  const onDone = (invoicesRaw: InvoicesTypes.InvoicesRaw) => {
    logger.debug(`Loader user invoices, invoices loaded`)

    invoices.loadInvoices(invoicesRaw);
    setUserInvoices();
    setReady(true);
  }

  const onError = (error: string) => {
    const msg = `Can't download user invoices, error: ${error}`;
    console.error(msg);
  }

  useUserInvoicesFetch({ onDone, onError });
  
  return null;
}
  

/**
 * UserInvoices Unauth
 */

const LoaderCoreUnauth:  React.FC<LoaderCoreProps> = (props: LoaderCoreProps) => {
  const invoices = useUserInvoices();

  const logger = LoadersTools.getLogger();
  logger.debug(`Loader user subscriptions - render, unauth`);
  
  const {
    setUserInvoices,
  } = useUserStateSetters();

  useEffect(() => {
    logger.debug('Reset user invoices');
    
    invoices.reset();
    setUserInvoices();
  }, []);

  return null;
}
