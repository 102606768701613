export const DNDContext = {

  // View data grid
  image: {
    gallery: 'image-gallery',
    cell:    'image-cell',
  },

  marker: {
    gallery:   'marker-gallery',
    cell:      'marker-cell',
    docHeader: 'marker-doc-header',
    editor:    'marker-editor',
  },
  
  viewContent: {
    customFields: {
      column:  'view-section__customFields-column',
    },
    table: {
      section: 'view-section__table-section',
      column:  'view-section__table-column',
      row:     'view-section__table-row',
    },
  },

  viewChangelog: {
    table: {
      column: 'view-changelog__table-column',
      row:    'view-changelog__table-row'
    }
  }
}