import { UnitsType }           from "app/arch/types/types";
import { PageFormatType }      from "app/arch/types/types";
import { PageOrientationType } from "app/arch/types/types";
import * as Types from './types';


/**
 * Table
 */
export const getTable = (): Types.Table => ({
  header: {
    css: {
      color:       "#000",
      background:  "#eee",
      padding:     "6px",
      fontSize:    "18px",
      fontWeight:  "600",
      fontVariant: "small-caps",
    }
  }
});


/**
 * Page
 */
export const getPageLayout = (): Types.PageLayout => ({
  format: PageFormatType.A4,
  orientation: PageOrientationType.PORTRAIT,
});

export const getPageMargins = (): Types.PageMargins => ({
  locked: true,
  top: 20,
  right: 20,
  bottom: 20,
  left: 20,
  units: UnitsType.METRIC
});

export const getPage = (): Types.Page => ({
  layout: getPageLayout(),
  margins: getPageMargins(),
});
