import React from 'react';

import useSelectUserImages from 'lego/components/user-files-selector/use-select-user-images';
import useSelectUserImageCameraBack from 'lego/components/user-files-selector/use-select-user-image-camera-back';

import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import Icon from 'app/ui/icons/icons';

import ButtonUploadComponent     from '../hls/button-upload';
import IconStyleContextComponent from '../hls/icon-style-context';
import useRepoImagesUpload       from '../hooks/use-repo-images-upload';

import Buttons from '../styles/buttons';
import { Title } from './styles';
import { MainWrapper } from './styles';


interface Props {
  onImagesUploaded: (images: RepoImagesTypes.ImagesRaw) => void;
}


export const UploadPanelMobileComponent: React.FC<Props> = (props: Props) => {
  const {
    onImagesUploaded
  } = props;
  
  const t = useTranslations();
  const uploadRepoImages = useRepoImagesUpload();

  
  const handleFilesSelected = (files: File[]) => {
    uploadRepoImages({
      files,
      onDone: (images: RepoImagesTypes.ImagesRaw) => {
        onImagesUploaded(images);
      },
      onError: () => {

      }
    });
  }

  const selectImages = useSelectUserImages({
    onFilesSelected: handleFilesSelected
  });

  const captureImage = useSelectUserImageCameraBack({
    onFilesSelected: handleFilesSelected
  });

  const handleGalleryFilesUpload = (event: React.MouseEvent) => {
    selectImages();
  }

  const handleCameraUpload = (event: React.MouseEvent) => {
    captureImage();
  }

  return (
    <MainWrapper>

      <Title>
        { t('upload from') }
      </Title>

      <IconStyleContextComponent>
        <Buttons>
          <ButtonUploadComponent
            Icon={Icon.Upload.Gallery}
            onClick={handleGalleryFilesUpload}
          />
          <ButtonUploadComponent
            Icon={Icon.Upload.Camera}
            onClick={handleCameraUpload}
          />          
        </Buttons>
      </IconStyleContextComponent>

    </MainWrapper>      
  );
}
