import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


const useRowSelectedSet = () => {
  const document = useDocState();

  const {
    setContentSession
  } = useEditorStatesSetters();

  const selectRow = (props: {
    rowAddr: Types.RowAddr | null,
    skipStateUpdate?: boolean,
  }) => {
    const {
      rowAddr, 
      skipStateUpdate
    } = props;
    
    document.contentSession.setRowSelected(rowAddr);

    if (skipStateUpdate) {
      return;
    }

    setContentSession();
  }

  return selectRow;
}


export default useRowSelectedSet;