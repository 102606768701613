import React from 'react';
import UserFilesSelectorComponent from 'lego/components/user-files-selector';
import KeyBindingsComponent       from 'lego/components/key-bindings';

import BackendServiceComponent from 'app/ui/components/backend-service';
import DocumentComponent from '../../document';
import { MainWrapper  } from './styles';


interface Props {
}


export const ViewEditorComponent: React.FC<Props> = (props: Props) => {
  const handlePointerDown = (event: React.MouseEvent) => {
  }

  const handleContextMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  }


  return (
    <>
      <BackendServiceComponent>

        <MainWrapper
          id='editor-view'
          onPointerDown={handlePointerDown}
          onContextMenu={handleContextMenu}
        > 
          <DocumentComponent />
        </MainWrapper>

        <KeyBindingsComponent />
      </BackendServiceComponent>

      <UserFilesSelectorComponent />
    </>
  );
}

