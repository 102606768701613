import { HistoryTypes } from "app/arch/editor-instruction/document/states/history-state";
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


export const useReloadRecoilStates = () => {
  const document = useDocState();

  const {
    setContent,
    setContentSession,
    
    setHeaderFields,
    setHeaderFieldsSession,
    
    setContentView,

    setContentSliceRequest,


    setReleaselogs,
    setReleaselogsSession,
    setReleaselogsView,
    setReleaselogsSliceRequest,

    setViewsCommon,

    setRepoMarkers,

    setHeaderMarkers,
    setHeaderMarkersSession,

    setHeaderMeta,
    setHeaderMetaSession,

    setEditorSession,
    setEditorImageSession,
    setEditorMarkerSession,

    setRepoImagesSession,
    
    //-----------
    // Review
    setMetaData,
    setRepoImages,
    
    setInstruction,
    setEditorImageSettings,
    setEditorImageWidgetsStyles,
    setEditorLogoSession,

  } = useEditorStatesSetters();



  const reloadInstructionState = () => {
    setInstruction();
  }

  const reloadDocImagesState = () => {
    setRepoImages();
  }

  const reloadWidgetStylesState = () => {
    setEditorImageWidgetsStyles();
  }

  const reloadContentStates = () => {
    /**
     * Content
     */
    setContent();
    setContentView();
    setHeaderFields();

    /**
     * Releaselog
     */
    setReleaselogs();
    setReleaselogsView();


    setHeaderMeta();
    setHeaderMarkers();
    setMetaData();
    setRepoMarkers();
    setViewsCommon();
    setEditorImageSettings();
  }

  const reloadSessionStates = () => {
    setRepoImagesSession();

    setContentSession();
    setHeaderFieldsSession();
    setContentSliceRequest();

    setReleaselogsSession();
    setReleaselogsSliceRequest();

    setHeaderMarkersSession();
    setHeaderMetaSession();
    setEditorImageSession();
    setEditorLogoSession();
    setEditorMarkerSession();
    setEditorSession();
  }

  const reloadAllStates = () => {
    reloadInstructionState();
    
    reloadDocImagesState();
    reloadWidgetStylesState();
    
    reloadContentStates();
    reloadSessionStates();
  }


  const loadDocumentState = (stateDeserialized: HistoryTypes.Record) => {
    const docState = document;

    docState.content.state             = stateDeserialized.content;
    docState.contentView.state         = stateDeserialized.contentView;
    docState.editorImageSettings.state = stateDeserialized.editorImageSettings;
    docState.headerFields.state        = stateDeserialized.headerFields;
    docState.headerMarkers.state       = stateDeserialized.headerMarkers;
    docState.headerMeta.state          = stateDeserialized.headerMeta;
    docState.metaData.state            = stateDeserialized.metaData;
    docState.releaselogs.state         = stateDeserialized.releaselogs;
    docState.releaselogsView.state     = stateDeserialized.releaselogsView;
    docState.repoMarkers.state         = stateDeserialized.repoMarkers;
    docState.viewsCommon.state         = stateDeserialized.viewsCommon;
  }

  return { 
    reloadAllStates,

    reloadInstructionState,
    
    reloadDocImagesState,
    reloadWidgetStylesState,

    reloadContentStates,

    reloadSessionStates,

    loadDocumentState
  };
}
