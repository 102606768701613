import styled from 'styled-components';
import TextBase        from 'lego/styles/text';
import RevisionBase    from '../styles/revision';
import ReleaseDateBase from '../styles/release-date';
import TextWrapBase from 'lego/styles/text-wrap';
import PanelHeaderBase from 'lego/styles/panel-header';



export const PanelHeader = styled(PanelHeaderBase)` 
`;


export const RevisionHeader = styled(RevisionBase)`
  display: flex;
  align-items: center;

  height: 100%;
  user-select: none;
  /* background: red; */
`;


export const ReleaseDateHeader = styled(ReleaseDateBase)`
  display: flex;
  align-items: center;

  height: 100%;
  user-select: none;
  /* background: red; */
`;

export const ProgressWrapper = styled.div`
  height: 100px;
  /* background: rgba(255, 0,255, 0.2); */
`;


export const TextWrapper = styled.div`
  width: 300px;
`;


export const TextWrap = styled(TextWrapBase)`
`;



export const Text = styled(TextBase)`
  text-align: center;
  background: ${props => props.theme.defs.colors.background.secondary};
`;