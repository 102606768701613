import React from 'react';
import { useRecoilValue } from 'recoil';

import useIsKeyDownStated from 'lego/components/key-bindings/hooks/use-is-key-down-stated';

import { settings } from 'app/configs';

import useSmartLinesWidgetsLines       from '../../../hooks/smart-lines/use-smart-lines-widgets-lines';
import useSmartLinesWidgetsStickyLines from '../../../hooks/smart-lines/use-smart-lines-widgets-sticky-lines';
import useSmartLinesWidgetsSrcLines    from '../../../hooks/smart-lines/use-smart-lines-widgets-src-lines';

import { SmartLinesRaw } from 'tools/smart-lines/types';
import { StickyLineRaw } from 'tools/smart-lines/types';

import SmartLineComponent   from './smart-line';
import StickyLinesComponent from './sticky-lines';

import { LinesWrapper }       from './styles';
import { StickyLinesWrapper } from './styles';
import { UIState_EditorImageSettings } from 'app/ui/states/editor-instruction';



interface Props {
}


export const SmartLinesComponent: React.FC<Props> = (props: Props) => {
  const smartLines  = useSmartLinesWidgetsLines();
  const stickyLines = useSmartLinesWidgetsStickyLines();
  const srcLines    = useSmartLinesWidgetsSrcLines();
  
  const smartLinesSettings = useRecoilValue(UIState_EditorImageSettings.smartLines);

  const deactivateKeyDown = useIsKeyDownStated({
    key: settings.actionKey.smartLinesDisabled,
    fallThrough: true 
  });
  
  
  const renderOrientedSmartLines = (key: string, smartLines: SmartLinesRaw) => {
    const lines = smartLines.map((line, idx) => {
      return (
        <SmartLineComponent
          key={idx}
          line={line}
        />
      );
    });

    return (
      <LinesWrapper key={key}>
      { lines }
      </LinesWrapper>
    );
  }

  const renderSmartLines = () => {
    return (
      smartLines &&
      <>
        { renderOrientedSmartLines("horizontal", smartLines.horizontal) }
        { renderOrientedSmartLines("vertical",   smartLines.vertical) }
      </>
    )
  }

  const renderSrcLines = () => {
    return (
      srcLines &&
      <>
        { renderOrientedSmartLines("horizontal", srcLines.horizontal) }
        { renderOrientedSmartLines("vertical",   srcLines.vertical) }
      </>
    )
  }

  
  const renderOrientedStickyLine = (key: string, stickyLine: StickyLineRaw | null) => {
    if (stickyLine === null) {
      return <></>;
    }

    const srcLine = stickyLine.srcLine;
    const dstLine = stickyLine.dstLine;

    return (
      <StickyLinesWrapper key={key}>
        <StickyLinesComponent
          srcLine={srcLine}
          dstLine={dstLine}
        />
      </StickyLinesWrapper>
    )
  }

  const renderStickyLine = () => {
    return (
      stickyLines &&
      <>
        { renderOrientedStickyLine("horizontal", stickyLines.horizontal) }
        { renderOrientedStickyLine("vertical",   stickyLines.vertical)   }
      </>
    )
  }

  if ( deactivateKeyDown ) {
    return <></>;
  }

  return (
    <>
    { smartLinesSettings.aligmentLines.visible && renderSmartLines()  }
    { smartLinesSettings.srcLines.visible      && renderSrcLines() }
    { renderStickyLine() }
    </>
  );
}
  
