import React from 'react';
import { useRecoilValue } from 'recoil';

import { UIState_HeaderFieldsSession } from 'app/ui/states/editor-instruction';
import { CellProps } from '../../types';

import { MainWrapper } from './styles';
import { Background }  from './styles';


interface Props extends CellProps {
  children: React.ReactNode;
}

export const CellLineSelectedComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr,
    children,
  } = props;

  const columnSelected = useRecoilValue(
    UIState_HeaderFieldsSession.isPageColumnSelected(pageCellAddr)
  );

  return (
    <MainWrapper>
      <Background selected={columnSelected} />
      { children }
    </MainWrapper>
  );
}
  
