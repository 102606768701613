import * as Types from "../document/states/persistent/content/types";
import { LexicalRegistry } from "./lexical-registry";

type CellAddr = Types.CellAddr;


export class LexicalRegistryContent2 extends LexicalRegistry<CellAddr> {
  protected getDebugName(): string {
    return "Content2View";
  }
  
  protected getId(cellAddr: CellAddr) {
    const {
      sectionId,
      columnId,
      rowId,
    } = cellAddr;

    const id = `${sectionId}::${columnId}::${rowId}`;
    return id;
  }
}
