import React from 'react';

import MenuComponent    from 'lego-v2/menu';
import { MenuConfig   } from 'lego-v2/menu/config';
import { MenuItemType } from 'lego-v2/menu/config';

import Icon from 'app/ui/icons/icons';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useDocState }     from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


interface Props {
  imageAddr: ContentTypes.ImageAddr;
  widgetsAddrs: ContentTypes.WidgetsAddrs;
  onHide: () => void;
}

export const WidgetsContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
    widgetsAddrs,

    onHide,
  } = props;

  const t = useTranslations();
  const document = useDocState();

  const {
    setContent,
    setEditorImageSession,
  } = useEditorStatesSetters();

  const duplicateWidgets = () => {
    const newWidgetsAddrs = document.content.cellImages_image_duplicateWidgets(widgetsAddrs);
    setContent();
    document.saveUndo();

    document.editorImageSession.setMultiSelectionSelectedWidgets(newWidgetsAddrs);
    setEditorImageSession();

    hideMenu();
  }

  const deleteWidgets = () => {
    widgetsAddrs.forEach(widgetAddr => {
      document.content.cellImages_image_deleteWidget(widgetAddr);
    })

    setContent();
    document.saveUndo();

    document.editorImageSession.setMultiSelectionImageAddr(null);
    document.editorImageSession.setMultiSelectionSelectedWidgets(null);
    setEditorImageSession();

    hideMenu();
  }


  const hideMenu = () => {
    onHide();
  }

  const menuConfig: MenuConfig = 
  {
    sections: [
      {
        items: [
          {
            type: MenuItemType.BUTTON,
            data: {
              title: t('duplicate'),
              iconComponent: Icon.Duplicate,
              onClick: (event: React.MouseEvent) => duplicateWidgets(),
            }
          },    
        ]
      },
      {
        items: [
          {
            type: MenuItemType.BUTTON,
            data: {
              title: t('delete'),
              variant: 'delete',
              iconComponent: Icon.Delete,
              onClick: () => deleteWidgets(),
            }
          },
        ]
      },
    ]
  }

  return (
    <>
      <MenuComponent
        config={menuConfig}
        onClose={hideMenu}
      />
    </>

  );
}
  
