import React from 'react';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';

import useDeskScale from '../../hooks/use-desk-scale';
import ImageViewDefinerComponent   from './image-view-definer';
import SmartLinesComponent         from '../../hls/widgets/smart-lines';
import SelectedWidgetsBoxComponent from '../../hls/widgets/selected-widgets-box';

import WidgetsComponent from './widgets';

import { SmartLinesWrapper } from './styles';
import { SelectedWidgetsBoxWrapper } from './styles';


interface Props {
  imageAddr: Types.ImageAddr;
}


export const EditorImageDeskContentComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr
  } = props;

  const scale = useDeskScale();

  return (
    <>
      <SmartLinesWrapper>
        <SmartLinesComponent />
      </SmartLinesWrapper>

      <SelectedWidgetsBoxWrapper>
        <SelectedWidgetsBoxComponent
          scale={scale}
          imageAddr={imageAddr}
        />
      </SelectedWidgetsBoxWrapper>

      <WidgetsComponent
        scale={scale}
        imageAddr={imageAddr}
      />

      <ImageViewDefinerComponent
        scale={scale}
        imageAddr={imageAddr}
      />
    </>
  );
}