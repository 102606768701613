import { ContextDef } from "../types";

import useDNDContextDisable from 'app/ui-v2/editor-instruction/hooks/use-dnd-context-disable';

export interface Props {
  contextsDefs: ContextDef[],
  dndContext: string | null,
}


const useDropOn = (props: Props) => {
  const {
    contextsDefs,
    dndContext,
  } = props;

  const disableDNDContext = useDNDContextDisable();

  const onDrop = (): boolean => {
    const contextDef = contextsDefs.find((contextDef) => contextDef.dndContext === dndContext);
    if ( contextDef === undefined ) {
      console.warn(`No DND drop handler for context ${dndContext}`);
      console.warn(contextsDefs);
      disableDNDContext();
      return false;
    }

    contextDef.onDropV2?.();
    disableDNDContext();
    return true;
  }

  return onDrop;
}


export default useDropOn;