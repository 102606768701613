import { MutableRefObject } from 'react';

import useUndo            from 'app/ui-v2/editor-instruction/hooks/use-undo';
import useEditorExit      from 'app/ui-v2/editor-instruction/hooks/use-editor-exit';
import useDocumentCreate  from 'app/ui-v2/editor-instruction/hooks/use-document-create';
import useDocumentSave    from 'app/ui-v2/editor-instruction/hooks/use-document-save';
import useDocumentSaveAs  from 'app/ui-v2/editor-instruction/hooks/use-document-save-as';
import useDocumentRelease from 'app/ui-v2/editor-instruction/hooks/use-document-release';
import useDocumentPreview from 'app/ui-v2/editor-instruction/hooks/use-document-preview';
import useDocTemplateSave from 'app/ui-v2/editor-instruction/hooks/use-doc-template-save';


const useFunctions = (
  closeMenuRef: MutableRefObject<(() => void) | null>
) => {
  const { 
    undo, 
    redo 
  } = useUndo();

  const createDocument   = useDocumentCreate();
  const saveDocument     = useDocumentSave();
  const saveAsDocument   = useDocumentSaveAs();
  const saveDocTemplate  = useDocTemplateSave();
  const exitEditor       = useEditorExit();

  const previewDocument  = useDocumentPreview();
  const releaseDocument  = useDocumentRelease();


  const closeMenu = () => {
    if (closeMenuRef.current !== null) {
      closeMenuRef.current();
    }
  }


  /**
   * File
   */
  const handleCreateNew = () => {
    createDocument({});
    closeMenu();
  }

  const handleSave = () => {
    saveDocument();
    closeMenu();
  }

  const handleSaveAs = () => {
    saveAsDocument();
    closeMenu();
  }

  const handleExportDefaults = () => {
    saveDocTemplate();
    closeMenu();
  }

  const handleExit = () => {
    exitEditor();
    closeMenu();
  }


  /**
   * Release
   */
  const handleReleasePDF = () => {
    releaseDocument();
    closeMenu();
  }

  const handlePreviewPDF = () => {
    previewDocument();
    closeMenu();
  }


  /**
   * Edit
   */
  const handleUndo = () => {
    undo();
    closeMenu();
  }

  const handleRedo = () => {
    redo();
    closeMenu();
  }


  return {
    handleCreateNew,
    handleSave,
    handleSaveAs, 
    handleExportDefaults,
    handleExit,

    handleReleasePDF,
    handlePreviewPDF,

    handleUndo,
    handleRedo,
  }
}

export default useFunctions;
