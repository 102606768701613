import React from 'react';
import SelectButtonDefault from 'lego/styles/select-button';
import { IconExpandLess } from './styles';
import { IconExpandMore } from './styles';
import { TextWrapper }    from './styles';
import { IconWrapper }    from './styles';


interface Props {
  children?: any;
  onClick: (event?: any) => void;

  SelectButton?: React.ComponentType<any>;
  expanded?: boolean;
}


export const SelectButtonComponent: React.FC<Props> = (props: Props) => {
  const { 
    children, 
    onClick,
    SelectButton: SelectButton_,
    expanded
  } = props;

  const SelectButton = SelectButton_ || SelectButtonDefault;
  const Icon = expanded ? IconExpandLess : IconExpandMore;

  return (
    <SelectButton onClick={onClick}>

      <TextWrapper>
      { children }
      </TextWrapper>

      <IconWrapper>
        <Icon />
      </IconWrapper>

    </SelectButton>
  );
}
  
