import React from 'react';
import { useRecoilValue } from 'recoil';

import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';

import { UIState_ViewsCommon } from 'app/ui/states/editor-instruction';
import { UIState_HeaderFields } from 'app/ui/states/editor-instruction';
import { UIState_HeaderFieldsSession } from 'app/ui/states/editor-instruction';

import { HeaderCellView } from './styles';


interface Props {
  pageColumnAddr: Types.PageColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellCSSComponent: React.FC<Props> = (props: Props) => {
  const { 
    pageColumnAddr,
    children
  } = props;
    
  const selected = useRecoilValue(UIState_HeaderFieldsSession.isPageColumnSelected(pageColumnAddr));
  // Not used for the moment
  // const columnHeaderCSS = useRecoilValue(UIState_DocContent2DocCustomFields.columnHeaderCSS(columnAddr));
  const tableHeaderCSS  = useRecoilValue(UIState_HeaderFields.tableHeaderCSS);
  const commonTableHeaderCSS = useRecoilValue(UIState_ViewsCommon.tableHeaderCSS);

  const color = commonTableHeaderCSS.color as string;
  const background = commonTableHeaderCSS.background as string;
  

  return (
    <HeaderCellView
      style={{
        ...tableHeaderCSS
      }}

      $selected={selected}
      $color={color} 
      $background={background}
    >
      { children }
    </HeaderCellView>
  );
}
