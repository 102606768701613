import { LexicalRegistry } from "./lexical-registry";
import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';


export class LexicalRegistryContent2CustomFields extends LexicalRegistry<Types.PageCellAddr> {
  protected getDebugName(): string {
    return "Content2CustomFields";
  }

  protected getId(pageCellAddr: Types.PageCellAddr) {
    const {
      pageIdx,
      columnId
    } = pageCellAddr;

    const id = `${pageIdx}::${columnId}`;
    return id;
  }
}
