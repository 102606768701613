import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { KeyBindingPriority } from 'lego/components/key-bindings/arch/types';
import useKeyBindingsBase     from 'lego/components/key-bindings/hooks/use-key-bindings';

import EditorInstruction from 'app/arch/editor-instruction';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';


export const useKeyBindings = (editorInstruction: EditorInstruction) => {
  const { 
    addKeyDownBinding, 
    removeKeyDownBinding,
  } = useKeyBindingsBase();

  // Do not use hooks which depend on EditorInstruction Context
  const docState = editorInstruction.document.docState;
  const setDocContent2Session = useSetRecoilState(UIState_ContentSession.state);

  useEffect(() => {
    const id =  addKeyDownBinding({
      check: (event: any) => event.key === 'Escape', 
      callback: __escape,
      priority: KeyBindingPriority.EDITOR_INSTRUCTION
    });
      
    return () => {
      removeKeyDownBinding(id);
    }
  }, []);

  const __escape = () => {
    docState.contentSession.setCellSelected(null);
    setDocContent2Session(docState.contentSession.state);
  };
}
