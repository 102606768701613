import React from 'react';
import { useRef }     from 'react';
import { useEffect }  from 'react';
import { useState }   from 'react';

import { HideIcon }     from './styles';
import { InputWrapper } from './styles';
import { Button }       from './styles';
import { ShowIcon }     from './styles';
import { MainWrapper }  from './styles';
import { InputTitle }   from './styles';
import { Input as InputDefault } from './styles';


interface Props {
  value?: string;
  type?: string;
  title?: string;

  focusOnLoad?: boolean;
  blurOnEnterKey?: boolean;
  
  onBlur?: (event?: any) => void;
  onValueChange?: (value: string) => void;
  onCommit?: (value: string) => void;
  onCancel?: () => void;
  onKeyDown?: (event: any) => void;

  disabled?: boolean;
  placeholder?: string;
  Input?: React.ElementType;

  dataTest?: string;
};


export const InputComponent = (props: Props) => {
  const {
    value: initValue,
    title,
    onBlur,
    onCancel,
    onCommit,
    onKeyDown,
    onValueChange,
    type: typeInit,
    focusOnLoad,
    blurOnEnterKey,
    disabled,
    placeholder,
    dataTest
  } = props;
  
  const [isFocused, setFocused] = useState(false);
  const [value, setValue] = useState(initValue || '');
  const [type, setType] = useState(typeInit || 'text');

  const Input = props.Input || InputDefault;

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && focusOnLoad) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    setValue(initValue ?? '');
  }, [initValue]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    onKeyDown && onKeyDown(event);

    if (blurOnEnterKey && event.key === 'Enter') {
      inputRef.current?.blur();
      event.stopPropagation();
      event.preventDefault();
    }

    if (event.key === "Enter") {
      onCommit?.(value);
    }

    else if (event.key === "Escape") {
      onCancel?.();
    } 
  }

  const handleChange = (event: any) => {
    setValue(event.target.value);
    onValueChange && onValueChange(event.target.value);
  }

  const handleFocus = (event: any) => {
    setFocused(true);
  }

  const handleBlur = (event: any) => {
    onBlur && onBlur(event);
    setFocused(false);
  }

  const handlePasswordToggle = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (typeInit === 'password') {
      if (type === 'password') {
        setType('text');
      }
      else {
        setType('password');
      }
    }
  }

  const renderVisibleToggle = () => {
    const Icon = type ==='password' ? ShowIcon : HideIcon;
    return (
      <Button 
        type="button"
        onClick={handlePasswordToggle}
      >
        <Icon />
      </Button>
    );
  }


  return (
    <MainWrapper>
      {
        title !== undefined &&
        <InputTitle>
        { title }
        </InputTitle>
      }

      <InputWrapper>
        <Input
          disabled={disabled}
          ref={inputRef}
          type={type}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          focused={isFocused}

          data-test={dataTest}
        />

        {
          typeInit === 'password' && 
          renderVisibleToggle()
        }

      </InputWrapper>
    </MainWrapper>
  )
}
