import React from 'react';
import { useRecoilValue } from 'recoil';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { UIState_Content }        from 'app/ui/states/editor-instruction';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';
import { UIState_ViewsCommon }   from 'app/ui/states/editor-instruction';

import { HeaderCellView } from './styles';


interface Props {
  columnAddr: Types.ColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellCssComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
    children,
  } = props;

  const selected        = useRecoilValue(UIState_ContentSession.isColumnSelected(columnAddr));
  const tableHeaderCSS  = useRecoilValue(UIState_ViewsCommon.tableHeaderCSS);
  const columnHeaderCSS = useRecoilValue(UIState_Content.columnHeaderCSS(columnAddr));

  const tableHeaderStyle = {
    padding:     tableHeaderCSS.padding,
    fontSize:    tableHeaderCSS.fontSize,
    fontWeight:  tableHeaderCSS.fontWeight,
    fontVariant: tableHeaderCSS.fontVariant,
  };

  const columnHeaderStyle = {
    justifyContent: columnHeaderCSS?.justifyContent
  };

  const color = tableHeaderCSS.color as string;
  const background = tableHeaderCSS.background as string;
  
  return (
    <HeaderCellView 
      $selected={selected}
      $color={color} 
      $background={background}
      style={{
        ...tableHeaderStyle,
        ...columnHeaderStyle,
      }}
    >
    { children }
    </HeaderCellView>
  );
}
