import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import jtl from 'tools/jtl';

import { useMutation } from 'app/arch/backend/use-mutation';
import { mutation    } from 'app/arch/backend';
import { useDocState           } from 'app/ui/contexts/document';
import { UIState_EditorImageWidgetsStyles } from 'app/ui/states/editor-instruction';

import { MsgDuration } from '../../../ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { MutationProps, OnDoneFn } from './types';



export const useCreateWidgetStyle = () => {
  // TODO FIXME - can be overwritten
  // if multiply calls at once
  // should be some kind of list/container
  const onDoneRef = useRef<null | undefined | OnDoneFn>(null);
  
  const document = useDocState();
  const setWidgetsStylesState = useSetRecoilState(UIState_EditorImageWidgetsStyles.state);

//-----------------------
  // Create widget style
  //
  const { 
    mutation: createWidgetStyleMutationRaw,
    data:     createWidgetStyleData,
  } = useMutation(
    mutation.createWidgetStyle,
    {
      onStart: {
        msg:  "Saving style...",
      },
      onEnd: 
      {
        msg: "Styles saved",
        duration: MsgDuration.MIDDLE
      }
    }
  );

  useEffect(() => {
    if (createWidgetStyleData === null || createWidgetStyleData === undefined) {
      return;
    }

    const response = createWidgetStyleData.widgetStyleCreate;
    if (response.ok) {
      const style = jtl.serialize.deserialize(response.widgetStyle.style);

      document.editorImageWidgetsStyles.createStyle(
        response.widgetStyle.id, 
        response.widgetStyle.name,
        style
      );
      setWidgetsStylesState(document.editorImageWidgetsStyles.state);
      onDoneRef.current && onDoneRef.current();
      onDoneRef.current = null;
    }
    else {
      console.warn(`Could not craete style`);
    }
  }, [createWidgetStyleData]);


  const createWidgetStyleMutation = (props: MutationProps) => {
    const { variables, onDone } = props;
    onDoneRef.current = onDone;
    createWidgetStyleMutationRaw({variables});
  }
  
  return {
    createWidgetStyleMutation
  }
}