// Data structure for css should be something like 
// 

/*
{
  Arrow: {
    Head: {
      css: {
      },
      other: {
        for example type of
        head - pointer, arrow, triangle
      }
    },
    Body: {
    },
    Tail: {
      css: {
      },
      other: {
        for example 
        jointed 
      }
    },

    Common: {
      css: {
      },
      other: {
      }
    }
  },

  Rectangle: {
  },

  Circle: {
  },

  TextBox: {
  },
  
  Common: {
  }
}

*/ 


const widgetsSettings = {
  css: {
    arrowHeadWidth:  "18px",
    arrowHeadHeight: "28px",
    arrowShaftWidth: "0px",

    // If backgroundColor is unset,
    // for ellipse, rectange it will have backgroundColor: transparent
    // and for text, arrowText it will have backgroundColor: white
    // backgroundColor: "transparent",
    
    borderColor:  "#FF3B30",
    borderRadius: "12px",
    borderWidth:  "9px",

    outlineColor: "#FFFFFF",
    outlineWidth: "3px",
    
    textMarginBottom: "8px",
    textMarginLeft:   "8px",
    textMarginRight:  "8px",
    textMarginTop:    "8px",
  }
}


export default widgetsSettings;
