import * as TypesShared from "../../types";
import * as Types from './types';


export const getHeaderMeta = (): Types.HeaderMeta => ({
  visible: TypesShared.PageItemVisibility.ALL
});

export const getHeaderFields = (): Types.HeaderFields => ({
  visible: TypesShared.PageItemVisibility.NONE
});

export const getHeaderMarkers = (): Types.HeaderMarkers => ({
  visible: TypesShared.PageItemVisibility.NONE
});


export const getHeader = (): Types.Header => ({
  meta: getHeaderMeta(),
  fields: getHeaderFields(),
  markers: getHeaderMarkers(),
});
