import React from 'react';
import { useEffect } from 'react';

import { ViewTypes } from 'app/arch/home-page-user/states/view';
import useViewParamsRead   from 'app/ui-v2/home-page/home-page-user/hooks/use-view-params-read';
import useGoHome           from 'app/ui-v2/home-page/hooks/use-go-home';
import ViewTitledComponent from 'app/ui-v2/home-page/hls/view-titled';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import ReleasesListComponent from './releases-list/releases-list';
import DocumentInfoComponent from './document-info/document-info';

import { AutoHeightWrapper } from './styles';
import { ReleasesWrapper } from './styles';


interface Props {
}


export const ViewDocumentComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const goHome = useGoHome();
  const params = useViewParamsRead() as ViewTypes.ViewParamsDocument;

  useEffect(() => {
    if (params === null) {
      goHome();
    }
  }, [params])

  if (params === null) {
    return null;
  }

  const {
    documentId
  } = params;

  return (
    <ViewTitledComponent title={t('document')}>
      <AutoHeightWrapper>
        <DocumentInfoComponent documentId={documentId} />
      </AutoHeightWrapper>

      <ReleasesWrapper>
        <ReleasesListComponent documentId={documentId} />
      </ReleasesWrapper>
    </ViewTitledComponent>
  );
}
  
