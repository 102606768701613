import jtl from 'tools/jtl';
import { CssStyle } from 'app/arch/editor-instruction/css-styles';
import { BackendServiceContext } from './context';
import { OnDoneFn } from './types';
import { useDeleteWidgetStyle } from './use-delete-widget-style';
import { useUpdateWidgetStyle } from './use-update-widget-style';
import { useCreateWidgetStyle } from './use-create-widget-style';


interface Props {
  children: any;
}


export const BackendServiceComponent: React.FC<Props> = (props: Props) => {
  const { createWidgetStyleMutation } = useCreateWidgetStyle();
  const { updateWidgetStyleMutation } = useUpdateWidgetStyle();
  const { deleteWidgetStyleMutation } = useDeleteWidgetStyle();

  const createWidgetStyle = (
    instructionId: number, 
    styleName: string,
    style: CssStyle,
    onDone?: OnDoneFn  
  ) => {
    createWidgetStyleMutation(
      {
        variables: { 
          instructionId: instructionId,
          name: styleName, 
          style: jtl.serialize.serialize(style) ,
        },
        onDone: () => {
          onDone && onDone();
        }
      }
    )
  }

  const updateWidgetStyleName = (
    id: number, 
    name: string,
    onDone?: OnDoneFn  
  ) => {
    updateWidgetStyleMutation({ 
      variables: { 
        id: id,
        name: name.trim()
      },
      onDone: () => {
        onDone && onDone();
      }
    });  
  }

  const deleteWidgetStyle = (
    id: number,
    onDone?: OnDoneFn  
  ) => {
    deleteWidgetStyleMutation({ 
      variables: { id },
      onDone: () => {
        onDone && onDone();
      }
    });
  }


  const context = {
    createWidgetStyle,
    updateWidgetStyleName,
    deleteWidgetStyle,
  };

  return (
    <BackendServiceContext.Provider 
      value={context}
    >
      {props.children}
    </BackendServiceContext.Provider>
  );
}
