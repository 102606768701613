import React from 'react';

import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import { DNDContext }   from 'app/ui-v2/editor-instruction/dnd-context';
import DNDDropComponent from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useRowMove       from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/row/use-row-move';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';

import { DropWrapper } from './styles';


interface Props {
  cellAddr: Types.CellAddr;
  children: React.ReactNode;
}


export const CellIndexDropComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children
  } = props;

  const moveRow = useRowMove();
  const getDNDData = useDNDDataGet();

  const handleDrop = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const droppedRowAddr = data.rowAddr;

    moveRow({
      srcRow: droppedRowAddr, 
      dstRow: cellAddr,
    });
  }

  const contextsDefs = [
    {
      dndContext: DNDContext.viewContent.table.row,
      onDropV2: handleDrop
    },
  ];

  return (
    <DNDDropComponent 
      contextsDefs={contextsDefs}
      Wrapper={DropWrapper}
    >
      { children }
    </DNDDropComponent>
  );
}
