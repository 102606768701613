import ButtonColor from 'lego/styles/button_color';
import { ButtonColorTransparent } from 'lego/styles/button_color';


interface Props {
  color: string;
  onColorSelected: (color: string) => void;
  isSelected?: boolean
}


export const ColorBoxComponent = (props: Props) => {
  const {
    color,
    onColorSelected,
    isSelected: isSelected_
  } = props;

  const isSelected = isSelected_ !== undefined ? isSelected_ : false;

  const isTransparent = color === 'transparent';

  const handleColorSelected = () => {
    onColorSelected(color);
  }

  const Button = isTransparent ? ButtonColorTransparent : ButtonColor;

  return (
    <Button
      color={color}
      borderColor={color}
      selected={isSelected}
      onClick={handleColorSelected}
    />
  );
};
