import { CssStyle } from 'app/arch/editor-instruction/css-styles';
import { CssStylePack } from 'app/arch/editor-instruction/css-styles';
import * as State from './state';


export class EditorImageWidgetsStyles {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }

  //----------------------
  // 
  // Setters
  setStyles(styles: CssStylePack[]) {
    const nextState = State.setStyles(this._state, styles);
    this.processNewState(nextState);    
  }

  createStyle(styleId: number, name: string, style: CssStyle) {
    const nextState = State.createStyle(this._state, styleId, name, style);
    this.processNewState(nextState);    
  }

  updateStyleName(styleId: number, name: string) {
    const nextState = State.updateStyleName(this._state, styleId, name);
    this.processNewState(nextState);    
  }

  deleteStyle(id: number) {
    const nextState = State.deleteStyle(this._state, id);
    this.processNewState(nextState);    
  }
  
  setStylesPredefined(styles: CssStylePack[]) {
    const nextState = State.setStylesPredefined(this._state, styles);
    this.processNewState(nextState);    
  }


  //----------------------
  // 
  // Getters
  getStylesPredefined() {
    return this._state.stylesPredefined;
  }

  
  private processNewState(newState: State.State) {
    return this._state = newState;
  }
}
