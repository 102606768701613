import { useState } from 'react';
import { useEffect } from 'react';

import ProgressComponent from 'app/ui-v2/hls/progress';
import { setup as setupProgressModal } from './use-progress-modal';

import { MainWrapper } from './styles';


interface Props {
}


export const ProgressModalComponent: React.FC<Props> = (props: Props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setupProgressModal(setVisible);
    
    return () => {
      setupProgressModal(null);
    }
  }, [setVisible]);


  return (
    <MainWrapper $visible={visible}>
      <ProgressComponent />
    </MainWrapper>
  );
};
