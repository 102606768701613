import Title from 'lego/styles/title';
import styled from 'styled-components';


export const Grid = styled.div`
  display: grid;
  grid-template-rows: 
    calc(40% - 100px) 
    200px 
    calc(60% - 100px);

  width: 100%;
  height: 100%;

  min-height: 200px;
`;


export const Content = styled.div`
  grid-row: 2; 
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px;

  /* background-color: red; */
`;

export const NoHassle = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.xxsmall};

  color:       ${props => props.theme.defs.colors.color.primary};
  font-size:   ${props => props.theme.defs.font.size.small};
  font-weight: ${props => props.theme.defs.font.weight.xsmall};
`;

export const NoItem = styled.div`
`;