import styled from 'styled-components';
import { Panel as PanelBase } from 'app-views/components/panel-titled/styles';


export const ProgressWrapper = styled.div`
  height: 100px;
`;


export const Panel = styled(PanelBase)`
  min-height: 80px;
`;
