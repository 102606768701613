import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }        from 'app/ui/contexts/document';
import { DNDContext }         from 'app/ui-v2/editor-instruction/dnd-context';
import DNDDropComponent       from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';


interface Props {
  markerAddr: ContentTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerDropComponent: React.FC<Props> = (props: Props) => {
  const { 
    markerAddr,
    children,
  } = props;

  const document = useDocState();
  const getDNDData = useDNDDataGet();

  const {
    setContent,
  } = useEditorStatesSetters();

  const handleDrop_fromGallery = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const markerAddrDropped = data.markerAddr;

    document.content.cellMarkers_addMarkerRight(
      markerAddrDropped,
      markerAddr
    );

    document.saveUndo();
    setContent();
  }

  const handleDrop_fromDocHeader = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const markerAddrDropped = data.markerAddr;
    const headerMarkerProps = document.headerMarkers.getMarkerProps(markerAddrDropped);

    document.content.cellMarkers_addMarkerRight(
      headerMarkerProps.repoMarkerAddr,
      markerAddr
    );
    
    document.saveUndo();
    setContent();  
  }

  const handleDrop_fromCell = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const markerAddrDropped = data.markerAddr;

    document.content.cellMarkers_moveMarkerOnMarker(
      markerAddrDropped,
      markerAddr
    );

    document.saveUndo();
    setContent();
  }

  const contextsDefs = [
    {
      dndContext: DNDContext.marker.cell,
      onDropV2: handleDrop_fromCell,
    },
    {
      dndContext: DNDContext.marker.gallery,
      onDropV2: handleDrop_fromGallery,
    },
    {
      dndContext: DNDContext.marker.docHeader,
      onDropV2: handleDrop_fromDocHeader,
    },
  ];


  return (
    <DNDDropComponent
      contextsDefs={contextsDefs}
    >
      { children }
    </DNDDropComponent>
  );
}
