import axios             from 'axios';
import { AxiosError }    from 'axios';
import { AxiosResponse } from 'axios';

import { ToastsTypes } from 'app/arch/app/info/toasts';
import { useApp }  from 'app/ui/hooks/app/use-app';
import useToastAdd from 'app/ui-v2/app/__modules/info/components/toasts/hooks/use-toast-add';


//---------------------------
// 
// Hook Download PDF
// 

export const usePDFDownload = () => {
  const app = useApp();
  const addToast = useToastAdd();


//---------------------------
// 
// HTTP download mechanics
// 

  const extractFilename = (response: AxiosResponse) => {
    const headers = response.headers;
    const contentDisposition = headers['content-disposition'];
    
    const filenameRegex = /attachment; filename="([^"]+)"/;
    const matches = filenameRegex.exec(contentDisposition);
    const filenameEncoded = matches ? matches[1] : null;
    if (filenameEncoded === null) {
      const msg = (
        `Attachment filename can't be extracted.\n`+
        `Received attachment header: \n` +
        `---------\n` +
        contentDisposition + `\n` +
        `---------\n`
      );
      console.error(msg);
      return null;
    }

    const filename = decodeURI(filenameEncoded);
    return filename;
  }

  const responseToFile = (response: AxiosResponse, filename: string) => {
    const blob = new Blob(
      [response.data], 
      { type: 'application/pdf' }
    );

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    link.remove();
  }

  const downloadPDF = (props: {url: string}) => {
    const {
      url
    } = props;

    const headers = app.auth.getAuthHeader()

    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers,
    })
    .then((response: AxiosResponse) => {
      const filename = extractFilename(response);

      if (filename === null) {
        const msg = `Error processing PDF filename`;
        addToast({
          text: msg,
          level: ToastsTypes.ToastLevel.ERROR,
        });
        return; 
      }

      responseToFile(response, filename);
    })
    .catch((error: AxiosError) => {
      const msg = `Error: ${error.message}`;
      addToast({
        text: msg,
        level: ToastsTypes.ToastLevel.ERROR,
      });
    });
  }

  return downloadPDF;
};
