import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig }   from 'lego-v2/menu/config';

import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { ContentSessionTypes as TypesSession }   from 'app/arch/editor-instruction/document/states/sessions/content-session';

import { Icon }            from "app/ui/icons/icons";
import { useDocState }     from "app/ui/contexts/document";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import useContextMenu      from "app/ui-v2/editor-instruction/hooks/use-context-menu";

import useSidetoolbarPropertiesShow from '../../use-sidetoolbar-properties-show';
import useSelectedReset     from '../use-selected-reset';
import useColumnsAutoAdjust from '../columns/use-columns-auto-adjust';
import usePageColumnSelectedSet from './use-page-column-selected-set';


const useColumnMenu = () => {
  const t = useTranslations();
  const document = useDocState();
  const columnsAutoAdjust = useColumnsAutoAdjust();
  
  const {
    hideMenu
  } = useContextMenu();

  const resetSelected = useSelectedReset();
  const setPageColumnSelected = usePageColumnSelectedSet();
  const showSideToolbarProps = useSidetoolbarPropertiesShow();

  const addField = (
    srcColumnAddr: Types.PageColumnAddr, 
    event: React.PointerEvent
  ) => {
      
    const newColumnName = t('custom fields, default column name, new');
    const newColumnAddr = document.headerFields.addColumnAfter(
      srcColumnAddr, 
      Types.ColumnType.TEXT,
      { name: newColumnName }
    );

    columnsAutoAdjust({ debounced: false });
    document.saveUndo();
    
    const newPageColumnAddr = {
      ...newColumnAddr,
      pageIdx: srcColumnAddr.pageIdx
    }

    setPageColumnSelected({ pageColumnAddr: newPageColumnAddr });
    hideMenu();
  }

  const deleteField = (columnAddr: Types.PageColumnAddr) => {
    document.headerFields.deleteColumn(columnAddr);
    columnsAutoAdjust({ debounced: false });
    document.saveUndo();

    resetSelected();
    hideMenu();
  }

  const showColumnProperties = (
    pageColumnAddr: Types.PageColumnAddr, 
    event: React.PointerEvent
  ) => {
    setPageColumnSelected({ pageColumnAddr });
    showSideToolbarProps(TypesSession.PanelPropertiesItem.HEADER);
    hideMenu();
  }
  

  const getConfig = (pageColumnAddr: Types.PageColumnAddr) => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('custom fields, add'),
                iconComponent: Icon.Create,
                onClick: (event: React.PointerEvent) =>  addField(pageColumnAddr, event),
              }
            },
          ],
        },        
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('custom fields, properties'),
                iconComponent: Icon.Settings,
                onClick: (event: React.PointerEvent) =>  showColumnProperties(pageColumnAddr, event),
              }
            },                
          ],
        },    
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('delete'),
                variant: 'delete',
                iconComponent: Icon.Delete,
                onClick: () =>  deleteField(pageColumnAddr),
              }
            },          
          ],
        },
      ]
    }

    return config;
  }


  const hook = (pageColumnAddr: Types.PageColumnAddr) => {
    const config = getConfig(pageColumnAddr);
    return config;
  }

  return hook;
}


export default useColumnMenu;