import React         from 'react';
import { useRef }    from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import { Input as InputDefault } from './styles';


export interface Props {
  name: string;
  onCancel: () => void;
  onDone: (newName: string) => void;
  Input?: React.ElementType;
}


export const NameInputComponent: React.FC<Props> = (props: Props) => {
  const { 
    name,
    onDone,
    onCancel,
  } = props;

  const [
    localName, 
    setLocalName
  ] = useState(name);

  const Input = props.Input || InputDefault;

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if ( ! inputRef.current) { return; }
    inputRef.current.focus();
  }, [inputRef.current]);


  const commitNewValue = () => {
    onDone(localName);
  }

  const cancelEditing = () => {
    onCancel();
  }

  const handleInputKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();

    if (event.key === "Enter") {
      commitNewValue();
    }
    else if (event.key === "Escape") { 
      cancelEditing();
    }
  }

  const handleInputBlur = () => {
    commitNewValue();
  }

  const handleInputChange = (event: any) => {
    setLocalName(event.target.value);
  }


  return (
    <Input
      ref={inputRef}
      value={localName}
      onBlur={handleInputBlur}
      onChange={handleInputChange}
      onKeyDown={handleInputKeyDown}
    />
  );
}
  
