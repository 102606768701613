import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { useDocState } from "app/ui/contexts/document";


const usePageColumnSelectedSet = () => {
  const document = useDocState();

  const {
    setHeaderFieldsSession
  } = useEditorStatesSetters();

  const selectPageColumn = (props : {
    pageColumnAddr: Types.PageColumnAddr | null,
    skipStateUpdate?: boolean,
  }) => {
    const {
      pageColumnAddr, 
      skipStateUpdate
    } = props;

    document.headerFieldsSession.setPageColumnSelected(pageColumnAddr);

    if (skipStateUpdate) {
      return;
    }
    
    setHeaderFieldsSession();
  }

  return selectPageColumn;
}

export default usePageColumnSelectedSet;