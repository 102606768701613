import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig }   from 'lego-v2/menu/config';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';

import Icon                from 'app/ui/icons/icons';
import { useDocState }     from 'app/ui/contexts/document';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useContextMenu      from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset from 'app/ui-v2/editor-image/hooks/use-selected-reset';
import useWidgetDuplicate from 'app/ui-v2/editor-image/hooks/widget/use-widget-duplicate';
import useWidgetStyleCopy from 'app/ui-v2/editor-image/hooks/widget/use-widget-style-copy';
import useWidgetStylePaste from 'app/ui-v2/editor-image/hooks/widget/use-widget-style-paste';
import useWidgetStyleSetDefault from 'app/ui-v2/editor-image/hooks/widget/use-widget-style-set-default';



const useWidgetContextMenuConfig = () => {
  const t = useTranslations();
  const document = useDocState();

  const {
    setContent,
  } = useEditorStatesSetters();

  const {
    showMenu,
    hideMenu,
  } = useContextMenu();

  const resetSelected = useSelectedReset();

  const duplicateWidget = useWidgetDuplicate();
  const copyWidgetStyle = useWidgetStyleCopy();
  const pasteWidgetStyle = useWidgetStylePaste();
  const setWidgetStyleDefault = useWidgetStyleSetDefault();

  const getConfig = (widgetAddr: ContentTypes.WidgetAddr) => {

    const menuConfig: MenuConfig = {
      sections: [
        {
          items: [
            {
              type: MenuItemType.SUB_MENU,
              data: {
                title: 'Styles',
                iconComponent: Icon.Styles,
                onClick: () => {
                  // Nothing to do here
                },
                sections: [
                  {
                    items: [
                      {
                        type: MenuItemType.BUTTON,
                        data: {
                          title: t('widget style copy'),
                          iconComponent: Icon.Copy,
                          onClick: () => {
                            copyWidgetStyle(widgetAddr);
                            hideMenu();
                          }
                        }
                      },    
                      {
                        type: MenuItemType.BUTTON,
                        data: {
                          title: t('widget style paste'),
                          iconComponent: Icon.Paste,
                          onClick: () => {
                            pasteWidgetStyle(widgetAddr);
                            hideMenu();
                          }
                        }
                      },
                    ]
                  },
                  {
                    items: [
                      {
                        type: MenuItemType.BUTTON,
                        data: {
                          title: t('widget style set as default'),
                          iconComponent: Icon.Default,
                          onClick: () => {
                            setWidgetStyleDefault(widgetAddr);
                            hideMenu();
                          }
                        }
                      }, 
                    ]
                  }
                ]
              }
            },    
          ]
        },
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('duplicate'),
                iconComponent: Icon.Duplicate,
                onClick: () => {
                  duplicateWidget(widgetAddr);
                  hideMenu();
                }
              }
            }
          ]
        },
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('widget move up'),
                iconComponent: Icon.Move.Up,
                onClick: () => {
                  document.content.cellImages_image_widget_moveTowardsTop(widgetAddr);
                  document.saveUndo();
                  setContent();
                }
              }
            },   
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('widget move down'),
                iconComponent: Icon.Move.Down,
                onClick: () => {
                  document.content.cellImages_image_widget_moveTowardsBottom(widgetAddr);
                  document.saveUndo();
                  setContent();
                }
              }
            },                  
          ]
        },        
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('widget delete'),
                variant: 'delete',
                iconComponent: Icon.Delete,
                onClick: () => {
                  document.content.cellImages_image_deleteWidget(widgetAddr);
                  document.saveUndo();
                  setContent();

                  resetSelected();
                  hideMenu();
                }
              }
            },
          ]
        },
      ]
    }
  
    return menuConfig;
  }


  const hook = (widgetAddr: ContentTypes.WidgetAddr) => {
    return getConfig(widgetAddr);
  }

  return hook;
}

export default useWidgetContextMenuConfig;