import styled from "styled-components";
import LightModeIconBase from '@mui/icons-material/LightMode';
import DarkModeIconBase from '@mui/icons-material/DarkMode';


export const LightModeIcon = styled(LightModeIconBase)`
  color: inherit;
`;

export const DarkModeIcon = styled(DarkModeIconBase)`
  color: inherit;
`;

