import LexicalTheme from "./themes/editor-theme";

import { 
  ListItemNode, 
  ListNode,
  ListType
} from "@lexical/list";

export const editorInitConfig = {
  namespace: 'EditorText',
  theme: LexicalTheme,
  onError(error: any) {
    throw error;
  },

  // editorState: initState,


  nodes: [
    ListItemNode, 
    ListNode,


    // SmartListNode,
    // SmartListItemNode,


    // CustomListNode,
    // {
    //   replace: ListNode,
    //   with: (node: ListNode) => {
    //     // console.log("create custom list")
    //     return new CustomListNode(
    //       node.__listType,
    //       node.__start,e
    //       node.__key
    //     );
    //   }
    // },

    // CustomParagraphNode,
    // {
    //   replace: ParagraphNode,
    //   with: (node: ParagraphNode) => {
    //     return new CustomParagraphNode();
    //   }
    // }

  ]
};

