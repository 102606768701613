import { useRef } from "react";
import { useDebouncedCallback } from "use-debounce";

import { DeltaMove } from "app/ui/hooks";
import { Position }  from "app/arch/types";
import { Size     }  from "app/arch/types";
import { CssStyle }  from "app/arch/editor-instruction/css-styles";

import { FrameHandlerBase    } from "../arch";
import { FrameHandlerTypical } from '../arch';
import { FrameTargetUpdate   } from "../arch";
import { FrameCtrlType       } from "../arch";

import FrameCtrlComponent  from "./frame-ctrl";
import { StyledFrameBase } from './style-frame-ctrls-rectangular';


const LEFT_BTN = 0;
const UPDATE_DEBOUNCE_DELAY = 1;


export interface FrameProps {
  dataTest?: string | null;
  scale?: number;
  position: Position;
  size: Size;

  minSize: Size,
  frameComponent?: any;

  children?: React.ReactNode;

  onUpdateStart?: () => void;
  onUpdate?:      (update: FrameTargetUpdate) => void;
  onUpdateDone?:  (update: FrameTargetUpdate) => void;
  
  elementStyle?: CssStyle;

  handlerFactory?: (
    scale: number,
    position: Position, 
    size: Size, 
    minSize: Size,
  ) => FrameHandlerBase;
}


export const FrameResizeComponent: React.FC<FrameProps> = (props: FrameProps) => {
  const frameHandler  = useRef<FrameHandlerBase | null>(null);
  const frameCtrlType = useRef<FrameCtrlType    | null>(null);

  const StyledFrame = props.frameComponent || StyledFrameBase;
  const scale = props.scale || 1;
  const elementStyle = (
    props.elementStyle !== undefined ? 
    props.elementStyle : 
    {}
  );

  const {
    onUpdateStart,
    onUpdate,
    onUpdateDone,
    dataTest
  } = props;

  const handlerFactory = props.handlerFactory || defaultHandlerFactory;

  const processDebounced = useDebouncedCallback((delta: DeltaMove) => {
    const update = frameHandler.current!.process(frameCtrlType.current !, delta);
    onUpdate?.(update);
  }, UPDATE_DEBOUNCE_DELAY);
  
  const handleOnDragStart = (ctrlType: FrameCtrlType) => {
    onUpdateStart?.();
    
    frameHandler.current = handlerFactory(
      scale,
      props.position,
      props.size,
      props.minSize
    );

    frameCtrlType.current = ctrlType;
  }

  const handleOnDragMove = (delta: DeltaMove) => {
    if (frameHandler.current === null) return;
    processDebounced(delta);
  }

  const handleOnDragEnd = (delta: DeltaMove) => {
    if (frameHandler.current === null) return;
    processDebounced.cancel();
    
    const update = frameHandler.current!.process(frameCtrlType.current !, delta);
    onUpdateDone?.(update); 

    frameHandler.current = null;
    frameCtrlType.current = null;
  }

  const getDataTest = (postfix: string) => {
    if (dataTest === null) {
      return '';
    }

    return (
      `${dataTest}`
      + `__ctrl`
      + `__${postfix}`
    );
  }

  const render = () => {
    return (
      <StyledFrame
        scale={scale}
        elementStyle={elementStyle}
      >
        {
          props.children ? props.children : null
        }

        {
          // Top ctrls
        }
        <div 
          data-test={getDataTest('top-left')}
          className="ctrl ctrlCorner ctrlTopLeftWrapper "
        >
          <FrameCtrlComponent
            ctrlType={FrameCtrlType.TOP_LEFT}
            onDragStart={handleOnDragStart}
            onDragMove={handleOnDragMove}
            onDragEnd={handleOnDragEnd}
          />
        </div>

        <div
          data-test={getDataTest('top-middle')}
          className="ctrl ctrlHorizontal ctrlTopHorizontalWrapper"
        >
          <FrameCtrlComponent
            ctrlType={FrameCtrlType.TOP_MIDDLE}
            onDragStart={handleOnDragStart}
            onDragMove={handleOnDragMove}
            onDragEnd={handleOnDragEnd}
          />
        </div>

        <div 
          data-test={getDataTest('top-right')}
          className="ctrl ctrlCorner ctrlTopRightWrapper"
        >
          <FrameCtrlComponent
            ctrlType={FrameCtrlType.TOP_RIGHT}
            onDragStart={handleOnDragStart}
            onDragMove={handleOnDragMove}
            onDragEnd={handleOnDragEnd}
          />
        </div>


        {
          // Middle ctrls
        }
        <div 
          data-test={getDataTest('middle-left')}
          className="ctrl ctrlVertical ctrlLeftVerticalWrapper"
        >
          <FrameCtrlComponent
            ctrlType={FrameCtrlType.MIDDLE_LEFT}
            onDragStart={handleOnDragStart}
            onDragMove={handleOnDragMove}
            onDragEnd={handleOnDragEnd}
          />
        </div>
        
        <div 
          data-test={getDataTest('middle-right')}
          className="ctrl ctrlVertical ctrlRightVerticalWrapper"
        >
          <FrameCtrlComponent
            ctrlType={FrameCtrlType.MIDDLE_RIGHT}
            onDragStart={handleOnDragStart}
            onDragMove={handleOnDragMove}
            onDragEnd={handleOnDragEnd}
          />
        </div>


        {
          // Bottom ctrls
        }
        <div 
          data-test={getDataTest('bottom-left')}
          className="ctrl ctrlCorner ctrlBottomLeftWrapper"
        >
          <FrameCtrlComponent
            ctrlType={FrameCtrlType.BOTTOM_LEFT}
            onDragStart={handleOnDragStart}
            onDragMove={handleOnDragMove}
            onDragEnd={handleOnDragEnd}
          />
        </div>
        
        <div 
          data-test={getDataTest('bottom-middle')}
          className="ctrl ctrlHorizontal ctrlBottomHorizontalWrapper"
        >
          <FrameCtrlComponent
            ctrlType={FrameCtrlType.BOTTOM_MIDDLE}
            onDragStart={handleOnDragStart}
            onDragMove={handleOnDragMove}
            onDragEnd={handleOnDragEnd}
          />
        </div>

        <div 
          data-test={getDataTest('bottom-right')}
          className="ctrl ctrlCorner ctrlBottomRightWrapper"
        >
          <FrameCtrlComponent
            ctrlType={FrameCtrlType.BOTTOM_RIGHT}
            onDragStart={handleOnDragStart}
            onDragMove={handleOnDragMove}
            onDragEnd={handleOnDragEnd}
          />
        </div>

      </StyledFrame>
    );
  }

  return render();
}


const defaultHandlerFactory = (
  scale: number,
  position: Position, 
  size: Size, 
  minSize: Size,
) => {
  return new FrameHandlerTypical(scale, position, size, minSize);
}
