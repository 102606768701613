import styled from 'styled-components';
import * as Defaults from './defaults';


const FontDefaults = Defaults.getFont();


export const EditorEditableBase = styled.div`
  width: 100%;
  height: 100%;
  user-select: none;
  /* background: rgba(255,0,0,0.2); */
  /* padding: 0px; */
  /* background: blue; */
  /* pointer-events: all ; */

  .editor-paragraph {
    // Not sure why position relative is needed.
    // Maybe it is not need, but it is set like this
    // in lexcial demo. Therefore leaving it here.
    position: relative;

    /* display: flex; */
    /* flex-direction: column; */
    margin: 0;
    padding: 0;
    line-height: 1.2;
  } 

  .editor-paragraph:last-child {
    
  }

  .editor-input {
    width: 100%;
    height: 100%;

    box-sizing: border-box;

    outline: 0;
    caret-color: #000;

    font-size: ${FontDefaults.size.init}px;
    color: ${FontDefaults.color};
    /* 
      Setting background to explicit white will cause a problem
      on row adder
     */
    /* background-color: ${FontDefaults.background}; */
  }

  .ed-smartlistitem-row {
    /* background-color: red; */
    display: flex;
    flex-direction: row;
  }

  .ed-smartlistitem-idx {
    /* background-color: red; */
    display: flex;
    justify-content: center;
    padding: 0 4px 0 4px;
    flex-direction: row;
    /* background-color: green; */
    min-width: 20px;
  }


  .editor-text-bold {
    font-weight: bold;
  }

  .editor-text-italic {
    font-style: italic;
  }

  .editor-text-underline {
    text-decoration: underline;
  }
/*  padding-inline-start when tab press TODO*/
  .editor-list-ol {
    padding-inline-start: 20px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 12px;
  }

  .editor-list-ol li {
    margin-bottom: 1px;
  }

  .editor-list-ul {
    padding-inline-start: 20px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 12px;
  }

  .editor-list-ul li {
    margin-bottom: 1px;
  }

  .ltr {
    text-align: left;
  }

  .rtl {
    text-align: right;
  }
`;

export const EditorEditableDisabled = styled(EditorEditableBase)`
  user-select: none;
  pointer-events: none;
`;

export const Placeholder = styled.div`
  display: none;
`;
