import * as State from './state';


export class Instruction {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }

  //-----------------
  //
  // Setters

  setInstructionId(id: number) {
    const newState = State.setInstructionId(this._state, id);
    this.processNewState(newState);
  }

  setRevision(revision: number) {
    const newState = State.setRevision(this._state, revision);
    this.processNewState(newState);
  }

  //-----------------
  //
  // Getters
  
  getId() {
    return State.getId(this._state);
  }

  getRevision() {
    return State.getRevision(this._state);
  }

  private processNewState(newState: State.State) {
    this._state = newState;
  }
}
