import { useEffect } from 'react';
import { useRef }    from 'react';

import { useAuth } from 'services/auth';

import { mutation  }   from 'app/arch/backend';
import { useMutation } from 'app/arch/backend/use-mutation';
import { useApp } from 'app/ui/hooks/app/use-app';
import useAppStateSetters from './use-app-state-setters';


type OnDone = () => void;
type OnError = (error: string) => void;


export interface HookProps {
  googleToken: String;
  onDone?: OnDone;
  onError?: OnError;
}


export const useUserGoogleLogin = () => {
  const app = useApp();
  const auth = useAuth();

  const {
    setApp
  } = useAppStateSetters();

  const hookPropsRef  = useRef<HookProps | null>(null);


  /**
   * Mutation
   */

  const { 
    mutation: mutate,
    data: response,
    error,
  } = useMutation(mutation.loginUserGoogle);


  /**
   * Response
   */

  useEffect(() => {
    if ( ! response ) {
      return;
    }

    const {
      onDone,
      onError
    } = hookPropsRef.current !;
    resetRef();

    const data = response.userGoogleLogin;

    const ok: boolean = data.ok;
    if ( ! ok ) {
      onError?.('Google authentication failed.');
      return;
    }

    const jwtToken: string = data.token;
    const authenticated = auth.setToken(jwtToken);
    if ( ! authenticated ) {
      onError?.('Google authentication failed. Invalid tokend');
      return;
    }

    app.appState.updateState({isLogged: true});
    setApp();

    onDone?.();
  }, [response]);


  /**
   * Error
   */

  useEffect(() => {
    if ( ! error ) {
      return;
    }

    const {
      onError
    } = hookPropsRef.current !;
    resetRef();

    onError?.(error.message);
  }, [error]);


  const resetRef = () => {
    hookPropsRef.current = null;
  }

  const hook = (props: HookProps) => {
    const { googleToken } = props;
    hookPropsRef.current = props;

    mutate({
      variables: { googleToken }
    });
  }

  return hook;
}
