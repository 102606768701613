import i18next from 'i18next';
import produce from 'immer';
import { original } from 'immer';

import Logger   from 'libs/debug';
import jtl      from 'tools/jtl';
import { uuid } from 'tools/uuid';

import { Size }     from 'app/arch/types';
import { CssStyle } from 'app/arch/editor-instruction/css-styles';
import { RepoImagesTypes } from '../../loadable/repo-images';
import { RepoMarkersTools } from '../repo-markers';
import { RepoMarkersTypes } from '../repo-markers';

import  * as Defaults from './defaults';
import  * as Tools from './tools';
import  * as Types from './types';
import  * as State from './state';



export class Content {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }

  //----------------------
  // Setters
  //

  testCrash() {
    this._state = produce(this._state, draft => {
      const columnsProps = State.getColumnsProps(draft);
      const columnsAddrs = State.getColumnsAddrs(draft);

      const columnKey = Tools.getColumnKey(columnsAddrs[0]);
      delete columnsProps[columnKey];
      console.log("Test cras");
      console.log("Removed column props for column ", columnKey);
    });
  }


  /**
   * 
   * Column
   * 
   */

  addColumn(
    columnType: Types.ColumnType,
    columnProps?: Types.ColumnPropsUpdate
  ): Types.ColumnAddr {
    const columnAddr = Tools.createColumnAddr();

    this._state = produce(this._state, draft => {
      const columnsAddrs = State.getColumnsAddrs(draft);
      const idx = columnsAddrs.length;
    
      this.__addColumnAtIdx(
        draft,
        idx,
        columnAddr,
        columnType,
        columnProps
      );
    });

    return columnAddr;
  }

  addColumnAfter(
    srcColumnAddr: Types.ColumnAddr,
    columnType:    Types.ColumnType,
    columnProps?:  Types.ColumnPropsUpdate
  ): Types.ColumnAddr {
    const columnAddr = Tools.createColumnAddr();

    this._state = produce(this._state, draft => {
      const idx = State.getColumnIdx(draft, srcColumnAddr);

      this.__addColumnAtIdx(
        draft,
        idx + 1,
        columnAddr,
        columnType,
        columnProps
      );
    });

    return columnAddr;
  }

  private __addColumnAtIdx(
    draft: State.State, 
    idx: number,
    columnAddr: Types.ColumnAddr,
    columnType:  Types.ColumnType,
    columnPropsUpdate?: Types.ColumnPropsUpdate,
  ) {
    const columnsAddrs  = State.getColumnsAddrs(draft);
    const columnsProps  = State.getColumnsProps(draft);
    
    const columnProps = {
      ...Defaults.getColumnProps(columnType),
      ...columnPropsUpdate
    };
  
    const columnKey = Tools.getColumnKey(columnAddr);
  
    columnsAddrs.splice(idx, 0, columnAddr);
    columnsProps[columnKey]  = columnProps;
  
    //
    // Create cells
    //
    const sectionsAddrs = State.getSectionsAddrs(draft);
    sectionsAddrs.forEach((sectionAddr) => {
      const rowsAddrs = State.getRowsAddrs(draft, sectionAddr);
      rowsAddrs.forEach((rowAddr) => {
  
        const cellAddr: Types.CellAddr = {
          columnId: columnAddr.columnId,
          sectionId: sectionAddr.sectionId,
          rowId: rowAddr.rowId
        }
        this.__createCell(draft, cellAddr, columnType);
      });
    });
  }

  deleteColumn(columnAddr: Types.ColumnAddr) {
    this._state = produce(this._state, draft => {
      const columnType = State.getColumnType(draft, columnAddr);
      if (columnType === Types.ColumnType.INDEX) {
        const msg = `Index column can't be deleted`;
        throw new Error(msg);
      }
  
      const columnsAddrs = State.getColumnsAddrs(draft);
      const columnsProps = State.getColumnsProps(draft);
      
      const idx = State.getColumnIdx(draft, columnAddr);
      const columnKey = Tools.getColumnKey(columnAddr);
    
      columnsAddrs.splice(idx, 1);
      delete columnsProps[columnKey];
    
      //
      // Delete cells
      //
      const sectionsAddrs = State.getSectionsAddrs(draft);
      sectionsAddrs.forEach((sectionAddr) => {
        const rowsAddrs = State.getRowsAddrs(draft, sectionAddr);
        rowsAddrs.forEach((rowAddr) => {
    
          const cellAddr: Types.CellAddr = {
            columnId: columnAddr.columnId,
            sectionId: sectionAddr.sectionId,
            rowId: rowAddr.rowId
          }
          this.__deleteCell(draft, cellAddr);
        });
      });
    });
  }

  updateColumn(
    columnAddr: Types.ColumnAddr,
    update: Types.ColumnPropsUpdate
  ) {
    this._state = produce(this._state, draft => {
      const columnProps = State.getColumnProps(draft, columnAddr);
      Object.assign(columnProps, update);
    });
  }

  updateColumnCSS(
    columnAddr: Types.ColumnAddr,
    updateCSS: React.CSSProperties
  ) {
    this._state = produce(this._state, draft => {
      const columnProps = State.getColumnProps(draft, columnAddr);
      const css = columnProps.css;
      columnProps.css = {
        ...css,
        ...updateCSS,
      }
    });
  }

  updateColumnHeaderCSS(
    columnAddr: Types.ColumnAddr,
    updateCSS: React.CSSProperties
  ) {
    this._state = produce(this._state, draft => {
      const columnProps = State.getColumnProps(draft, columnAddr);
      const header = columnProps.header;
      const css = header.css;
      header.css = {
        ...css,
        ...updateCSS,
      }
    });
  }

  moveColumn(
    srcColumnAddr: Types.ColumnAddr,
    dstColumnAddr: Types.ColumnAddr,
  ) {
    this._state = produce(this._state, draft => {
      if ( Tools.compareColumnAddr(srcColumnAddr, dstColumnAddr )) {
        console.log(`Src and dst columns are the same. Skipping move.`)
        return;
      }
    
      const columnsAddrs = State.getColumnsAddrs(draft);
    
      const srcColumnIdx = State.getColumnIdx(draft, srcColumnAddr);
      const srcIdxLowerThanDstIdx = (srcColumnIdx < State.getColumnIdx(draft, dstColumnAddr));
    
      const srcColumn = columnsAddrs.splice(srcColumnIdx, 1)[0];
      const dstColumnIdx = State.getColumnIdx(draft, dstColumnAddr);
    
      if (srcIdxLowerThanDstIdx) {
        columnsAddrs.splice(dstColumnIdx + 1, 0, srcColumn);
      }
      else {
        columnsAddrs.splice(dstColumnIdx, 0, srcColumn);
      }
    });
  }



  /**
   * 
   * Column Images
   * 
   */

  columnImages_updateColumn(
    columnAddr: Types.ColumnAddr,
    update: Types.ColumnImagesPropsUpdate,
  ) {
    this._state = produce(this._state, draft => {
      const columnProps = State.getColumnProps(draft, columnAddr) as Types.ColumnImagesProps;
      jtl.object.update(columnProps, update);
      // TODO 
      // This method is also used to update css on imageBorder props.
      // Then I'm using recoil to listen on imageBorder.css - and it
      // gets refresh. Not sure why, as it should not - because
      // jtl.object.update supposedly is not changing reference to 
      // the object.
    });
  }


  /**
   * 
   * Sections
   * 
   */

  updateSectionsConfig(configUpdate: Types.SectionsConfigUpdate) {
    this._state = produce(this._state, draft => {
      const config = State.getSectionsConfig(draft);
      Object.assign(config, configUpdate);
    });
  }

  updateSectionsNamesCSS(cssUpdate?: React.CSSProperties) {
    this._state = produce(this._state, draft => {
      const config = State.getSectionsConfig(draft);
      const sectionsNames = config.sectionsNames;
      
      sectionsNames.css = {
        ...sectionsNames.css,
        ...cssUpdate,
      }
    });
  }


  /**
   * 
   * Section
   * 
   */

  addSection(
    sectionPropsUpdate?: Types.SectionPropsUpdate
  ): Types.SectionAddr {
    const sectionAddr = Tools.createSectionAddr();
    const sectionsAddrs = this.getSectionsAddrs();
    const sectionIdx = sectionsAddrs.length;
      
    this.__addSectionAtIdx(
      sectionIdx,
      sectionAddr,
      sectionPropsUpdate
    );

    return sectionAddr;
  }

  addSectionAbove(
    srcSectionAddr: Types.SectionAddr,
    sectionPropsUpdate?: Types.SectionPropsUpdate
  ): Types.SectionAddr {
    const sectionAddr = Tools.createSectionAddr();
    const sectionIdx = this.getSectionIdx(srcSectionAddr);

    this.__addSectionAtIdx(
      sectionIdx,
      sectionAddr,
      sectionPropsUpdate
    );

    return sectionAddr;
  }

  addSectionBelow(
    srcSectionAddr: Types.SectionAddr,
    sectionPropsUpdate?: Types.SectionPropsUpdate
  ): Types.SectionAddr {
    const sectionAddr = Tools.createSectionAddr();
    const sectionIdx = this.getSectionIdx(srcSectionAddr);

    this.__addSectionAtIdx(
      sectionIdx + 1,
      sectionAddr,
      sectionPropsUpdate
    );

    return sectionAddr;
  }

  __addSectionAtIdx(
    sectionIdx: number,
    sectionAddr: Types.SectionAddr,
    sectionPropsUpdate?: Types.SectionPropsUpdate
  ) {
    this._state = produce(this._state, draft => {
      const sectionsAddrs = State.getSectionsAddrs(draft);
      const sectionsProps = State.getSectionsProps(draft);
      const sectionsRows  = State.getSectionsRows(draft);
      const sectionsCells = State.getSectionsCells(draft);
    
      const sectionKeys = Tools.getSectionKey(sectionAddr);
    
      const sectionProps = {
        ...Defaults.getSectionProps(),
        ...sectionPropsUpdate
      };
      
      const sectionCells: Types.Cells = Defaults.getCells();
      const sectionRows: Types.Rows = Defaults.getRows();
    
      sectionsAddrs.splice(sectionIdx, 0, sectionAddr);
    
      sectionsProps[sectionKeys] = sectionProps;
      sectionsRows[sectionKeys]  = sectionRows;
      sectionsCells[sectionKeys] = sectionCells;
    });

    // Add one default row
    this.addRow(sectionAddr);

    const isSectionLast = this.isSectionLast(sectionAddr);
    if ( ! isSectionLast) {
      // If this is not the last section
      // we are done.
      return;
    }

    // It is the last section
    //
    // Add row (rowAdder)
    this.addRow(sectionAddr);

    //
    // Remove last row (rowAdder) from section before this one,
    // section which used to be last before adding this 
    // section.
    const idx = this.getSectionIdx(sectionAddr);
    if (idx > 0) {
      const sectionsAddrs = this.getSectionsAddrs();
      const prevSectionAddr = sectionsAddrs[idx - 1];
      this.deleteLastRow(prevSectionAddr)
    }
  }

  deleteSection(sectionAddr: Types.SectionAddr) {
    const isSectionLast = this.isSectionLast(sectionAddr);

    this._state = produce(this._state, draft => {
      this.__deleteSection(draft, sectionAddr);
    });

    // If it wasn't the last section
    // we are done.
    if ( ! isSectionLast ) {
      return;
    }

    // If it was last section, add row (rowAdder)
    // to previous sections
    const sectionsAddrs = this.getSectionsAddrs();
    if (sectionsAddrs.length === 0) {
      return;
    }

    const lastSectionAddr = sectionsAddrs[sectionsAddrs.length - 1];
    this.addRow(lastSectionAddr);
  }

  private __deleteSection(
    draft: State.State,
    sectionAddr: Types.SectionAddr
  ) {
    const log = Logger.getContentState();
    log.log(
      "Delete section\n" +
      Tools.debugSectionAddr(sectionAddr)
    );

    //
    // First delete rows (which will delete cells as well)
    //
    const rowsAddrs = State.getRowsAddrs(draft, sectionAddr);
    rowsAddrs.map((rowAddr) => {
      this.__deleteRow(draft, rowAddr);
    });

    //
    // Delete section and remaining containers
    //
    const sectionsAddrs = State.getSectionsAddrs(draft);
    const sectionsProps = State.getSectionsProps(draft);
    const sectionsRows  = State.getSectionsRows(draft);
    const sectionsCells = State.getSectionsCells(draft);

    const sectionIdx = State.getSectionIdx(draft, sectionAddr);
    const sectionKeys = Tools.getSectionKey(sectionAddr);

    sectionsAddrs.splice(sectionIdx, 1);
    delete sectionsProps[sectionKeys];
    delete sectionsRows[sectionKeys];
    delete sectionsCells[sectionKeys];
  }

  updateSection(
    sectionAddr: Types.SectionAddr,
    update: Types.SectionPropsUpdate
  ) {
    this._state = produce(this._state, draft => {
      const sectionProps = State.getSectionProps(draft, sectionAddr);
      Object.assign(sectionProps, update);
    });
  }

  moveSection(
    srcSectionAddr: Types.SectionAddr,
    dstSectionAddr: Types.SectionAddr,
  ) {
    const sameSection = Tools.compareSectionAddr(srcSectionAddr, dstSectionAddr);
    if ( sameSection ) {
      console.log("The same section, not moving");
      return;
    }

    this._state = produce(this._state, draft => {
      const srcSectionIdx = State.getSectionIdx(draft, srcSectionAddr);
      const dstSectionIdxTmp = State.getSectionIdx(draft, dstSectionAddr);
    
      const srcIdxLowerThanDstIdx = (srcSectionIdx < dstSectionIdxTmp);
    
      const sectionsAddrs = State.getSectionsAddrs(draft);
      sectionsAddrs.splice(srcSectionIdx, 1)[0];
    
      const dstSectionIdx = State.getSectionIdx(draft, dstSectionAddr);
    
      if (srcIdxLowerThanDstIdx) {
        sectionsAddrs.splice(dstSectionIdx + 1, 0, srcSectionAddr);
      }
      else {
        sectionsAddrs.splice(dstSectionIdx, 0, srcSectionAddr);
      }
    });

    //
    // Logic to add/remove row if section become/stop being last
    const isSrcSectionLast = this.isSectionLast(srcSectionAddr);
    const isDstSectionLast = this.isSectionLast(dstSectionAddr);

    if (isSrcSectionLast) {
      this.addRow(srcSectionAddr);
      this.deleteLastRow(dstSectionAddr);
    }

    if (isDstSectionLast) {
      this.addRow(dstSectionAddr);
      this.deleteLastRow(srcSectionAddr);
    }
  }

  deleteLastRow(sectionAddr: Types.SectionAddr) {
    this._state = produce(this._state, draft => {
      const rowsAddrs = State.getRowsAddrs(draft, sectionAddr);
      if (rowsAddrs.length === 0) {
        console.warn(`No rows found`);
        return;
      }

      const rowAddr = rowsAddrs[rowsAddrs.length - 1];
      this.__deleteRow(draft, rowAddr);
    });
  }

  /**
   * 
   * Row
   * 
   */

  addRow(
    sectionAddr: Types.SectionAddr
  ): Types.RowAddr {
    const rowAddr = Tools.createRowAddr(sectionAddr);

    this._state = produce(this._state, draft => {
      const rowsAddrs = State.getRowsAddrs(draft, rowAddr);
      const rowIdx = rowsAddrs.length;
      
      this.__addRowAtIdx(
        draft,
        rowAddr,
        rowIdx, 
      );
    });

    return rowAddr;
  }

  addRowAbove(
    srcRowAddr: Types.RowAddr,
  ): Types.RowAddr {
    const rowAddr = Tools.createRowAddr(srcRowAddr);

    this._state = produce(this._state, draft => {
      const rowIdx = State.getRowIdxSection(draft, srcRowAddr);
      this.__addRowAtIdx(
        draft,
        rowAddr,
        rowIdx, 
      );
    });

    return rowAddr;
  }

  addRowBelow(
    srcRowAddr: Types.RowAddr,
  ): Types.RowAddr {
    const rowAddr = Tools.createRowAddr(srcRowAddr);

    this._state = produce(this._state, draft => {
      const rowIdx = State.getRowIdxSection(draft, srcRowAddr);
      this.__addRowAtIdx(
        draft,
        rowAddr,
        rowIdx + 1, 
      );
    });

    return rowAddr;
  }

  private __addRowAtIdx(
    draft: State.State, 
    rowAddr: Types.RowAddr,
    idx: number,
  ) {
    const rowsAddrs = State.getRowsAddrs(draft, rowAddr);
    const rowsProps = State.getRowsProps(draft, rowAddr)
    const rowKey = Tools.getRowKey(rowAddr);
  
    rowsAddrs.splice(idx, 0, rowAddr);
    rowsProps[rowKey] = Defaults.getRowProps();
  
    const columnsAddrs = State.getColumnsAddrs(draft);
    columnsAddrs.forEach((columnAddr) => {
      const cellAddr = {
        columnId: columnAddr.columnId,
        sectionId: rowAddr.sectionId,
        rowId: rowAddr.rowId,
      }
  
      const columnProps = State.getColumnProps(draft, columnAddr);
      this.__createCell(draft, cellAddr, columnProps.type);
    });
  }

  deleteRow(rowAddr: Types.RowAddr) {
    this._state = produce(this._state, draft => {
      this.__deleteRow(draft, rowAddr);
    });
  }

  private __deleteRow(
    draft: State.State, 
    rowAddr: Types.RowAddr
  ) {
    const log = Logger.getContentState();
    log.log(
      "Delete row\n" +
      Tools.debugRowAddr(rowAddr)
    );

    const rowsAddrs = State.getRowsAddrs(draft, rowAddr);
    const rowsProps = State.getRowsProps(draft, rowAddr)
    const rowIdx    = State.getRowIdxSection(draft, rowAddr);
    const rowKey    = Tools.getRowKey(rowAddr);

    rowsAddrs.splice(rowIdx, 1);
    delete rowsProps[rowKey];

    //
    // Delete row cells
    //
    const columnsAddrs = State.getColumnsAddrs(draft);
    columnsAddrs.forEach((columnAddr) => {
      const cellAddr: Types.CellAddr = {
        columnId: columnAddr.columnId,
        sectionId: rowAddr.sectionId,
        rowId: rowAddr.rowId,
      }

      this.__deleteCell(draft, cellAddr);
    });
  }

  moveRowToRow(
    srcRowAddr: Types.RowAddr,
    dstRowAddr: Types.RowAddr,
  ) {
    const sameRow = Tools.compareRowAddr(srcRowAddr, dstRowAddr);
    if ( sameRow ) {
      console.log("The same row, not moving");
      return;
    }
  
    const sameSection = Tools.compareSectionAddr(
      srcRowAddr, 
      dstRowAddr
    );
    
    if ( sameSection ) {
      this.__moveRowToRow_sameSection(srcRowAddr, dstRowAddr);
    }
    else {
      this.__moveRowToRow_diffrentSection(srcRowAddr, dstRowAddr);
    }
  }

  private __moveRowToRow_sameSection(
    srcRowAddr: Types.RowAddr,
    dstRowAddr: Types.RowAddr,
  ) {
    this._state = produce(this._state, draft => {
      const srcRowIdx    = State.getRowIdxSection(draft, srcRowAddr);
      const dstRowIdxTmp = State.getRowIdxSection(draft, dstRowAddr);
    
      const srcIdxLowerThanDstIdx = (srcRowIdx < dstRowIdxTmp);
  
      const rowsAddrs = State.getRowsAddrs(draft, srcRowAddr);
      const srcRow    = rowsAddrs.splice(srcRowIdx, 1)[0];
      const dstRowIdx = State.getRowIdxSection(draft, dstRowAddr);
  
      if (srcIdxLowerThanDstIdx) {
        rowsAddrs.splice(dstRowIdx + 1, 0, srcRow);
      }
      else {
        rowsAddrs.splice(dstRowIdx, 0, srcRow);
      }
    });
  }

  private __moveRowToRow_diffrentSection(
    srcRowAddr: Types.RowAddr,
    dstRowAddr: Types.RowAddr,
  ) {
    this._state = produce(this._state, draft => {
      const srcRowsProps = State.getRowsProps(draft, srcRowAddr);
      const srcRowKey    = Tools.getRowKey(srcRowAddr);
      const srcRowProps  = srcRowsProps[srcRowKey];
      
      const dstRowsAddrs = State.getRowsAddrs(draft, dstRowAddr);
      const dstRowsProps = State.getRowsProps(draft, dstRowAddr);
      const dstRowIdx    = State.getRowIdxSection(draft, dstRowAddr);
  
      const newRowAddr = {
        rowId: srcRowAddr.rowId,
        sectionId: dstRowAddr.sectionId,
      }
      const newRowKey = Tools.getRowKey(newRowAddr);
      const newRowProps = srcRowProps;

      dstRowsAddrs.splice(dstRowIdx + 1, 0, newRowAddr);
      dstRowsProps[newRowKey] = newRowProps;
  
      this.__copyRowCells(
        draft,
        srcRowAddr,
        newRowAddr
      );

      this.__deleteRow(draft, srcRowAddr);
    });
  }

  moveRowToSection(
    srcRowAddr: Types.RowAddr,
    dstSection: Types.SectionAddr,
  ) {
    const sameSection = Tools.compareSectionAddr(
      srcRowAddr, 
      dstSection
    );
    
    if ( sameSection ) {
      this.__moveRowToSection_sameSection(srcRowAddr);
    }
    else {
      this.__moveRowToSection_diffrentSection(srcRowAddr, dstSection);
    }
  }

  private __moveRowToSection_sameSection(
    srcRowAddr: Types.RowAddr,
  ) {
    this._state = produce(this._state, draft => {
      const srcRowIdx    = State.getRowIdxSection(draft, srcRowAddr);
      if (srcRowIdx === 0) {
        // It is already there.
        return;
      }

      const rowsAddrs = State.getRowsAddrs(draft, srcRowAddr);
      const srcRow    = rowsAddrs.splice(srcRowIdx, 1)[0];
  
      rowsAddrs.splice(0, 0, srcRow);
    });
  }

  private __moveRowToSection_diffrentSection(
    srcRowAddr: Types.RowAddr,
    dstSection: Types.SectionAddr,
  ) {
    this._state = produce(this._state, draft => {
      const srcRowsProps = State.getRowsProps(draft, srcRowAddr);
      const srcRowKey    = Tools.getRowKey(srcRowAddr);
      const srcRowProps  = srcRowsProps[srcRowKey];
      
      const dstRowsAddrs = State.getRowsAddrs(draft, dstSection);
      const dstRowsProps = State.getRowsProps(draft, dstSection);
  
      const newRowAddr = {
        rowId: srcRowAddr.rowId,
        sectionId: dstSection.sectionId,
      }
      const newRowKey = Tools.getRowKey(newRowAddr);
      const newRowProps = srcRowProps;

      const dstRowIdx = 0;
      dstRowsAddrs.splice(dstRowIdx, 0, newRowAddr);
      dstRowsProps[newRowKey] = newRowProps;
  
      this.__copyRowCells(
        draft,
        srcRowAddr,
        newRowAddr
      );

      this.__deleteRow(draft, srcRowAddr);
    });
  }

  private __copyRowCells(
    draft: State.State,
    srcRowAddr: Types.RowAddr,
    dstRowAddr: Types.RowAddr,
  ) {
    const log = Logger.getContentState();

    const srcCells = State.getCells(draft, srcRowAddr);
    const dstCells = State.getCells(draft, dstRowAddr);

    const columnsAddrs = State.getColumnsAddrs(draft);
    columnsAddrs.forEach((columnAddr) => {
      const srcCellAddr = {
        columnId: columnAddr.columnId,
        sectionId: srcRowAddr.sectionId,
        rowId: srcRowAddr.rowId,
      }
      const srcCellKey = Tools.getCellKey(srcCellAddr);

      const newCellAddr = {
        columnId: columnAddr.columnId,
        sectionId: dstRowAddr.sectionId,
        rowId: dstRowAddr.rowId,
      }
      const newCellKey = Tools.getCellKey(newCellAddr);

      log.log(
        "Copy cell\n" +
        "Src cell\n" +
        Tools.debugCellAddr(srcCellAddr) + "\n" +
        "Dst cell\n" +
        Tools.debugCellAddr(newCellAddr)
      );

      const srcCell = srcCells[srcCellKey];

      const columnProps = State.getColumnProps(draft, columnAddr);


      /**
       * 
       * Copy Markers cell
       * 
       */


      if ( columnProps.type === Types.ColumnType.MARKERS) {
        const srcMarkerCell = srcCell as Types.MarkersCell;
        const markers = srcMarkerCell.markers;

        const markersConverted = markers.addrs.map(markerAddr => {
          const markerKey = Tools.getMarkerKey(markerAddr);
          const markerProps = markers.props[markerKey];

          const dstMarkerProps = JSON.parse(JSON.stringify(markerProps));
          const dstMarkerAddr: Types.MarkerAddr = {
            markerId: markerAddr.markerId,
            sectionId: dstRowAddr.sectionId,
            columnId: columnAddr.columnId,
            rowId: dstRowAddr.rowId, 
          }

          return { dstMarkerAddr, dstMarkerProps};
        });

        const dstMarkersAddrs = markersConverted.map((item) => item.dstMarkerAddr);
        const dstMarkersProps: { [key: string]: any } = {};
        markersConverted.forEach(({ dstMarkerAddr, dstMarkerProps}) => {
          const Key = Tools.getMarkerKey(dstMarkerAddr);
            dstMarkersProps[Key] = dstMarkerProps;
        });

        const dstMarkerCell: Types.MarkersCell = {
          markers: {
            addrs: dstMarkersAddrs,
            props: dstMarkersProps,
          }
        };

        dstCells[newCellKey] = dstMarkerCell;
      }


      /**
       * 
       * Copy Image Cell
       * 
       */

      else if ( columnProps.type === Types.ColumnType.IMAGES) {
        const srcImagesCell = srcCell as Types.ImagesCell;
        const images = srcImagesCell.images;

        const imagesConverted = images.addrs.map(imageAddr => {
          const imageKey = Tools.getImageKey(imageAddr);
          const imageProps = images.props[imageKey];

          // Convert widgets
          const widgetsConverted = imageProps.widgets.addrs.map((widgetAddr) => {
            const widgetKey = Tools.getWidgetKey(widgetAddr);
            const widgetProps = imageProps.widgets.props[widgetKey];

            const dstWidgetProps = JSON.parse(JSON.stringify(widgetProps));
            const dstWidgetAddr: Types.WidgetAddr = {
              widgetId: widgetAddr.widgetId,
              imageId: imageAddr.imageId,
              sectionId: dstRowAddr.sectionId,
              columnId: columnAddr.columnId,
              rowId: dstRowAddr.rowId, 
            }
  
            return { dstWidgetAddr, dstWidgetProps};
          });

          const dstWidgetsAddrs = widgetsConverted.map(item => item.dstWidgetAddr);
          const dstWidgetsProps: { [key: string]: any } = {};
          widgetsConverted.forEach(({ dstWidgetAddr, dstWidgetProps}) => {
            const Key = Tools.getWidgetKey(dstWidgetAddr);
            dstWidgetsProps[Key] = dstWidgetProps;
          });

          const widgetsCopy = {
            addrs: dstWidgetsAddrs,
            props: dstWidgetsProps,
          }

          const dstImageProps = JSON.parse(JSON.stringify(imageProps));
          dstImageProps.widgets = widgetsCopy;

          const dstImageAddr: Types.ImageAddr = {
            imageId: imageAddr.imageId,
            sectionId: dstRowAddr.sectionId,
            columnId: columnAddr.columnId,
            rowId: dstRowAddr.rowId, 
          }

          return { dstImageAddr, dstImageProps};
        });

        const dstImagesAddrs = imagesConverted.map((item) => item.dstImageAddr);
        const dstImagesProps: { [key: string]: any } = {};
        imagesConverted.forEach(({ dstImageAddr, dstImageProps}) => {
          const Key = Tools.getImageKey(dstImageAddr);
            dstImagesProps[Key] = dstImageProps;
        });

        const dstImagesCell: Types.ImagesCell = {
          images: {
            addrs: dstImagesAddrs,
            props: dstImagesProps,
          }
        };

        dstCells[newCellKey] = dstImagesCell;
      }


      /**
       * 
       * Copy Index Cell
       * 
       */

      else if ( columnProps.type === Types.ColumnType.INDEX) {
        const srcIndexCell = srcCell as Types.IndexCell;
        dstCells[newCellKey] = JSON.parse(JSON.stringify(srcIndexCell));;
      }


      /**
       * 
       * Copy Text Cell
       * 
       */

      else if ( columnProps.type === Types.ColumnType.TEXT) {
        const srcTextCell = srcCell as Types.TextCell;
        dstCells[newCellKey] = JSON.parse(JSON.stringify(srcTextCell));
      }

      
      else {
        const msg = `Unknown column type ${columnProps.type}`;
        throw new Error(msg);
      }
    });
  }

  checkForLastRow(rowAddr: Types.RowAddr) {
    const lastRow = this.isRowLast(rowAddr);
    if (! lastRow) {
      return;
    }

    this.addRow(rowAddr);
  }
  

  private __createCell(
    draft: State.State,
    cellAddr: Types.CellAddr,
    cellType: Types.ColumnType,
  ) {
    const log = Logger.getContentState();
    log.log(
      "Create cell\n" +
      Tools.debugCellAddr(cellAddr)
    );

    const cells = State.getCells(draft, cellAddr);
    const cellKey = Tools.getCellKey(cellAddr);

    if (cellKey in cells) {
      const msg = `Can create cell, as cell already exists`;
      throw new Error(msg);
    }

    const cellDef = Defaults.getCell(cellType);
    cells[cellKey] = cellDef;
  }

  private __deleteCell(
    draft: State.State,
    cellAddr: Types.CellAddr
  ) {
    const log = Logger.getContentState();
    log.log(
      "Delete cell called\n" +
      Tools.debugCellAddr(cellAddr)
    );

    const cells = State.getCells(draft, cellAddr);
    const cellKey = Tools.getCellKey(cellAddr);

    if (! ( cellKey in cells )) {
      const msg = `Can delete cell, as it is missing`;
      throw new Error(msg);
    }

    delete cells[cellKey];
  }

    
  /**
   * Cell Text
   */
  cellText_writeContent(
    cellAddr: Types.CellAddr, 
    editorState: string
  ) {
    this._state = produce(this._state, draft => {
      const cell = State.getCell(draft, cellAddr) as Types.TextCell;
      cell.editorState = editorState;
    });
  }


  /**
   * Cells Images
   */

  cellsImages_removeImage(bid: number) {
    const imagesAddrs = this.cellsImages_getImagesAddrs();
    const imagesAddrsToRemove: Types.ImageAddr[] = [];

    imagesAddrs.forEach((imageAddr) => {
      const imageProps = this.cellImages_getImageProps(imageAddr);
      if (imageProps.bid === bid) {
        imagesAddrsToRemove.push(imageAddr);
      }
    });

    imagesAddrsToRemove.forEach((imageAddr) => {
      this.cellImages_removeImage(imageAddr);
    });
  }


  /**
   * Cell Images
   */

  cellImages_addImage(
    cellAddr: Types.CellAddr,
    backendImage: RepoImagesTypes.Image
  ) {
    const logger = Tools.getLogger();
    
    /**
     * Add image
     */
    const imageId = uuid();
    const imageAddr: Types.ImageAddr = {
      ...cellAddr,
      imageId
    }

    logger.debug(
      `Cell[${jtl.object.hash(cellAddr)}] add `
      + `image[${jtl.object.hash(imageAddr)}]`
    );

    
    // 
    // Add image to cell
    //
    this._state = produce(this._state, draft => {
      const imagesAddrs = State.cellImages_getImagesAddrs(draft, cellAddr);
      const imagesProps = State.cellImages_getImagesProps(draft, cellAddr);
      const imageKey = Tools.getImageKey(imageAddr);

      
      imagesAddrs.push(imageAddr);
      imagesProps[imageKey] = Defaults.getImageProps(backendImage);
    });

    //
    // Add image to image :) (as widget)
    //
    this.cellImages_image_addWidget(
      imageAddr,
      Types.WidgetType.IMAGE,
      {}, // TODO - should it be default style ?
      { backendImage }
    );

    return imageAddr;
  }

  cellImages_removeImage(
    imageAddr: Types.ImageAddr
  ) {
    this._state = produce(this._state, draft => {
      const imagesAddrs = State.cellImages_getImagesAddrs(draft, imageAddr);
      const imagesProps = State.cellImages_getImagesProps(draft, imageAddr);

      const imageIdx = State.cellImages_getImageIdx(draft, imageAddr);
      const imageKey = Tools.getImageKey(imageAddr);
    
      imagesAddrs.splice(imageIdx, 1);
      delete imagesProps[imageKey];
    });
  }
  
  cellImages_moveImageOnImage(
    srcImageAddr: Types.ImageAddr, 
    dstImageAddr: Types.ImageAddr, 
  ) {
    const sameImage = Tools.compareImageAddr(srcImageAddr, dstImageAddr);
    if ( sameImage ) {
      console.log("The same image, not moving");
      return;
    }

    const sameCell = Tools.compareCellAddr(srcImageAddr, dstImageAddr);

    //
    // Move within the same cell
    //
    if ( sameCell ) {
      this._state = produce(this._state, draft => {
        const imagesAddrs  = State.cellImages_getImagesAddrs(draft, srcImageAddr);
        const srcImgIdx    = State.cellImages_getImageIdx(draft, srcImageAddr);
        const dstImgIdxTmp = State.cellImages_getImageIdx(draft, dstImageAddr);
      
        const srcIdxLowerThanDstIdx = (srcImgIdx < dstImgIdxTmp);
    
        imagesAddrs.splice(srcImgIdx, 1);
        const dstImgIdx = State.cellImages_getImageIdx(draft, dstImageAddr);
    
        if (srcIdxLowerThanDstIdx) {
          imagesAddrs.splice(dstImgIdx + 1, 0, srcImageAddr);
        }
        else {
          imagesAddrs.splice(dstImgIdx, 0, srcImageAddr);
        }
      });

      return;
    }
    
    //
    // Move across two different cells
    //
    const dstImgIdx = this.cellImages_getImageIdx(dstImageAddr);
    this.cellImages_moveImageIntoCell(
      srcImageAddr,
      dstImageAddr,
      dstImgIdx
    );
  }

  cellImages_moveImageIntoCell(
    srcImageAddr: Types.ImageAddr, 
    dstCellAddr: Types.CellAddr, 
    dstImgIdx?: number,
  ) {
    this._state = produce(this._state, draft => {
      const sameCell = Tools.compareCellAddr(srcImageAddr, dstCellAddr);
      if ( sameCell ) {
        console.log("The same cell, not moving");
        return;
      }
     
      const srcImgsAddrs = State.cellImages_getImagesAddrs(draft, srcImageAddr);
      const srcImgsProps = State.cellImages_getImagesProps(draft, srcImageAddr);

      const srcImgIdx = State.cellImages_getImageIdx(draft, srcImageAddr);
      const srcImgKey = Tools.getImageKey(srcImageAddr);
      const srcImgProps = srcImgsProps[srcImgKey];

      srcImgsAddrs.splice(srcImgIdx, 1);
      delete srcImgsProps[srcImgKey];
  
      const dstImgsAddrs = State.cellImages_getImagesAddrs(draft, dstCellAddr);
      const dstImgsProps = State.cellImages_getImagesProps(draft, dstCellAddr);

      const dstImgAddr: Types.ImageAddr = {
        ...dstCellAddr,
        imageId: srcImageAddr.imageId
      }
      const dstImgKey = Tools.getImageKey(dstImgAddr);

      if (dstImgIdx === undefined) {
        dstImgsAddrs.push(dstImgAddr);
      }
      else {
        dstImgsAddrs.splice(dstImgIdx + 1, 0, dstImgAddr);
      }

      const newWidgets = this.__copyCellWidgets(srcImgProps.widgets, dstCellAddr);
      dstImgsProps[dstImgKey] = {
        ...srcImgProps,
        widgets: newWidgets
      };
    });
  }

  private __copyCellWidgets(
    srcWidgets: Types.Widgets,
    dstCellAddr: Types.CellAddr,
  ) {
    const newWidgets = Defaults.getWidgets();
    
    srcWidgets.addrs.map((srcWidgetAddr) => {
      const srcWidgetKey = Tools.getWidgetKey(srcWidgetAddr);
      const srcWidgetProps = srcWidgets.props[srcWidgetKey];
    
      const newWidgetAddrs = {
        ...dstCellAddr,
        imageId: srcWidgetAddr.imageId,
        widgetId: srcWidgetAddr.widgetId,
      };

      const newWidgetKey = Tools.getWidgetKey(newWidgetAddrs);
      newWidgets.addrs.push(newWidgetAddrs);
      newWidgets.props[newWidgetKey] = srcWidgetProps;
    });

    return newWidgets;
  }

  cellImages_updateImage(
    imageAddr: Types.ImageAddr,
    update: Types.ImagePropsUpdate
  ) {
    this._state = produce(this._state, draft => {
      const imageProps = State.cellImages_getImageProps(draft, imageAddr);
      Object.assign(imageProps, update);
    });
  }


  /**
   * Cell Image, Image
   */
  cellImages_image_addWidget(
    imageAddr: Types.ImageAddr,
    widgetType: Types.WidgetType,
    style: CssStyle,
    customConstructonData?: any, // For now only for image
  ) {
    const logger = Tools.getLogger();

    const widgetId = uuid();
    const widgetAddr: Types.WidgetAddr = {
      ...imageAddr,
      widgetId
    }

    logger.debug(
      `Image[${jtl.object.hash(imageAddr)}] add `
      + `widget[${jtl.object.hash(widgetAddr)}]`
    );

    this._state = produce(this._state, draft => {
      const imageProps = this.cellImages_getImageProps(imageAddr);

      const widgetProps = Defaults.getWidgetProps(
        widgetType,
        imageProps,
        style,
        customConstructonData
      );

      const widgetsAddrs = State.cellImages_image_getWidgetsAddrs(draft, imageAddr);
      const widgetsProps = State.cellImages_image_getWidgetsProps(draft, imageAddr);
      const widgetKey = Tools.getWidgetKey(widgetAddr);

      widgetsAddrs.push(widgetAddr);
      widgetsProps[widgetKey] = widgetProps;
    });

    return widgetAddr;
  }

  cellImages_image_cloneWidget(
    imageAddr: Types.ImageAddr,
    widgetProps: Types.WidgetProps,
  ) {
    const widgetId = uuid();
    const widgetAddr: Types.WidgetAddr = {
      ...imageAddr,
      widgetId
    }

    this._state = produce(this._state, draft => {
      const widgetsAddrs = State.cellImages_image_getWidgetsAddrs(draft, imageAddr);
      const widgetsProps = State.cellImages_image_getWidgetsProps(draft, imageAddr);
      const widgetKey = Tools.getWidgetKey(widgetAddr);

      widgetsAddrs.push(widgetAddr);
      widgetsProps[widgetKey] = widgetProps;
    });

    return widgetAddr;
  }

  cellImages_image_deleteWidget(
    widgetAddr: Types.WidgetAddr,
  ) {
    this._state = produce(this._state, draft => {
      const widgetsAddrs = State.cellImages_image_getWidgetsAddrs(draft, widgetAddr);
      const widgetsProps = State.cellImages_image_getWidgetsProps(draft, widgetAddr);
      
      const widgetIdx = State.cellImages_image_getWidgetIdx(draft, widgetAddr);
      const widgetKey = Tools.getWidgetKey(widgetAddr);

      widgetsAddrs.splice(widgetIdx, 1);
      delete widgetsProps[widgetKey];
    });
  }

  cellImages_image_duplicateWidget(
    widgetAddr: Types.WidgetAddr,
  ): Types.WidgetAddr {
    const newWidgetAddr = {
      ...widgetAddr,
      widgetId: uuid()
    }

    this._state = produce(this._state, draft => {
      const widgetsAddrs = State.cellImages_image_getWidgetsAddrs(draft, widgetAddr);
      const widgetsProps = State.cellImages_image_getWidgetsProps(draft, widgetAddr);
      const widgetProps  = State.cellImages_image_getWidgetProps(draft, widgetAddr);
  
      const orgWidgetProps = original(widgetProps);
      const clonedWidgetProps: Types.WidgetProps = jtl.object.copy(orgWidgetProps);
      
      Tools.cascadeWidgetPosition(clonedWidgetProps);
  
      const t = i18next.t;
      clonedWidgetProps.name = `${widgetProps.name} ${t('copy noun')}`
      
      const newWidgetKey = Tools.getWidgetKey(newWidgetAddr);
      widgetsAddrs.push(newWidgetAddr);
      widgetsProps[newWidgetKey] = clonedWidgetProps;
    });

    return newWidgetAddr;
  }

  cellImages_image_duplicateWidgets(
    widgetsAddrs: Types.WidgetsAddrs,
  ): Types.WidgetsAddrs {
    const newWidgetsAddrs = widgetsAddrs.map((widgetAddr) => {
      return this.cellImages_image_duplicateWidget(widgetAddr);
    })

    return newWidgetsAddrs;
  }

  cellImages_image_updateWidget(
    widgetAddr: Types.WidgetAddr,
    update: Types.WidgetBasePropsUpdate
  ) {
    this._state = produce(this._state, draft => {
      const widgetProps = State.cellImages_image_getWidgetProps(draft, widgetAddr);
      Object.assign(widgetProps, update);
    });
  }

  cellImages_image_widget_setPosition(
    widgetAddr: Types.WidgetAddr,
    widgetPosition: Types.WidgetPosition
  ) {
    this._state = produce(this._state, draft => {
      const widgetProps = State.cellImages_image_getWidgetProps(draft, widgetAddr);

      if (Tools.isWidgetBoxed(widgetProps.type)) {
        const position = widgetPosition as Types.WidgetBoxedPosition;
        const widgetBoxedProps = widgetProps as Types.WidgetBoxedProps;
        widgetBoxedProps.position = position;
      }

      else if (Tools.isWidgetArrow(widgetProps.type)) {
        const arrowPosition = widgetPosition as Types.WidgetArrowPosition;
        const startPoint = arrowPosition.startPoint;
        const endPoint   = arrowPosition.endPoint;
        
        const widgetArrowProps = widgetProps as Types.WidgetArrowProps;
        widgetArrowProps.startPoint = startPoint;
        widgetArrowProps.endPoint   = endPoint;
      }

      else {
        console.warn(`Set position not implement for widget ${widgetProps.type}`);
      }
    });
  }

  cellImages_image_widget_setStyle(
    widgetAddr: Types.WidgetAddr,
    style: CssStyle
  ) {
    this._state = produce(this._state, draft => {
      const widgetProps = State.cellImages_image_getWidgetProps(draft, widgetAddr);
      
      const typedCSS = Tools.getWidgetStyleTyped(
        widgetProps.type,
        style
      );
      
      widgetProps.style = typedCSS;
    });
  }

  cellImages_image_widget_updateStyle(
    widgetAddr: Types.WidgetAddr,
    styleUpdate: CssStyle
  ) {
    this._state = produce(this._state, draft => {
      const widgetProps = State.cellImages_image_getWidgetProps(draft, widgetAddr);
      
      const updateCSS = {
        ...widgetProps.style,
        ...styleUpdate,
      }

      const typedCSS = Tools.getWidgetStyleTyped(
        widgetProps.type,
        updateCSS
      );

      widgetProps.style = typedCSS;
    });
  }

  cellImages_image_widget_moveTowardsTop(
    widgetAddr: Types.WidgetAddr,
  ) {
    this._state = produce(this._state, draft => {
      const widgetsAddrs = State.cellImages_image_getWidgetsAddrs(draft, widgetAddr);
      const widgetIdx = State.cellImages_image_getWidgetIdx(draft, widgetAddr);
      
      if (widgetIdx < widgetsAddrs.length - 1) {
        widgetsAddrs.splice(widgetIdx, 1);
        widgetsAddrs.splice(widgetIdx + 1, 0, widgetAddr);
      }
    });
  }

  cellImages_image_widget_moveTowardsBottom(
    widgetAddr: Types.WidgetAddr,
  ) {
    this._state = produce(this._state, draft => {
      const widgetsAddrs = State.cellImages_image_getWidgetsAddrs(draft, widgetAddr);
      const widgetIdx = State.cellImages_image_getWidgetIdx(draft, widgetAddr);
      
      if (widgetIdx > 0) {
        widgetsAddrs.splice(widgetIdx, 1);
        widgetsAddrs.splice(widgetIdx - 1, 0, widgetAddr);
      }
    }); 
  }

  cellImages_image_widget_changeOrder(
    imageAddr: Types.ImageAddr,
    srcWidgetIdx: number,
    dstWidgetIdx: number
  ) {
    this._state = produce(this._state, draft => {
      const logger = Tools.getLogger();
      logger.debug(`Moving widget from idx: ${srcWidgetIdx} to ${dstWidgetIdx}`);

      if (srcWidgetIdx === dstWidgetIdx) {
        logger.debug(`The same idx, skip move`);
        return;
      }
      const widgetsAddrs = State.cellImages_image_getWidgetsAddrs(draft, imageAddr);
      const srcIdxLowerThanDstIdx = (srcWidgetIdx < dstWidgetIdx);

      const srcWidgetAddr = widgetsAddrs[srcWidgetIdx];
      const dstWidgetAddr = widgetsAddrs[dstWidgetIdx];

      widgetsAddrs.splice(srcWidgetIdx, 1);
      dstWidgetIdx = State.cellImages_image_getWidgetIdx(draft, dstWidgetAddr);

      if (srcIdxLowerThanDstIdx) {
        widgetsAddrs.splice(dstWidgetIdx + 1, 0, srcWidgetAddr);
      }
      else {
        widgetsAddrs.splice(dstWidgetIdx, 0, srcWidgetAddr);
      }
    }); 
  }

  cellImages_image_widgetBoxed_setSize(
    widgetAddr: Types.WidgetAddr,
    size: Size
  ) {
    this._state = produce(this._state, draft => {
      const widgetProps = State.cellImages_image_getWidgetProps(draft, widgetAddr) as Types.WidgetBoxedProps;
      widgetProps.size = size;
    });
  }
  
  cellImages_image_widgetImage_update(
    widgetAddr: Types.WidgetAddr,
    update: Types.WidgetImagePropsUpdate
  ) {
    this._state = produce(this._state, draft => {
      const widgetProps = State.cellImages_image_getWidgetProps(draft, widgetAddr) as Types.WidgetImageProps;
      Object.assign(widgetProps, update);
    });
  }

  cellImages_image_widgetArrowPlain_update(
    widgetAddr: Types.WidgetAddr,
    update: Types.WidgetArrowPropsUpdate
  ) {
    this._state = produce(this._state, draft => {
      const widgetProps = State.cellImages_image_getWidgetProps(draft, widgetAddr) as Types.WidgetArrowProps;
      Object.assign(widgetProps, update);
    });
  }

  cellImages_image_widgetArrowText_update(
    widgetAddr: Types.WidgetAddr,
    update: Types.WidgetArrowTextPropsUpdate
  ) {
    this._state = produce(this._state, draft => {
      const widgetProps = State.cellImages_image_getWidgetProps(draft, widgetAddr) as Types.WidgetArrowTextProps;
      Object.assign(widgetProps, update);
    });
  }

  cellImages_image_widgetText_update(
    widgetAddr: Types.WidgetAddr,
    update: Types.WidgetTextPropsUpdate
  ) {
    this._state = produce(this._state, draft => {
      const widgetProps = State.cellImages_image_getWidgetProps(draft, widgetAddr) as Types.WidgetTextProps;
      Object.assign(widgetProps, update);
    });
  }

  cellImages_image_widgetEditorText_setEditorState(
    widgetAddr: Types.WidgetAddr,
    editorTextState: string
  ) {
    this._state = produce(this._state, draft => {
      const widgetProps = State.cellImages_image_getWidgetProps(draft, widgetAddr);
      const widgetEditorTextProps = widgetProps as (
        Types.WidgetTextProps |
        Types.WidgetArrowTextProps
      );
      widgetEditorTextProps.editorTextState = editorTextState;
    });
  }


  /**
   * Cells Markers
   */
  cellsMarkers_removeMarker(
    repoMarkerAddr: RepoMarkersTypes.MarkerAddr
  ) {
    this._state = produce(this._state, draft => {
      const markersAddrs = this.cellsMarkers_getMarkersAddrs();

      const markersAddrsToDelete = markersAddrs.filter((markerAddr) => {
        const markerProps = State.cellMarkers_getMarkerProps_(draft, markerAddr);
        return RepoMarkersTools.compareMarkerAddr(
          markerProps.repoMarkerAddr, 
          repoMarkerAddr);
      });

      markersAddrsToDelete.forEach(markerAddr => {
        this.__cellMarkers_removeMarker(draft, markerAddr);
      });
    });
  }


  /**
   * 
   * Cell Markers
   * 
   */

  cellMarkers_addMarker(
    repoMarkerAddr: RepoMarkersTypes.MarkerAddr,
    cellAddr:  Types.CellAddr,
  ): Types.MarkerAddr {
    const markersAddrs = this.cellMarkers_getMarkersAddrs_(cellAddr);
    const idx = markersAddrs.length;

    const markerAddr = this.__cellMarkers_addMarker(
      repoMarkerAddr,
      cellAddr,
      idx,
    );

    return markerAddr;
  }

  cellMarkers_addMarkerLeft(
    repoMarkerAddr: RepoMarkersTypes.MarkerAddr,
    srcMarkerAddr: Types.MarkerAddr
  ): Types.MarkerAddr {
    const idx = this.cellMarkers_getMarkerIdx_(srcMarkerAddr);

    const markerAddr = this.__cellMarkers_addMarker(
      repoMarkerAddr,
      srcMarkerAddr,
      idx,
    );
    
    return markerAddr;
  }

  cellMarkers_addMarkerRight(
    repoMarkerAddr: RepoMarkersTypes.MarkerAddr,
    srcMarkerAddr: Types.MarkerAddr
  ): Types.MarkerAddr {
    const idx = this.cellMarkers_getMarkerIdx_(srcMarkerAddr);
    
    const markerAddr = this.__cellMarkers_addMarker(
      repoMarkerAddr,
      srcMarkerAddr,
      idx + 1,
    );
    
    return markerAddr;
  }

  private __cellMarkers_addMarker(
    repoMarkerAddr: RepoMarkersTypes.MarkerAddr,
    cellAddr:  Types.CellAddr,
    idx: number,
  ): Types.MarkerAddr {
    const markerId = uuid();
    const markerAddr = {
      ...cellAddr,
      markerId
    }

    this._state = produce(this._state, draft => {
      const markersAddrs = State.cellMarkers_getMarkersAddrs_(draft, cellAddr);
      const markersProps = State.cellMarkers_getMarkersProps_(draft, cellAddr);

      const markerKey = Tools.getMarkerKey(markerAddr);

      markersAddrs.splice(idx, 0, markerAddr);
      markersProps[markerKey] = Defaults.getMarkerProps(repoMarkerAddr);
    });

    return markerAddr;
  }

  cellMarkers_removeMarker(
    markerAddr: Types.MarkerAddr
  ) {
    this._state = produce(this._state, draft => {
      this.__cellMarkers_removeMarker(draft, markerAddr);
    });
  }

  private __cellMarkers_removeMarker(
    draft: State.State,
    markerAddr: Types.MarkerAddr
  ) {
    const markersAddrs = State.cellMarkers_getMarkersAddrs_(draft, markerAddr);
    const markersProps = State.cellMarkers_getMarkersProps_(draft, markerAddr);

    const markerIdx = State.cellMarkers_getMarkerIdx_(draft, markerAddr);
    const markerKey = Tools.getMarkerKey(markerAddr);

    markersAddrs.splice(markerIdx, 1);
    delete markersProps[markerKey];
  }

  cellMarkers_moveMarkerOnMarker(
    srcMarkerAddr: Types.MarkerAddr,
    dstMarkerAddr: Types.MarkerAddr
  ) {
    this._state = produce(this._state, draft => {
      const sameMarker = Tools.compareMarkerAddr(srcMarkerAddr, dstMarkerAddr);
      if ( sameMarker ) {
        console.log("The same marker, not moving");
        return;
      }
    
      const sameCell = Tools.compareCellAddr(srcMarkerAddr, dstMarkerAddr);
      
      //
      // Move within the same cell
      //
      if ( sameCell ) {
        const markersAddrs = State.cellMarkers_getMarkersAddrs_(draft, srcMarkerAddr);

        const srcMarkerIdx    = State.cellMarkers_getMarkerIdx_(draft, srcMarkerAddr);
        const dstMarkerIdxTmp = State.cellMarkers_getMarkerIdx_(draft, dstMarkerAddr);
      
        const srcIdxLowerThanDstIdx = (srcMarkerIdx < dstMarkerIdxTmp);
        markersAddrs.splice(srcMarkerIdx, 1);

        const dstMarkerIdx = State.cellMarkers_getMarkerIdx_(draft, dstMarkerAddr);
    
        if (srcIdxLowerThanDstIdx) {
          markersAddrs.splice(dstMarkerIdx + 1, 0, srcMarkerAddr);
        }
        else {
          markersAddrs.splice(dstMarkerIdx, 0, srcMarkerAddr);
        }
      }
    
      //
      // Move across two different cells
      //
      else {
        const dstMarkerIdx = State.cellMarkers_getMarkerIdx_(draft, dstMarkerAddr);
        const idx = dstMarkerIdx + 1;

        this.__cellMarkers_moveMarkerIntoCell(
          draft, 
          srcMarkerAddr,
          dstMarkerAddr,
          idx
        );
      }
    });
  }

  cellMarkers_moveMarkerIntoCell(
    srcMarkerAddr: Types.MarkerAddr,
    dstCellAddr: Types.CellAddr
  ) {
    this._state = produce(this._state, draft => {
      const dstMarkersAddrs = State.cellMarkers_getMarkersAddrs_(draft, dstCellAddr);
      const idx = dstMarkersAddrs.length;

      this.__cellMarkers_moveMarkerIntoCell(
        draft, 
        srcMarkerAddr,
        dstCellAddr,
        idx
      );
    });
  }

  private __cellMarkers_moveMarkerIntoCell(
    draft: State.State,
    srcMarkerAddr: Types.MarkerAddr,
    dstCellAddr: Types.CellAddr,
    idx: number,
  ) {
    const srcMarkerProps = State.cellMarkers_getMarkerProps_(
      draft, 
      srcMarkerAddr
    );
    this.__cellMarkers_removeMarker(draft, srcMarkerAddr);

    const dstMarkersAddrs = State.cellMarkers_getMarkersAddrs_(draft, dstCellAddr);
    const dstMarkersProps = State.cellMarkers_getMarkersProps_(draft, dstCellAddr);
    
    const newMarkerAddr: Types.MarkerAddr = {
      ...dstCellAddr,
      markerId: srcMarkerAddr.markerId
    }
    const newMarkerKey = Tools.getMarkerKey(newMarkerAddr);
    dstMarkersAddrs.splice(idx, 0, newMarkerAddr);
    dstMarkersProps[newMarkerKey] = srcMarkerProps;
  }


  //----------------------
  // Getters
  //

  getContent() {
    return State.getContent(this._state);
  }

  /**
   * 
   * Columns
   * 
   */

  getColumnsAddrs() {
    return State.getColumnsAddrs(this._state);
  }

  getColumnsProps() {
    return State.getColumnsProps(this._state);
  }


  /**
   * 
   * Column
   * 
   */

  getColumnProps(columnAddr: Types.ColumnAddr): Types.ColumnProps {
    return State.getColumnProps(this._state, columnAddr);
  }

  getColumnWidth(columnAddr: Types.ColumnAddr): number {
    return State.getColumnWidth(this._state, columnAddr);
  }

  getColumnName(columnAddr: Types.ColumnAddr): string {
    return State.getColumnName(this._state, columnAddr);
  }

  isColumnVisible(columnAddr: Types.ColumnAddr): boolean {
    return State.isColumnVisible(this._state, columnAddr);
  }


  getColumnType(columnAddr: Types.ColumnAddr): Types.ColumnType {
    return State.getColumnType(this._state, columnAddr);
  }

  getColumnIdx(columnAddr: Types.ColumnAddr): number {
    return State.getColumnIdx(this._state, columnAddr);
  }


  /**
   * 
   * Sections
   * 
   */
  
  getSectionsAddrs() {
    return State.getSectionsAddrs(this._state);
  }
 
  getSectionsProps() {
    return State.getSectionsProps(this._state);
  }
 
  getSectionsRows() {
    return State.getSectionsRows(this._state);
  }

  getSectionsCells() {
    return State.getSectionsCells(this._state);
  }

  getSectionsConfig() {
    return State.getSectionsConfig(this._state);
  }


  /**
   * 
   * Section
   * 
   */

  getSectionProps(sectionAddr: Types.SectionAddr) {
    return State.getSectionProps(this._state, sectionAddr);
  }

  getSectionRows(sectionAddr: Types.SectionAddr) {
    return State.getSectionRows(this._state, sectionAddr);
  }

  getSectionCells(sectionAddr: Types.SectionAddr) {
    return State.getSectionCells(this._state, sectionAddr);
  }

  getSectionIdx(sectionAddr: Types.SectionAddr) {
    return State.getSectionIdx(this._state, sectionAddr);
  }

  isSectionLast(sectionAddr: Types.SectionAddr): boolean {
    return State.isSectionLast(this._state, sectionAddr);
  }

  /**
   * 
   * Rows
   * 
   */
  
  getRowsAddrs(sectionAddr: Types.SectionAddr) {
    return State.getRowsAddrs(this._state, sectionAddr);
  }

  getRowsProps(sectionAddr: Types.SectionAddr) {
    return State.getRowsProps(this._state, sectionAddr);
  }


  /**
   * 
   * Row
   * 
   */

  getRowIdxSection(rowAddr: Types.RowAddr) {
    return State.getRowIdxSection(this._state, rowAddr);
  }

  getRowIdxGlobal(rowAddr: Types.RowAddr) {
    return State.getRowIdxGlobal(this._state, rowAddr);
  }

  isRowLast(rowAddr: Types.RowAddr): boolean {
    return State.isRowLast(this._state, rowAddr);
  }

  isRowPresent(rowAddr: Types.RowAddr): boolean {
    return State.isRowPresent(this._state, rowAddr);
  }

  isRowAdder(rowAddr: Types.RowAddr): boolean {
    return State.isRowAdder(this._state, rowAddr);
  }

  

  /**
   * 
   * Cells
   * 
   */

  getCells(sectionAddr: Types.SectionAddr) {
    return State.getCells(this._state, sectionAddr);
  }

  getCellsOfType(columnType: Types.ColumnType) {
    return State.getCellsOfType(this._state, columnType);
  }
  

  /**
   * Cell
   */
  getCell(cellAddr: Types.CellAddr) {
    return State.getCell(this._state, cellAddr);
  }


  /**
   * Cells Images
   */
  cellsImages_getImagesAddrs(): Types.ImageAddr[] {
    return State.cellsImages_getImagesAddrs(this._state);
  }

  /**
   * Cell Images
   */

  cellImages_getImageProps = (
    imageAddr: Types.ImageAddr
  ): Types.ImageProps => {
    return State.cellImages_getImageProps(this._state, imageAddr);
  }

  cellImages_image_getWidgetsAddrs(
    imageAddr: Types.ImageAddr
  ): Types.WidgetsAddrs {
    return State.cellImages_image_getWidgetsAddrs(this._state, imageAddr);
  }

  cellImages_image_getWidgetsProps(
    imageAddr: Types.ImageAddr
  ): Types.WidgetsProps {
    return State.cellImages_image_getWidgetsProps(this._state, imageAddr);
  }


  /**
   * Cell Images
   */

  cellImages_getImageIdx(imageAddr: Types.ImageAddr) {
    return State.cellImages_getImageIdx(this._state, imageAddr);
  }

  cellImages_image_getWidgets(
    imageAddr: Types.ImageAddr
  ) {
    return State.cellImages_image_getWidgets(this._state, imageAddr);
  }

  cellImages_image_getWidgetProps(
    widgetAddr: Types.WidgetAddr
  ): Types.WidgetProps {
    return State.cellImages_image_getWidgetProps(this._state, widgetAddr);
  }

  cellImages_image_getWidgetIdx(widgetAddr: Types.WidgetAddr) {
    return State.cellImages_image_getWidgetIdx(this._state, widgetAddr);
  }

  cellImages_image_widget_getStyle(widgetAddr: Types.WidgetAddr) {
    return State.cellImages_image_widget_getStyle(this._state, widgetAddr);
  }


  /**
   * Cells Markers
   */
  cellsMarkers_getMarkersAddrs(): Types.MarkerAddr[] {
    return State.cellsMarkers_getMarkersAddrs(this._state);
  }

    
  /**
   * Cell Markers
   */
  cellMarkers_getMarkers_(cellAddr: Types.CellAddr) {
    return State.cellMarkers_getMarkers_(this._state, cellAddr);
  }

  cellMarkers_getMarkersAddrs_(cellAddr: Types.CellAddr) {
    return State.cellMarkers_getMarkersAddrs_(this._state, cellAddr);
  }

  cellMarkers_getMarkersProps_(cellAddr: Types.CellAddr) {
    return State.cellMarkers_getMarkersProps_(this._state, cellAddr);
  }

  cellMarkers_getMarkerProps(markerAddr: Types.MarkerAddr) {
    return State.cellMarkers_getMarkerProps_(this._state, markerAddr);
  }

  cellMarkers_getMarkerIdx_(markerAddr: Types.MarkerAddr) {
    return State.cellMarkers_getMarkerIdx_(this._state, markerAddr);
  }
}
