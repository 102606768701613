import React from 'react';

import { UUID } from 'app/arch/types';
import ResizerComponent from 'app/ui-v2/editor-instruction/views/shared/components/columns-resizers/resizer';
import usePageContentSize from '../../hooks/page/use-page-content-size';
import { Column } from './types';


interface Props {
  dataTest?: string;
  columns: Column[];

  onResizeStart?: () => void;
  onSetColumnsWidth: (columns: Column[]) => void;
  onResizeEnd?: () => void;

  debug?: boolean;
}


export const ColumnsResizersComponent: React.FC<Props> = (props: Props) => {
  const { 
    columns,

    onResizeStart,
    onSetColumnsWidth,
    onResizeEnd,

    debug,
  } = props;

  const contentSize = usePageContentSize();

  const renderResizer = (
    idx: number,
    columnId: UUID, 
    columns: Column[], 
    left: number
  ) => {
    const dataTest = (
      props.dataTest === undefined ?
      null : 
      `${props.dataTest}__resizer-${idx}`
    );

    return (
      <ResizerComponent 
        key={columnId}
        dataTest={dataTest}

        columns={columns}
        columnId={columnId}
        postitionLeft={left}
        contentSize={contentSize}

        onResizeStart={onResizeStart}
        onSetColumnsWidth={onSetColumnsWidth}
        onResizeEnd={onResizeEnd}

        debug={debug}
      />
    );
  }

  const renderResizers = () => {
    let currentTotalWidth = 0;
    const columnsButLast = columns.slice(0, -1);

    const resizersComps = columnsButLast.map((column, idx) => {
      const left = currentTotalWidth + column.width;
      currentTotalWidth += column.width;
      return renderResizer(idx, column.id, columns, left);
    });

    return resizersComps;
  }

  return (
    <>
      { renderResizers() }
    </>
  );
}
