import { Position  } from "app/arch/types";
import { CssStyle  } from "app/arch/editor-instruction/css-styles";


export enum ShapeLayerType  {
  BOTTOM = 'bottom',
  MIDDLE = 'middle',
  TOP    = 'top'
}


export interface ShapeProps {
  layer: ShapeLayerType;
  style: CssStyle;
  startPoint: Position;
  endPoint: Position;
}