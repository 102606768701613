import { useDocState } from "app/ui/contexts/document";
import useDocContentColumnsWidthSignal      from "../../views/view-content/hooks/doc-content/columns/use-columns-width-signal";
import useDocContentColumnsVisibleSignal    from "../../views/view-content/hooks/doc-content/columns/use-columns-visible-signal";
import useDocCustomFieldsColumnsWidthSignal from "../../views/view-content/hooks/doc-custom-fields/columns/use-columns-width-signal";
import useReleaselogsColumnsWidthsSignal    from "../../views/view-changelog/hooks/columns/use-releaselogs-columns-widths-signal";

import useDocContentSelectedReset    from "../../views/view-content/hooks/use-selected-reset";
import useDocReleaselogSelectedReset from "../../views/view-changelog/hooks/use-selected-reset";
import useEditorImageSelectedReset   from "app/ui-v2/editor-image/hooks/use-selected-reset";
import useEditorStatesSetters from "./../use-editor-states-setters";
import useUndoEditorMarkerSession from "./__use-editor-marker-session";


const useUndo = () => {
  const document = useDocState();

  const {
    setContent,
    setContentView,
    setEditorImageSettings,
    setHeaderFields,
    setHeaderMarkers,
    setHeaderMeta,
    setMetaData,
    setReleaselogs,
    setReleaselogsView,
    setRepoMarkers,
    setViewsCommon,
  } = useEditorStatesSetters();

  const signalDocContentColumnsWidth   = useDocContentColumnsWidthSignal();
  const signalDocContentColumnsVisible = useDocContentColumnsVisibleSignal();

  const signalDocCustomFieldsColumnsWidth = useDocCustomFieldsColumnsWidthSignal();

  const signalDocReleaselogsColumnsWidths = useReleaselogsColumnsWidthsSignal();

  const docContentResetSelected    = useDocContentSelectedReset();
  const docReleaselogResetSelected = useDocReleaselogSelectedReset();
  const editorImageResetSelected   = useEditorImageSelectedReset();


  const undoEditorMarkerSession = useUndoEditorMarkerSession();

  const setRecoilStates = () => {
    setContent();
    setContentView();
    setEditorImageSettings();
    setHeaderFields();
    setHeaderMarkers();
    setHeaderMeta();
    setMetaData();
    setReleaselogs();
    setReleaselogsView();
    setRepoMarkers();
    setViewsCommon();
  }

  const sendSignals = () => {
    signalDocContentColumnsWidth();
    signalDocContentColumnsVisible();

    signalDocCustomFieldsColumnsWidth();
    signalDocReleaselogsColumnsWidths();
  }

  const resetSelected = () => {
    docContentResetSelected();
    docReleaselogResetSelected();
    editorImageResetSelected();
  }

  const undo = () => {
    document.undo();

    setRecoilStates();
    sendSignals();

    // TODO improve reset selected
    // Reset selected - will reset selected 
    // cells, images etc, regardless if it is needed or not
    // Make it smarter like it has been done with undoEditorMarkerSession
    resetSelected();
    undoEditorMarkerSession.onUndo();
  }

  const redo = () => {
    document.redo();
    setRecoilStates();
    sendSignals();

    resetSelected();
    undoEditorMarkerSession.onRedo();
  }

  return { undo, redo };
}


export default useUndo;
