import { useRef } from 'react';
import { useEffect } from 'react';
import environment from 'app/environment';
import useControlsDraggersEnabledWatch from 'app/ui-v2/app/__modules/controls/hooks/use-controls-draggers-enabled-watch';


export interface Props {
  onDragMove:(event: PointerEvent) => void;
  onDragEnd:(event: PointerEvent) => void;
  
  stopPropagation?: boolean;
};


export const useDragging = (props: Props) => {
  const {
    onDragMove,
    onDragEnd,
  } = props;

  const stopPropagation = (
    props.stopPropagation !== undefined ?
    props.stopPropagation :
    true
  );

  const draggersEnabled = useControlsDraggersEnabledWatch();
  const lastEventRef = useRef<PointerEvent | null>(null);
  const handlersRegistered = useRef<boolean>(false);


  useEffect(() => {
    if (handlersRegistered.current) {
      registerHandlersRaw();
    }

    return () => {
      if (handlersRegistered.current) {
        unregisterHandlersRaw();
      }
    };
  }, [onDragEnd, onDragMove]);


  /**
   * If dragging has been disabled, finish up 
   * dragging which was taking place.
   */
  useEffect(() => {
    if (handlersRegistered.current === false) {
      return;
    }

    if (draggersEnabled) {
      return;
    }

    const lastEvent = lastEventRef.current;
    if (lastEvent === null) {
      const msg = `Error while disabling draggers`;
      throw new Error(msg);
    }

    handlePointerUp(lastEvent);
  }, [draggersEnabled]);


  const validEvent = (event: PointerEvent | React.PointerEvent, ignoreMouseButton?: boolean) => {
    if (event.pointerType === 'mouse') {
      if (event.button === 0 || ignoreMouseButton) {
        return true;
      }
    }

    if (event.pointerType === 'touch' && event.isPrimary) {
      return true;
    }

    return false;
  }


  /**
   * Handlers
   */

  const handlePointerDown = (event: React.PointerEvent): boolean => {
    if ( ! validEvent(event)) {
      return false;
    }

    event.preventDefault();

    if ( stopPropagation ) {
      event.stopPropagation();
    }

    lastEventRef.current = event.nativeEvent;
    registerHandlers();
    return true;
  };

  const handlePointerMove = (event: PointerEvent) => {
    if ( ! validEvent(event, true) ) {
      return;
    }

    event.preventDefault();

    if ( stopPropagation ) {
      event.stopPropagation();
    }

    lastEventRef.current = event;
    onDragMove?.(event);
  }

  const handlePointerUp = (event: PointerEvent) => {
    if ( ! validEvent(event) ) {
      return;
    }

    event.preventDefault();

    if ( stopPropagation) {
      event.stopPropagation();
    }

    onDragEnd?.(event);
    unregisterHandlers();
  }

  const handlePointerCancel = (event: PointerEvent) => {
    const msg = (
      `Pointer cancel received for dragger. `
      + `This is the most likely implementation error.`
    );

    unregisterHandlers();

    if (environment.dev) {
      throw new Error(msg);
    }
    
    console.warn(msg);
  }

  /**
   * Handlers registration routines
   */

  const registerHandlers = () => {
    if (handlersRegistered.current) {
      console.warn("Handlers already registered");
      return;
    }

    registerHandlersRaw();
    handlersRegistered.current = true;
  }

  const registerHandlersRaw = () => {
    window.document.addEventListener("pointerup", handlePointerUp);
    window.document.addEventListener("pointermove", handlePointerMove);
    window.document.addEventListener("pointercancel", handlePointerCancel);
  }

  const unregisterHandlers = () => {
    if ( ! handlersRegistered.current) {
      console.warn("Handlers not registered");
      return;
    }

    unregisterHandlersRaw();
    handlersRegistered.current = false;
  }

  const unregisterHandlersRaw = () => {
    window.document.removeEventListener("pointerup", handlePointerUp);
    window.document.removeEventListener("pointermove", handlePointerMove);
    window.document.removeEventListener("pointercancel", handlePointerCancel);
  }


  return { startDragging: handlePointerDown };
};
