import jtl from "tools/jtl";
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config";

import { MarkerAddr } from "app/arch/editor-instruction/document/states/persistent/repo-markers/types";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import PanelItemGenerator from "app/ui-v2/editor-instruction/panel-items/generator";

import useFunctions from "./__use-functions";
import useStates from "./__use-states";
import Config from "../config";


const useItems = (markerAddr: MarkerAddr) => {
  const t = useTranslations();
  const fns = useFunctions(markerAddr);
  const states = useStates(markerAddr);

  // const getLabelEnabled = () => 
  //   PanelItemGenerator.getToggleOnOff({
  //     title: t('enabled'),
  //     selected: states.labelEnabled,
  //     onClick: (enabled: boolean) => {
  //       const update = { enabled };
  //       fns.updateLabel({update});
  //     },
  //   });

  /**
   * Font
   */
  const getLabelFontSize = () => 
    PanelItemGenerator.getFontSize({
      css: states.markerProps.label.css,
      minValue: 6, 
      maxValue: 32,
      dataTest: "marker-label-font-size",
      onChange: (cssUpdate: React.CSSProperties) => { 
        fns.updateLabelCSS({cssUpdate, skipUndoSave: true});
      },
      onChangeDone: (cssUpdate: React.CSSProperties) => { 
        fns.updateLabelCSS({cssUpdate, skipUndoSave: false });
      },
    });

  const getLabelFontWeight = () =>
    PanelItemGenerator.getFontWeight({
      css: states.markerProps.label.css,
      dataTest: "marker-label-font-weight",
      onChange: (cssUpdate: React.CSSProperties) => { 
        fns.updateLabelCSS({cssUpdate, skipUndoSave: true });
      },
      onChangeDone: (cssUpdate: React.CSSProperties) => { 
        fns.updateLabelCSS({cssUpdate});
      },
    });
    
  const getLabelFontCapslock = () => 
    PanelItemGenerator.getFontCapslock({
      css: states.markerProps.label.css,
      dataTest: "marker-label-font-capsLock",
      onClick: (cssUpdate: React.CSSProperties) => { 
        fns.updateLabelCSS({cssUpdate});
      },
    });

  const getLabelColor = () => ({
    title: t('text color'),
    dataTest: "marker-label-color",
    type: PanelControlsItemSectionItemType.COLOR_PICKER,
    onColorSelected: (color: string) => {
      const cssUpdate = { color };
      fns.updateLabelCSS({cssUpdate});
    },
    color: jtl.css.getValue(states.markerProps.label.css, 'color'),
  });

  const getLabelBackgroundColor = () => ({
    title: t('background'),
    dataTest: "marker-label-background-color",
    type: PanelControlsItemSectionItemType.COLOR_PICKER,
    onColorSelected: (backgroundColor: string) => {
      console.log(backgroundColor);
      const cssUpdate = { backgroundColor };
      fns.updateLabelCSS({cssUpdate});
    },
    color: jtl.css.getValue(states.markerProps.label.css, 'backgroundColor'),
  });


  /**
   * Border
   */
  const getBorderEnabled = () => PanelItemGenerator.getToggleOnOff({
    title: '',
    selected: states.borderEnabled,
    onClick: (enabled: boolean) => {
      const borderStyle = (
        enabled ?
        'solid' : 
        'none'
      );

      const cssUpdate =  { borderStyle };
      fns.updateLabelCSS({cssUpdate});
    },
  });

  const getBorderColor = () => ({
    title: t('color'),
    skip: ! states.borderEnabled,
    dataTest: "marker-label-border-color",
    color: jtl.css.getValue(states.markerProps.label.css, 'borderColor'),
    type: PanelControlsItemSectionItemType.COLOR_PICKER,
    onColorSelected: (borderColor: string) => { 
      const cssUpdate = { borderColor };
      fns.updateLabelCSS({cssUpdate});
    },
  });

  const getBorderWidth = () => PanelItemGenerator.getBorderWidth({
    css: states.markerProps.label.css,
    skip: ! states.borderEnabled,
    minValue: Config.border.width.min,
    maxValue: Config.border.width.max,
    onChange: (cssUpdate: React.CSSProperties) => {
      fns.updateLabelCSS({
        cssUpdate, 
        skipUndoSave: true
      });
    },
    onChangeDone: (cssUpdate: React.CSSProperties) => {
      fns.updateLabelCSS({ cssUpdate });
    },
  });

  const getBorderRadius = () => PanelItemGenerator.getBorderRadius({
    css: states.markerProps.label.css,
    minValue: 0, 
    maxValue: 50,
    onChange: (cssUpdate: React.CSSProperties) => {
      fns.updateLabelCSS({
        cssUpdate, 
        skipUndoSave: true
      });
    },
    onChangeDone: (cssUpdate: React.CSSProperties) => {
      fns.updateLabelCSS({ cssUpdate });
    },
  });

  return {
    getLabelFontSize,
    getLabelFontWeight,
    getLabelFontCapslock,
    getLabelColor,
    getLabelBackgroundColor,
  
    getBorderEnabled,
    getBorderColor,
    getBorderWidth,
    getBorderRadius,
  }

}

export default useItems;
