import { CssStyle } from "app/arch/editor-instruction/css-styles";
import { Position } from "app/arch/types";
import { ShapeCalcArrow } from "./shape-calc-arrow";


export interface ShapeCalculatorProps {
  style: CssStyle;
  startPoint: Position;
  endPoint: Position;
}

export class ShapeCalcPointer extends ShapeCalcArrow {
  override get arrowShaftWidth() { 
    return 0; 
  }

  override get arrowHeadAngle() {
    return Math.atan2(
      this.arrowHeadWidth / 2, 
      this.arrowHeadHeight);
  }

  override get widthTotal() {
    const width =
      2 * this.outlineWidthAngledHorizontal + 
      2 * this.borderWidthAngledHorizontal + 
      this.arrowHeadWidth
    ;

    return width;
  }

}