import useUserSystem, { OS } from 'app/ui/hooks/user-system';

const ActionKeyMap: any = {
  [OS.Mac]:     '⌘',
  [OS.Windows]: '⌃',
  [OS.Linux]:   '⌃',
  [OS.Unknown]: '⌃',
}

const ShiftKeyMap: any = {
  [OS.Mac]:     '⇧',
  [OS.Windows]: '⇧',
  [OS.Linux]:   '⇧',
  [OS.Unknown]: '⇧',
}



const useSystemKeys = () => {
  const userSystem = useUserSystem();
  // const userSystem = OS.Windows;
  
  return {
    action: ActionKeyMap[userSystem],
    shift: ShiftKeyMap[userSystem]
  };
}

export default useSystemKeys;
