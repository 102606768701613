import environment   from "app/environment";
import AuthService   from "services/auth";
import { States }    from "./states/states";
import DocTemplates  from "../doc-templates";
import HomePageUser  from "../home-page-user";
import HomePageGuest from "../home-page-guest";

import Demo     from "./demo";
import Google   from "./google";
import Products from "./products";
import Screen   from "./screen";
import User     from "./user";
import Debug    from "./debug";
import Controls from "./controls";
import Info     from "./info";


export class App {
  private static instance: App;
  
  private _auth: AuthService;

  private _states: States;
  private _docTemplates: DocTemplates;
  private _homePageUser: HomePageUser;
  private _homePageGuest: HomePageGuest;

  private _debug: Debug;
  private _demo: Demo;
  private _google: Google;
  private _products: Products;
  private _screen: Screen;
  private _info: Info;
  private _user: User;
  private _controls: Controls;

  private constructor() {
    this._auth = new AuthService();

    this._docTemplates  = new DocTemplates();
    this._homePageUser  = new HomePageUser();
    this._homePageGuest = new HomePageGuest();

    this._debug    = new Debug();
    this._demo     = new Demo();
    this._google   = new Google();
    this._products = new Products();
    this._screen   = new Screen();
    this._info     = new Info();
    this._controls = new Controls();

    this._states = new States();

    this._user = new User
  }

  get auth() { return this._auth; }

  get user() { return this._user; }
  
  get appState() { return this._states.appState; }
  get repos()    { return this._states.repos; }
  get products() { return this._products; }

  get docTemplates()  { return this._docTemplates; }
  get homePageUser()  { return this._homePageUser; }
  get homePageGuest() { return this._homePageGuest; }

  get google()   { return this._google; }
  get screen()   { return this._screen; }
  get info()     { return this._info; }
  get controls() { return this._controls; }

  get debug() { return this._debug; }
  
  get demo() { return this._demo; }
  
  reset() {
    this._auth.reset();

    this._states.appState.updateState({isLogged: false});
    this._states.repos.reset();
    
    this._user.reset();
    this._products.reset();
    
    this._docTemplates.reset();
    this._homePageUser.reset();
    this._homePageGuest.reset();
  }

  public static getInstance(): App {
    if ( ! App.instance) {
      App.instance = new App();
      if ( environment.dev ) {
        window.app =  App.instance;
      }
    }
    return App.instance;
  }

}

const AppInstance = App.getInstance();
export default AppInstance;