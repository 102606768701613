import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { useMutation } from 'app/arch/backend/use-mutation';
import { mutation } from 'app/arch/backend';
import { MsgDuration } from '../../../ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { useDocState } from 'app/ui/contexts/document';
import { UIState_EditorImageWidgetsStyles } from 'app/ui/states/editor-instruction';
import { MutationProps, OnDoneFn } from './types';


export const useUpdateWidgetStyle = () => {
  // TODO FIXME - can be overwritten
  // if multiply calls at once
  // should be some kind of list/container
  const onDoneRef = useRef<null | undefined | OnDoneFn>(null);
  
  const document = useDocState();
  const setWidgetsStylesState = useSetRecoilState(UIState_EditorImageWidgetsStyles.state);

//-----------------------
  // Update widget style
  //

  const { 
    mutation: updateWidgetStyleMutationRaw,
    data:     updateWidgetStyleData,
  } = useMutation(
    mutation.updateWidgetStyle,
    {
      onStart: {
        msg:  "Updating...",
      },
      onEnd: 
      {
        msg: "Style update",
        duration: MsgDuration.MIDDLE
      }
    }
  );

  useEffect(() => {
    if (updateWidgetStyleData === null || updateWidgetStyleData === undefined) {
      return;
    }

    const response = updateWidgetStyleData.widgetStyleUpdate;
    if (response.ok) {
      document.editorImageWidgetsStyles.updateStyleName(
        response.widgetStyle.id, 
        response.widgetStyle.name
      );
      setWidgetsStylesState(document.editorImageWidgetsStyles.state);
      onDoneRef.current && onDoneRef.current();
      onDoneRef.current = null;
    }
    else {
      console.warn(`Could not update style name`);
    }
  }, [updateWidgetStyleData]);

  const updateWidgetStyleMutation = (props: MutationProps) => {
    const { variables, onDone } = props;
    onDoneRef.current = onDone;
    updateWidgetStyleMutationRaw({variables});
  }

  return {
    updateWidgetStyleMutation
  }
}