import { ThemeConstsProps } from "../types";
import { getColorSet      } from "./__common";


export const themeConsts_colors = (props: ThemeConstsProps) => {
  const { darkMode } = props;

  const colors = {
    color: {
      // primary:   darkMode ? "#FFFFEB" : "#000000", // Theme header
      primary:   darkMode ? "#FFFFEB" : "#333", // Theme header
      secondary: darkMode ? "#FFF"    : "#FFF",
      third:     darkMode ? "#E0E0E0" : "rgba(0, 0, 0, 0.85)",
      forth:     darkMode ? "#383838" : "rgba(0, 0, 0, 0.85)",
      // fifth:     darkMode ? "#bbb"    : "rgba(0, 0, 0, 0.85)",
    },

    background: {
      primary:   darkMode ? "#383838" : "#f6f6f6", // Panel
      secondary: darkMode ? "#636363" : "#ffffff", // Button 
      third:     darkMode ? "#383838" : "#ececec", // Menu Bar Item Panel
      forth:     darkMode ? "#b9b9b9" : "#fff",
      fifth:     darkMode ? "#8e8e8e" : "#e2e2e2",
      sixth:     darkMode ? "#333"    : "#777",    // tooltip
      seventh:   darkMode ? "#111"    : "#ececec", // landing page and subviews backgroiund
      eight:     darkMode ? "#4a4a4a" : "#dfdfdf",    // ControlText
      nine :     darkMode ? "#4f4f4f" : "#ececec",    // separator in toptoolbar
    },

    border: {
      primary:   darkMode ? "#747474" : "#AAA", // Panel
      secondary: darkMode ? "#747474" : "#CCC", // Button 
      third:     darkMode ? "#999"    : "#ddd", // cell border 
      forth:     darkMode ? "#383838" : "#f6f6f6", // image editor image/page 
    },

    separator: {
      primary:   darkMode ? "#FFFFEB" : "#333", // Theme header
      secondary: darkMode ? "#383838" : "#333", // Theme header
    },

    scrollbar: {
      thumb: {
        primary:   darkMode ? "#383838" : "#777",
        secondary: darkMode ? "#b9b9b9" : "#777",
      },

      track: {
        // primary:   darkMode ? "#8e8e8e" : "#e2e2e2",
        // secondary: darkMode ? "#383838" : "#333",
      },

      border: {
        primary:   darkMode ? "#333" : "#e2e2e2",
        // secondary: darkMode ? "#383838" : "#333", 
      },
    },

    special: {
      delete: getColorSet(358, 73,  55),
      overlayer: darkMode ? "#383838" : "#e2e2e2",
      disabled:  darkMode ? "#585858" : "#585858",
      faded: 'rgba(59, 59, 58, 0.1)',
      white: '#FEFEFE',
      red:   '#D8000C',
      green: '#00D80C',

      // grey: {
      //   primary: '#888',
      // }
    },

  };

  return colors;
}
