import React from 'react';

import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";

import MarkerSelectComponent      from './marker-select';
import MarkerDropComponent        from './marker-drop';
import MarkerDraggerComponent     from './marker-dragger';
import MarkerContextMenuComponent from './marker-context-menu';
import MarkerEditComponent        from './marker-edit';
import MarkerViewComponent        from './marker-view';


interface Props  {
  markerAddr: Types.MarkerAddr;
}


export const MarkerComponent: React.FC<Props> = (props: Props) => {
  const { 
    markerAddr,
  } = props;

  return (
    <MarkerSelectComponent markerAddr={markerAddr} >
      <MarkerContextMenuComponent markerAddr={markerAddr} >
        <MarkerEditComponent markerAddr={markerAddr} >
          <MarkerDropComponent markerAddr={markerAddr} >
            <MarkerDraggerComponent markerAddr={markerAddr} > 
              <MarkerViewComponent markerAddr={markerAddr} />
            </MarkerDraggerComponent>
          </MarkerDropComponent>
        </MarkerEditComponent>
      </MarkerContextMenuComponent>
    </MarkerSelectComponent>
  );
}
