import DraggerComponent  from "lego-v2/dragger/ui";
import { DraggerUpdate } from "lego-v2/dragger/arch";

import { Position } from "app/arch/types";
import { Size     } from "app/arch/types";
import { CssStyle } from "app/arch/editor-instruction/css-styles";

import { DraggerSizeMin         } from "../types";
import { DraggerTailUpdateStart } from "../types";
import { DraggerTailUpdate      } from "../types";

import { ShapeCalcArrow } from "../../shapes-calculators/shape-calc-arrow";

import { Control  } from "./styles";
import { Detacher } from "./styles";


const BTN_LEFT = 0;

interface Props {
  scale: number;
  disabled?: boolean;

  startPoint: Position;
  endPoint: Position;
  style: CssStyle;

  setWidgetSelected: (event: React.MouseEvent) => void;
  onContextMenu?: (event: any) => void;

  onUpdateStart?: (update: DraggerTailUpdateStart) => void;
  onUpdate:       (update: DraggerTailUpdate) => void;
  onUpdateDone:   (update: DraggerTailUpdate) => void;

  dataTest: string | null;
}


export const DraggerTailBlankComponent: React.FC<Props> = (props: Props) => {
  const {
    scale, 
    disabled,

    startPoint, 
    endPoint, 
    style, 

    setWidgetSelected,
    onContextMenu,

    onUpdateStart,
    onUpdate,
    onUpdateDone,

    dataTest,
  } = props;

  
  //----------------------------------------
  // Render props
  //

  const shapeCalc = new ShapeCalcArrow({
    style,
    startPoint,
    endPoint
  });

  const width  = Math.max(DraggerSizeMin, shapeCalc.bodyWidthTotal);
  const height = DraggerSizeMin;

  const left = endPoint[0] - width / 2;
  const top  = endPoint[1] - height;

  const tailDataTest = (
    dataTest !== null ?
    `${dataTest}__tail` : 
    null
  );

  const handlePointerDown = (event: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    
    if (event.button !== BTN_LEFT) {
      return;
    }

    setWidgetSelected(event);
    event.stopPropagation();
  }

  const createUpdate = (update: DraggerUpdate) => {
    const draggerTailUpdate: DraggerTailUpdate = {
      delta: update.delta,
    };

    return draggerTailUpdate;
  }

  const handleDraggerUpdateStart = (event: React.PointerEvent) => {
    const clickOffset = [
      event.nativeEvent.offsetX - width / 2,
      event.nativeEvent.offsetY - height
    ] as Size;

    const update = { clickOffset };
    onUpdateStart?.(update);
  }

  const handleDraggerUpdate = (update: DraggerUpdate) => {
    const tailUpdate = createUpdate(update);
    onUpdate(tailUpdate);
  }
  
  const handleDraggerUpdateDone = (update: DraggerUpdate) => {
    handleDraggerUpdate(update);

    const tailUpdate = createUpdate(update);
    onUpdateDone(tailUpdate);
  }
  
  
  
  return (
    <div 
      onPointerDown={handlePointerDown}
      onContextMenu={onContextMenu}
    >
      <DraggerComponent
        scale={scale}
        disabled={disabled}
        position={endPoint}
        onUpdateStart={handleDraggerUpdateStart}
        onUpdate={handleDraggerUpdate}
        onUpdateDone={handleDraggerUpdateDone}
      >
        <Detacher
          style={{
            left:  `${left}px`,
            top:   `${top}px`,

            width:  `${width}px`,
            height: `${height}px`,
            
            transform: `rotate(${shapeCalc.arrowAngle}rad)`,
            transformOrigin: `${width / 2}px ${height}px`
          }}
        >
          <Control
            style={{
              width:  `${width}px`,
              height: `${height}px`,
            }}
            data-test={tailDataTest}
          />

        </Detacher>
      </DraggerComponent>
    </div>
  );
}
