import React from 'react';
import * as Types from '../../types';

import { BlankIcon }   from './styles';
import { IconWrapper } from './styles';
import { Item }        from './styles';
import { IconScaler }  from './styles';
import { TextWrapper } from './styles';
import { KeyBindingWrapper } from './styles';


interface Props extends Types.MenubarItemPanelItemProps {
}


export const MenubarItemPanelItemComponent: React.FC<Props> = (props: Props) => {
  const {
    config,
  } = props;

  const Icon = config.icon || BlankIcon;

  const handleClick = (event: React.MouseEvent) => {
  event.stopPropagation();
    config.onClick?.();
  }

  return (
    <Item 
      onClick={handleClick}
      data-test={config.dataTest}
    >
      <IconWrapper>
        <IconScaler>
          <Icon />
        </IconScaler>
      </IconWrapper>

      <TextWrapper>
        { config.title }
      </TextWrapper>

      <KeyBindingWrapper>
        { config.keysLabel }
      </KeyBindingWrapper>

    </Item>
  );
}
