import { useRef, useState } from "react";

import { Position  } from "app/arch/types";
import { CssStyle  } from "app/arch/editor-instruction/css-styles";
import { DeltaMove } from "app/ui/hooks";
import { useDraggingDelta } from "app/ui/hooks";

import { DraggerBodyUpdate } from "../types";

import { Control  } from "./styles";
import { Detacher } from "./styles";
import { ShapeCalcArrow } from "../../shapes-calculators/shape-calc-arrow";


const MIN_WIDTH  = 36;
const MIN_HEIGHT = 36;

const LEFT_BTN = 0;


interface Props {
  startPoint: Position;
  endPoint: Position;
  style: CssStyle;

  scale: number;
  setWidgetSelected: (event: React.MouseEvent) => void;
  onContextMenu?: (event: any) => void;

  onUpdateStart?: () => void;
  onUpdate:     (update: DraggerBodyUpdate) => void;
  onUpdateDone: (update: DraggerBodyUpdate) => void;
  disabled?: boolean;
  
  dataTest: string | null;
}


export const DraggerBodyComponent: React.FC<Props> = (props: Props) => {
  const {
    startPoint, 
    endPoint, 
    style,

    setWidgetSelected,
    onContextMenu,
    scale,
    disabled,

    dataTest,
  } = props;

  const [isDragging, setIsDragging] = useState(false);
  const dragStartData = useRef({
    startPoint,
    endPoint,
  });

  const shapeCalc = new ShapeCalcArrow({
    style,
    startPoint,
    endPoint
  });
  const c = shapeCalc;


  const topCut = 0;
  const dx = endPoint[0] - startPoint[0];
  const dy = endPoint[1] - startPoint[1];

  let height = Math.sqrt(dx ** 2 + dy ** 2) - topCut;

  
  const width  = Math.max(c.bodyWidthTotal, MIN_WIDTH);
  height = Math.max(height, MIN_HEIGHT);

  const left = startPoint[0] - width / 2;
  const top  = startPoint[1] + topCut;

  const bodyDataTest = (
    dataTest !== null ?
    `${dataTest}__body` :
    null
  );

  const getDragUpdate = (delta: DeltaMove): DraggerBodyUpdate => {
    const newStartPoint: Position = [
      dragStartData.current.startPoint[0] + delta.x / scale,
      dragStartData.current.startPoint[1] + delta.y / scale,
    ];

    const newEndPoint: Position = [
      dragStartData.current.endPoint[0] + delta.x / scale,
      dragStartData.current.endPoint[1] + delta.y / scale,
    ];

    const update = {
      startPoint: newStartPoint,
      endPoint: newEndPoint,
    };

    return update;
  }

  const handleDragMove = (delta: DeltaMove) => {
    const update = getDragUpdate(delta);
    props.onUpdate(update);
  }

  const handleDragEnd = (delta: DeltaMove) => {
    handleDragMove(delta);
    setIsDragging(false);

    const update = getDragUpdate(delta);
    props.onUpdateDone(update);
  }

  const {
    startDragging
  } = useDraggingDelta({
    onDragMove: handleDragMove,
    onDragEnd: handleDragEnd,
  });

  const handleDragStart = (event: React.PointerEvent) => {
    if (disabled) { 
      return;
    }


    const started = startDragging(event);
    if ( ! started ) {
      return;
    }

    setWidgetSelected(event);
    setIsDragging(true);
    props.onUpdateStart?.();
    
    dragStartData.current = {
      startPoint,
      endPoint
    };

  }

  const cursorStyle = (
    isDragging ?
    { cursor: 'move' } :
    {}
  );

  return (
    <div 
      onPointerDown={handleDragStart}
      onContextMenu={onContextMenu}
      style={cursorStyle}
    >
      <Detacher
        style={{
          transform: `rotate(${c.arrowAngle}rad)`,
          transformOrigin: `${width / 2}px 0px`,
  
          left: `${left}px`,
          top:  `${top}px`,
        }}
      >
        <Control
          style={{
            width:  `${width}px`,
            height: `${height}px`,
          }}
          data-test={bodyDataTest}
        />
      </Detacher>
    </div>
  );
}
