import React from 'react';

import BtnGuestRunComponent from './btn-guest-run';
import FlexEditLogoComponent from 'app/ui-v2/hls/branding/flex-edit-logo';

import { Grid } from './styles';
import { Content } from './styles';
import { NoHassle } from './styles';
import { NoItem } from './styles';


interface Props {
}


export const ViewRunDemoComponent: React.FC<Props> = (props: Props) => {

  return (
    <Grid>
      <Content>
        <FlexEditLogoComponent />
        <BtnGuestRunComponent />

        {/* <NoHassle>
          <NoItem>No fees</NoItem>
          <NoItem>No registraion</NoItem>
          <NoItem>No hassle</NoItem>
          <NoItem>Click & play</NoItem>
        </NoHassle> */}

      </Content>
    </Grid>
  );
}
