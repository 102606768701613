import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig   } from 'lego-v2/menu/config';
import { useIsMobile }  from 'lego-hooks/use-is-mobile';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { UploadSource }        from 'app/arch/types/types-v2';
import { Icon }                from "app/ui/icons/icons";
import { useTranslations }     from 'app/ui/hooks/app/use-translation';
import { useDocState }         from "app/ui/contexts/document";
import useContextMenu          from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useImageEdit            from 'app/ui-v2/editor-image/hooks/image/use-image-edit';

import useEditorStatesSetters from '../../../../../hooks/use-editor-states-setters';
import useCellImageUpload     from './use-cell-image-upload';


const useCellImageMenu = () => {
  const t = useTranslations();
  const docState = useDocState();
  const isMobile = useIsMobile();

  const uploadCellImage = useCellImageUpload();

  const {
    setContent,
  } = useEditorStatesSetters();

  const editImageHook = useImageEdit();

  const { hideMenu }   = useContextMenu();

  const uploadImage = (cellAddr: Types.CellAddr) => {
    const source   = UploadSource.DISK;
    uploadCellImage({
      cellAddr, 
      source
    });
    hideMenu();
  }

  const editImage = (imageAddr: Types.ImageAddr) => {
    editImageHook(imageAddr);
    hideMenu();
  }

  const removeImage = (imageAddr: Types.ImageAddr) => {
    docState.content.cellImages_removeImage(imageAddr);
    docState.saveUndo();
    setContent();
    hideMenu();
  }

  const getConfig = (imageAddr: Types.ImageAddr) => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('images, upload from disk'),
                iconComponent: Icon.Upload.Disk,
                onClick: (event: React.MouseEvent) => uploadImage(imageAddr),
              }
            },
            {
              skip: ! isMobile,
              type: MenuItemType.BUTTON,
              data: {
                title: t('image, upload from camera'),
                iconComponent: Icon.Upload.Camera,
                onClick: (event: React.MouseEvent) => uploadImage(imageAddr),
              }
            },              
          ],
        },
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('image, edit'),
                iconComponent: Icon.Edit,
                onClick: (event: React.MouseEvent) => editImage(imageAddr),
              }
            },            
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('image, remove'),
                iconComponent: Icon.Delete,
                onClick: (event: React.MouseEvent) => removeImage(imageAddr),
                variant: 'delete'
              }
            },      
          ],
        },
      ]
    }

    return config;
  }


  const hook = (imageAddr: Types.ImageAddr) => {
    const config = getConfig(imageAddr);
    return config;
  }

  return hook;
}


export default useCellImageMenu;