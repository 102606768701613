import React, { useState } from 'react';

import { ColorPaletteType } from 'app/arch/types/types';
import MoreButtonComponent from 'app/ui-v2/editor-instruction/components/more-button';

// import "react-color-palette/css";
import { ColorPickerView } from './types';
import ColorPickerCustomComponent  from './color-picker-custom';
import ColorPickerPaletteComponent from './color-picker-palette';
import ColorPickerPreviewComponent from './color-picker-preview';

import { MainWrapper         } from './styles';



interface Props {
  color?: string;
  disabled?: boolean;
  colorPalette: ColorPaletteType,
  onColorSelected?: (color: string) => void;
  dataTest?: string;
}


export const ColorPickerComponent: React.FC<Props> = (props: Props) => {
  const { 
    color, 
    disabled,
    onColorSelected,
    colorPalette, 
    dataTest
  } = props;
  
  const [viewMode, setViewMode] = useState<ColorPickerView>(ColorPickerView.PREVIEW);

  const handleColorChange = (newColor: string) => {
    onColorSelected?.(newColor);
  };

  const hanldeShowPicker = () => {
    setViewMode(ColorPickerView.PALETTE);
  }  

  const handleExpandMore = () => {
    if ( viewMode === ColorPickerView.EXPANDED ) {
      setViewMode(ColorPickerView.PALETTE);
    }
    else {
      setViewMode(ColorPickerView.EXPANDED);
    }
  }


  const renderColors = () => {
    return (
      <ColorPickerPaletteComponent
        dataTestPrefix={dataTest}
        color={color} 
        onColorSelected={handleColorChange} 
        colorPalette={colorPalette}
      />
    );
  }  

  const renderCustom = () => {
    return (
      <ColorPickerCustomComponent
        color={color} 
        onColorSelected={handleColorChange} 
      />
    );
  }

  const renderPreview = () => {
    return (
      <ColorPickerPreviewComponent
        color={color || 'red'} 
        onClick={hanldeShowPicker} 
      />
    );
  }

  const renderMoreButton = () => (
    <MoreButtonComponent
      onClick={handleExpandMore}
      expanded={viewMode === ColorPickerView.EXPANDED}
    />
  );


  return (
    <MainWrapper 
      $disabled={disabled}
      data-test={dataTest}
    >
      {
      //   viewMode !== ColorPickerView.PREVIEW && 
      //   <ColorPickerModeToggleComponent 
      //     viewMode={viewMode}
      //     onViewModeSelected={(viewMode: ColorPickerView) => { setViewMode(viewMode); }}
      //   />
      }
      {  viewMode === ColorPickerView.PREVIEW && renderPreview() }

      {
        viewMode === ColorPickerView.PALETTE && 
        <>
          { renderColors() }
          { renderMoreButton() }
        </>
      }

      {
        viewMode === ColorPickerView.EXPANDED && 
        <>
          { renderColors() }
          { renderCustom() }
          { renderMoreButton() }
        </>
      }
    </MainWrapper>
  );
}
