import { Size } from 'app/arch/types';

import { MainWrapper } from './styles';
import { Scaler } from './styles';


interface Props {
  scale: number;
  contentSize: Size;
  children: React.ReactNode;
};


export const ScalerComponent: React.FC<Props> = (props: Props) => {
  const { 
    scale,
    contentSize,
    children,
  } = props;
  
  return (
    <MainWrapper
      style={{
        width: `${contentSize[0] * scale}px`,
      }}
    >
      <Scaler
        style={{
          width: `${contentSize[0]}px`,
          transform: `scale(${scale})`,
          transformOrigin: `0 0`,
        }}
      > 
        { children }
      </Scaler>
    </MainWrapper>
  );
};
