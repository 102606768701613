import { ApolloClient   } from '@apollo/client';
import { ApolloLink     } from '@apollo/client';
import { fromPromise    } from '@apollo/client';
import { InMemoryCache  } from '@apollo/client';
import { createHttpLink } from '@apollo/client';
import { setContext     } from '@apollo/client/link/context';
import { onError        } from '@apollo/client/link/error'

import app from 'app/arch/app';
import environment from 'app/environment';
import { mutation } from 'app/arch/backend';


//-----------------
// Links
//-----------------

// 
// Http
//
const httpLink =  createHttpLink({
  uri: environment.backend.urlGraphql,
  includeExtensions: true
});


// 
// Auth
//
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...app.auth.getAuthHeader()
    }
  }
});


const refreshToken = (oldToken: string) => {
  return graphqlClientNoAuth.mutate({
    mutation: mutation.refreshJwtToken,
    variables: { token: oldToken }
  }).then((response) => {
    console.log(response);
    console.warn('Save me in localstorage - not implemented');
  });
};


// 
// Error
//
const errorLink = onError(({ 
  graphQLErrors, 
  networkError, 
  response,
  operation, 
  forward 
}) => {
  for (let err of graphQLErrors || []) {
    if ( ! err.extensions) continue;

    switch (err.extensions.code) {
      // currently not used
      // at all - but seems to 
      // be working 
      case "UNAUTHENTICATED": {
        const oldToken = app.auth.getToken();
        if (oldToken === null) {
          return;
        }

        const obs = fromPromise(
          refreshToken(oldToken).catch((error) => {
            console.warn(`Can't refresh token`);
            return;
          })
        );
      
        const obsFiltered = obs
          .filter((value) => Boolean(value))
          .flatMap((accessToken) => {
            const oldHeaders = operation.getContext().headers;
            // modify the operation context with a new token
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `JWT ${accessToken}`,
              },
            });
      
            // retry the request, returning the new observable
            return forward(operation);
          });
      
        return obsFiltered;
      }

      case 'TOKEN_EXPIRED': {
        console.warn("JWT token has expired");

        // TODO
        // We should display here relogin screen
        var rootDiv = document.getElementById('root');
        if (rootDiv) {
          rootDiv.style.visibility = 'hidden';
        }

        const sesExpiredDiv = document.createElement("div");
        sesExpiredDiv.id = "session-expired";

        var sesExpiredMsgDiv = document.createElement("div");
        sesExpiredMsgDiv.id = "session-expired-msg";
        sesExpiredMsgDiv.textContent = "Session expired";

        sesExpiredDiv.appendChild(sesExpiredMsgDiv);
        document.body.appendChild(sesExpiredDiv);


        setTimeout(() => {
          app.reset();
          location.reload();
        }, 1000);
      }
    }
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export const graphqlClientAuth = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache()
});

export const graphqlClientNoAuth = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});

