import styled from "styled-components";


export const MainWrapper = styled.div`

`;


export const SelectedArea = styled.div<{$dragging: boolean}>`
  position: absolute;
  z-index: 1;
  box-sizing: border-box;

  border-radius: ${props => props.theme.defs.border.radius.normal};

  outline-width: 2px;
  outline-style: ${props => props.$dragging ? 'none' : 'solid'};
  /* outline-width: ${props => props.$dragging ? 'none' : 'solid'}; */
  outline-color: ${props => props.theme.defs.accentColor.primary};

  background:    ${props => props.$dragging ? 'none' : props.theme.defs.accentColor.opacity01.primary};
`;

export const WidgetsContextMenuFiller = styled.div`
  /* background: red; */
  width: 100%;
  height: 100%;
`;

export const WidgetsContextMenuPositioner = styled.div`
  position: absolute;
  /* background: green; */
`;