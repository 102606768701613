import { LexicalComposer  } from "@lexical/react/LexicalComposer";
import { editorInitConfig } from "./config";


interface Props {
  children: React.ReactNode;
};


export const EditorTextContext: React.FC<Props> = (props: Props) => {
  const {
    children,
  } = props;

  return (
    <LexicalComposer initialConfig={editorInitConfig}>
      { children }
    </LexicalComposer>
  );
}
