import React from 'react';

import IconsRepo from 'tools/icon-repo/icons-repo';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { MarkerViewProps } from './types';
import MarkerViewSignBaseComponent from '../marker-view-sign-base';


interface Props extends MarkerViewProps {
}


export const MarkerViewSignComponent: React.FC<Props> = (props: Props) => {
  const {
    markerProps
  } = props;

  const iconDescriptor = IconsRepo.getIcon(markerProps.icon.addr);
  const Icon = iconDescriptor.Icon;

  return  (
    <MarkerViewSignBaseComponent
      signType={markerProps.type as RepoMarkersTypes.MarkerSignType} 
      Icon={Icon} 
    />
  );
}
