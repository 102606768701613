import React from 'react';

import { CellPropsBase } from '../types';

import CellsLineSelectedComponent    from '../parts/cells-line-selected';
import CellOverflowClipperComponent  from '../parts/cell-overflow-clipper';
import CellIndexContextMenuComponent from './cell-index-context-menu';
import CellIndexValueComponent       from './cell-index-value';
import CellIndexSelectComponent      from './cell-index-select';
import CellIndexDraggerComponent     from './cell-index-dragger';
import CellIndexDropComponent        from './cell-index-drop';
import CellIndexColumnCssComponent   from './cell-index-column-css';


interface Props extends CellPropsBase {
}


export const CellIndexComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr
  } = props;
  

  return (
    <CellOverflowClipperComponent>
      <CellIndexSelectComponent cellAddr={cellAddr} >
        <CellsLineSelectedComponent cellAddr={cellAddr} >
          <CellIndexContextMenuComponent cellAddr={cellAddr} >
            <CellIndexDropComponent cellAddr={cellAddr} >
              <CellIndexDraggerComponent cellAddr={cellAddr} >
                <CellIndexColumnCssComponent cellAddr={cellAddr} >
                  <CellIndexValueComponent cellAddr={cellAddr} />
                </CellIndexColumnCssComponent>
              </CellIndexDraggerComponent>
            </CellIndexDropComponent>            
          </CellIndexContextMenuComponent>
        </CellsLineSelectedComponent>
      </CellIndexSelectComponent>
    </CellOverflowClipperComponent>
  );
}
