import { Position } from "app/arch/types";
import useDebugPointSet from "app/ui-v2/app/__modules/debug/hooks/use-debug-point-set";
import useRescaleScrollbarUpdate, { ScrollProps } from "./use-rescale-scrollbar-update";


interface HookProps {
  scaleInit: number,
  scaleNew: number,
  scalePoint: Position,
  scrollbarInit: ScrollProps,
}


const useRescaleAtPoint = () => {
  const setDebugPoint = useDebugPointSet();
  const scrollUpdate = useRescaleScrollbarUpdate();

  const rescaleAtPoint = ({
    scaleInit,
    scaleNew,
    scalePoint,
    scrollbarInit
  }: HookProps) => {

    setDebugPoint({x: scalePoint[0], y: scalePoint[1]});

    const scrollbarNew = scrollUpdate({
      scrollbarInit,
      scaleInit,
      scaleNew,
      scalePointInit: scalePoint,
      scalePointNew: scalePoint,
    });

    return scrollbarNew;
  }


  return rescaleAtPoint;
}

export default useRescaleAtPoint;
