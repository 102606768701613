import { PanelControlsConfig } from "lego/components/panel-controls/config";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import useItems from "./__use-items";
import useState from "./__use-state";


const useConfigColors = () => {
  const t = useTranslations();
  const items = useItems();
  const states = useState();


  const getConfig = (removeMe: any) => {
    
    const config: PanelControlsConfig = {
      disabled: states.configDisabled,
      items: [
        {
          title: t('colors'),
          sections: [
            {
              subsections:[
                {
                  skip: states.fillDisabled,
                  title: t('fill'),
                  items: [
                    items.getFillColor(),
                  ]
                },
                {
                  title: t('border'),
                  items: [
                    items.getBorderColor(),
                    items.getBorderWidth(),
                    items.getBorderRoundCorners(),
                  ]
                },
                {
                  title: t('outline'),
                  items: [
                    items.getOutlineColor(),
                    items.getOutlineWidth(),
                  ]
                },
              ]
            }
          ]
        },
      ]
    } // config

    return config;
  }

  return getConfig;
}


export default useConfigColors;