import React from 'react';
import { useEditor } from 'app/ui-v2/editor-instruction/hooks/use-editor';
import LexicalToolbarComponent from 'app/ui-v2/editor-instruction/hls/lexical/lexical-toolbar';
import { ToolbarProps } from '../types';
import { TopToolbar } from './styles';


interface Props extends ToolbarProps {
}


export const ToolbarWidgetTextComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr
  } = props;

  const editor = useEditor();
  const lexicalRegistry = editor.lexicalRegistrContent2Widgets;
  const lexical = lexicalRegistry.getLexical(widgetAddr);

  return (
    <TopToolbar>
      <LexicalToolbarComponent lexical={lexical} />
    </TopToolbar>
  );
}
