import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useSelectedReset = () => {
  const document = useDocState();
  const {
    setContentSession
  } = useEditorStatesSetters();

  const hook = (props?: {
    skipStateUpdate?: boolean
  }) => {
    const skipStateUpdate = props?.skipStateUpdate ?? false;

    document.contentSession.setColumnSelected(null);
    document.contentSession.setSectionSelected(null);
    document.contentSession.setRowSelected(null);
    document.contentSession.setCellSelected(null);
    document.contentSession.cellImages_setImageSelected(null);
    document.contentSession.cellMarkers_setMarkerSelected(null);

    if (skipStateUpdate) {
      return;
    }

    setContentSession();
  }

  return hook;
}


export default useSelectedReset;
