import React        from 'react';
import { useRef }   from 'react';
import { useState } from 'react';

import ControlSubItemComponent from 'lego/components/panel-controls/controls/control-sub-item';

import { MsgDuration } from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { MsgLevel    } from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { useMsgBox   } from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { useCreateWidgetStyle } from 'app/ui/components/backend-service/use-create-widget-style';
import { useInstruction  } from 'app/ui/hooks/editor-instruction/use-instruction';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import WidgetPreviewComponent  from '../control-widgets-styles-lib/parts/widget-preview';

import { ButtonSave       } from './styles';
import { Input            } from './styles';
import { InputWrapper     } from './styles';
import { MainWrapper      } from './styles';
import { PanelWidgetStyle } from './styles';


interface Props {
  widgetStyle: any;
}

const STYLE_NAME_MIN_LEN = 3;


export const ControlWidgetStyleSaveComponent: React.FC<Props> = (props: Props) => {
  const { 
    widgetStyle 
  } = props;
  
  const t = useTranslations();
  const instruction = useInstruction();
  const [styleName, setStyleName] = useState('');

  const { createWidgetStyleMutation } = useCreateWidgetStyle();
  const { showMsgBox } = useMsgBox();
  const inputRef = useRef<HTMLInputElement>(null);

  const isNewStyleNameValid = () => {
    return styleName.length >= STYLE_NAME_MIN_LEN;
  }

  const saveNewStyle = () => {
    if ( ! isNewStyleNameValid() ) {
      const msg = t('widget style name not valid');

      showMsgBox(
        msg,
        MsgLevel.WARN,
        MsgDuration.MIDDLE
      );

      return;
    }

    createWidgetStyleMutation({
      variables: {
        instructionId: instruction.id,
        name: styleName,
        style: JSON.stringify(widgetStyle)
      },
      onDone: (data?: any) => {
        inputRef.current?.blur();
        setStyleName('');
      }
    });
  }

  const handleInputChange = (event: any) => {
    setStyleName(event.target.value);
  }

  const handleInputKeyDown = (event: React.KeyboardEvent) => {
    const key = event.key;
    event.stopPropagation();

    if (key === 'Enter') {
      saveNewStyle();
    }
    else if (key === 'Escape') {
      setStyleName("");
      inputRef.current!.blur();
    }
  }

  const handleSave = (event: React.MouseEvent) => {
    saveNewStyle();
  }
  
  return (
    <MainWrapper>
      <ControlSubItemComponent
        title={t('widget style name')}
        component={
          <InputWrapper>
            <Input
              ref={inputRef}
              value={styleName}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
            />

            <ButtonSave 
              onClick={handleSave}
              disabled={ ! isNewStyleNameValid() }
            >
              { t('widget style save') }
            </ButtonSave>
          </InputWrapper>
        }
      />

      <ControlSubItemComponent 
        title={t('widget style preview')}
        component={
          <PanelWidgetStyle>
            <WidgetPreviewComponent
              style={widgetStyle}
            />
          </PanelWidgetStyle>
        }
      />
      
    </MainWrapper>
  );
}
