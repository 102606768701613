import React from 'react';
import { useEffect } from 'react';
import { useRef    } from 'react';
import { useState  } from 'react';

import MenuComponent from 'lego-v2/menu';
import { useSidePanelContext } from 'lego/components/side-controls/side-panel/use-side-panel-context';

import { Position } from 'app/arch/types';
import { CssStylePack } from 'app/arch/editor-instruction/css-styles';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useDeleteWidgetStyle } from 'app/ui/components/backend-service/use-delete-widget-style';
import { useDialogYesNo } from 'app/ui/components/editor-instruction/dialogs/dialog-yes-no/use-dialog-yes-no';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import { getConfig } from './config';

import { MenuPanel   } from './styles';
import { MenuWrapper } from './styles';


interface Props {
  stylePack: CssStylePack
  widgetAddr: ContentTypes.WidgetAddr | null;
  onClose: () => void;
  onStyleApplied: (stylePack: CssStylePack) => void;
  deleteDisabled?: boolean;
}

const BOTTOM_PADDING = 8;
const POSITION_X = 0;
const POSITION_Y = 0;

export const ContextMenuStyleComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const document = useDocState();
  const context = useSidePanelContext();
  const ignoreMenuCloseRef = useRef(false);
  
  const { 
    stylePack, 
    widgetAddr, 
    onClose,
    onStyleApplied: onStyleApply,
    deleteDisabled
  } = props;

  const {
    setContent
  } = useEditorStatesSetters();

  const [position, setPosition] = useState<Position | null>(null);
  const menuWrapperRef  = useRef<HTMLDivElement>(null);
  
  const {
    deleteWidgetStyleMutation
  } = useDeleteWidgetStyle();

  const {
    show: showDialogYesNo,
  } = useDialogYesNo();

  useEffect(() => {
    if (menuWrapperRef.current === null) return;
    if (position !== null) return;

    const rect = menuWrapperRef.current.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const divBottom    = rect.bottom + BOTTOM_PADDING - context.scrollTop;

    if (divBottom > windowHeight) {
      // console.log("out of screen")
      const y = -1 * (divBottom - windowHeight);
      setPosition([POSITION_X, POSITION_Y - context.scrollTop + y]);
    }
    else {
      setPosition([POSITION_X, POSITION_Y - context.scrollTop]);
    }
  }, [menuWrapperRef.current]);

  const prevScrollTopRef = useRef<number | null>(null);
  useEffect(() => {
    if (prevScrollTopRef.current != null && prevScrollTopRef.current != context.scrollTop) {
      onClose();
    }
    prevScrollTopRef.current = context.scrollTop;

  }, [context.scrollTop]);

  if (widgetAddr === null) {
    return null;
  }

  const applyStyle = () => {
    document.content.cellImages_image_widget_setStyle(
      widgetAddr,
      stylePack.style
    );
    document.saveUndo();
    setContent();
    onStyleApply(stylePack);
    onClose();
  }

  const deleteStyle = () => {
    ignoreMenuCloseRef.current = true;

    showDialogYesNo({
      title: t("delete widget style"),
      message: t("delete widget style message"),
      noAutoFocus: true,
      onNoHandler: () => {
      },
      onYesHandler: () => {
        deleteStyleExecute();
      }
    });
  }

  const deleteStyleExecute = () => {
    const id = stylePack.id;
    console.log(stylePack)
    deleteWidgetStyleMutation({ 
      variables: { id },
      onDone: () => {
        // console.log("done", id);
      }
    });
  }

  const setDefaultStyle = () => {
    document.editorImageSettings.setWidgetStyle(stylePack.style);
    onClose();
  }

  const handleClose = () => {
    if (ignoreMenuCloseRef.current) {
      ignoreMenuCloseRef.current = false;
      return;
    }
    onClose();
  }

  const cmds = {
    applyStyle,
    deleteStyle,
    setDefaultStyle
  }

  const states = {

  }
  
  const menuConfig = getConfig(cmds, states, deleteDisabled);
  const top = position ? `${position[1]}px` : '0px';

  return (
    <MenuWrapper
      ref={menuWrapperRef}
      $visible={position !== null}
      style={{top}}
    >
      <MenuComponent 
        config={menuConfig}
        onClose={handleClose}
        Panel={MenuPanel}
      />
    </MenuWrapper>
  );
}
  
