import React, { useEffect, useRef } from 'react';

import BtnColorComponent   from 'lego-v2/btn-color';
import { BtnColorProps   } from "lego-v2/btn-color";

import ColorView1Component   from 'lego-v2/colors-view1';
import { ColorsView1Props  } from "lego-v2/colors-view1";

import { MainWrapper  } from './styles';
import { PanelWrapper } from './styles';
import { StyledPanel  } from './styles';


interface Props {
  id?: string,
  btnProps: BtnColorProps,
  colorsViewProps: ColorsView1Props,
  isPanelVisible: boolean,
  togglePanelVisible: () => void,
}


export const DropdownColorPickerComponent = (props: Props) => {
  const mainWrapperRef = useRef<HTMLDivElement>(null);

  const idProps = ( 
    props.id !== undefined ?
    {id: props.id} :
    {}
  );

  useEffect(() => {
    if ( ! props.isPanelVisible) return;
    window.addEventListener('keydown', handleKeydown, true);

    return () => {
      window.removeEventListener('keydown', handleKeydown, true);
    };
  }, [props.isPanelVisible]);

  const handleKeydown = (event: KeyboardEvent) => {
    if ( ! props.isPanelVisible ) {
      return;
    }
    if (event.key === 'Escape') {
      hidePanel();
      event.stopPropagation();
      event.preventDefault();
    }
  }

  const hidePanel = () => {
    if (props.isPanelVisible) {
      props.togglePanelVisible();
    }
  }

  // const handleLostFocus = (e: any) => {
  //   const lostFocusToChild = mainWrapperRef.current!.contains(e.relatedTarget);
  //   if ( ! lostFocusToChild ) {
  //     hidePanel();
  //   }
  // }

  const stopPropagation = (event: any) => { 
    event.stopPropagation(); 
  }

  return (
    <MainWrapper
      {...idProps}
      ref={mainWrapperRef}
      // onBlur={handleLostFocus}
      onClick={stopPropagation}
      onPointerDown={stopPropagation}
      onPointerUp={stopPropagation}

    >
      <BtnColorComponent {...props.btnProps}/>
    {
      props.isPanelVisible &&
      <PanelWrapper>
        <StyledPanel
          onClick={stopPropagation}
          onPointerDown={stopPropagation}
          onPointerUp={stopPropagation}
        >
          <ColorView1Component {...props.colorsViewProps}/>
        </StyledPanel>
      </PanelWrapper>
    }
    </MainWrapper>
  );
};
