type SetterFn = (visible: boolean) => void;

let __setVisible: SetterFn | null = null;

export const setup = (setVisible: SetterFn | null) => {
  __setVisible = setVisible;
}


export const useProgressModal = () => {
  const showProgressModal = () => {
    __setVisible?.(true);
  }

  const hideProgressModal = () => {
    __setVisible?.(false);
  }

  return { 
    showProgressModal, 
    hideProgressModal
  };
}