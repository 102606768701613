import styled from 'styled-components';
import { StyledDropdownBtnBase } from 'lego-v2/dropdown-btn/styles';
import PanelForth from 'lego/styles/panel_forth';
import Input from 'lego/styles/input';
import TopToolbarButton from 'app/ui-v2/editor-instruction/styles/top-toolbar-button';
import { getScrollbarCSS } from 'lego/styles/scrollbar';


export const MainWrapper  = styled.div`
`;

export const PanelWrapper = styled.div`
  position: relative;
`;

export const StyledDropdownBtn = styled(StyledDropdownBtnBase)`
  .text {
    width: 30px;
  }
`;

export const StyledPanel = styled(PanelForth)`
  position: absolute;
  left: 17px;
  top: 3px;
  overflow: clip;
  padding: 0px;
`;


export const Clipper = styled.div`
  height: 180px;
  width: 60px;
  overflow-x: clip;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  
  gap:     ${props => props.theme.defs.gap.xxsmall};
  padding: ${props => props.theme.defs.padding.normal};

  ${props => getScrollbarCSS(props)};

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.defs.colors.scrollbar.thumb.secondary};
  }

  /* ::-webkit-scrollbar {
    width:  ${props => props.theme.scrollbar.width.small};
    height: ${props => props.theme.scrollbar.width.small};
  } */
`;

export const InputCtrlsWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
`;

export const StyledFontSizeInput = styled(Input)`
  width: 36px;
  box-sizing: border-box;
  text-align: center;

  &:focus-visible {
    outline-style: none;
  }

  border-style: none;
  border-radius: 0;
  background: ${props =>  props.theme.defs.colors.background.primary};
`;


export const ButtonGroupLeft = styled(TopToolbarButton)`
`;

export const ButtonGroupRight = styled(TopToolbarButton)`
`;