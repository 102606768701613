import { UUID } from "app/arch/types"
import { IconAddr } from "tools/icon-repo/types";


/**
 * Marker
 */

export type MarkerAddr = {
  markerId: UUID;
}

export enum MarkerGroup {
  LABEL = "label",
  ICON = "icon",
  SIGN = "sign",
}

export enum MarkerType {
  LABEL = "label",

  ICON  = "icon",

  SIGN_INFO        = "sign-info",
  SIGN_WARNING     = "sign-warning",
  SIGN_PROHIBITION = "sign-prohibition",
  SIGN_MANDATORY   = "sign-mandatory",
}

export type MarkerSignType = Exclude<
  MarkerType, 
  MarkerType.LABEL | MarkerType.ICON
>;

export type LabelProps = {
  text: string,
  css: React.CSSProperties,
}

export type LabelPropsUpdate = Partial<
  Omit<LabelProps, 'css'>
>;

export type IconProps = {
  addr: IconAddr,
  size: number,
  css: React.CSSProperties,
}

export type IconPropsUpdate = Partial<
  Omit<IconProps, 'css'>
>;

export type MarkerProps = {
  type:  MarkerType,
  label: LabelProps,
  icon:  IconProps,
}

export type MarkerPropsUpdate = Partial<
  Omit<MarkerProps, 'label' | 'icon'>
>

/**
 * Markers
 */

export type MarkersAddrs = MarkerAddr[];

export type MarkersProps = {
  [markerKey: string]: MarkerProps
}

export type Markers = {
  addrs: MarkersAddrs,
  props: MarkersProps,
}
