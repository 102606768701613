import { PanelControlsConfig } from "lego/components/panel-controls/config";
import { useTranslations }     from "app/ui/hooks/app/use-translation";

import useDocInfoConfigSection from "./doc-info";
import useDocCustomFieldsConfigSection from "./doc-custom-fields";
import useDocMarkersSection from "./doc-markers";


const usePanelDocHeaderConfig = () => {
  const t = useTranslations();

  const docInfoSection = useDocInfoConfigSection();
  const docCustomFieldsSection = useDocCustomFieldsConfigSection();
  const docMarkersSection = useDocMarkersSection();

  const getConfig = (): PanelControlsConfig => ({
    items: [
      {
        title: t('page header'),
        sections: [
          docInfoSection,
          docCustomFieldsSection,
          docMarkersSection,
        ]
      },
    ]
  });

  return getConfig;
}


export default usePanelDocHeaderConfig;
