import jtl from "tools/jtl";
import Logger from "libs/debug";
import Template          from "./states/template";
import { TemplateTools } from "./states/template";
import { TemplateTypes } from "./states/template";
import { HistoryTypes } from "../editor-instruction/document/states/history-state";
import { LexicalTools } from "../tools";


type ContentStatesNames = keyof(HistoryTypes.Record);

// Remaining 
// Section/section less?
//
// content:             ContentState.State,
// headerMarkers:       HeaderMarkersState.State,
// metaData:            MetaDataState.State,
// releaselogs:         ReleaselogsState.State,
// check widget styles
//  #TODO COPY WIDGET STYLES


const DIRECT_COPY_STATES: ContentStatesNames[] = [
  'contentView',
  'editorImageSettings',
  // 'headerFields',
  'headerMeta',
  'releaselogsView',
  'repoMarkers',
  'viewsCommon',
];


export class DocTemplates {
  private _template: Template;
  
  constructor() {
    const logger = Logger.getDocTemplates();
    logger.debug("DocumentTemplate construct");
    this._template = new Template();
  }

  get template() { return this._template; }

  reset() {
    this._template.reset();
  }

  loadTemplates(reposTemplates: TemplateTypes.BackendTemplates) {
    this._template.setTemplates(reposTemplates);
  }

  applyTemplate(
    repoId: TemplateTypes.RepoId,
    content: HistoryTypes.Record
  ): HistoryTypes.Record {
    const logger = TemplateTools.getLogger();
    
    const templatePresent = this.template.hasTemplate(repoId);
    if ( ! templatePresent ) {
      logger.debug(`No template for repo: ${repoId}`);
      return content;
    }

    const template = this.template.getTemplate(repoId);
  
    this.__copyDirectStates(content, template);
    this.__copyHeaderFieldsState(content, template);
  
    return content;
  }

  private __copyDirectStates(
    content: HistoryTypes.Record,
    template: TemplateTypes.TemplateContent,
  ) {
    DIRECT_COPY_STATES.forEach(stateName => {
      this.__copyState(
        stateName,
        content,
        template
      )
    });
  }

  private __copyHeaderFieldsState(
    content: HistoryTypes.Record,
    template: TemplateTypes.TemplateContent,
  ) {
    // Copy header fields
    this.__copyState(
      'headerFields',
      content,
      template
    );

    // Clean up TextEditor content
    const headerFields = content.headerFields;
    const cells = headerFields.fields.cells;
   
    const keys = Object.keys(cells);
    keys.forEach((cellAddr) => {
      const cell = cells[cellAddr];
      cell.editorState = LexicalTools.getEditorInitStateSerial();
    });
  }

  private __copyState(
    stateName: ContentStatesNames,
    content: HistoryTypes.Record,
    template: TemplateTypes.TemplateContent,
  ) {
    const logger = TemplateTools.getLogger();
    logger.debug(`Copying template state to content: ${stateName}`);

    const state = template[stateName];
    const stateCopy = jtl.object.copy(state);

    content[stateName] = stateCopy;
  }
}
