import React from 'react';
import { useRecoilValue } from 'recoil';

import { RepoMarkersTools } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';

import { UIState_RepoMarkers } from 'app/ui/states/editor-instruction';
import MarkerViewIconComponent from 'app/ui-v2/editor-markers/hls/marker-views/marker-view-icon';
import MarkerViewSignComponent from 'app/ui-v2/editor-markers/hls/marker-views/marker-view-sign';
import MarkerViewLabelComponent from 'app/ui-v2/editor-markers/hls/marker-views/marker-view-label';

import { Marker } from './styles';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
  selected?: boolean;
}


export const MarkerViewComponent: React.FC<Props> = (props: Props) => {
  const { 
    markerAddr,
    selected,
  } = props;

  const markerProps = useRecoilValue(UIState_RepoMarkers.markerProps(markerAddr));
  const markerGroup = RepoMarkersTools.getMarkerGroup(markerProps.type);
  
  const MarkerViewMap: {[markerGroup in RepoMarkersTypes.MarkerGroup]: any} = {
    [RepoMarkersTypes.MarkerGroup.LABEL]: MarkerViewLabelComponent,
    [RepoMarkersTypes.MarkerGroup.SIGN ]: MarkerViewSignComponent,
    [RepoMarkersTypes.MarkerGroup.ICON ]: MarkerViewIconComponent,
  }

  const MarkerViewComponent = MarkerViewMap[markerGroup];


  return (
    <Marker selected={selected}>
      <MarkerViewComponent  markerProps={markerProps} />
    </Marker>
  );
}
