import React from 'react';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { useDocState }     from 'app/ui/contexts/document';
import { DNDContext }      from 'app/ui-v2/editor-instruction/dnd-context';
import DNDDropComponent    from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';


interface Props {
  imageAddr: Types.ImageAddr;
  children: React.ReactNode;
}


export const ImageDropComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
    children
  } = props;

  const document = useDocState();
  const getDNDData = useDNDDataGet();

  const {
    setContent
  } = useEditorStatesSetters();

  const handleDrop_fromCell = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }
    const data = JSON.parse(dataSerial);
    const imageAddrDropped = data.imageAddr as Types.ImageAddr;

    document.content.cellImages_moveImageOnImage(
      imageAddrDropped,
      imageAddr
    );

    document.saveUndo();
    setContent();
  }

  const handleDrop_fromGallery = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const imageGid = data.imageGid;

    const docImage = document.repoImages.getImage(imageGid);
    const cellAddr = imageAddr;
    const newImageAddr = document.content.cellImages_addImage(cellAddr, docImage);

    document.content.cellImages_moveImageOnImage(
      newImageAddr, 
      imageAddr
    );
    document.saveUndo();
    setContent();
  }

  const contextsDefs = [
    {
      dndContext: DNDContext.image.cell,
      onDropV2: handleDrop_fromCell,
    },
    {
      dndContext: DNDContext.image.gallery,
      onDropV2: handleDrop_fromGallery,
    },
  ];


  return (
    <DNDDropComponent contextsDefs={contextsDefs} >
      { children }
    </DNDDropComponent>
  );
}
