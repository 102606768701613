import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { useEditor }  from "app/ui-v2/editor-instruction/hooks/use-editor";


interface Props {
  pageCellAddr: Types.PageCellAddr;
}


export const RegistrationPlugin: React.FC<Props> = (props: Props) => {
  const { 
    pageCellAddr,
  } = props;

  const editor = useEditor();
  const [lexical] = useLexicalComposerContext();
  
  useEffect(() => {
    const registry = editor.lexicalRegistryContent2CustomFields;
      registry.addLexical(pageCellAddr, lexical);

    return () => {
      registry.removeLexical(pageCellAddr);
    };
  }, [lexical]);

  return null;
}

