import React from 'react';

import { NonOAuthError } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';

import { useProgressModal } from 'lego-v2/progress-modal';

import GoogleLogoComponent from '../google-logo';
import { useUserGoogleLogin } from 'app/ui-v2/app/hooks/use-user-google-login';
import useUserGoHome          from 'app/ui-v2/home-page/home-page-user/hooks/user-user-go-home';
import { useTranslations }    from 'app/ui/hooks/app/use-translation';

import { MainWrapper } from './styles';
import { Button } from './styles';
import { LogoWrapper } from './styles';
import { Text } from './styles';



interface Props {
  signup: boolean;
}


export const GoogleLoginBtnComponent: React.FC<Props> = (props: Props) => {
  const {
    signup
  } = props;

  const t = useTranslations();
  const userGoHome = useUserGoHome();
  const loginGoogleUser = useUserGoogleLogin();

  const btnText = (
    signup ?
    t("google oauth, singup") :
    t("google oauth, login")
  );

  const {
    showProgressModal, 
    hideProgressModal 
  } = useProgressModal();


  const googleAuth = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      showProgressModal();
      
      loginGoogleUser({
        googleToken: tokenResponse.access_token,
        onDone: () => {
          userGoHome();
        },
        onError: (error: string) => {
          // TODO 
          // 
          console.error(error);
          hideProgressModal();
        }
      });
    },
    onError: (errorResponse) => {
      console.warn(errorResponse.error)
      console.warn(errorResponse.error_description)
    },
    onNonOAuthError: (nonOAuthError: NonOAuthError) => {
      console.warn(nonOAuthError.type);
    }
  });

  const handleClick = () => {
    googleAuth();
  }


  return (
    <MainWrapper>
      <Button onClick={handleClick} >
        <LogoWrapper>
          <GoogleLogoComponent size={18} />
        </LogoWrapper>

        <Text>
          { btnText }
        </Text>

      </Button>
    </MainWrapper>
  );
};