import { SideToolbarConfig } from "lego/components/side-controls/side-toolbar/config";
import useItems from "./__use-items";


const useConfigColors = () => {
  const items = useItems();


  const getConfig = () => {
    const config: SideToolbarConfig = {
      items: [
        items.getColors(),
        items.getArrow(),
        items.getTextBox(),
        items.getStyles(),
        items.getView(),
        items.getLayers(),
      ]
    }

    return config;
  }

  return getConfig;
}


export default useConfigColors;