import React from 'react';

import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState } from 'app/ui/contexts/document';

import { Text } from './styles';


interface Props {
  columnAddr: ReleaselogsTypes.ColumnAddr;
}


export const HeaderCellViewComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
  } = props;

  const document = useDocState();
  const columnProps = document.releaselogs.getColumnProps(columnAddr);

  const columnNameDisplayed = (
    columnProps.name.length ?
    columnProps.name :
    <>&nbsp;</>
  );

  return (
    <Text>
    { columnNameDisplayed }
    </Text>
  );
}
  
