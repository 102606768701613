import * as Types from '../../types';

import MenuBarItemPanelStyled from 'lego/styles/menu_bar_item_panel';

import { StyledSeparator } from './styles';


interface Props extends Types.MenubarItemPanelProps {
  MenubarItemPanelItem: React.ElementType;
}


export const MenubarItemPanelComponent: React.FC<Props> = (props: Props) => {
  const { 
    config,
    MenubarItemPanelItem
  } = props;

  const renderListItem = (item: Types.SectionItem, idx: number) => {
    return (
      <MenubarItemPanelItem 
        key={idx}
        config={item}
      />
    );
  }
  
  const renderItems = (items: Types.SectionItem[]) => {
    const itemsComponents = items.map((item: Types.SectionItem, idx: number) => {
      return renderListItem(item, idx);
    });
    return itemsComponents;
  }


  const renderSections = () => {
    const sectionsComponents = config.sections.map((section: Types.PanelSection, idx: number) => {
      const isLast = idx === config.sections.length - 1;

      return (
        <div
          key={idx}
        >
          { renderItems(section.items) }
          { isLast || <StyledSeparator /> }
        </div>
      )

    });
    return sectionsComponents;
  }


  return (
    <MenuBarItemPanelStyled>
      { renderSections()}
    </MenuBarItemPanelStyled>
  );
}
