import { useRecoilValue } from "recoil";
import jtl from "tools/jtl";

import { DraggerUpdate     } from "lego-v2/dragger/arch";
import { FrameTargetUpdate } from "lego-v2/frame-resize/arch";

import { DraggerBoxEditorTextUpdateStart } from './types';
import { DraggerBoxEditorTextUpdate }      from './types';
import { FrameBoxEditorTextUpdateStart }   from './types';
import { FrameBoxEditorTextUpdate }        from './types';

import { Position } from "app/arch/types";
import { Size     } from "app/arch/types";
import { CssStyle } from "app/arch/editor-instruction/css-styles";

import DraggerSmartLinesComponent       from "app/ui-v2/editor-image/hls/widgets/smart-lines/dragger-smart-lines";
import FrameResizerSmartLinesComponent  from "app/ui-v2/editor-image/hls/widgets/smart-lines/frame-resizer-smart-lines";
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';

import { WidgetPropsBase } from "../../../../types";

import { FrameBox }      from './styles';
import { Frame }   from './styles';
import { Dragger } from './styles';


interface Props extends WidgetPropsBase {
  position: Position;
  size: Size;
  selected?: boolean;

  onDraggerUpdateStart?: (update: DraggerBoxEditorTextUpdateStart) => void;
  onDraggerUpdate?:      (update: DraggerBoxEditorTextUpdate) => void;
  onDraggerUpdateDone?:  (update: DraggerBoxEditorTextUpdate) => void;
  
  onFrameUpdateStart?: (update: FrameBoxEditorTextUpdateStart) => void;
  onFrameUpdate?:      (update: FrameBoxEditorTextUpdate) => void;
  onFrameUpdateDone?:  (update: FrameBoxEditorTextUpdate) => void;

  widgetStyle: CssStyle;

  children: React.ReactNode;
}


export const BoxFrameComponent: React.FC<Props> = (props: Props) => {
  const {
    dataTest,

    scale,
    position, 
    size, 
    selected: wigetPartSelected,
    widgetStyle,
    editDisabled, 

    widgetAddr,

    onDraggerUpdateStart,
    onDraggerUpdate,
    onDraggerUpdateDone,

    onFrameUpdateStart,
    onFrameUpdate,
    onFrameUpdateDone,

    children,
  } = props;

  const widgetEdited = useRecoilValue(UIState_EditorImageSession.isWidgetEdited(widgetAddr));

  const actionDisabled = (editDisabled || widgetEdited);

  const outlineWidth = jtl.css.valueToNumber(widgetStyle.outlineWidth);

  const left = position[0] - outlineWidth;
  const top  = position[1] - outlineWidth;
  const width  = size[0] + 2 * outlineWidth;
  const height = size[1] + 2 * outlineWidth;

  const boxDataTest = `${dataTest}__box`;

  //-----------------
  // Dragger updates

  const createDraggerBoxUpdate = (update: DraggerUpdate) => {
    const boxUpdate: DraggerBoxEditorTextUpdate = {
      delta: update.delta
    }

    return boxUpdate;
  }

  const handleDraggerUpdateStart = () => {
    onDraggerUpdateStart?.({});
  }

  const handleDraggerUpdate = (update: DraggerUpdate) => {
    const boxUpdate = createDraggerBoxUpdate(update);
    onDraggerUpdate?.(boxUpdate);
  }
  
  const handleDraggerUpdateDone = (update: DraggerUpdate) => {
    handleDraggerUpdate(update);

    const boxUpdate = createDraggerBoxUpdate(update);
    onDraggerUpdateDone?.(boxUpdate);
  }


  //-----------------
  // Frame updates

  const createFrameBoxUpdate = (update: FrameTargetUpdate) => {
    const boxUpdate: FrameBoxEditorTextUpdate = {
      position: update.position !,
      size: update.size !
    };

    return boxUpdate;
  }

  const handleFrameUpdateStart = () => {
    onFrameUpdateStart?.({});
  }

  const handleFrameUpdate = (update: FrameTargetUpdate) => {
    const boxUpdate = createFrameBoxUpdate(update);
    onFrameUpdate?.(boxUpdate);
  }

  const handleFrameUpdateDone = (update: FrameTargetUpdate) => {
    handleFrameUpdate(update);

    const boxUpdate = createFrameBoxUpdate(update);
    onFrameUpdateDone?.(boxUpdate);
  }


  //----------------------------------------
  // Render
  //
  return (
    <FrameBox
      $left={left}
      $top={top}
      $width={width}
      $height={height}
      data-test={boxDataTest}
    >
    {
      <DraggerSmartLinesComponent
        widgetAddr={widgetAddr}
        disabled={actionDisabled}
        position={position}

        onUpdateStart={handleDraggerUpdateStart}
        onUpdate={handleDraggerUpdate}
        onUpdateDone={handleDraggerUpdateDone}

        scale={scale}
        component={Dragger}
      >
        { children }
      </DraggerSmartLinesComponent>
    }
    {
        wigetPartSelected && 
      ! widgetEdited  &&
      <FrameResizerSmartLinesComponent
        dataTest={dataTest}
        scale={scale}
        minSize={[20, 20]}
        size={size}
        position={position}
        elementStyle={widgetStyle}

        onUpdateStart={handleFrameUpdateStart}
        onUpdate={handleFrameUpdate}
        onUpdateDone={handleFrameUpdateDone}

        frameComponent={Frame}
        widgetAddr={widgetAddr}
      />
    }

    </FrameBox>
  );
}
