import styled from "styled-components";


const SIZE = 20;


export interface PointProps {
  $top: number;
  $left: number;
}


export const Point = styled.div.attrs<PointProps>(props =>({
  style: {
    top:  `${props.$top}px`,
    left: `${props.$left}px`,
  }
}))<PointProps>`
  position: fixed;
  background: rgba(255, 0, 0, 0.7);

  width: ${SIZE}px;
  height: ${SIZE}px;

  border-radius: 50%;
  box-sizing: border-box;

  border-color: white;
  border-style: none;

  outline-color: rgba(0, 0, 0, 0.7);
  outline-style: none;

  box-shadow: 0 0 4px rgba(0, 0, 0, 0.7);
  transform: translate(-50%, -50%);
`;
