import { gql } from '@apollo/client';
import { Observable } from 'rxjs';
import { mutation } from 'app/arch/backend';
import { graphqlClientNoAuth } from '../../../graphql';
import { graphqlClientAuth } from '../../../graphql';

import { AuthProvider } from '../auth-provider';
import { TokenRefresher } from './token-refresher';
import * as jwtDecode from 'jwt-decode';

const TOKEN_NAME = 'jwt-token';
const USER_ID    = 'user_id';
const USERNAME   = 'username';


const TOKEN_REFRESH_TIME_CYCLE        = 15 * 60 * 1000 // 15 minutes
const TOKEN_REFRESH_RESCUE_TIME_CYCLE =  2 * 60 * 1000

export class AuthJwtProvider implements AuthProvider {
  private _tokenRefresher: TokenRefresher;

  constructor() {
    const callback = () => {
      this.refreshToken();
    }

    this._tokenRefresher = new TokenRefresher(callback);
  }

  init() {
    this.refreshToken();
  }

  // FIXME: this should not be exported I guess
  reset() {
    this.removeAuthData();
    this._tokenRefresher.stop();
  }

  login(email: string, password: string) {
    return new Observable((observer: any) => {
      graphqlClientNoAuth
        .mutate({
          mutation: mutation.getJwtToken,
          variables: { email, password }
        })
        .then((result: any) => {
          const data = result.data.tokenGet;
          const token   = data.token;
          const payload = data.payload;
      
          this.saveAuthData(token, payload);
          this._tokenRefresher.schedule(TOKEN_REFRESH_TIME_CYCLE);
          observer.next(result);
        })
        .catch((error: any) => {
          console.error(`Auth problem: ${error}`);
          observer.error(error);
        });
    });
  }

  logout(): Observable<any> {
    return new Observable((observer: any) => {
      this._tokenRefresher.stop();
  
      const logoutObs = this.queryGraphql('userLogout');
      logoutObs.subscribe({
        next: (result: any) => {
          // FIXME
          // If I raise GraphQLError in backend
          // it will come here rather then in .error
          // Therefore it is not populated
          if ("errors" in result) {
            result.errors.forEach((error: any) => {
              console.log(`Error: ${error.message}`);
            });
          }

          this.removeAuthData();
          observer.next(result);
        },
        error: (error: any) => {
          this.removeAuthData();
          console.log('Error while loging out:', error.message);
          observer.error(error);
        }
      });
    });
  };

  isLogged() {
    const token = localStorage.getItem(TOKEN_NAME);
    if ( token !== null && token !== "" ) {
      return true;
    }
    return false;
  }

  getUsername() {
    return localStorage.getItem(USERNAME);
  }

  getUserId() {
    return +localStorage.getItem(USER_ID) !;
  }

  getToken() {
    return localStorage.getItem(TOKEN_NAME);
  }

  setToken(jwtToken: string | undefined | null): boolean {
    if ( ! jwtToken ) {
      return false;
    }

    let payload: any = null;
    try {
      payload = jwtDecode.jwtDecode(jwtToken);
    }
    catch (error) {
      console.error(error);
      return false;
    }

    this.saveAuthData(jwtToken, payload);
    this._tokenRefresher.schedule(TOKEN_REFRESH_TIME_CYCLE);

    return true;
  }

  private refreshToken() {
    const token = this.getToken();
    if (token === null) {
      console.log(`No token to refresh`);
      this._tokenRefresher.stop();
      return;
    }

    graphqlClientNoAuth.mutate({
      mutation: mutation.refreshJwtToken,
      variables: { token: token }
    }).then((response) => {
      const newToken = response.data.tokenRefresh.token;
      const payload  = response.data.tokenRefresh.payload;

      // console.log(token);
      // console.log(newToken);
      this.saveAuthData(newToken, payload);
      this._tokenRefresher.schedule(TOKEN_REFRESH_TIME_CYCLE);
    }).catch((error) => {
      const gerrs = error.graphQLErrors;
      if ( gerrs !== null && gerrs.length > 0) {
        const gerr = gerrs[0];
        if ('extensions' in gerr && 'code' in gerr.extensions) {
          const code = gerr.extensions.code;
          if (code === 'TOKEN_EXPIRED') {
            
            // We tried to refresh token but it failed
            // Remove it from cache.
            this.removeAuthData();

            // Do not schedule token refresh 
            // it already expired
            return;
          }
        }
      }
      this._tokenRefresher.schedule(TOKEN_REFRESH_RESCUE_TIME_CYCLE);
    });
  }


  private queryGraphql(query: string) {
    return graphqlClientAuth.watchQuery({
      query: gql`{${query}}`,
      fetchPolicy: 'network-only',
      // nextFetchPolicy: 'network-only',
      errorPolicy: 'all'
    });
  }

  private saveAuthData(token: string, payload: any) {
    localStorage.setItem(TOKEN_NAME, token);
    localStorage.setItem(USER_ID,    payload.userId);
    localStorage.setItem(USERNAME,   payload.email);
  }

  private removeAuthData() {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(USERNAME);
  }
}
