import ColorBoxComponent   from '../color-box';

import { MainWrapper     } from './styles';
import { ColorRowWrapper } from './styles';


export interface Props {
  colors: string[][];
  onColorSelected: (color: string) => void;
  selectedColor?: string;
}


export const ColorView1Component = (props: Props) => {
  const colors = props.colors;

  const renderColors = () => {
    const colorsComponents = colors.map((colorsRow, rowIdx) => {

      const colorsRowComponent = colorsRow.map((color) => {
        const isSelected = color === props.selectedColor;

        return (
          <ColorBoxComponent
            key={color}
            color={color}
            onColorSelected={props.onColorSelected}
            isSelected={isSelected}
          />
        );
      });

      return (
        <ColorRowWrapper
          key={rowIdx}
        >
          {colorsRowComponent}
        </ColorRowWrapper>
      );
    });

    return colorsComponents;
  }

  return (
    <MainWrapper>
      { renderColors() }
    </MainWrapper>
  );
};
