import React from 'react';
import { RepoMarkersTypes }   from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { useDocState }        from 'app/ui/contexts/document';
import { DNDContext }         from 'app/ui-v2/editor-instruction/dnd-context';
import DNDDropComponent       from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerDropComponent: React.FC<Props> = (props: Props) => {
  const { 
    markerAddr,
    children 
  } = props;

  const document = useDocState();
  const getDNDData = useDNDDataGet();
  
  const {
     setRepoMarkers
   } = useEditorStatesSetters();

  const handleDrop_fromGallery = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const markerAddrDropped = data.markerAddr;

    document.repoMarkers.moveMarker(markerAddrDropped, markerAddr);
    document.saveUndo();
    setRepoMarkers();
  }


  const contextsDefs = [
    {
      dndContext: DNDContext.marker.gallery,
      onDropV2: handleDrop_fromGallery,
    },
  ];

  return (
    <DNDDropComponent contextsDefs={contextsDefs} >
      { children }
    </DNDDropComponent>
  );
}
