import environment from "app/environment";
import { Area } from "app/arch/types";
import { Size } from "app/arch/types";
import { CssStyle } from "app/arch/editor-instruction/css-styles";
import { Page } from "app/arch/print/page";

import { uuid } from 'tools/uuid';
import jtl      from "tools/jtl";
import Logger   from "libs/debug"

import DocState from '../../doc-state';
import  * as Types from './types';


export const getLogger = () => {
  return Logger.getContentState();
}


/**
 * 
 * Column
 * 
 */

export const getColumnKey = (
  columnAddr: Types.ColumnAddr
): string => (
  `${columnAddr.columnId}`
);

export const createColumnAddr = (): Types.ColumnAddr => {
  const columnId = uuid();
  const columnAddr = { columnId };
  return columnAddr;
}

export const compareColumnAddr = (
  srcColumnAddr: Types.ColumnAddr,
  dstColumnAddr: Types.ColumnAddr,
): boolean => (
  srcColumnAddr.columnId === dstColumnAddr.columnId
);

export const getColumnDataTest = (props: {
  columnAddr: Types.ColumnAddr, 
  docState: DocState,
  prefix: string,
}): string => {
  if ( ! environment.dev) {
    return '';
  }

  const {
    columnAddr,
    docState,
    prefix
  } = props;

  const columnIdx = docState.content.getColumnIdx(columnAddr);
  return `${prefix}-${columnIdx}`;
}



/**
 * 
 * Section
 * 
 */

export const getSectionKey = (sectionAddr: Types.SectionAddr): string => (
  `${sectionAddr.sectionId}`
);

export const createSectionAddr = (): Types.SectionAddr => {
  const sectionId = uuid();
  const sectionAddr = { sectionId };
  return sectionAddr;
}

export const compareSectionAddr = (
  srcSectionAddr: Types.SectionAddr,
  dstSectionAddr: Types.SectionAddr,
): boolean => (
  srcSectionAddr.sectionId === dstSectionAddr.sectionId
);

export const debugSectionAddr = (sectionAddr: Types.SectionAddr): string => (
  `sectionId: ${sectionAddr.sectionId}\n`
);

export const getSectionDataTest = (props: {
  sectionAddr: Types.SectionAddr, 
  docState: DocState,
  prefix: string,
}): string => {
  if ( ! environment.dev) {
    return '';
  }

  const {
    sectionAddr,
    docState,
    prefix
  } = props;

  const sectionIdx = docState.content.getSectionIdx(sectionAddr);
  return `${prefix}-${sectionIdx}`;
}

/**
 * 
 * Row
 * 
 */

export const getRowKey = (
  rowAddr: Types.RowAddr
): string => (
  `${rowAddr.sectionId}::` +
  `${rowAddr.rowId}`
);

export const createRowAddr = (sectionAddr: Types.SectionAddr): Types.RowAddr => {
  const rowId = uuid();
  const rowAddr = { 
    ...sectionAddr,
    rowId
  };
  return rowAddr;
}

export const compareRowAddr = (
  srcRowAddr: Types.RowAddr,
  dstRowAddr: Types.RowAddr,
): boolean => (
      srcRowAddr.sectionId === dstRowAddr.sectionId
  && srcRowAddr.rowId === dstRowAddr.rowId
);

export const debugRowAddr = (rowAddr: Types.RowAddr): string => (
  `sectionId: ${rowAddr.sectionId}\n` +
  `rowId: ${rowAddr.rowId}`
);


/**
 * 
 * Cell
 * 
 */

export const getCellKey = (cellAddr: Types.CellAddr): string => (
  `${cellAddr.columnId}::`  +
  `${cellAddr.sectionId}::` +
  `${cellAddr.rowId}`
);

export const compareCellAddr = (
  srcCellAddr: Types.CellAddr,
  dstCellAddr: Types.CellAddr,
): boolean => (
     srcCellAddr.sectionId === dstCellAddr.sectionId
  && srcCellAddr.columnId  === dstCellAddr.columnId
  && srcCellAddr.rowId     === dstCellAddr.rowId
);

export const debugCellAddr = (
  cellAddr: Types.CellAddr
): string => (
  `sectionId: ${cellAddr.sectionId}\n` +
  `rowId: ${cellAddr.rowId}` +
  `columnId: ${cellAddr.columnId}\n`
);

export const getCellDataTest = (props: {
  cellAddr: Types.CellAddr, 
  docState: DocState,
  prefix: string,
}): string => {
  if ( ! environment.dev) {
    return '';
  }

  const {
    cellAddr,
    docState,
    prefix
  } = props;

  const rowIdx    = docState.content.getRowIdxGlobal(cellAddr);
  const columnIdx = docState.content.getColumnIdx(cellAddr);
    
  return `${prefix}-${columnIdx}-${rowIdx}`;
}


/**
 * 
 * Image
 * 
 */

export const compareImageAddr = (
  srcImageAddr: Types.ImageAddr,
  dstImageAddr: Types.ImageAddr,
): boolean => (
      srcImageAddr.sectionId === dstImageAddr.sectionId
  && srcImageAddr.columnId  === dstImageAddr.columnId
  && srcImageAddr.rowId     === dstImageAddr.rowId
  && srcImageAddr.imageId   === dstImageAddr.imageId
);

export const getImageKey = (
  imageAddr: Types.ImageAddr
): string => (""
  + `${imageAddr.columnId}::`
  + `${imageAddr.sectionId}::`
  + `${imageAddr.rowId}::`
  + `${imageAddr.imageId}`
);

export const getImageDataTest = (props: {
  imageAddr: Types.ImageAddr, 
  docState: DocState,
  prefix: string,
}): string => {
  if ( ! environment.dev) {
    return '';
  }

  const {
    imageAddr,
    docState,
    prefix
  } = props;

  const rowIdx    = docState.content.getRowIdxGlobal(imageAddr);
  const columnIdx = docState.content.getColumnIdx(imageAddr);
  const imageIdx  = docState.content.cellImages_getImageIdx(imageAddr);
    
  return `${prefix}-${columnIdx}-${rowIdx}-${imageIdx}`;
}

export const fitImageView = (
  docState: DocState,
  widgetsAddrs: Types.WidgetAddr[],
) => {

  const widgetsBBoxes = widgetsAddrs.map((widgetAddr) => {
    const widgetProps = docState.content.cellImages_image_getWidgetProps(widgetAddr);
    const wigetBBox = getWidgetBBox(widgetProps);
    return wigetBBox;      
  });

  const viewArea = widgetsBBoxes.reduce((acc, bbox) => {
    return {
        x1: Math.min(acc.x1, bbox.x),
        y1: Math.min(acc.y1, bbox.y),
        x2: Math.max(acc.x2, bbox.x + bbox.width),
        y2: Math.max(acc.y2, bbox.y + bbox.height)
    };
  }, {
      x1: Infinity,
      y1: Infinity,
      x2: -Infinity,
      y2: -Infinity
  });

  return viewArea;
}


/**
 * 
 * Widget
 * 
 */

export const compareWidgetAddr = (
  srcWidgetAddr: Types.WidgetAddr,
  dstWidgetAddr: Types.WidgetAddr,
): boolean => (
      srcWidgetAddr.sectionId === dstWidgetAddr.sectionId
  && srcWidgetAddr.columnId  === dstWidgetAddr.columnId
  && srcWidgetAddr.rowId     === dstWidgetAddr.rowId
  && srcWidgetAddr.imageId   === dstWidgetAddr.imageId
  && srcWidgetAddr.widgetId  === dstWidgetAddr.widgetId
);

export const getWidgetKey = (
  widgetAddr: Types.WidgetAddr
): string => (""
  + `${widgetAddr.columnId}::`
  + `${widgetAddr.sectionId}::`
  + `${widgetAddr.rowId}::`
  + `${widgetAddr.imageId}::`
  + `${widgetAddr.widgetId}`
);

export const debugWidgetAddr = (
  widgetAddr: Types.WidgetAddr | null
): string => {

  if (widgetAddr === null ){
    return 'widget addr: null';
  }

  return (""
    + `widget addr:\n`
    + `  sectionId: ${widgetAddr.sectionId}\n`
    + `  columnId: ${widgetAddr.columnId}\n`
    + `  rowId: ${widgetAddr.rowId}\n`
    + `  imageId: ${widgetAddr.imageId}\n`
    + `  widgetId: ${widgetAddr.widgetId}\n`
  );
}

export const isWidgetBoxed = (widgetType: Types.WidgetType) => {
  return [
    Types.WidgetType.ELLIPSE,
    Types.WidgetType.RECTANGLE,
    Types.WidgetType.IMAGE,
    Types.WidgetType.TEXT,
  ].includes(widgetType);
}

export const isWidgetArrow = (widgetType: Types.WidgetType) => {
  return [
    Types.WidgetType.ARROW_PLAIN,
    Types.WidgetType.ARROW_TEXT,
  ].includes(widgetType);
}

export const isWidgetArrowTextLess = (widgetType: Types.WidgetType) => {
  return [
    Types.WidgetType.ARROW_PLAIN,
  ].includes(widgetType);
}

export const isWidgetArrowText = (widgetType: Types.WidgetType) => {
  return [
    Types.WidgetType.ARROW_TEXT,
  ].includes(widgetType);
}

export const isWidgetEditorText = (widgetType: Types.WidgetType) => {
  return [
    Types.WidgetType.TEXT,
    Types.WidgetType.ARROW_TEXT,
  ].includes(widgetType);
}

export const isWidgetSmartLess = (widgetType: Types.WidgetType) => {
  return [
    Types.WidgetType.ARROW_PLAIN,
  ].includes(widgetType);
}


export const getWidgetBBox = (widgetProps: Types.WidgetProps): Area => {
  let area: Area = {
    x: 0, y: 0, width: 0, height: 0
  };

  if (isWidgetBoxed(widgetProps.type)) {
    area = __getWidgetBoxedBBox(widgetProps as Types.WidgetBoxedProps);
  }
  else if (isWidgetArrowTextLess(widgetProps.type)) {
    area = __getWidgetArrowTextLessBBox(widgetProps as Types.WidgetArrowProps);
  }
  else if (isWidgetArrowText(widgetProps.type)) {
    area = __getWidgetArrowTextBBox(widgetProps as Types.WidgetArrowTextProps);
  }
  else {
    console.warn(`Get widget bbox not implement for ${widgetProps.type}`);
  }
  return area;
}

export const getWidgetsBBox = (widgetsProps: Types.WidgetProps[]) => {
  const initValue = {
    max: {
      x: Number.NEGATIVE_INFINITY,
      y: Number.NEGATIVE_INFINITY,
    },
    min: {
      x: Number.POSITIVE_INFINITY,
      y: Number.POSITIVE_INFINITY,
    }
  };

  const minMaxCorrners = widgetsProps.reduce((accumulator, widgetProps) => {

    const bbox = getWidgetBBox(widgetProps);

    accumulator.min.x = Math.min(accumulator.min.x, bbox.x);
    accumulator.min.y = Math.min(accumulator.min.y, bbox.y);
    accumulator.max.x = Math.max(accumulator.max.x, bbox.x + bbox.width);
    accumulator.max.y = Math.max(accumulator.max.y, bbox.y + bbox.height);
    
    return accumulator;
  }, initValue);

  const bbox = {
    x: minMaxCorrners.min.x,
    y: minMaxCorrners.min.y,
    width:  minMaxCorrners.max.x - minMaxCorrners.min.x,
    height: minMaxCorrners.max.y - minMaxCorrners.min.y,
  };

  return bbox;
}

export const __getWidgetBoxedBBox = (
  widgetProps: Types.WidgetBoxedProps
): Area => {
  const position = __getWidgetPositionStyled(widgetProps);
  const size     = __getWidgetSizeStyled(widgetProps);

  const x = position[0];
  const y = position[1];
  const width  = size[0];
  const height = size[1];

  const bbox = {
    x, y, width, height
  };

  return bbox;
}

export const __getWidgetArrowTextLessBBox = (
  widgetProps: Types.WidgetArrowProps
): Area => {
  const startPoint = widgetProps.startPoint;
  const endPoint   = widgetProps.endPoint;

  const xmin = Math.min(startPoint[0], endPoint[0]);
  const xmax = Math.max(startPoint[0], endPoint[0]);

  const ymin = Math.min(startPoint[1], endPoint[1]);
  const ymax = Math.max(startPoint[1], endPoint[1]);

  const arrowShaftWidth  = jtl.css.valueToNumber(widgetProps.style.arrowShaftWidth);
  const borderTotalWidth = jtl.css.getFramingWidth(widgetProps.style);
  const radians = jtl.geometry.calculateAngle(xmin, ymin, xmax, ymax);

  const x = xmin - (arrowShaftWidth / 2 + borderTotalWidth) * Math.cos(radians);
  const y = ymin + (arrowShaftWidth / 2 + borderTotalWidth) * Math.sin(radians);
  const width  = xmax - xmin + (arrowShaftWidth +  2 * borderTotalWidth) * Math.cos(radians);
  const height = ymax - ymin - (arrowShaftWidth +  2 * borderTotalWidth) * Math.sin(radians);

  const bbox = {
    x, y, width, height
  };

  return bbox;
}

export const __getWidgetArrowTextBBox = (
  widgetProps: Types.WidgetArrowTextProps
): Area => {
  const startPoint = widgetProps.startPoint;
  const endPoint   = widgetProps.endPoint;
  const tailSize   = widgetProps.tailSize;

  const borderTotalWidth = jtl.css.getFramingWidth(widgetProps.style);

  const boxLeft  = endPoint[0] - tailSize[0] / 2 - borderTotalWidth;
  const boxRight = boxLeft + tailSize[0] + borderTotalWidth * 2;

  const boxTop    = endPoint[1] - tailSize[1] / 2 - borderTotalWidth;
  const boxBottom = boxTop + tailSize[1] + borderTotalWidth * 2;

  const maxX = Math.max(boxLeft, boxRight, startPoint[0]);
  const minX = Math.min(boxLeft, boxRight, startPoint[0]);

  const maxY = Math.max(boxTop, boxBottom, startPoint[1]);
  const minY = Math.min(boxTop, boxBottom, startPoint[1]);

  const width  = maxX - minX;
  const height = maxY - minY;
  const x = minX;
  const y = minY;
  
  const bbox = {
    x, y, width, height
  };

  return bbox;
}

const __getWidgetPositionStyled = (widgetProps: Types.WidgetBoxedProps) => {
  const position   = widgetProps.position;
  const totalWidth = jtl.css.getFramingWidth(widgetProps.style);

  const positionStyled = [
    position[0] - totalWidth,
    position[1] - totalWidth
  ];

  return positionStyled;
}

const __getWidgetSizeStyled = (widgetProps: Types.WidgetBoxedProps) => {
  const size       = widgetProps.size;
  const totalWidth = jtl.css.getFramingWidth(widgetProps.style);

  const sizeStyled = [
    size[0] + 2 * totalWidth,
    size[1] + 2 * totalWidth
  ] ;

  return sizeStyled;
}


export const cascadeWidgetPosition = (widgetProps: Types.WidgetProps) => {
  const DELTA_MOVE = 10;

  const widgetType = widgetProps.type;
  switch(widgetType) {
    case Types.WidgetType.ARROW_PLAIN : 
    case Types.WidgetType.ARROW_TEXT :  {
      const widgetArrowProps = widgetProps as Types.WidgetArrowProps;
      widgetArrowProps.startPoint = [
        widgetArrowProps.startPoint[0] + DELTA_MOVE,
        widgetArrowProps.startPoint[1] + DELTA_MOVE
      ];
      widgetArrowProps.endPoint = [
        widgetArrowProps.endPoint[0] + DELTA_MOVE,
        widgetArrowProps.endPoint[1] + DELTA_MOVE
      ]
      break;
    }

    case Types.WidgetType.IMAGE :
    case Types.WidgetType.TEXT  :
    case Types.WidgetType.ELLIPSE  :
    case Types.WidgetType.RECTANGLE : {
      const widgetBoxedProps = widgetProps as Types.WidgetBoxedProps;
      widgetBoxedProps.position = [
        widgetBoxedProps.position[0] + DELTA_MOVE,
        widgetBoxedProps.position[1] + DELTA_MOVE
      ];
      break;
    }

    default: {
      const msg = `Unknown widget type ${widgetType}`;
      throw new Error(msg);
    }
  }
}

export const getWidgetStyleTyped = (
  widgetType: Types.WidgetType,
  style: CssStyle
) => {

  const WidgetTypedCSS: {[widgetType in Types.WidgetType]: any} = {
    [Types.WidgetType.ARROW_PLAIN] : {

    },
    [Types.WidgetType.ARROW_TEXT]  : {

    },
    [Types.WidgetType.IMAGE]       : {

    },
    [Types.WidgetType.RECTANGLE]   : {

    },
    [Types.WidgetType.TEXT]        : {

    },
    [Types.WidgetType.ELLIPSE]     : {
      borderRadius: '50%'
    },
  }

  const typedCSS = WidgetTypedCSS[widgetType];
  const updateCSS = {
    ...style,
    ...typedCSS,
  }

  return updateCSS;
}


/**
 * 
 * Marker
 * 
 */
export const getMarkerKey = (
  markerAddr: Types.MarkerAddr
): string => (
  `${markerAddr.columnId}::`  +
  `${markerAddr.sectionId}::` +
  `${markerAddr.rowId}::` +
  `${markerAddr.markerId}`
);


export const compareMarkerAddr = (
  srcMarkerAddr: Types.MarkerAddr,
  dstMarkerAddr: Types.MarkerAddr,
): boolean => (
     srcMarkerAddr.sectionId === dstMarkerAddr.sectionId
  && srcMarkerAddr.columnId  === dstMarkerAddr.columnId
  && srcMarkerAddr.rowId     === dstMarkerAddr.rowId
  && srcMarkerAddr.markerId  === dstMarkerAddr.markerId
);


/**
 * 
 * columnsAutoAdjust
 * 
 */

export const columnsAutoAdjust = (document: DocState) => {
  const columnsAddrs = document.content.getColumnsAddrs();
  const columnsAddrsVisible = columnsAddrs.filter((columnAddr) => {
    const columnProps = document.content.getColumnProps(columnAddr);
    return columnProps.visible;
  });

  const indexColumnAddr = columnsAddrsVisible.find((columnAddr) => {
    const columnType = document.content.getColumnType(columnAddr);
    const isIndex = (columnType === Types.ColumnType.INDEX);
    return isIndex;
  });

  if (indexColumnAddr === undefined) {
    const msg = 'Index column not found';
    throw new Error(msg);
  }

  const indexColumnWidth = document.content.getColumnWidth(indexColumnAddr);

  const columnsNoIdxAddr  = columnsAddrsVisible.filter((columnAddr) => {
    const columnType = document.content.getColumnType(columnAddr);
    const isNotIndex = (columnType !== Types.ColumnType.INDEX);
    return isNotIndex;
  });

  const pageLayout  = document.viewsCommon.getPageLayout();
  const pageMargins = document.viewsCommon.getPageMargins();
  
  const contentSize = Page.getBodySizePx(
    pageLayout.format, 
    pageLayout.orientation, 
    pageMargins
  ) as Size;

  const totalWidth        = contentSize[0] - indexColumnWidth;
  const columnsWidthTotal = columnsNoIdxAddr.reduce((accumulator, columnAddr) => {
    const newValue = accumulator + document.content.getColumnWidth(columnAddr);
    return newValue;
  }, 0);

  const deltaWidth = totalWidth - columnsWidthTotal;

  const columnsWidthRatio = columnsNoIdxAddr.map((columnAddr) => {
    const columnWidth = document.content.getColumnWidth(columnAddr);
    return columnWidth / columnsWidthTotal;
  });

  const columnsWidthInit = columnsNoIdxAddr.map((columnAddr) => {
    const columnWidth = document.content.getColumnWidth(columnAddr);
    return columnWidth;
  });

  columnsNoIdxAddr.forEach((columnAddr, idx) => {
    const widthInit     = columnsWidthInit[idx];
    const deltaRatioed  = columnsWidthRatio[idx] * deltaWidth;
    const widthAdjusted = widthInit + deltaRatioed;
    const update = { width: widthAdjusted };

    document.content.updateColumn(columnAddr, update);
  });
}
