import { atom } from 'recoil';
import { selector } from 'recoil';
import { WidgetsStylesState as State } from 'app/arch/editor-instruction/document/states/loadable/editor-image-widgets-styles';


export const state = atom<State.State | null>({
  key: "editorInstruction_widgetsStyles",
  default: null
});


//--------------------
//
// Selectors
//
export const widgetsStyles = selector({
  key: `editorInstruction_widgetsStyles_styles`,
  get: ({ get }) => {
    const stateObj = get(state);
    if (stateObj === null) return [];

    return stateObj.styles;
  }
});
