import React from 'react';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { useDocState }        from 'app/ui/contexts/document';
import useColumnSelectedSet   from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/column/use-column-selected-set';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import HLS_HeaderCellNameEditComponent from 'app/ui-v2/editor-instruction/views/shared/components/header-cell/header-cell-name-edit';


interface Props {
  columnAddr: Types.ColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellNameEditComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
    children,
  } = props;
  
  const document = useDocState();
  const columnProps = document.content.getColumnProps(columnAddr);

  const setColumnSelected = useColumnSelectedSet();
  const { 
    setContent 
  } = useEditorStatesSetters();

  const handleColumnSelected = () => {
    setColumnSelected({columnAddr});
  }

  const handleColumnNameEditDone = (columnName: string) => {
    const update = {
      name: columnName
    };
    
    document.content.updateColumn(
      columnAddr, 
      update
    );
    setContent();
    document.saveUndo();
  }

  return (
    <HLS_HeaderCellNameEditComponent
      columnName={columnProps.name}
      onColumnSelected={handleColumnSelected}
      onColumnNameEditDone={handleColumnNameEditDone}
    >
      { children }
    </HLS_HeaderCellNameEditComponent>
  );
}
