import deepEqual from 'deep-equal';
import Logger    from 'libs/debug';
import * as Types from './types';


export type EditorState = {
  "root": any
};

export const getEditorInitState = () => {
  const editorState = {
    "root":{
      "children":[{
        "children": [],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "paragraph",
        "version": 1,
        "textFormat": 0,
      }],
      "direction": null,
      "format": "",
      "indent": 0,
      "type": "root",
      "version": 1
    }
  }

  return editorState;
}

export const getEditorInitStateSerial = (): string => {
  const serial = JSON.stringify(getEditorInitState());
  return serial
}



export const validateInitState = (editorState: EditorState): Types.StateValid => {
  const log = Logger.getLexicalTools();

  const savedState = getEditorInitState();
  const structureValid = deepEqual(savedState, editorState, {strict: true});

  const savedStateVer  = savedState.root.version;
  const editorStateVer = editorState.root.version;
  const versionValid   = (savedStateVer === editorStateVer);
  
  log.debug(
    `Lexical initial state\n` +
    `  structure valid:${structureValid}\n` +
    `  version valid:${versionValid}\n`
  );

  const stateValid: Types.StateValid = {
    versionValid,
    structureValid
  };

  return stateValid;
}