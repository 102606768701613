import React from 'react';

import { CellPropsBase } from '../types';

import ColumnCssComponent              from '../parts/column-css';
import CellOverflowClipperComponent    from '../parts/cell-overflow-clipper';
import CellSelectedBorderComponent     from '../parts/cell-selected-border';
import CellSelectComponent             from '../parts/cell-select';
import CellsLineSelectedComponent      from '../parts/cells-line-selected';
import CellMarkersDropComponent        from './cell-markers-drop';
import CellMarkersContextMenuComponent from './cell-markers-context-menu';
import MarkersComponent                from './markers';


interface Props extends CellPropsBase {
}


export const CellMarkersComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
  } = props;

  return (
    <CellSelectedBorderComponent cellAddr={cellAddr} >
      <CellOverflowClipperComponent>
        <CellsLineSelectedComponent cellAddr={cellAddr} >
          <CellSelectComponent cellAddr={cellAddr} >
            <CellMarkersContextMenuComponent cellAddr={cellAddr} >
              <CellMarkersDropComponent cellAddr={cellAddr}>
                <ColumnCssComponent cellAddr={cellAddr} >
                  <MarkersComponent cellAddr={cellAddr} />
                </ColumnCssComponent>
              </CellMarkersDropComponent>
            </CellMarkersContextMenuComponent>
          </CellSelectComponent>
        </CellsLineSelectedComponent>
      </CellOverflowClipperComponent>
    </CellSelectedBorderComponent>
  );
}
