import styled from 'styled-components';
import ButtonBase from 'lego/styles/button';
import TextWrap from 'lego/styles/text-wrap';


export const Button = styled(ButtonBase)`
  width: 200px;
  max-width: unset;
  height: 60px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${props => props.theme.defs.gap.normal};

  padding: 
    ${props => props.theme.defs.padding.normal}
    ${props => props.theme.defs.padding.large}
    ${props => props.theme.defs.padding.normal}
    ${props => props.theme.defs.padding.large}
  ;
`;


export const IconWrap = styled.div`
  position: relative;
  bottom: 2px;

  flex: 0 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;


export const Text = styled(TextWrap)`
  flex: 0 1 auto;
  min-width: 0;


  font-size: ${props => props.theme.defs.font.size.xxlarge};
  font-weight: ${props => props.theme.defs.font.weight.medium};
  font-variant: small-caps;
`;
