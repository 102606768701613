import produce from 'immer';

import CheckerCellEmtpy from './helpers/checker-cell-emtpy';

import * as State from './state';
import * as Types from './types';
import * as Tools from './tools';


export class Releaselogs {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * 
   * Releaselog
   * 
   */

  addReleaselog() {
    const releaselogAddr = Tools.createReleaselogAddr();
    const nextState = State.addReleaselog(
      this._state, 
      releaselogAddr
    );
    this.processNewState(nextState);

    return releaselogAddr;
  }

  setReleaselogEditable(
    releaselogAddr: Types.ReleaselogAddr, 
    editable: boolean
  ) {
    const nextState = State.setReleaselogEditable(
      this._state, 
      releaselogAddr,
      editable
    );
    this.processNewState(nextState);
  }

  updateReleaselogInfo(
    releaselogAddr: Types.ReleaselogAddr,
    update: Types.ReleaseInfoUpdate,
  ) {
    const nextState = State.updateReleaselogInfo(
      this._state, 
      releaselogAddr, 
      update
    );
    this.processNewState(nextState);
  }

  updateReleaselogInfoDescription(
    releaselogAddr: Types.ReleaselogAddr,
    update: Types.ReleaseInfoDescriptionUpdate,
  ) {
    const nextState = State.updateReleaselogInfoDescription(
      this._state, 
      releaselogAddr, 
      update
    );
    this.processNewState(nextState);
  }

  commitReleaselog(revision: number, date: string) {
    const releaselogsAddrs   = this.getReleaselogsAddrs();
    if ( releaselogsAddrs.length === 0 ) {
      const msg = "No logs in releaselogs";
      throw new Error(msg);
    }

    const releaselogActive = this.getReleaselogActive();

    // Remove last row (row adder);
    this.deleteLastRow(releaselogActive);
    this.updateReleaselogInfo(releaselogActive, {
      date,
      revision,
    });
    this.setReleaselogEditable(releaselogActive, false);

    // Now clone all props to new releaselog
    const srcColumnsAddrs = this.getColumnsAddrs(releaselogActive);
    const dstReleaselogAddr = this.addReleaselog();

    srcColumnsAddrs.forEach((srcColumnAddr) => {
      const srcColumnProps = this.getColumnProps(srcColumnAddr);

      const dstColumnAddr = this.addColumn(
        dstReleaselogAddr, 
        srcColumnProps.type, 
        {
          name: srcColumnProps.name,
          width: srcColumnProps.width,
        }
      );

      this.updateColumnCSS(dstColumnAddr, srcColumnProps.css);
      this.updateColumnHeaderCSS(dstColumnAddr, srcColumnProps.header.css);
    });


    this.addRow(dstReleaselogAddr);
    this.addRow(dstReleaselogAddr);

    return dstReleaselogAddr;
  }

  deleteLastRow(releaselogAddr: Types.ReleaselogAddr) {
    this._state = produce(this._state, draft => {

      const rowsAddrs = State.getRowsAddrs(draft, releaselogAddr);
      if (rowsAddrs.length === 0) {
        console.warn(`No rows found`);
        return;
      }

      const rowAddr = rowsAddrs[rowsAddrs.length - 1];
      this.__deleteRow(draft, rowAddr);
    });
  }


  /**
   * 
   * Column
   * 
   */

  addColumn(
    releaselogAddr: Types.ReleaselogAddr, 
    columnType:   Types.ColumnType,
    columnProps?: Types.ColumnPropsUpdate
  ): Types.ColumnAddr {
    const columnAddr = Tools.createColumnAddr(releaselogAddr);

    const nextState = State.addColumn(
      this._state, 
      columnAddr, 
      columnType,
      columnProps
    );

    this.processNewState(nextState);
    return columnAddr;
  }

  addColumnAfter(
    srcColumnAddr: Types.ColumnAddr,
    columnType:   Types.ColumnType,
    columnProps?: Types.ColumnPropsUpdate
  ): Types.ColumnAddr {
    const columnAddr = Tools.createColumnAddr(srcColumnAddr);

    const nextState = State.addColumnAfter(
      this._state, 
      srcColumnAddr,
      columnAddr, 
      columnType,
      columnProps
    );

    this.processNewState(nextState);
    return columnAddr;
  }

  deleteColumn(columnAddr: Types.ColumnAddr) {
    const nextState = State.deleteColumn(
      this._state, 
      columnAddr
    );
    this.processNewState(nextState);
  }
  
  moveColumn(
    srcColumnAddr: Types.ColumnAddr,
    dstColumnAddr: Types.ColumnAddr,
  ) {
    const nextState = State.moveColumn(
      this._state, 
      srcColumnAddr, 
      dstColumnAddr
    );
    this.processNewState(nextState);
  }

  setColumnName(
    columnAddr: Types.ColumnAddr,
    columnName: string,
  ) {
    const nextState = State.setColumnName(
      this._state, 
      columnAddr, 
      columnName
    );
    this.processNewState(nextState);
  }

  updateColumnProps(
    columnAddr: Types.ColumnAddr, 
    update: Types.ColumnPropsUpdate
  ) {
    const nextState = State.updateColumnProps(
      this._state, 
      columnAddr, 
      update
    );
    this.processNewState(nextState);
  }

  updateColumnCSS(
    columnAddr: Types.ColumnAddr,
    cssUpdate: React.CSSProperties
  ) {
    const nextState = State.updateColumnCSS(
      this._state, 
      columnAddr, 
      cssUpdate
    );
    this.processNewState(nextState);
  }

  updateColumnHeaderCSS(
    columnAddr: Types.ColumnAddr,
    cssUpdate: React.CSSProperties
  ) {
    const nextState = State.updateColumnHeaderCSS(
      this._state, 
      columnAddr, 
      cssUpdate
    );
    this.processNewState(nextState);
  }


  /**
   * 
   * Row
   * 
   */

  addRow(
    releaselogAddr: Types.ReleaselogAddr, 
  ): Types.RowAddr {
    const rowAddr = Tools.createRowAddr(releaselogAddr);
    
    const nextState = State.addRow(
      this._state, 
      rowAddr
    );
    this.processNewState(nextState);
    return rowAddr;
  }

  addRowAbove(
    srcRowAddr: Types.RowAddr,
  ): Types.RowAddr {
    const rowAddr = Tools.createRowAddr(srcRowAddr);
    
    const nextState = State.addRowAbove(
      this._state, 
      srcRowAddr,
      rowAddr
    );
    this.processNewState(nextState);

    return rowAddr;
  }

  addRowBelow(
    srcRowAddr: Types.RowAddr,
  ): Types.RowAddr {
    const rowAddr = Tools.createRowAddr(srcRowAddr);
    
    const nextState = State.addRowBelow(
      this._state, 
      srcRowAddr,
      rowAddr
    );
    this.processNewState(nextState);

    return rowAddr;
  }

  deleteRow(rowAddr: Types.RowAddr) {
    this._state = produce(this._state, draft => {
      this.__deleteRow(draft, rowAddr);
    });
  }

  __deleteRow(
    draft: State.State, 
    rowAddr: Types.RowAddr
  ) {
    const rowsAddrs = State.getRowsAddrs(draft, rowAddr);
    const rowsProps = State.getRowsProps(draft, rowAddr);
    
    const rowIdx = State.getRowIdx(draft, rowAddr);
    const rowKey = Tools.getRowKey(rowAddr);

    rowsAddrs.splice(rowIdx, 1);
    delete rowsProps[rowKey];

    //
    // Delete cells
    //
    const columnsAddrs = State.getColumnsAddrs(draft, rowAddr);
    columnsAddrs.forEach((columnAddr) => {
      const cellAddr: Types.CellAddr = {
        releaselogId: rowAddr.releaselogId,
        columnId: columnAddr.columnId,
        rowId: rowAddr.rowId
      }

      State.__deleteCell(draft, cellAddr);
    });
  }



  moveRow(
    srcRowAddr: Types.RowAddr,
    dstRowAddr: Types.RowAddr,
  ) {
    const nextState = State.moveRow(
      this._state, 
      srcRowAddr, 
      dstRowAddr
    );
    this.processNewState(nextState);
  }
  

  /**
   * 
   * Cell
   * 
   */

  cellText_writeEditorState(
    cellAddr: Types.CellAddr,
    editorState: string
  ) {
    const nextState = State.cellText_writeEditorState(this._state, cellAddr, editorState);
    this.processNewState(nextState);
  }


  //----------------------
  // Getters
  //

  /**
   * 
   * Releaselogs
   * 
   */

  getReleaselogsAddrs() {
    return State.getReleaselogsAddrs(this._state);
  }

  getReleaselogsProps() {
    return State.getReleaselogsProps(this._state);
  }

  getReleaselogsChangelogs() {
    return State.getReleaselogsChangelogs(this._state);
  }

  getReleaselogActive(): Types.ReleaselogAddr {
    return State.getReleaselogActive(this._state);
  }

  isReleaselogEmpty(
    releaselogAddr: Types.ReleaselogAddr, 
  ) {
    const columnsAddrs = this.getColumnsAddrs(releaselogAddr);
    const rowsAddrs = this.getRowsAddrs(releaselogAddr);

    const cellChecker = new CheckerCellEmtpy();

    for (let colIdx = 0; colIdx < columnsAddrs.length; colIdx++) {
      const columnAddr = columnsAddrs[colIdx];
      const column = this.getColumnProps(columnAddr);

      for (let rowIdx = 0; rowIdx < rowsAddrs.length; rowIdx++) {
        const rowAddr = rowsAddrs[rowIdx];
      
        const cellAddr: Types.CellAddr = {
          ...columnAddr,
          ...rowAddr,
        };

        const cell = this.getCell(cellAddr);
        const isCellEmpty = cellChecker.isEmpty(cell, column.type);
        if ( ! isCellEmpty ) {
          return false;
        }
      }      
    }

    return true;
  }


  /**
   * 
   * Releaselog
   * 
   */

  getReleaselogProps(releaselogAddr: Types.ReleaselogAddr) {
    return State.getReleaselogProps(
      this._state, 
      releaselogAddr
    );
  }

  getReleaselogChangelog(releaselogAddr: Types.ReleaselogAddr) {
    return State.getReleaselogChangelog(
      this._state, 
      releaselogAddr
    );
  }

  getReleaselogInfo(releaselogAddr: Types.ReleaselogAddr) {
    return State.getReleaselogInfo(
      this._state, 
      releaselogAddr
    );
  }

  getReleaselogIdx(releaselogAddr: Types.ReleaselogAddr) {
    return State.getReleaselogIdx(
      this._state, 
      releaselogAddr
    );
  }

  isReleaselogEditable(
    releaselogAddr: Types.ReleaselogAddr
  ) {
    return State.isReleaselogEditable(this._state, releaselogAddr);
  }

  isReleaselogActive(
    releaselogAddr: Types.ReleaselogAddr
  ) {
    return State.isReleaselogActive(this._state, releaselogAddr);
  }


  /**
   * 
   * Columns
   * 
   */

  getColumnsAddrs(
    releaselogAddr: Types.ReleaselogAddr
  ) {
    return State.getColumnsAddrs(this._state, releaselogAddr);
  }

  getColumnsProps(
    releaselogAddr: Types.ReleaselogAddr
  ) {
    return State.getColumnsProps(this._state, releaselogAddr);
  }


  /**
   * 
   * Column
   * 
   */

  getColumnProps(columnAddr: Types.ColumnAddr) {
    return State.getColumnProps(this._state, columnAddr);
  }

  getColumnIdx(columnAddr: Types.ColumnAddr) {
    return State.getColumnIdx(this._state, columnAddr);
  }

  isColumnIndexType(columnAddr: Types.ColumnAddr) {
    return State.isColumnIndexType(this._state, columnAddr);
  }


  /**
   * 
   * Rows
   * 
   */

  getRowsAddrs(
    releaselogAddr: Types.ReleaselogAddr
  ) {
    return State.getRowsAddrs(this._state, releaselogAddr);
  }

  getRowsProps(
    releaselogAddr: Types.ReleaselogAddr
  ) {
    return State.getRowsProps(this._state, releaselogAddr);
  }

  
  /**
   * 
   * Row
   * 
   */

  getRowIdx(
    rowAddr: Types.RowAddr
  ): number {
    return State.getRowIdx(this._state, rowAddr);
  }

  isRowLast(
    rowAddr: Types.RowAddr
  ): boolean {
    return State.isRowLast(this._state, rowAddr);
  }


  /**
   * 
   * Cells
   * 
   */

  getCells(releaselogAddr: Types.ReleaselogAddr) {
    return State.getCells(this._state, releaselogAddr);
  }


  /**
   * 
   * Cell
   * 
   */

  getCell(cellAddr: Types.CellAddr) {
    return State.getCell(this._state, cellAddr);
  }

  cellText_getEditorState(cellAddr: Types.CellAddr) {
    return State.cellText_getEditorState(this._state, cellAddr);
  }
  
  private processNewState(newState: State.State) {
    return this._state = newState;
  }
}
