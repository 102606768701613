import React from 'react';
import { useRecoilValue } from 'recoil';

import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';
import useCellsRowContext from '../../../../../hooks/use-cells-row-context';

import { MainWrapper } from './styles';
import { Background }  from './styles';


interface Props {
  cellAddr: Types.CellAddr;
  children: React.ReactNode;
}


export const CellsLineSelectedComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children,
  } = props;

  const cellsRowContext = useCellsRowContext();
  const rowAdder = cellsRowContext.rowAdder;

  const columnSelected  = useRecoilValue(UIState_ContentSession.isColumnSelected(cellAddr));
  const rowSelected     = useRecoilValue(UIState_ContentSession.isRowSelected(cellAddr));
  const sectionSelected = useRecoilValue(UIState_ContentSession.isSectionSelected(cellAddr));

  const selected = (
    ! rowAdder && 
    (
      columnSelected || 
      rowSelected    || 
      sectionSelected
    )
  );
 
  return (
    <MainWrapper>
      <Background selected={selected} />
      { children }
    </MainWrapper>
  );
}
  
