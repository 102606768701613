import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { UIState_EditorSession } from 'app/ui/states/editor-instruction';

import * as Types from '../types';
import useDropOn from '../hooks/use-drop-on';
import useDropInCheck from '../hooks/use-drop-in-check';
import { Wrapper as WrapperDefault } from './styles';


interface Props extends Types.DNDDropProps {
}


export const DndDropMouseComponent: React.FC<Props> = (props: Props) => {
  const {
    contextsDefs,
    children
  } = props;

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const dndContext = useRecoilValue(UIState_EditorSession.DNDContext);
  const dragging   = contextsDefs.some((contextDef) => contextDef.dndContext === dndContext);
  
  const checkDropIn = useDropInCheck({wrapperRef});
  const onDrop = useDropOn({contextsDefs, dndContext});

  const Wrapper = (
    props.Wrapper !== undefined ?
    props.Wrapper :
    WrapperDefault
  );

  
  useEffect(() => {
    if ( ! dragging) {
      return;
    }

    __registerHandlers();
    return () => {
      __unregisterHandlers();
    };
  }, [dragging]);


  const __registerHandlers = () => {
    window.document.addEventListener("pointerup", handlePointerUp);
  }

  const __unregisterHandlers = () => {
    window.document.removeEventListener("pointerup", handlePointerUp);
  }

  const handlePointerUp = (event: PointerEvent) => {
    if ( ! dragging ) {
      return;
    }

    const x = event.clientX;
    const y = event.clientY;

    if (checkDropIn(x, y)) {
      onDrop();
    }
  }


  return (
    <Wrapper ref={wrapperRef} >
    { children }
    </Wrapper>
  );
}
