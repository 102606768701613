import { useRecoilValue } from "recoil";

import environment from "app/environment";
import { useInstruction }    from "app/ui/hooks/editor-instruction/use-instruction";
import { UIState_HeaderMeta } from "app/ui/states/editor-instruction";
import useRepo from "app/ui-v2/editor-instruction/hooks/use-repo";


//---------------------------
// 
// Hook Use Logo URL
// 

export const useLogoUrl = () => {
  const repo = useRepo();
  const instruction = useInstruction();
  const logoImage   = useRecoilValue(UIState_HeaderMeta.logoImage);

  let logoUrl: string | null = null;

  if ( logoImage !== null ) {
    logoUrl = environment.backend.getInstructionLogoUrl(
      repo.id !,
      instruction.id !,
      logoImage.filename
    );
  }

  return logoUrl;
}
