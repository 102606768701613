import React from 'react';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import * as Tools from 'app/arch/editor-instruction/document/states/persistent/content/tools';

import { useDocState }      from 'app/ui/contexts/document';
import useSelectedReset     from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useColumnSelectedSet from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/column/use-column-selected-set';

import { MainWrapper } from './styles';


interface Props {
  columnAddr: Types.ColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
    children,
  } = props;

  const document = useDocState();
  const resetAllSelected = useSelectedReset();
  const setColumnSelected = useColumnSelectedSet();
  
  const handleSelectColumn = (event: React.MouseEvent) => {
    event.stopPropagation();

    const selectedColumn = document.contentSession.getColumnSelected();
    const isSelected = (
      selectedColumn &&
      Tools.compareColumnAddr(columnAddr, selectedColumn)
    );

    if ( isSelected ) {
      return;
    }

    resetAllSelected();
    setColumnSelected({columnAddr});
  }

  return (
    <MainWrapper onPointerDown={handleSelectColumn}>
      { children }
    </MainWrapper>
  );
}
  
