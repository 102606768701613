import React from 'react';
import useDebugPointWatch from '../../hooks/use-debug-point-watch';
import { Point } from './styles';


interface Props {
}


export const DebugPointComponent: React.FC<Props> = (props: Props) => {
  const debugPoint = useDebugPointWatch();

  if ( ! debugPoint ) {
    return null;
  }

  return (
    <Point
      $left={debugPoint.x}
      $top={debugPoint.y}
    />
  );
}
