import React from 'react';
import { useEffect } from 'react';
import { useState  } from 'react';
import { useRef    } from 'react';

import { CssStyle }     from 'app/arch/editor-instruction/css-styles';
import { CssStylePack } from 'app/arch/editor-instruction/css-styles';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }  from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import ContextMenuStyleComponent from '../context-menu-style';

import { PanelWidgetStyle } from './styles';
import { StyleItem }        from './styles';
import { StyleItemWrapper } from './styles';
import { StylesWrapper }    from './styles';
import { StyleContextMenuStyle  } from './styles';
import { StyleContextMenuStyleRel } from './styles';


interface Props {
  widgetAddr: ContentTypes.WidgetAddr | null;
  styles: any;
  deleteDisabled?: boolean;
}


export const StylesListComponent: React.FC<Props> = (props: Props) => {
  const { 
    widgetAddr, 
    styles, 
    deleteDisabled
  } = props;

  const document = useDocState();

  const {
    setContent
  } = useEditorStatesSetters();

  const [
    styleSelected, 
    setStyleSelected
  ] = useState<CssStylePack | null>(null);

  const styleBackupRef = useRef<CssStyle | null>(null);

  useEffect(() => {
    if (widgetAddr === null) {
      return;
    }

    const style = document.content.cellImages_image_widget_getStyle(widgetAddr);
    styleBackupRef.current = style;
  }, [widgetAddr]);


  const stylesSorted = [...styles].sort((a, b) => {
    const aa = a.name?? '';
    const bb = b.name?? '';
    return aa.localeCompare(bb, undefined, { numeric: true });
  });

  const setDefaultWidgetStyle = (stylePack: CssStylePack) => {
    document.editorImageSettings.setWidgetStyle(stylePack.style);
  }

  const setWidgetSelectedStyle = (stylePack: CssStylePack) => {
    if (widgetAddr === null) {
      return;
    }

    document.content.cellImages_image_widget_setStyle(
      widgetAddr,
      stylePack.style
    );

    document.saveUndo();
    setContent();
  }

  const handleSelectStyle = (stylePack: CssStylePack) => {
    setDefaultWidgetStyle(stylePack);
    setWidgetSelectedStyle(stylePack);
  }

  const handlePointerEnterStyle = (stylePack: CssStylePack) => {
    setStyleSelected(null);
    applyStyle(widgetAddr!, stylePack.style);
  }
  
  const handlePointerLeaveStyle = (stylePack: CssStylePack) => {
    if (styleSelected !== null) {
      return;
    }
    applyStyle(widgetAddr!, styleBackupRef!.current!);
  }
  
  const handleStyleSelected = (stylePack: CssStylePack) => {
    setStyleSelected(stylePack);
  }

  const applyStyle = (
    widgetAddr: ContentTypes.WidgetAddr, 
    style: CssStyle
  ) => {
    document.content.cellImages_image_widget_setStyle(widgetAddr, style);
    setContent();
  }

  const handleContextMenuClose = () => {
    setStyleSelected(null);
    applyStyle(widgetAddr!, styleBackupRef.current!);
  }

  const handleStyleApplied = (stylePack: CssStylePack) => {
    styleBackupRef.current = stylePack.style;
  }

  const renderStyle = (stylePack: CssStylePack, idx: number) => {

    return (
      <StyleItemWrapper
        key={idx}
      >

      {
        styleSelected === stylePack &&
        <StyleContextMenuStyleRel>
          <StyleContextMenuStyle>
            <ContextMenuStyleComponent 
              stylePack={stylePack}
              widgetAddr={widgetAddr}
              deleteDisabled={deleteDisabled}
              onClose={handleContextMenuClose}
              onStyleApplied={handleStyleApplied}
            />
          </StyleContextMenuStyle>
        </StyleContextMenuStyleRel>
      }

        <StyleItem
          selected={styleSelected === stylePack}
          onClick={() => handleStyleSelected(stylePack)}
          onContextMenu={() => handleStyleSelected(stylePack)}
          onPointerEnter={() => handlePointerEnterStyle(stylePack)}
          onPointerLeave={() => handlePointerLeaveStyle(stylePack)}
        >
          {stylePack.name}
        </StyleItem>

      </StyleItemWrapper>

    );
  }

  const renderStyles = (styles: CssStylePack[]) => {
    const stylesComponent = stylesSorted.map((stylePack: CssStylePack, idx: number) => {
      return renderStyle(stylePack, idx);
    })

    return (
      <PanelWidgetStyle>
        <StylesWrapper>
          { stylesComponent }
        </StylesWrapper>
      </PanelWidgetStyle>
    );
  }
  
  return (
    <>
      { 
        styles.length !== 0 &&
        renderStyles(styles)
      }
    </>
  );
}
  
