import jtl from "tools/jtl";

import { Position } from "app/arch/types";
import { Size }     from "app/arch/types";
import { CssStyle } from "app/arch/editor-instruction/css-styles";
import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { ContentTools } from "app/arch/editor-instruction/document/states/persistent/content";

import { SmartLines } from "./smart-lines";


export class SmartLinesWidgets extends SmartLines {
  loadCustom(
    size: Size, 
    position: Position, 
    widgetStyle: CssStyle
  ) {
    if (position === undefined ) {
      console.warn("Loading smart lines from widgets, problem with position");
      return false;
    }

    if (size === undefined ) {
      console.warn("Loading smart lines from widgets, problem with size");
      return false;
    }

    // Content lines - no background, no outline
    {
      // this.addLines(size, position);
    }

    // Content + background + ouline
    {
      const frameWidth = jtl.css.getFramingWidth(widgetStyle);
      const size_     = size.map((value: number) => value + 2 * frameWidth) as Size;
      const position_ = position.map((value: number) => value - frameWidth) as Position;

      this.addLines(size_, position_);
    }

    return true;
  }
  
  loadWidget(widgetProps: ContentTypes.WidgetProps) {
    let ok = true;
    if (ContentTools.isWidgetArrowText(widgetProps.type)) {
      const widgetArrowProps = widgetProps as ContentTypes.WidgetArrowTextProps;
      ok = this.loadCustom(
        widgetArrowProps.tailSize, 
        widgetArrowProps.endPoint, 
        widgetArrowProps.style
      );
    }
    else if (ContentTools.isWidgetArrowTextLess(widgetProps.type)) {
      // skip
    }
    else if (ContentTools.isWidgetBoxed(widgetProps.type)) {
      const widgetBoxedProps = widgetProps as ContentTypes.WidgetBoxedProps;

      ok = this.loadCustom(
        widgetBoxedProps.size, 
        widgetBoxedProps.position, 
        widgetBoxedProps.style
      );
    }
    else {
      console.warn(`Widget type not implemented ${widgetProps.type}`);
    }

    if ( ! ok ) {
      console.warn(`Problem with widget ${widgetProps.type}`);
    }

    return ok;
  }

  loadWidgets(widgetsProps: ContentTypes.WidgetProps[]) {
    this.reset();

    widgetsProps.forEach((widgetProps) => {
      const ok = this.loadWidget(widgetProps);
    });

    // const horizontalLen = this._lines.horizontal.length;
    // const verticalLen   = this._lines.vertical.length;

    // console.log(`Loaded hor: ${horizontalLen}, ver: ${verticalLen}`);
    this.sort();
  }
}
