import { useRecoilValue } from "recoil";
import { UIDebugPoint } from "../states";


const useDebugPointWatch = () => {
  const debugPoint = useRecoilValue(UIDebugPoint.point);
  return debugPoint;
}


export default useDebugPointWatch;