import React from 'react';
import { useState } from 'react';

import NameInputComponent from './name-input';
import { MainWrapper } from './styles';


interface Props {
  name: string;
  onEditStart?:  () => void;
  onEditCancel?: () => void;
  onEditDone?:   (newName: string) => void;
  children: React.ReactNode;
  Input?: React.ElementType;
}


export const NameEditComponent: React.FC<Props> = (props: Props) => {
  const {
    name,
    onEditStart,
    onEditCancel,
    onEditDone,
    children,
    Input,
  } = props;

  const [
    nameEditing, 
    setNameEditing
  ] = useState(false);
  
  const handleNameEdit = () => {
    setNameEditing(true);
    onEditStart?.();
  }

  const handleDone = (newColumnName: string) => {
    onEditDone?.(newColumnName);
    setNameEditing(false);
  }

  const handleCancel = () => {
    onEditCancel?.();
    setNameEditing(false);
  }

  const renderNameEditing = () => (
    <NameInputComponent
      name={name}
      onCancel={handleCancel}
      onDone={handleDone}
      Input={Input}
    />
  );

  const render = () => (
    <MainWrapper onDoubleClick={handleNameEdit}>
      { children }
    </MainWrapper>
  );

  return (
    <>
     { ! nameEditing && render() }
     { nameEditing   && renderNameEditing() }
    </>
  );
}
