import React from 'react';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { useDocState }        from 'app/ui/contexts/document';
import { DNDContext }         from 'app/ui-v2/editor-instruction/dnd-context';
import DNDDropComponent       from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';

import { DropWrapper } from './styles';


interface Props {
  columnAddr: Types.ColumnAddr;
  disabled: boolean;
  children: React.ReactNode;
}


export const HeaderCellDropComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
    disabled,
    children
  } = props;


  if (disabled) {
    return children;
  }

  return (
    <EnabledComponent 
      columnAddr={columnAddr} 
      disabled={disabled} 
    >
      { children }
    </EnabledComponent>
  );
}



export const EnabledComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
    children
  } = props;

  const document  = useDocState();
  const getDNDData = useDNDDataGet();

  const {
    setContent
  } = useEditorStatesSetters();
  
  const handleDrop = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const droppedColumnAddr = data.columnAddr;

    document.content.moveColumn(droppedColumnAddr, columnAddr);
    document.saveUndo();
    setContent();
  }

  const contextsDefs = [{
    dndContext: DNDContext.viewContent.table.column,
    onDropV2: handleDrop
  }];

  return (
    <DNDDropComponent 
      contextsDefs={contextsDefs} 
      Wrapper={DropWrapper}
    >
      { children }
    </DNDDropComponent>
  );
}
