import React         from 'react';
import { useRef }    from 'react';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import useIsKeyDownStated from 'lego/components/key-bindings/hooks/use-is-key-down-stated';
import { DraggerUpdate }  from 'lego-v2/dragger/arch';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { settings }            from 'app/configs';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';

import ResizerWidgetComponent from '../resizer-widget';
import { ImageScaleUpdate }   from '../types';
import { ImageViewUpdate }    from '../types';
import { Detacher }   from './styles';
import { Positioner } from './styles';


interface Props {
  imageAddr: ContentTypes.ImageAddr;
  onUpdateStart: () => void;

  viewArea: ContentTypes.ImageViewArea;
  onViewAreaUpdate:     (update: ImageViewUpdate) => void;
  onViewAreaUpdateDone: (update: ImageViewUpdate) => void;

  viewScale: number;
  onViewScaleUpdate:     (update: ImageScaleUpdate) => void;
  onViewScaleUpdateDone: (update: ImageScaleUpdate) => void;
}


export const ResizerComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
    onUpdateStart,

    viewArea, 
    onViewAreaUpdate, 
    onViewAreaUpdateDone,

    viewScale,
    onViewScaleUpdate,
    onViewScaleUpdateDone,
  } = props;

  const isImageSelected = useRecoilValue(UIState_ContentSession.cellImages_isImageSelected(imageAddr));
  const disabled = ! isImageSelected;

  const viewAreaRef       = useRef<ContentTypes.ImageViewArea>(viewArea);
  const viewScaleRef      = useRef<number>(viewScale);
  const lastDraggerUpdate = useRef<DraggerUpdate | null>(null);
  const isResizing        = useRef(false);

  const resizeViewAreaKeyDown = useIsKeyDownStated({
    key: settings.actionKey.imageResizeModeToggle,
    fallThrough: true 
  });


  //----------------------
  // Toggle resizer mode
  // during resizing
  //
  useEffect(() => {
    if ( ! isResizing.current ) { return; }
    if (lastDraggerUpdate.current === null) { return; }

    if (resizeViewAreaKeyDown) {
      // First reset view scale 
      // to its initial settings
      const scale  = viewScaleRef.current;
      const update = { scale };
      onViewScaleUpdate(update);

      // Then use last dragger update
      // to update view area
      // handleViewAreaUpdate(lastDraggerUpdate.current!);
    }
    else {
      // First reset view area
      // to its initial settings
      const viewArea = viewAreaRef.current;
      const update = { viewArea };
      onViewAreaUpdate(update);

      // Then use last dragger update
      // to update view scale
      handleViewScaleUpdate(lastDraggerUpdate.current!);
    }
  }, [resizeViewAreaKeyDown]);


  const calcViewArea = (update: DraggerUpdate) => { 
    const x1 = viewAreaRef.current.x1;
    const y1 = viewAreaRef.current.y1;

    const x2 = viewAreaRef.current.x2 + update.position[0] / viewScale;
    const y2 = viewAreaRef.current.y2 + update.position[1] / viewScale;

    return { x1, y1, x2, y2 };
  }

  const calculateViewScale = (update: DraggerUpdate) => { 
    const {x1, y1, x2, y2} = viewAreaRef.current;

    const width  = (x2 - x1) * viewScaleRef.current;
    const height = (y2 - y1) * viewScaleRef.current;

    const {
      newWidth, 
      newHeight,
    } = calculateSize(update);

    const xScale = newWidth  / width;
    const yScale = newHeight / height;
    const scale  = Math.max(xScale, yScale);

    return scale * viewScaleRef.current;
  }

  const calculateSize = (update: DraggerUpdate) => { 
    const {x1, y1, x2, y2} = viewAreaRef.current;

    const width  = (x2 - x1) * viewScaleRef.current;
    const height = (y2 - y1) * viewScaleRef.current;

    const newWidth  = width  + update.delta.x;
    const newHeight = height + update.delta.y;

    return {
      newWidth, 
      newHeight
    };
  }

  //----------------------
  //
  // View scale updates
  //
  const handleViewScaleUpdate = (draggerUpdate: DraggerUpdate) => {
    const scale = calculateViewScale(draggerUpdate);
    const update = { scale };
    onViewScaleUpdate(update);
  }

  const handleViewScaleUpdateDone = (draggerUpdate: DraggerUpdate) => {
    handleViewScaleUpdate(draggerUpdate);

    const scale = calculateViewScale(draggerUpdate);
    const update = { scale };
    onViewScaleUpdateDone(update);
  }


  //----------------------
  //
  // View area updates
  //
  const handleViewAreaUpdate = (draggerUpdate: DraggerUpdate) => {
    const viewArea = calcViewArea(draggerUpdate);
    const update = { viewArea };
    onViewAreaUpdate(update);
  }

  const handleViewAreaUpdateDone = (draggerUpdate: DraggerUpdate) => {
    handleViewAreaUpdate(draggerUpdate);

    const viewArea = calcViewArea(draggerUpdate);
    const update = { viewArea };
    onViewAreaUpdateDone(update);
  }


  //-------------------
  // Dragger updates
  //
  const handleUpdateResizeStart = () => {
    viewAreaRef.current  = viewArea;
    viewScaleRef.current = viewScale;
    isResizing.current   = true;

    onUpdateStart?.();
  }   

  const handleUpdateResize = (update: DraggerUpdate) => {
    lastDraggerUpdate.current = update;
    if ( resizeViewAreaKeyDown ) {
      // console.log('handleViewAreaUpdate');
      handleViewAreaUpdate(update);
    }
    else {
      // console.log('handleViewScaleUpdate');
      handleViewScaleUpdate(update);
    }    
  }

  const handleUpdateResizeDone = (update: DraggerUpdate) => {
    if ( resizeViewAreaKeyDown ) {
      handleViewAreaUpdateDone(update);
    }
    else {
      handleViewScaleUpdateDone(update);
    }  

    lastDraggerUpdate.current = null;
    isResizing.current = false;
  }

  return (
    <Positioner>
      <Detacher>
        <ResizerWidgetComponent
          disabled={disabled}
          
          onUpdateResizeStart={handleUpdateResizeStart}
          onUpdateResize={handleUpdateResize}
          onUpdateResizeDone={handleUpdateResizeDone}
        />
      </Detacher>
    </Positioner>
  );
}
  
